/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
    Card, CardBody, CardHeader,
    Container, Media,
    Row, Table,
} from "reactstrap";
import Header from "../../components/Headers/Header.js";
import AudioPlayer from "react-h5-audio-player";
import {CopyToClipboard} from "react-copy-to-clipboard";
import moment from "moment/moment";
import {axiosGet, axiosPost} from "../../util/apiConfig";
import {Device} from "@twilio/voice-sdk";

const USER_STATE = {
    CONNECTING : "Connecting",
    READY : "Ready",
    ON_CALL : "On call",
    OFFLINE : "Offline",
}


class Listing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
   const classId = localStorage.getItem("classId");
    this.setState(
      {
        allSessionData: [],
        allCounts: [],
        voiceMessageList: [],
        userState: USER_STATE.OFFLINE,
        notificationCallModal: false,
        messageStates: {},
        teachersList: [],
        failedPayments: [],
        unMatchedPayments: [],
        classId: classId ? classId : null,
        activeCard: "active",
      },
      () => {
          this.getVoiceMessage();
          this.getAllTeachers();
          this.getAllFailedPayments();
          this.getAllUnMatchedEmailsSection();
      }
    );
  }

  getVoiceMessage = () => {
        const { activeCard } = this.state;
        // API --
        axiosGet(`sales/get-sales-followup-voice-message?status=${activeCard}`)
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        voiceMessageList: response.data || [],
                    });
                } else {
                    this.setState({
                        voiceMessageList: [],
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    voiceMessageList: [],
                });
            });
    };

  getAllFailedPayments = () => {
        axiosGet(`payment/get-failed-payments`)
            .then((response) => {
                if (!response.isError) {
                    const failedPayments = response.data.data || [];
                    const failedPaumentsList = failedPayments.filter((payment) => payment.status === 'failed');
                    console.log(failedPaumentsList, 'failedPaumentsList')
                    this.setState({
                        failedPayments: failedPaumentsList || [],
                    });
                } else {
                    this.setState({
                        failedPayments: [],
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    failedPayments: [],
                });
            });
    };

  getAllUnMatchedEmailsSection = () => {
        axiosGet(`payment/unmatched-emails`)
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        unMatchedPayments: response.data || [],
                    });
                } else {
                    this.setState({
                        unMatchedPayments: [],
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    unMatchedPayments: [],
                });
            });
    };


    clientConnect = (index) => {
        let { voiceMessageList } = this.state;
        let selectedNotification = voiceMessageList[index];
        this.setState({
            notificationCallModal: true,
            selectedNumber: selectedNotification.mobile || ''
        });
    };

    rejectCall = ()=>{
        const {device, selectedNumber} = this.state
        device.emit("disconnect");
        device.emit("cancel");
        device.emit("reject");
        device.disconnectAll({params: {To: selectedNumber} });
    }

    handleCall = async (sale, index) => {
        const confirm = window.confirm(`Are you sure want to call? \nPlease verify details,\nMobile: ${sale.mobile}, `)
        if(!confirm){
            return false;
        }
        const {device, userType } = this.state;
        device.emit("connect");
        device.connect({params: {To: sale.mobile, userType } }).then((call) => {
            call.on("accept", () => {
                this.updateMessageState(index, USER_STATE.ON_CALL);
                console.log("call accepted");
            });
            call.on("disconnect", () => {
                console.log("The call has been disconnected.");
                this.updateMessageState(index, USER_STATE.READY);
            });
            call.on("reject", () => {
                console.log("The call was rejected.");
            });
            call.on("cancel", () => {
                console.log("The call was rejected.");
                this.updateMessageState(index, USER_STATE.READY);
            });
        })
    };

    callInit = (sale, index) => {
        axiosPost('sales/token', {})
            .then((response) => {
                if (!response.isError) {
                    this.setState(
                        {
                            loading: false
                        },
                        () => {
                            const token = response.data.token;
                            const device = new Device(token, {
                                logLevel: 1,
                                codecPreferences: ["opus", "pcmu"],
                            });
                            device.register();
                            this.setState({device})
                            device.addListener("connect", (device) => {
                                console.log("Connect event listener added .....");
                                return device;
                            });
                            device.on("registered", () => {
                                console.log("Agent registered");
                                this.setState({userState: USER_STATE.READY});
                            });
                            device.on("connect", (connection) => {
                                console.log("Call connect");
                                this.setState({userState: USER_STATE.ON_CALL})
                            });
                            device.on("disconnect", () => {
                                console.log("Disconnect event");
                                this.setState({userState: USER_STATE.READY})
                            });

                            setTimeout(()=>{
                                this.handleCall(sale, index)
                            }, 1000)

                            return () => {
                                device.destroy();
                                this.setState({device: undefined})
                                this.setState({userState: USER_STATE.OFFLINE})
                            };
                        }
                    );
                } else {
                    this.setState({
                        loading: false,
                        errorModal: true,
                        modalTitle: 'Sorry!',
                        message: 'Something is wrong. Try again later.',
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    errorModal: true,
                    modalTitle: 'Sorry!',
                    message: 'Something is wrong. Try again later.',
                });
                console.log(error);
            });
    }

    updateMessageState = (index, newState) => {
        this.setState(prevState => ({
            messageStates: {
                ...prevState.messageStates,
                [index]: newState
            }
        }));
    };

    getAllTeachers = () => {
        let url = 'teacher/list-of-all-teacher?status=Coach Pending';
        const { classId } = this.state;
        if (classId) {
            url = `teacher/list-of-all-teacher/${classId}?status=Coach Pending`;
        }
        axiosGet(url, {} )
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        teachersList: response.data?.result || []
                    });
                } else {
                    this.setState({
                        teachersList: [],
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    teachersList: [],
                });
            });
    };

  render() {
    let {
    voiceMessageList,
    teachersList,
    failedPayments,
    unMatchedPayments,
    activeCard,
    } = this.state;

    return (
      <>
        <Header  />
        {/* Page content */}
         {/*Create two box in row*/}
        <Container fluid>
            <Row>
              <div className="col col-6 ">
                    <div className='col'>
                        <Card className='shadow' >
                            <CardHeader className='border-0'>
                                <h3 className='mb-0'>Calls</h3>
                            </CardHeader>
                            <div className="col-md-4 text-center" style={{ float: "left", paddingBottom: '20px' }}>
                                <ul className="nav nav-pills" >
                                    <li style={{cursor: "pointer"}} className="nav-item">
                                        <a
                                            className={`nav-link ${
                                                activeCard == "active" ? "active" : ""
                                            }`}
                                            onClick={() => {
                                                this.setState(
                                                    {activeCard: "active", page: 1},
                                                    () => {
                                                        this.getVoiceMessage();
                                                    }
                                                );
                                            }}
                                        >
                                            Incoming
                                        </a>
                                    </li>
                                    <li style={{cursor: "pointer"}} className="nav-item">
                                        <a
                                            className={`nav-link ${
                                                activeCard == "inactive" ? "active" : ""
                                            }`}
                                            onClick={() => {
                                                this.setState(
                                                    {activeCard: "inactive", page: 1},
                                                    () => {
                                                        this.getVoiceMessage();
                                                    }
                                                );
                                            }}
                                        >
                                            Outgoing
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <CardBody>
                                <Table
                                    className='align-items-center table-flush cp;'
                                    responsive
                                    style={{ marginBottom: '100px', height: '500px', overflowY: 'scroll', display: 'block'}}
                                >
                                    <thead className='thead-light'>
                                    <tr>
                                        <th scope='col'></th>
                                        <th scope='col'>Mobile Number</th>
                                        <th scope='col'>Time</th>
                                        <th scope='col'>Audio</th>
                                        <th scope='col'>Link</th>
                                        <th scope='col'>Status</th>

                                        {/*<th scope='col'>Notification Sent</th>*/}
                                        <th scope='col' />
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {voiceMessageList &&
                                        voiceMessageList.map((value, index) => {
                                            const isActive = Object.values(this.state.messageStates).some(
                                                state => state === 'Connecting' || state === 'On call'
                                            )
                                            const userStateForMessage = this.state.messageStates[index] || USER_STATE.OFFLINE;
                                            return (
                                                value.isActive && (
                                                    <tr key={index}>
                                                        <td  className={'table-column-width'} >
                                                            {
                                                                (userStateForMessage === 'Connecting' || userStateForMessage === 'On call') ? (
                                                                    <button
                                                                        className={'btn btn-danger'}
                                                                        onClick={() => this.rejectCall(value.mobile)}
                                                                    >Reject
                                                                    </button>
                                                                ): (
                                                                    <button
                                                                        className={'btn btn-white'}
                                                                        disabled={isActive}
                                                                        onClick={() =>this.callInit(value, index)}
                                                                    ><i className="fa fa-phone" style={{fontSize:'20px'}} ></i>
                                                                    </button>
                                                                )
                                                            }
                                                            <button onClick={() => this.clientConnect(index)} className={'btn btn-white'}>
                                                                <i className="fa fa-comments" style={{fontSize:'20px'}} aria-hidden="true" ></i>
                                                            </button>
                                                        </td>
                                                        <td className={'table-column-width'}>
                                                            {value.mobile && (
                                                                <a href={"tel:" + value.mobile}>{value.mobile}</a>
                                                            )}{" "}
                                                        </td>
                                                        <td  className={'table-column-width'}>
                                                            <Media className='align-items-center'>
                                                                <Media>
                                          <span className='mb-0 text-sm'>
                                            {value.createdAt
                                                ? moment(value.createdAt).format('ll hh:mm A')
                                                : ''}
                                          </span>
                                                                </Media>
                                                            </Media>
                                                        </td>
                                                        <td  className={'table-column-width'}>
                                                            {
                                                                value.fileName ? (
                                                                    <AudioPlayer
                                                                        autoPlay={false}
                                                                        autoPlayAfterSrcChange={false}
                                                                        src={value.fileName}
                                                                        onPlay={e => console.log("onPlay")}
                                                                        // other props here
                                                                    />
                                                                ): (
                                                                    <></>
                                                                )
                                                            }
                                                        </td>
                                                        <td className={'table-column-width'}>
                                                            {
                                                                value.fileName ? (
                                                                    <CopyToClipboard text={value.fileName}>
                                                                        <i
                                                                            style={{cursor: 'pointer'}}
                                                                            className='BtnFill'
                                                                            color='success'
                                                                        ><i className='fa fa-copy'></i>
                                                                        </i>
                                                                    </CopyToClipboard>
                                                                ):(
                                                                    <></>
                                                                )
                                                            }
                                                        </td>
                                                        <td className={'table-column-width'}>
                                                            {value.callStatus && (
                                                                <span>{value.callStatus}</span>
                                                            )}{" "}
                                                        </td>

                                                    </tr>
                                                )
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </div>
              </div>
              <div className="col col-6">
                  <Card className='shadow' >
                          <CardHeader className='border-0'>
                              <h3 className='mb-0'>Pending Makeup Class Coaches</h3>
                          </CardHeader>
                          <CardBody>
                              <Table
                                  className='align-items-center table-flush cp;'
                                  responsive

                                  style={{ marginBottom: '100px', height: '500px', overflowY: 'scroll', display: 'block'}}
                              >
                                  <thead className='thead-light'>
                                  <tr>
                                      <th scope='col'>Name</th>
                                      <th scope='col'>Pending</th>

                                      {/*<th scope='col'>Notification Sent</th>*/}
                                      <th scope='col' />
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {teachersList &&
                                      teachersList.map((value, index) => {
                                          return (
                                              <tr key={index}>
                                                  <td className={'table-column-width'}>
                                                      {value?.name || ''}
                                                  </td>
                                                  <td className={'table-column-width'}>
                                                      {value?.count}
                                                  </td>
                                              </tr>
                                          );
                                      })}
                                  </tbody>
                              </Table>
                          </CardBody>
                      </Card>
              </div>
            </Row>
            <hr className='my-10' style={{border:' 1px solid #a69a9a'}}/>
            <Row>
                <div className="col col-6 ">
                    <div className='col'>
                        <Card className='shadow' >
                            <CardHeader className='border-0'>
                                <h3 className='mb-0'>Un-Matched</h3>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    className='align-items-center table-flush cp;'
                                    responsive
                                    style={{ marginBottom: '100px', height: '500px', overflowY: 'scroll', display: 'block'}}
                                >
                                    <thead className='thead-light'>
                                    <tr>
                                        <th scope='col'>Email</th>
                                        <th scope='col'>Section Name</th>
                                        {/*<th scope='col'>Notification Sent</th>*/}
                                        <th scope='col' />
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {unMatchedPayments &&
                                        unMatchedPayments.map((value, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className={'table-column-width'}>
                                                        {value?.email || ''}
                                                    </td>
                                                    <td className={'table-column-width'}>
                                                        {value?.name || ''}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="col col-6">
                    <Card className='shadow' >
                        <CardHeader className='border-0'>
                            <h3 className='mb-0'>Failed Payments</h3>
                        </CardHeader>
                        <CardBody>
                            <Table
                                className='align-items-center table-flush cp;'
                                responsive

                                style={{ marginBottom: '100px', height: '500px', overflowY: 'scroll', display: 'block'}}
                            >
                                <thead className='thead-light'>
                                <tr>
                                    <th scope='col'>Name</th>
                                    <th scope='col'>Email</th>
                                    <th scope='col'>Date</th>
                                    <th scope='col'>Amount</th>
                                    <th scope='col'>Status</th>

                                    {/*<th scope='col'>Notification Sent</th>*/}
                                    <th scope='col' />
                                </tr>
                                </thead>
                                <tbody>
                                {failedPayments &&
                                    failedPayments.map((value, index) => {
                                        const currencyFormatter = new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        });
                                        return (
                                            <tr key={index}>
                                                <td className={'table-column-width'}>
                                                    {value?.billing_details?.name || value?.name || ''}
                                                </td>
                                                <td className={'table-column-width'}>
                                                    {value?.billing_details?.email || value?.receipt_email || ''}
                                                </td>
                                                <td className={'table-column-width'}>{moment.unix(value.created).format('YYYY-MM-DD')}</td>
                                                <td>{currencyFormatter.format(value.amount / 100)}</td>
                                                <td className={'table-column-width'}>{value?.status}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
      </>
    );
  }
}

export default Listing;
