/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
  Form,
  FormGroup,
  InputGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {axiosGet, axiosPost} from "util/apiConfig.js";
import {
  privateSection,
  dateForPaymentListing,
  halfClass,
  fullClass,
} from "util/constance";
import moment from "moment";
import CardBody from "reactstrap/lib/CardBody";
import Select from "react-select";
import classnames from "classnames";
import styles from "./SalesNotification.css";
import {Device} from "@twilio/voice-sdk";
import debounce from 'lodash/debounce';

const USER_STATE = {
  CONNECTING : "Connecting",
  READY : "Ready",
  ON_CALL : "On call",
  OFFLINE : "Offline",
}


class PrivateSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeTab: "1",
      allPrivateSection: [],
      allSections: [],
      allSectionsData: [],
      teachersList: [],
      showAddHocClass: false,
      sectionName: null,
      sectionNameError: null,
      startDate: null,
      startDateError: null,
      startTime: null,
      startTimeError: null,
      endTime: null,
      endTimeError: null,
      teacherName: null,
      roomUrl: null,
      timeZone: null,
      isFree: false,
      uploading: false,

      page: 1,
      totalPage: 0,
      limit: 10,

      successModal: false,
      successMessage: "",
      errorModal: false,
      errorMessage: "",
      modalTitle: "",
      message: "",
      classId: null,
      activeCard: "active",
      sortOrder: '',
      total: 0,
      userState: USER_STATE.OFFLINE,
      messageStates: {},
      searchValue: '',
    };
    this.debouncedSearch = debounce(this.getAllPrivateSection, 700);
  }

  componentDidMount() {
    let storage = localStorage.getItem('Sanga_Admin');
    storage = storage ? JSON.parse(storage) : null;
    const classId = localStorage.getItem("classId");
    this.setState(
      {
        classId: classId ? classId : null,
        allPrivateSection: [],
        allSections: [],
        allSectionsData: [],
        userType: storage && storage.userType,
      },
      () => {
        this.getAllPrivateSection();
        this.getAllSections();
        this.getAllTeachers();
      }
    );
  }

  getAllTeachers = () => {
    let teachers = [{ value: null, label: "All Coaches" }];
    axiosGet(`teacher/list-of-all-teacher`)
        .then((response) => {
          if (!response.isError) {
            let data = response.data?.result || [];
            data.map((_data) => {
              teachers.push({ value: _data.teacherId, label: _data.name });
            });
            this.setState({
              teachersList: teachers,
            });
          } else {
            this.setState({
              teachersList: teachers,
            });
          }
        })
        .catch((error) => {
          this.setState({
            teachersList: teachers,
          });
        });
  };

  searchSection = async (e) => {
    let val = e.target.value;
    this.setState({ searchValue: val }, () => {
      this.debouncedSearch(val);
    });
  };

  // for getting all approved student -
  getAllPrivateSection = async (sectionName = null) => {
    const { page, limit, filterTeacher, classId, activeCard, sortOrder } = this.state;
    let url = `section/get-private-sections/${page}/${limit}?sectionType=${privateSection}&status=${activeCard}`;
    if (classId) {
        url = `section/get-private-sections/${page}/${limit}?sectionType=${privateSection}&status=${activeCard}&classId=${classId}`;
    }
    if (sectionName) {
      url = `section/get-private-sections/${page}/${limit}?sectionType=${privateSection}&status=${activeCard}&sectionName=${sectionName}`;
    }
    if (sortOrder) {
      url = `section/get-private-sections/${page}/${limit}?sectionType=${privateSection}&status=${activeCard}&sortOrder=${sortOrder}`;
    }
    if (filterTeacher) {
      let concatString = "&&";
      url = `${url}${concatString}teacherId=${filterTeacher}`;
    }
    this.setState({ loading: false });
    await axiosGet(url)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            loading: false,
            allPrivateSection: response.data?.result,
            totalPage: response.data?.noofpage,
            total: response.data?.total,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // for getting all section list for add hoc class-
  getAllSections = () => {
    this.setState({ loading: false });
    axiosGet(`section/get-sections-dropdown`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            loading: false,
            allSections: response.data,
          });
          this.setSectionData(response.data);
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setSectionData = (sectionData) => {
    let newObj = [];
    sectionData.map((data) => {
      let tt = { value: data.sectionId, label: data.name };
      newObj.push(tt);
    });
    let allSectionsData = newObj;
    this.setState({ allSectionsData });
  };
  // For changing page -
  changePage = (status) => {
    if (status && status === "next") {
      this.setState({ page: this.state.page + 1 }, () =>
        this.getAllPrivateSection()
      );
    } else if (status && status === "prev") {
      this.setState({ page: this.state.page - 1 }, () =>
        this.getAllPrivateSection()
      );
    }
  };

  //redirect to URL
  redirectToUrl = (urlToRedirect, token = null) => {
    urlToRedirect =
      urlToRedirect.includes("http") || urlToRedirect.includes("https")
        ? urlToRedirect
        : `http://${urlToRedirect}`;
    if (token) {
        urlToRedirect = `${urlToRedirect}?token=${token}`;
    }
    window.open(`${urlToRedirect}`, "_blank");
    return false;
  };

  redirectToUrlCard = (urlToRedirect) => {
    const newURL = urlToRedirect.replace('userdashboardpreview' , 'payment')
    window.open(`${newURL}`, "_blank");
    return false;
  };

  //redirect to section
  goToSection = (classId, sectionId) => {
    // this.props.history.push({
    //   pathname: "/admin/section",
    //   state: { classId: classId, sectionId: sectionId },
    // });

    const url = `/admin/section?classId=${classId}&sectionId=${sectionId}`;
    window.open(url, "_blank");
  };

  //redirect to section
  sendMail = (classId, sectionId) => {
    let confirm = window.confirm("Are you sure you want to Send Mail?");
    if (confirm) {
      axiosPost(`section/send-email-private-section/${sectionId}`)
          .then((response) => {
            if (!response.isError) {
              this.setState(
                  {
                    successModal: true,
                    modalTitle: "Success",
                    message: "Mail sent it successfully.",
                  },
                  () => {
                  }
              );
            } else {
              this.setState({
                errorModal: true,
                modalTitle: "Sorry!",
                message: response.message
                    ? response.message
                    : "Something is wrong. Try again later.",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              errorModal: true,
              modalTitle: "Sorry!",
              message: error.message
                  ? error.message
                  : "Something is wrong. Try again later.",
            });
          });
    }
  };

  showAddHocClassModal = (showAddHocClass) => {
    this.setState({
      showAddHocClass: !showAddHocClass,
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      modalTitle: "",
      message: "",
    });
  };

  handleSectionChange = (e) => {
    let { allSections } = this.state;
    let getSection = allSections.find((data) => data.sectionId === e.value);
    this.setState({
      sectionName: e,
      teacherName: getSection.teacherName,
      roomUrl: getSection.roomURL,
      timeZone: getSection.localTimeZone,
    });
  };

  resetAddHocForm = () => {
    this.setState({
      sectionName: null,
      startDate: null,
      startTime: null,
      endTime: null,
      teacherName: null,
      roomUrl: null,
      timeZone: null,
      uploading: false,
      sectionNameError: false,
      startDateError: false,
      startTimeError: false,
      endTimeError: false,
    });
  };

  resetWithoutTimeAddHocForm = () => {
    this.setState({
      sectionName: null,
      sectionNameError: false
    });
  };

  saveAddhocClass = () => {
    let { sectionName, startDate, startTime, endTime, timeZone, isFree } = this.state;

    !sectionName
      ? this.setState({ sectionNameError: true })
      : this.setState({ sectionNameError: false });
    !startDate
      ? this.setState({ startDateError: true })
      : this.setState({ startDateError: false });
    !startTime
      ? this.setState({ startTimeError: true })
      : this.setState({ startTimeError: false });
    !endTime
      ? this.setState({ endTimeError: true })
      : this.setState({ endTimeError: false });

    if (sectionName && startDate && startTime && endTime) {
      let timeSplitStart = startTime.split(":");
      let timeSplitEnd = endTime.split(":");

      let startingTime = "";
      let endingTime = "";

      if (timeSplitStart && timeSplitStart[0] && timeSplitStart[1]) {
        let startHours = "";
        let status = "";
        timeSplitStart[0] >= 12
          ? (startHours = parseInt(parseInt(timeSplitStart[0]) - 12))
          : (startHours = timeSplitStart[0]);
        timeSplitStart[0] >= 12 ? (status = "PM") : (status = "AM");
        startingTime = startHours + ":" + timeSplitStart[1] + " " + status;
      }
      if (timeSplitEnd && timeSplitEnd[0] && timeSplitEnd[1]) {
        let startHours = "";
        let status = "";
        timeSplitEnd[0] >= 12
          ? (startHours = parseInt(parseInt(timeSplitEnd[0]) - 12))
          : (startHours = timeSplitEnd[0]);
        timeSplitEnd[0] >= 12 ? (status = "PM") : (status = "AM");
        endingTime = startHours + ":" + timeSplitEnd[1] + " " + status;
      }

      this.setState({ uploading: true });
      let dataToSend = {
        sectionId: sectionName.value,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        startTime: startingTime,
        endTime: endingTime,
        localTimeZone: timeZone,
        isFree: isFree,
      };
      axiosPost(`section/add-extra-session`, dataToSend)
        .then((response) => {
          this.setState({
            uploading: false,
            showAddHocClass: false,
          });
          if (!response.isError) {
            this.setState(
              {
                successModal: true,
                modalTitle: "Success",
                message: "Successfully saved.",
              },
              () => {
                this.resetAddHocForm();
              }
            );
          } else {
            this.setState(
              {
                errorModal: true,
                modalTitle: "Sorry!",
                message: response.message,
              },
              () => {
                this.resetAddHocForm();
              }
            );
          }
        })
        .catch((errorResponse) => {
          this.setState(
            {
              errorModal: true,
              uploading: false,
              showAddHocClass: false,
              modalTitle: "Sorry!",
              message: errorResponse.message,
            },
            () => {
              this.resetAddHocForm();
            }
          );
        });
    }
  };

  saveNotificationToParent = () => {
    let { sectionName, isFree } = this.state;
    !sectionName
      ? this.setState({ sectionNameError: true })
      : this.setState({ sectionNameError: false });
    let dataToSend = {
      sectionId: sectionName.value,
      isFree
    };
    if (sectionName ) {
      axiosPost(`makeup/send-email-notification-parent`, dataToSend)
        .then((response) => {
          this.setState({
            uploading: false,
            showAddHocClass: false,
          });
          if (!response.isError) {
            this.setState(
              {
                successModal: true,
                modalTitle: "Success",
                message: "Sent email successfully.",
              },
              () => {
                this.resetWithoutTimeAddHocForm();
              }
            );
          } else {
            this.setState(
              {
                errorModal: true,
                modalTitle: "Sorry!",
                message: response.message,
              },
              () => {
                this.resetWithoutTimeAddHocForm();
              }
            );
          }
        })
        .catch((errorResponse) => {
          this.setState(
            {
              errorModal: true,
              uploading: false,
              showAddHocClass: false,
              modalTitle: "Sorry!",
              message: errorResponse.message,
            },
            () => {
              this.resetWithoutTimeAddHocForm();
            }
          );
        });
    }
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  calculateMonthGapMessage =(dateString) => {
    if(!dateString){
      return 'red'
    }
    const createdAtDate = new Date(dateString);
    const currentDate = new Date();
    const monthsDiff = (currentDate.getFullYear() - createdAtDate.getFullYear()) * 12 +
        (currentDate.getMonth() - createdAtDate.getMonth());
    if(monthsDiff === 0){
      return 'green'
    }
    return 'red'
  }

  getBelt =(belt) => {
    if(!belt){
      return 'red'
    }
    if(belt == 'TBD'){
      return 'red'
    }
    return belt.toLowerCase()
  }

  calculateMonthGapCuriculum =(chapterLockStatus) => {
    if(!chapterLockStatus || chapterLockStatus.length === 0){
      return 'red'
    }
    const lockedObjects = chapterLockStatus.filter((obj) => !obj.isLocked);
    lockedObjects.sort((a, b) => b.updatedAt - a.updatedAt);
    const latestUpdatedAt = lockedObjects.length > 0 ? lockedObjects[0].updatedAt : null;
    if(!latestUpdatedAt){
      return 'red'
    }
    this.calculateMonthGapMessage(latestUpdatedAt)
  }

  handleSort = () => {
    const { sortOrder } = this.state;
    if (sortOrder === 'asc') {
      this.setState({ sortOrder: 'desc' }, () => {
          this.getAllPrivateSection()
      });
    } else {
      this.setState({ sortOrder: 'asc' }, () => {
          this.getAllPrivateSection()
      });
    }
  }

  isTimeInRange(timezone) {
    const now = new Date();
    const userTime = new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: false, timeZone: timezone }).format(now);
    const hour = parseInt(userTime, 10);

    // Check if the time is between 8 AM (8) and 9 PM (21)
    return hour >= 8 && hour < 21;
  }

  updateMessageState = (index, newState) => {
    this.setState(prevState => ({
      messageStates: {
        ...prevState.messageStates,
        [index]: newState
      }
    }));
  };

  callInit = (sale, index) => {
    axiosPost('sales/token', {})
        .then((response) => {
          if (!response.isError) {
            this.setState(
                {
                  loading: false
                },
                () => {
                  const token = response.data.token;
                  const device = new Device(token, {
                    logLevel: 1,
                    codecPreferences: ["opus", "pcmu"],
                  });
                  device.register();
                  this.setState({device})
                  device.addListener("connect", (device) => {
                    console.log("Connect event listener added .....");
                    return device;
                  });
                  device.on("registered", () => {
                    console.log("Agent registered");
                    this.setState({userState: USER_STATE.READY});
                  });
                  device.on("connect", (connection) => {
                    console.log("Call connect");
                    this.setState({userState: USER_STATE.ON_CALL})
                  });
                  device.on("disconnect", () => {
                    console.log("Disconnect event");
                    this.setState({userState: USER_STATE.READY})
                  });

                  setTimeout(()=>{
                    this.handleCall(sale, index)
                  }, 1000)
                  return () => {
                    device.destroy();
                    this.setState({device: undefined})
                    this.setState({userState: USER_STATE.OFFLINE})
                  };
                }
            );
          } else {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            errorModal: true,
            modalTitle: 'Sorry!',
            message: 'Something is wrong. Try again later.',
          });
          console.log(error);
        });
  }

  handleCall = async (sale, index) => {
    const confirm = window.confirm(`Are you sure want to call? \nPlease verify details\nSection name: ${sale?.sectionName || ''},\nMobile: ${sale.mobileNumber || ''}, `)
    if(!confirm){
      return false;
    }
    const {device} = this.state;
    device.emit("connect");
    device.connect({params: {To: `${sale.mobileNumber.replace('-', '')}`, sectionId: sale.sectionId } }).then((call) => {
      call.on("accept", () => {
        this.updateMessageState(index, USER_STATE.ON_CALL);
        console.log("call accepted");
      });
      call.on("disconnect", () => {
        console.log("The call has been disconnected.");
        this.updateMessageState(index, USER_STATE.READY);
      });
      call.on("reject", () => {
        console.log("The call was rejected.");
      });
      call.on("cancel", () => {
        console.log("The call was rejected.");
        this.updateMessageState(index, USER_STATE.READY);
      });
    })
  };


  rejectCall = ()=>{
    const {device, selectedNumber} = this.state
    device.emit("disconnect");
    device.emit("cancel");
    device.emit("reject");
    device.disconnectAll({params: {To: selectedNumber} });
  }

  clientConnect = (index) => {
    let { allPrivateSection } = this.state;
    let selectedNotification = allPrivateSection[index];
    this.setState({
      notificationCallModal: true,
      selectedNumber: selectedNotification.mobileNumber.replace('-', '') || ''
    });
  };

  replyMessageResponse = () => {
    let confirm = window.confirm('Are you sure want send message?');
    if (confirm) {
      let { twillioMessage, loading, selectedNumber } = this.state;
      if(!twillioMessage || twillioMessage == ''){
        return alert('Please enter message');
      }
      if (twillioMessage && !loading) {
        this.setState({ loading: true });
        let dataToSend = {
          message: twillioMessage,
          to: `${selectedNumber}`,
        };
        // API -
        axiosPost('message/send-reply', dataToSend)
            .then((response) => {
              if (!response.isError) {
                this.setState(
                    {
                      loading: false,
                      notificationCallModal: false,
                      twillioMessage: '',
                      selectedNumber: '',
                    },
                    () => {
                    }
                );
              } else {
                this.setState({
                  loading: false,
                  modalTitle: 'Sorry!',
                  message: 'Something is wrong. Try again later.',
                  notificationCallModal: false,
                  twillioMessage: '',
                  selectedNumber: '',
                });
              }
            })
            .catch((error) => {
              this.setState({
                loading: false,
                errorModal: true,
                message: 'Something is wrong. Try again later.',
                notificationCallModal: false,
                twillioMessage: '',
                selectedNumber: '',
              });
            });
      }
    }
  };

  render() {
    let {
      allPrivateSection,
      page,
      totalPage,
      showAddHocClass,
      sectionName,
      sectionNameError,
      allSectionsData,
      startDate,
      startDateError,
      endTime,
      endTimeError,
      startTime,
      startTimeError,
      teacherName,
      roomUrl,
      timeZone,
      uploading,
      message,
      successModal,
      errorModal,
      activeTab,
      teachersList,
      modalTitle,
      activeCard,
      isFree,
      sortOrder,
      notificationCallModal,
      notesError,
      twillioMessage,
      loading,
      userType
    } = this.state;


    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>

            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0" style={{ display: "flex" }}>
                  <h3 className="mb-0">All Private Section</h3>
                  <Button
                    style={{ float: "right", marginLeft: "30%" }}
                    color="primary"
                    type="button"
                    onClick={() => this.showAddHocClassModal(showAddHocClass)}
                  >
                    Add AddHoc Class
                  </Button>
                </CardHeader>
                <div className="col-md-4 text-center" style={{ float: "left", paddingBottom: '20px' }}>
                    <ul className="nav nav-pills" >
                      <li style={{cursor: "pointer"}} className="nav-item">
                        <a
                            className={`nav-link ${
                                activeCard == "active" ? "active" : ""
                            }`}
                            onClick={() => {
                              this.setState(
                                  {activeCard: "active", page: 1},
                                  () => {
                                    this.getAllPrivateSection();
                                  }
                              );
                            }}
                        >
                          Active
                        </a>
                      </li>
                      <li style={{cursor: "pointer"}} className="nav-item">
                        <a
                            className={`nav-link ${
                                activeCard == "inactive" ? "active" : ""
                            }`}
                            onClick={() => {
                              this.setState(
                                  {activeCard: "inactive", page: 1},
                                  () => {
                                    this.getAllPrivateSection();
                                  }
                              );
                            }}
                        >
                          Inactive
                        </a>
                      </li>
                    </ul>
                </div>
                <div>
                  <div className="col-md-4 text-center" style={{ float: "left", paddingBottom: '20px' }}>
                    <input
                        placeholder="Search by Section name or Email"
                        type="text"
                        name="search"
                        className="form-control emailInput"
                        onChange={this.searchSection}
                    />
                  </div>
                  <div className="col-md-4 text-center" style={{ float: "left", paddingBottom: '20px' }}>
                    <Select
                        options={teachersList}
                        placeholder="All Coaches"
                        isSearchable={true}
                        onChange={(e) => {
                          this.setState(
                              { filterTeacher: e.value, page: 1 },
                              () => {
                                this.getAllPrivateSection();
                              }
                          );
                        }}
                    />
                  </div>
                </div>



                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      {
                        userType === 'admin' && (
                            <th scope="col"></th>
                        )
                      }
                      <th scope="col">Email</th>
                      <th scope="col">Mobile No</th>
                      <th scope="col">Section Name</th>
                      <th scope="col">Update Sessions Status</th>
                      <th scope="col">Coach Name</th>
                      <th scope="col">Class Length</th>
                      <th scope="col">Days</th>
                      <th scope="col">Timing</th>
                      <th scope="col">Timezone</th>
                      <th scope="col">Start Date</th>
                      <th scope="col" onClick={this.handleSort} className={sortOrder === "asc" ? ' tableHeader' +
                          ' sortArrowUp' : 'tableHeader' +
                          ' sortArrowDown'}>End Date</th>
                      <th scope="col">Sessions</th>
                      <th scope="col">ZOOM Link</th>

                      <th scope="col">Action</th>
                      <th scope="col">Mail</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {allPrivateSection &&
                      allPrivateSection.length > 0 &&
                      allPrivateSection.map((data, index) => {
                      const isActiveTime = this.isTimeInRange(data?.localTimeZone || 'Asia/kolkata');
                      const activeDays = data?.daysOfWeek?.filter(day => day.isActive).map(day => day.name);
                      const isExpired = new Date() > new Date(data.endDate);
                      const isActive = Object.values(this.state.messageStates).some(
                          state => state === 'Connecting' || state === 'On call'
                      )
                      const userStateForMessage = this.state.messageStates[index] || USER_STATE.OFFLINE;
                      return (
                          <tr key={index} style={{backgroundColor: isExpired ? '#d7d77f' : ''}}>
                            <td  className={'table-column-width'} >
                              {
                                (userStateForMessage === 'Connecting' || userStateForMessage === 'On call') ? (
                                    <button
                                        className={'btn btn-danger'}
                                        onClick={() => this.rejectCall(data.mobile)}
                                    ><i className="fa fa-phone" style={{fontSize:'20px'}} ></i>
                                    </button>
                                ): (
                                    isActiveTime ? (
                                        <button
                                            className={'btn btn-white'}
                                            disabled={isActive}
                                            onClick={() =>this.callInit(data, index)}
                                        ><i className="fa fa-phone" style={{fontSize:'20px'}} ></i>
                                        </button>
                                    ): (
                                        <></>
                                    )
                                )
                              }
                              <button onClick={() => this.clientConnect(index)} className={'btn btn-white'}>
                                <i className="fa fa-comments" style={{fontSize:'20px'}} aria-hidden="true" ></i>
                              </button>
                            </td>

                            <td >
                              <a className={'fa fa-certificate'} style={{color: this.getBelt(data?.belt), marginRight: '5px'}}></a>
                              <a className={'fa fa-comments'} style={{color: this.calculateMonthGapMessage(data?.messages?.createdAt || null),  marginRight: '5px'}}></a>
                              <a className={'fa fa-book'} style={{color: this.calculateMonthGapCuriculum(data?.chapterLockStatus || []),}}></a>
                            </td>
                            {
                              userType === 'admin' && (
                                  <td>
                                    <a
                                        href={"#"}
                                        onClick={() =>
                                            this.redirectToUrlCard(data.publicUrl)
                                        }
                                    >
                                      $
                                    </a>
                                  </td>
                              )
                            }
                            <td>
                              {data.email && (
                                <a target={'_blank'} href={'/admin/student-management?sectionId='+data.sectionId}>
                                  {data.email}
                                </a>
                              )}{" "}
                            </td>
                            <td>
                              {data.mobileNumber && (
                                  <a
                                      href={"tel:" + data.mobileNumber}
                                  >
                                    {data.mobileNumber}
                                  </a>
                              )}{" "}
                            </td>
                            <td>
                              {data.sectionName && (
                                <a
                                  href={"#"}
                                  onClick={() =>
                                    this.redirectToUrl(data.publicUrl)
                                  }
                                >
                                  {data.sectionName}
                                </a>
                              )}{" "}
                            </td>
                            <td>
                              {data.sectionName && (
                                <a
                                  href={"#"}
                                  onClick={() =>
                                    this.redirectToUrl(data.publicUrl, data.token)
                                  }
                                >
                                  Update Sessions
                                </a>
                              )}{" "}
                            </td>
                            <td>
                              {data?.teacherName || ''}
                            </td>
                            <td>
                              {data.classLength == fullClass
                                ? "Full  (50 mins)"
                                : data.classLength == halfClass
                                ? "Half (25 mins)"
                                : ""}{" "}
                            </td>
                            <td>{
                              activeDays && activeDays.map(day => {
                                return (
                                    <a>{day + " "}</a>
                                )
                              })
                            }</td>
                            <td>
                              {data.startTime && data.startTime} TO{" "}
                              {data.endTime && data.endTime}
                            </td>
                            <td>{data.localTimeZone && data.localTimeZone}</td>
                            <td>
                              {data.startDate &&
                                moment(data.startDate).format(
                                  dateForPaymentListing
                                )}
                            </td>
                            <td>
                              {data.endDate &&
                                moment(data.endDate).format(
                                  dateForPaymentListing
                                )}
                            </td>
                            <td>
                              {data.numberOfSessions && data.numberOfSessions}
                            </td>

                            <td>
                              {data.zoomLink && (
                                <a
                                  href={"#"}
                                  onClick={() =>
                                    this.redirectToUrl(data.zoomLink)
                                  }
                                >
                                  Start Class
                                </a>
                              )}
                            </td>


                            <td>
                              <Button
                                color="primary"
                                onClick={() =>
                                  this.goToSection(data.classId, data.sectionId)
                                }
                              >
                                Edit
                              </Button>

                            </td>
                            <td>
                              <Button
                                  color="primary"
                                  onClick={() =>
                                      this.sendMail(data.classId, data.sectionId)
                                  }
                              >
                                Send Mail Sign-up
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className={page > 1 ? "" : "disabled"}>
                        <PaginationLink
                          onClick={() => this.changePage("prev")}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className="active">
                        <PaginationLink onClick={(e) => e.preventDefault()}>
                          {page}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalPage > page ? "" : "disabled"}
                      >
                        <PaginationLink onClick={() => this.changePage("next")}>
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
            className='modal-dialog-centered'
            size='lg'
            isOpen={notificationCallModal}
            toggle={() =>
                this.toggleModal('notificationCallModal')
            }
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0'>
              <CardHeader className='bg-transparent pb-5'>
                <div className='text-muted text-center'>
                  <small>{'Message'}</small>
                </div>
              </CardHeader>
              <CardBody className='px-lg-5 py-lg-5'>
                <Form role='form'>
                  <FormGroup className={notesError ? 'has-danger' : ''}>
                    <InputGroup className='input-group-alternative'>
                                     <textarea
                                         placeholder='Enter Message'
                                         cols={100}
                                         rows={5}
                                         value={twillioMessage}
                                         onChange={(e) =>
                                             this.setState({ twillioMessage: e.target.value })
                                         }
                                     />
                    </InputGroup>
                  </FormGroup>

                  <div className="row text-right">
                    <div className="ml-auto" style={{marginRight: '10px'}}>
                      <Button
                          color="primary"
                          type="button"
                          onClick={() => this.setState({ notificationCallModal: !notificationCallModal })}
                      >
                        Cancel
                      </Button>
                      <Button
                          disabled={!!loading}
                          color="primary"
                          type="button"
                          onClick={() => this.replyMessageResponse()}
                      >
                        Send Message
                      </Button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={showAddHocClass}
          toggle={() => this.showAddHocClassModal(showAddHocClass)}
        >

          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-5">
                <div className="text-muted text-center mt-2 mb-3">
                  <small>Add Addhoc class</small>
                </div>
              </CardHeader>
              <Nav tabs>
                <NavItem>
                  <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        this.toggle("1");
                      }}>
                    Request with time
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                  >
                    Request without time
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup className={sectionNameError ? "has-danger" : ""}>
                        <label>Section name</label>
                        <InputGroup className="input-group-alternative">
                          <div style={{ width: "100%" }}>
                            <Select
                                options={allSectionsData}
                                placeholder="Select section"
                                value={sectionName}
                                onChange={(e) => this.handleSectionChange(e)}
                                isSearchable={true}
                            />
                          </div>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className={startDateError ? "has-danger" : ""}>
                        <label>Start Date</label>
                        <InputGroup className="input-group-alternative">
                          <Input
                              placeholder="Date"
                              type="date"
                              name="startDate"
                              value={startDate}
                              onChange={(e) =>
                                  this.setState({
                                    startDate: e.target.value,
                                    startDateError: false,
                                  })
                              }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className={startTimeError ? "has-danger" : ""}>
                        <label>Start Time</label>
                        <InputGroup className="input-group-alternative">
                          <Input
                              placeholder="Time"
                              type="time"
                              name="startTime"
                              value={startTime}
                              onChange={(e) =>
                                  this.setState({ startTime: e.target.value })
                              }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className={endTimeError ? "has-danger" : ""}>
                        <label>End Time</label>
                        <InputGroup className="input-group-alternative">
                          <Input
                              placeholder="Time"
                              type="time"
                              name="endTime"
                              value={endTime}
                              onChange={(e) =>
                                  this.setState({ endTime: e.target.value })
                              }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <label>Teacher Name</label>
                        <InputGroup className="input-group-alternative">
                          <Input
                              disabled
                              placeholder="Teacher Name"
                              type="text"
                              name="teacherName"
                              value={teacherName}
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <label>Room Url</label>
                        <InputGroup className="input-group-alternative">
                          <Input
                              disabled
                              placeholder="Room Url"
                              type="text"
                              name="roomUrl"
                              value={roomUrl}
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <label>Time Zone</label>
                        <InputGroup className="input-group-alternative">
                          <Input
                              disabled
                              placeholder="Time zone"
                              type="text"
                              name="roomUrl"
                              value={timeZone}
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <input
                            type="checkbox"
                            name="roomUrl"
                            value={timeZone}
                            defaultChecked={isFree ? true : false}
                            onChange={() =>
                                this.setState({ isFree: !this.state.isFree })
                            }
                        />
                        <label>
                           &nbsp; Free Class
                        </label>


                         </FormGroup>

                      <div className="text-center">
                        {uploading ? (
                            <span style={{ color: "red" }}>Wait...</span>
                        ) : null}

                        <Button
                            disabled={uploading ? true : false}
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={() => {
                              this.saveAddhocClass();
                            }}
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </TabPane>
                <TabPane tabId="2">
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup className={sectionNameError ? "has-danger" : ""}>
                        <label>Section name</label>
                        <InputGroup className="input-group-alternative">
                          <div style={{ width: "100%" }}>
                            <Select
                                options={allSectionsData}
                                placeholder="Select section"
                                value={sectionName}
                                onChange={(e) => this.handleSectionChange(e)}
                                isSearchable={true}
                            />
                          </div>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <input
                            type="checkbox"
                            name="roomUrl"
                            value={timeZone}
                            defaultChecked={isFree ? true : false}
                            onChange={() =>
                                this.setState({ isFree: !this.state.isFree })
                            }
                        />
                        <label>
                          &nbsp; Free Class
                        </label>


                      </FormGroup>


                      <div className="text-center">
                        {uploading ? (
                            <span style={{ color: "red" }}>Wait...</span>
                        ) : null}

                        <Button
                            disabled={uploading ? true : false}
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={() => {
                              this.saveNotificationToParent();
                            }}
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </TabPane>
              </TabContent>
            </Card>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.successModal || this.state.errorModal}
          toggle={() =>
            successModal
              ? this.toggleModal("successModal")
              : errorModal
              ? this.toggleModal("errorModal")
              : null
          }
        >


          <div className="modal-header">
            <h5 className="modal-title" id="successModalLabel">
              {modalTitle}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                successModal
                  ? this.toggleModal("successModal")
                  : errorModal
                  ? this.toggleModal("errorModal")
                  : null
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          {this.state.successModal ? (
            <div className="modal-body" style={{ color: "green" }}>
              {message}
            </div>
          ) : this.state.errorModal ? (
            <div className="modal-body" style={{ color: "red" }}>
              {message}
            </div>
          ) : null}
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              onClick={() =>
                successModal
                  ? this.toggleModal("successModal")
                  : errorModal
                  ? this.toggleModal("errorModal")
                  : null
              }
            >
              Ok
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default PrivateSection;
