/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import Cookies from 'js-cookie';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal,
} from 'reactstrap';
import { axiosPost } from 'util/apiConfig.js';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',

      emailError: '',
      passwordError: '',

      modalTitle: '',
      successModal: false,
      errorModal: false,
      message: '',
    };
  }

  componentDidMount() {}

  handleChange = (e) => {
    let target = e.target;
    let value = target.value;
    let name = target.name;

    this.setState({
      [name]: value,
    });
  };

  onKeyUpHandler = (e) => {
    let keyCode = e.keyCode;
    if (keyCode === 13) {
      this.signIn();
    }
  };

  signIn = () => {
    let { email, password } = this.state;
    !email
      ? this.setState({ emailError: 'Give your mail id' })
      : this.setState({ emailError: '' });
    !password
      ? this.setState({ passwordError: 'Give your password' })
      : this.setState({ passwordError: '' });

    if (email && password) {
      this.setState({ loading: true });
      let dataToSend = {
        email: email,
        password: password,
      };
      // API -
      axiosPost('auth/admin-sign-in', dataToSend)
        .then((response) => {
          if (!response.isError) {
            this.successLogin(response.data);
            this.setState({ loading: false });
          } else {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: response.message,
            });
          }
        })
        .catch((errorResponse) => {
          this.setState({
            loading: false,
            errorModal: true,
            modalTitle: 'Sorry!',
            message: errorResponse.message,
          });
        });
    }
  };

  successLogin = async (loginData) => {
    // let token = loginData && loginData.token;
    if (loginData) {
      let setTokenStatus = await this.setLoginData(loginData);
      if (setTokenStatus) {
        if(loginData.userType === 'admin' || loginData.userType === 'sub_admin'){
         return await this.redirectToDashboard();
        }
        if(loginData.userType === 'sales'){
          return await this.redirectToSales();
        }
        if(loginData.userType === 'support'){
          return await this.redirectToSupport();
        }
        if(loginData.userType === 'sales_admin'){
          return await this.redirectToSalesAdmin();
        }
        await this.redirectToLogin();
      }
    }
  };

  setLoginData = (data) => {
    return new Promise((resolve, reject) => {
      localStorage.setItem('Sanga_Admin', JSON.stringify(data));
      localStorage.setItem('userRole', data.userType);
      Cookies.set('token', data.token, { expires: 60 });
      resolve(true);
    });
  };

  redirectToDashboard = () => {
    return new Promise((resolve, reject) => {
      this.props.history.push({
        pathname: '/admin',
        state: {},
      });
      resolve(true);
    });
  };

  redirectToSales = () => {
    return new Promise((resolve, reject) => {
      this.props.history.push({
        pathname: '/admin/sales-notification',
        state: {},
      });
      resolve(true);
    });
  };

  redirectToSupport = () => {
    return new Promise((resolve, reject) => {
      this.props.history.push({
        pathname: '/admin/message',
        state: {},
      });
      resolve(true);
    });
  };

  redirectToSalesAdmin = () => {
    return new Promise((resolve, reject) => {
      this.props.history.push({
        pathname: '/admin/sales-followup',
        state: {},
      });
      resolve(true);
    });
  };

  redirectToLogin = () => {
    return new Promise((resolve, reject) => {
      this.props.history.push({
        pathname: 'auth/login',
        state: {},
      });
      resolve(true);
    });
  };

  // Modal control -
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      modalTitle: '',
      message: '',
    });
  };

  render() {
    let {
      email,
      password,
      emailError,
      passwordError,
      modalTitle,
      message,
      successModal,
      errorModal,
      loading,
    } = this.state;
    return (
      <>
        <Col lg='5' md='7'>
          <Card className='bg-secondary shadow border-0'>
            <CardHeader className='bg-transparent pb-5'>
              <div className='text-muted text-center mt-2 mb-3'>
                <small>Sign In</small>
              </div>
            </CardHeader>
            <CardBody className='px-lg-5 py-lg-5'>
              <Form role='form'>
                <FormGroup className='mb-3'>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-email-83' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Email'
                      type='email'
                      autoComplete='new-email'
                      name='email'
                      value={email}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      onKeyUp={(event) => this.onKeyUpHandler(event)}
                    />
                    {emailError ? (
                      <span style={{ color: 'red' }}>*{emailError}</span>
                    ) : null}
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-lock-circle-open' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Password'
                      type='password'
                      autoComplete='new-password'
                      name='password'
                      value={password}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      onKeyUp={(event) => this.onKeyUpHandler(event)}
                    />
                    {passwordError ? (
                      <span style={{ color: 'red' }}>*{passwordError}</span>
                    ) : null}
                  </InputGroup>
                </FormGroup>

                <div className='custom-control custom-control-alternative custom-checkbox'></div>

                <div className='text-center'>
                  {!loading ? (
                    <Button
                      className='my-4'
                      color='primary'
                      type='button'
                      onClick={() => {
                        this.signIn();
                      }}
                    >
                      Sign in
                    </Button>
                  ) : (
                    <Button className='my-4' color='primary' type='button'>
                      Sign in
                    </Button>
                  )}
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className='mt-3'>
            <Col xs='6'></Col>
            <Col className='text-right' xs='6'></Col>
          </Row>
        </Col>

        <Modal
          className='modal-dialog-centered'
          isOpen={this.state.successModal || this.state.errorModal}
          toggle={() =>
            successModal
              ? this.toggleModal('successModal')
              : errorModal
              ? this.toggleModal('errorModal')
              : null
          }
        >
          <div className='modal-header'>
            <h5 className='modal-title' id='successModalLabel'>
              {modalTitle}
            </h5>
            <button
              aria-label='Close'
              className='close'
              data-dismiss='modal'
              type='button'
              onClick={() =>
                successModal
                  ? this.toggleModal('successModal')
                  : errorModal
                  ? this.toggleModal('errorModal')
                  : null
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          {this.state.successModal ? (
            <div className='modal-body' style={{ color: 'green' }}>
              {message}
            </div>
          ) : this.state.errorModal ? (
            <div className='modal-body' style={{ color: 'red' }}>
              {message}
            </div>
          ) : null}
          <div className='modal-footer'>
            <Button
              color='primary'
              type='button'
              onClick={() =>
                successModal
                  ? this.toggleModal('successModal')
                  : errorModal
                  ? this.toggleModal('errorModal')
                  : null
              }
            >
              Ok
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default Login;
