/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
  Button,
  Form,
  FormGroup,
  InputGroup,
  Input,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import { axiosGet, axiosPost } from 'util/apiConfig.js';

class RoomManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      userId: '',
      userName: '',
      roomList: [],

      roomName: '',
      roomUrl: '',
      roomNameError: false,
      roomUrlError: false,
      editModal: false,

      newPage: 1,
      totalNewPage: '',
      rowLimit: 10,

      modalTitle: '',
      successModal: false,
      errorModal: false,
      message: '',
    };
  }

  componentDidMount() {
    this.setState(
      {
        userId: '',
        userName: '',
        roomList: [],
        teachersList: [],
      },
      () => {
        this.getRoomList();
      }
    );
  }

  // to get new registered teacher's list -
  getRoomList = () => {
    let { newPage, rowLimit } = this.state;
    this.setState({ loading: true });
    // API -
    axiosGet(`zoom/get-zoom-links/${newPage}/${rowLimit}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            roomList: response.data?.result,
            totalNewPage: response.data?.noofpage,
            loading: false,
          });
        } else {
          this.setState({
            roomList: [],
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          roomList: [],
          loading: false,
        });
      });
  };

  // For pagination -
  previousPage = (status) => {
    let { listPage, newPage } = this.state;
    if (status == 'list') {
      this.setState({ listPage: listPage - 1 }, () => {
        this.getRoomList();
      });
    } else if (status == 'new') {
      this.setState({ newPage: newPage - 1 }, () => {
        this.getRoomList();
      });
    }
  };

  // For pagination -
  nextPage = (status) => {
    let { listPage, newPage } = this.state;
    if (status == 'list') {
      this.setState({ listPage: listPage + 1 }, () => {
        this.getRoomList();
      });
    } else if (status == 'new') {
      this.setState({ newPage: newPage + 1 }, () => {
        this.getRoomList();
      });
    }
  };

  //to edit room -
  editRoom = (index) => {
    let { roomList } = this.state;
    let selectedRoom = roomList[index];
    this.setState({
      editModal: true,
      roomName: selectedRoom.roomName,
      roomUrl: selectedRoom.zoomLink,
      selectedRoom: selectedRoom,
    });
  };

  updateRoom = () => {
    let { roomName, roomUrl, selectedRoom } = this.state;
    !roomName
      ? this.setState({ roomNameError: true })
      : this.setState({ roomNameError: false });
    // !roomUrl? this.setState({roomUrlError: true}): this.setState({roomUrlError: false});
    if (roomName) {
      this.setState({ loading: true });

      let dataToSend = {
        zoomId: selectedRoom.zoomId,
        zoomLink: roomUrl,
        // roomName: roomName,
        // isAvailable: selectedRoom.isAvailable,
      };
      // API -
      axiosPost('zoom/edit-zoom-link', dataToSend)
        .then((response) => {
          if (!response.isError) {
            this.setState(
              {
                loading: false,
                selectedRoom: '',
                successModal: true,
                message: 'Successfully updated',
                modalTitle: 'Success',
              },
              () => {
                this.toggleModal('form');
                this.getRoomList();
              }
            );
          } else {
            this.setState(
              {
                loading: false,
                selectedRoom: '',
                errorModal: true,
                message: response.message,
                modalTitle: 'Sorry!',
              },
              () => {
                this.toggleModal('form');
              }
            );
          }
        })
        .catch((errorResponse) => {
          this.setState(
            {
              loading: false,
              selectedRoom: '',
              errorModal: true,
              message: errorResponse.message,
              modalTitle: 'Sorry!',
            },
            () => {
              this.toggleModal('form');
            }
          );
        });
    }
  };

  //delete record -
  deleteRoom = (index) => {
    let { roomList } = this.state;
    let selectedRoom = roomList[index];
    let dataToSend = {
      zoomId: selectedRoom.zoomId,
    };
    this.setState({ loading: true });
    // API -
    axiosPost('zoom/delete-zoom-link', dataToSend)
      .then((response) => {
        if (!response.isError) {
          this.setState(
            {
              loading: false,
              successModal: true,
              modalTitle: 'Success',
              message: 'Successfully deleted',
            },
            () => {
              this.getRoomList();
            }
          );
        } else {
          this.setState({
            loading: false,
            errorModal: true,
            modalTitle: 'Sorry!',
            message: 'Somethis is wrong. Try again later.',
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errorModal: true,
          modalTitle: 'Sorry!',
          message: 'Somethis is wrong. Try again later.',
        });
        console.log(error);
      });
  };

  // Modal control -
  toggleModal = (state) => {
    if (state == 'form') {
      this.setState({
        editModal: false,
        roomNameError: false,
        roomUrlError: false,
        roomName: '',
        roomUrl: '',
        selectedRoom: '',
      });
    } else {
      this.setState({
        [state]: !this.state[state],
        modalTitle: '',
        message: '',
      });
    }
  };

  render() {
    let {
      loading,
      roomList,
      roomName,
      roomUrl,
      roomNameError,
      editModal,
      successModal,
      errorModal,
      modalTitle,
      message,
      newPage,
      totalNewPage,
    } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <h3 className='mb-0'>Room List</h3>
                </CardHeader>

                <Table
                  className='align-items-center table-flush'
                  responsive
                  style={{ marginBottom: '100px' }}
                >
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Name</th>
                      <th scope='col'>URL</th>
                      <th scope='col'>Status</th>
                      <th scope='col' />
                    </tr>
                  </thead>
                  <tbody>
                    {roomList &&
                      roomList.map((value, index) => {
                        return (
                          <tr key={index}>
                            <th scope='row'>
                              <Media className='align-items-center'>
                                <Media>
                                  <span className='mb-0 text-sm'>
                                    {value.roomName ? value.roomName : ''}
                                  </span>
                                </Media>
                              </Media>
                            </th>

                            <td>{value.zoomLink ? value.zoomLink : ''}</td>

                            <td>
                              {!value.isAvailable ? (
                                <Badge color='' className='badge-dot mr-4'>
                                  <i className='bg-warning' />
                                  Not Available
                                </Badge>
                              ) : value.isAvailable ? (
                                <Badge color='' className='badge-dot mr-4'>
                                  <i className='bg-success' />
                                  Available
                                </Badge>
                              ) : null}
                            </td>

                            <td className='text-right'>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className='btn-icon-only text-light'
                                  href='JavaScript:Void(0);'
                                  role='button'
                                  size='sm'
                                  color=''
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className='fas fa-ellipsis-v' />
                                </DropdownToggle>
                                <DropdownMenu
                                  className='dropdown-menu-arrow'
                                  right
                                >
                                  <DropdownItem
                                    href='JavaScript:Void(0);'
                                    onClick={() => this.editRoom(index)}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    href='JavaScript:Void(0);'
                                    onClick={() => this.deleteRoom(index)}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className='py-4'>
                  <nav aria-label='...'>
                    <Pagination
                      className='pagination justify-content-end mb-0'
                      listClassName='justify-content-end mb-0'
                    >
                      <PaginationItem className={newPage > 1 ? '' : 'disabled'}>
                        <PaginationLink
                          href='JavaScript:Void(0);'
                          onClick={() => this.previousPage('new')}
                          tabIndex='-1'
                        >
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className='active'>
                        <PaginationLink
                          href='JavaScript:Void(0);'
                          onClick={(e) => e.preventDefault()}
                        >
                          {newPage}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalNewPage > newPage ? '' : 'disabled'}
                      >
                        <PaginationLink
                          href='JavaScript:Void(0);'
                          onClick={() => this.nextPage('new')}
                        >
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

          {/* For notification */}
          <Modal
            className='modal-dialog-centered'
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
              successModal
                ? this.toggleModal('successModal')
                : errorModal
                ? this.toggleModal('errorModal')
                : null
            }
          >
            <div className='modal-header'>
              <h5 className='modal-title' id='successModalLabel'>
                {modalTitle}
              </h5>
              <button
                aria-label='Close'
                className='close'
                data-dismiss='modal'
                type='button'
                onClick={() =>
                  successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                    ? this.toggleModal('errorModal')
                    : null
                }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            {this.state.successModal ? (
              <div className='modal-body' style={{ color: 'green' }}>
                {message}
              </div>
            ) : this.state.errorModal ? (
              <div className='modal-body' style={{ color: 'red' }}>
                {message}
              </div>
            ) : null}
            <div className='modal-footer'>
              <Button
                color='primary'
                type='button'
                onClick={() =>
                  successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                    ? this.toggleModal('errorModal')
                    : null
                }
              >
                Ok
              </Button>
            </div>
          </Modal>

          {/* For add room */}
          <Modal
            className='modal-dialog-centered'
            size='lg'
            isOpen={editModal}
            toggle={() => this.toggleModal('form')}
          >
            <div className='modal-body p-0'>
              <Card className='bg-secondary shadow border-0'>
                <CardHeader className='bg-transparent pb-5'>
                  <div className='text-muted text-center mt-2 mb-3'>
                    {editModal ? <small>Edit Room</small> : null}
                  </div>
                </CardHeader>
                <CardBody className='px-lg-5 py-lg-5'>
                  <Form role='form'>
                    <FormGroup className={roomNameError ? 'has-danger' : ''}>
                      <label>Room Name</label>
                      <InputGroup className='input-group-alternative'>
                        <Input
                          placeholder='Room name'
                          type='text'
                          name='className'
                          value={roomName}
                          onChange={(e) =>
                            this.setState({ roomName: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <label>Room Url</label>
                      <InputGroup className='input-group-alternative'>
                        <Input
                          placeholder='Room Url'
                          type='text'
                          name='className'
                          value={roomUrl}
                          onChange={(e) =>
                            this.setState({ roomUrl: e.target.value })
                          }
                        />
                      </InputGroup>
                      <span>
                        <a
                          href='JavaScript:Void(0);'
                          onClick={() => this.setState({ roomUrl: '' })}
                        >
                          Remove url
                        </a>
                      </span>
                    </FormGroup>
                    <div className='text-center'>
                      {editModal ? (
                        <Button
                          disabled={loading ? true : false}
                          className='my-4'
                          color='primary'
                          type='button'
                          onClick={() => this.updateRoom()}
                        >
                          Update
                        </Button>
                      ) : null}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default RoomManagement;
