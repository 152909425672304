import React from "react";
import Header from "../../components/Headers/Header";
import {
    Button,
    Card,
    Container, Input, InputGroup, Modal, Row,
} from "reactstrap";
import {Calendar, momentLocalizer, Views} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from "moment-timezone";
import {axiosGet, axiosPost} from "../../util/apiConfig";
import Select from "react-select";
import styles from "./SalesNotification.css";
import { fetchTimeZones } from 'util/timeZones';


const localizer = momentLocalizer(moment);
const views = Object.keys(Views).map(key => Views[key]);
const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '100%',
        zIndex: 1000, // Add your custom z-index value here
    }),
};

class TeacherCalender extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            teacherTimezone: '',
            teacherId: '',
            teacherName: '',
            timeZone: '',
            teacherAvailability: [],
            teacherVacation: [],
            slots: {},
            events: [], // Initialize events as an empty array
            sections: [] // Assuming you have a sections array
        }
    }

    componentDidMount() {
        const classId = localStorage.getItem("classId");
        this.setState(
            {
                classId: classId ? classId : null,
                selectedView: 'week',
                sections: [],
                teachersList: [],
            },
            () => {
                this.getTimeZones();
                this.getAllTeachers();
            }
        );

    }

    getTimeZones = () => {
        let timeZones = fetchTimeZones();
        let newTimeZoneObj = [];
        timeZones.map((data) => {
            let tt = {value:data, label: data }
            newTimeZoneObj.push(tt)
        })
        timeZones = newTimeZoneObj;
        this.setState({ timeZones });
    };

    getAllTeachers = () => {
        const { classId } = this.state;
        let teachers = [{ value: null, label: "All Coaches" }];
        let url = `teacher/list-of-all-teacher`;
        if (classId) {
            url = `teacher/list-of-all-teacher/${classId}`;
        }
        axiosGet(url)
            .then((response) => {
                if (!response.isError) {
                    let data = response.data?.result || [];
                    data.map((_data) => {
                        teachers.push({ value: _data.teacherId, label: _data.name , timeZone: _data.timeZone });
                    });
                    this.setState({
                        teachersList: teachers,
                    });
                } else {
                    this.setState({
                        teachersList: teachers,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    teachersList: teachers,
                });
            });
    };




    getSections = (teacherId) => {
        this.setState({events: []});
        axiosGet(`teacher/teacher-sessions/${teacherId}`)
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        sections: response.data || [],
                    }, () => {
                        if(response.data?.length > 0){
                            this.calculateAvailability();
                        }

                    });
                } else {
                    this.setState({
                        sections: [],
                    });
                }
            })
            .catch(() => {
                this.setState({
                    sections: [],
                });
            });
    }
    getTrialSections = (teacherId) => {
        this.setState({events: []});
        axiosGet(`teacher/teacher-trail-sessions/${teacherId}`)
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        sections: response.data || [],
                    }, () => {
                        if(response.data?.length > 0){
                            this.calculateTrialAvailability();
                        }

                    });
                } else {
                    this.setState({
                        sections: [],
                    });
                }
            })
            .catch(() => {
                this.setState({
                    sections: [],
                });
            });
    }
    getTeacherAvailability = (teacherId) => {
        axiosGet(`teacher/admin-get-teacher-hours/${teacherId}`)
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        teacherAvailability: response?.data?.teacherHoursDetail || [],
                    }, () => {
                        if(this.state.teacherAvailability){
                            this.calculateTeacherAvailability();
                        }
                    });
                } else {
                    this.setState({
                        teacherAvailability: [],
                    });
                }
            })
            .catch(() => {
                this.setState({
                    teacherAvailability: [],
                });
            });
    }

    getTeacherVacation = (teacherId) => {
        axiosGet(`teacher/get-teacher-on-vacation/${teacherId}`)
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        teacherVacation: response?.data || [],
                    }, () => {
                        if(this.state.teacherVacation && this.state.teacherVacation.length > 0){
                            this.calculateTeacherVacation();
                        }
                    });
                } else {
                    this.setState({
                        teacherVacation: [],
                    });
                }
            })
            .catch(() => {
                this.setState({
                    teacherVacation: [],
                });
            });
    }

    calculateTeacherAvailability = () => {
        const personAvailability = [];
        const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
        const { teacherAvailability } = this.state;
        for (const day of daysOfWeek) {
            const dayAvailability = teacherAvailability[day];
            if (dayAvailability && dayAvailability.length > 0) {
                for (const slot of dayAvailability) {
                    const start = moment(slot.start, 'h:mm a').tz(teacherAvailability.localTimeZone);
                    const end = moment(slot.end, 'h:mm a').tz(teacherAvailability.localTimeZone);
                    const currentDate = moment().startOf('year').day(day);
                    while (currentDate.isBefore(moment().endOf('year'))) {
                        const startTime = moment(currentDate)
                            .hour(start.hour())
                            .minute(start.minute())
                            .toDate();
                        const endTime = moment(currentDate)
                            .hour(end.hour())
                            .minute(end.minute())
                            .toDate();

                        personAvailability.push({
                            isAvailability: true,
                            start: startTime,
                            end: endTime,
                        });

                        currentDate.add(7, 'days'); // Move to the same day next week
                    }
                }
            }
        }

        this.setState({
            events: [...personAvailability, ...this.state.events]
        });
    };
    calculateTeacherVacation = () => {
        const personAvailability = [];
        const sections = this.state.teacherVacation;
        const teacherTimezone = this.state.teacherTimezone;
        for(const section of sections){
            // start should start of day
           const start = new Date(section.leaveOn);
           start.setHours(0);
           const end = new Date(section.leaveOn);
           end.setHours(23);
            personAvailability.push({
                teacherVacation: true,
                teacherVacationId: section.teacherVacationId,
                timeZone: teacherTimezone,
                start,
                end,
                title: 'Teacher On Vacation',
            });
        }
        this.setState({
            events: [...personAvailability, ...this.state.events]
        })

    };


    calculateAvailability = () => {
        const personAvailability = [];
        const sections = this.state.sections;
        for(const section of sections){
            const end = new Date(section.dateOfConduct);
            end.setMinutes(end.getMinutes() + (section.classLength === 'HALF' ? 25 : 50));
            personAvailability.push({
                    timeZone: section.localTimeZone,
                    status: section.status,
                    adHocClass: section.adHocClass,
                    start: new Date(section.dateOfConduct),
                    end: end,
                    title: <a href={'https://fiverkids.com/userdashboardpreview/' + section?.publicUserUrl[0]?.publicUrl} target='_blank' style={{color: 'black'}} >{section.name} - {section.startTime}</a>
                });
        }
        this.setState({
            events: [...personAvailability, ...this.state.events]
        })

    };
    calculateTrialAvailability = () => {
        const personAvailability = [];
        const sections = this.state.sections;
        for(const section of sections){
            const end = new Date(section.startTime);
            end.setMinutes(end.getMinutes() + 50);
            personAvailability.push({
                    timeZone: section.timezone,
                    isTrail: true,
                    start: new Date(section.startTime),
                    end: end,
                    title: <a style={{color: 'black'}} >Trial* {section.name}</a>
                });
        }
        this.setState({
            events: [...personAvailability, ...this.state.events]
        })

    };

    eventStyleGetter = (event, start, end, isSelected) => {
        // Customize event colors based on event properties
        let backgroundColor = 'yellow';
        let borderColor = '#79b4e7';
        let zIndex = 0;

        if (event.adHocClass === true) {
            backgroundColor = 'blue'; // Set a custom color for upcoming events
            borderColor = 'darkgreen';
        }
        if (event.status === 'Student on Leave' || event.status === 'Coach On Leave' || event.status === 'No Show Class') {
            backgroundColor = 'red'; // Set a custom color for upcoming events
            borderColor = 'darkgreen';
        }
        if (event.status === 'Complete') {
            backgroundColor = 'green'; // Set a custom color for upcoming events
            borderColor = 'darkgreen';
        }
        if(event.teacherVacation === true){
            // zIndex = 10;
            backgroundColor = '#ffa4a8'; // Set a custom color for upcoming events
        }

        if(event.isTrail === true){
            // zIndex = 10;
            backgroundColor = '#ff82d6';
            borderColor = '#ff82d6'; // Set a custom color for upcoming events
        }

        if (event.isAvailability) {
            backgroundColor = '#c4d0c4'; // Set a custom color for upcoming events
            borderColor = '#c4d0c4';
        }

        return {
            style: {
                fontSize: '12px',
                color: 'black',
                backgroundColor,
                borderColor,
                minHeight: '4%',
                zIndex,
                left: '10px !important',
                height: 'max-content !important',
            },
        };
    };

    addSlotToTeacher = (slot) => {
       const {filterTeacher, addTeachAvailability} = this.state;
       if(!filterTeacher) return alert('Please select teacher');
        this.setState({
            slots: slot,
        }, () => {
            this.addTeachAvailabilityModal(addTeachAvailability);
        });
    };
    handleEventClick = (slot) => {
      if(slot.isAvailability) {
          const alert = window.confirm('Are you sure you want to delete this slot?');
          if (alert) {
              const {teacherAvailability} = this.state;
              const day = moment(slot.start).format('ddd').toLowerCase();
              const slots = teacherAvailability[day];
              const index = slots.findIndex((item) => item.start === moment(slot.start).format('h:mm a') && item.end === moment(slot.end).format('h:mm a'));
              slots.splice(index, 1);
              const teacherId = this.state.filterTeacher;
              const requestData = {
                  teacherId: teacherId,
                  day: day,
                  slots: slots
              }
              axiosPost("teacher/admin-delete-teacher-hours", requestData)
                  .then((response) => {
                      if (!response.isError) {
                          this.setState(
                              {
                                  events: [],
                                  timeZone: '',
                                  loading: false,
                                  successModal: true,
                                  message: "Successfully saved",
                              },
                              () => {
                                  this.getSections(teacherId);
                                  this.getTrialSections(teacherId);
                                  this.getTeacherAvailability(teacherId);
                                  this.getTeacherVacation(teacherId);
                              }
                          );
                      } else {
                          this.setState({
                              loading: false,
                              successModal: false,
                              errorModal: true,
                              message: "Error while saving..",
                          });
                      }
                  })
                  .catch((errorResponse) => {
                      this.setState({
                          loading: false,
                          successModal: false,
                          errorModal: true,
                          message: errorResponse.message,
                      });
                      console.log("errorResponse", errorResponse);
                  });
          }
      };
      if(slot?.teacherVacation){
          const alert = window.confirm('Are you sure you want to delete this vacation?');
          if (alert) {
              const teacherId = this.state.filterTeacher;
              const requestData = {
                  teacherId: teacherId,
                  teacherVacationId: slot.teacherVacationId,
              }
              axiosPost("teacher/admin-delete-teacher-vacation", requestData)
                  .then((response) => {
                      if (!response.isError) {
                          this.setState(
                              {
                                  events: [],
                                  timeZone: '',
                                  loading: false,
                                  successModal: true,
                                  message: "Successfully saved",
                              },
                              () => {
                                  this.getSections(teacherId);
                                  this.getTrialSections(teacherId);
                                  this.getTeacherAvailability(teacherId);
                                  this.getTeacherVacation(teacherId);
                              }
                          );
                      } else {
                          this.setState({
                              loading: false,
                              successModal: false,
                              errorModal: true,
                              message: "Error while saving..",
                          });
                      }
                  })
                  .catch((errorResponse) => {
                      this.setState({
                          loading: false,
                          successModal: false,
                          errorModal: true,
                          message: errorResponse.message,
                      });
                      console.log("errorResponse", errorResponse);
                  });
          }
      }

    };

    addTeachAvailabilityModal = (addTeachAvailability) => {
        this.setState({
            addTeachAvailability: !addTeachAvailability,
        });
    };
    addTeacherVacationModal = (addTeacherVacation) => {
        this.setState({
            addTeacherVacation: !addTeacherVacation,
        });
    };


    submitSlots = (slot) => {
        const startTimeSlot = moment(slot.start).format('ddd h:mm a');
        const endTimeSlot = moment(slot.end).format('ddd h:mm a');
        const startDay = startTimeSlot.substring(0, 3).toLowerCase();
        const startTime = startTimeSlot.substring(4, 12);
        const endTime = endTimeSlot.substring(4, 12);
        const {teacherId, teacherName, addTeachAvailability, teacherTimezone } = this.state;
        const startDayOld = this.state.teacherAvailability[startDay];
        const requestData = {
            teacherId,
            teacherName,
            localTimeZone: teacherTimezone,
            [startDay]: [
                ...startDayOld,
                {
                start: startTime,
                end: endTime,
                isActive: true,
                isChecked: true,
            }],
        }
        axiosPost("teacher/admin-add-teacher-hours", requestData)
            .then((response) => {
                if (!response.isError) {
                    this.setState(
                        {
                            timeZone: '',
                            loading: false,
                            successModal: true,
                            message: "Successfully saved",
                        },
                        () => {
                            this.addTeachAvailabilityModal(addTeachAvailability);
                            this.getTeacherAvailability(teacherId);
                        }
                    );
                } else {
                    this.setState({
                        loading: false,
                        successModal: false,
                        errorModal: true,
                        message: "Error while saving..",
                    });
                }
            })
            .catch((errorResponse) => {
                this.setState({
                    loading: false,
                    successModal: false,
                    errorModal: true,
                    message: errorResponse.message,
                });
                console.log("errorResponse", errorResponse);
            });
    }

    submitVacation = (date) => {
        if(!date) return;
        const {teacherId, addTeacherVacation} = this.state;
        const requestData = {
            teacherId,
            leaveOn: date
        }
        axiosPost("teacher/admin-add-teacher-vacation", requestData)
            .then((response) => {
                if (!response.isError) {
                    this.setState(
                        {
                            timeZone: '',
                            loading: false,
                            successModal: true,
                            message: "Successfully saved",
                            fromDate: '',
                        },
                        () => {
                            this.addTeacherVacationModal(addTeacherVacation);
                            this.getTeacherVacation(teacherId);
                        }
                    );
                } else {
                    this.setState({
                        loading: false,
                        successModal: false,
                        errorModal: true,
                        message: "Error while saving..",
                    });
                }
            })
            .catch((errorResponse) => {
                this.setState({
                    loading: false,
                    successModal: false,
                    errorModal: true,
                    message: errorResponse.message,
                });
                console.log("errorResponse", errorResponse);
            });
    }

    getTodayDate = (years = 0 , months = 0) => {
        const today = new Date();
        const year = today.getFullYear() + years;
        const month = String(today.getMonth() + months).padStart(2, '0'); // Month is 0-indexed
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    render() {
        let { teachersList, addTeachAvailability, timeZones,
            timeZone,teacherTimezone, addTeacherVacation, fromDate, } = this.state;
        return (
            <>
                <Header />
                <Container className="mt--7" fluid>
                    <br />
                    <br />
                    {/* Table */}
                    <Row>

                        <div className='col'>
                            <Card className='shadow'>
                                <div>
                                        <div className="col-md-4 text-center" style={{ float: "left", marginTop: '10px', marginBottom: '10px' }}>
                                            <Select
                                                className={styles['custom-select'] + styles['rbc-event-label']}
                                                styles={customStyles} // Apply the custom styles
                                                options={teachersList}
                                                placeholder="All Coaches"
                                                isSearchable={true}
                                                onChange={(e) => {
                                                    this.setState(
                                                        { filterTeacher: e.value, page: 1, teacherId: e.value, teacherName: e.label, teacherTimezone: e.timeZone },
                                                        () => {
                                                            this.getSections(e.value);
                                                            this.getTrialSections(e.value);
                                                            this.getTeacherAvailability(e.value);
                                                            this.getTeacherVacation(e.value);
                                                            // this.getAllPrivateSection();
                                                        }
                                                    );
                                                }}
                                            />
                                        </div>
                                        {
                                            this.state.teacherId && (
                                                <div className="col-md-4 text-center " style={{ float: "right",  marginTop: '10px', marginBottom: '10px'}}>
                                                    <Button
                                                        color="primary"
                                                        type="button"
                                                        onClick={() => this.addTeacherVacationModal(addTeacherVacation)}
                                                    >
                                                        Teacher Vacation
                                                    </Button>
                                                </div>
                                            )
                                        }

                                 </div>
                                <div style={{marginTop: '10px'}}>
                                    <Calendar
                                        localizer={localizer}
                                        events={this.state.events}
                                        startAccessor="start"
                                        endAccessor="end"
                                        style={{ height: 1600, fontSize: '12px' }}
                                        selectable={true}
                                        eventPropGetter={this.eventStyleGetter}
                                        onSelectSlot={(slot) => this.addSlotToTeacher(slot)}
                                        onSelectEvent={(event) => this.handleEventClick(event)}
                                        defaultDate={new Date()} // Set the default date to today or any desired start date
                                        views={views}
                                        onView={view => this.setState({ selectedView: view })}
                                        defaultView={Views.WEEK} // Set the default view to 'week'
                                        view={this.state.selectedView} // Control the view with state
                                    />
                                </div>
                            </Card>
                        </div>
                    </Row>
                </Container>


                <Modal
                    className="modal-dialog-centered"
                    isOpen={addTeachAvailability}
                    toggle={() => this.addTeachAvailabilityModal(addTeachAvailability)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="successModalLabel">
                            {'Are you sure want to add availability for this teacher?'}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() =>
                                this.addTeachAvailabilityModal(addTeachAvailability)
                            }
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ color: "green" }}>
                        <div style={{width: '71%', marginBottom: '20px'}}>
                            <Select
                                isDisabled={true}
                                options={timeZones}
                                placeholder="Select timezone"
                                value={teacherTimezone ? {value:teacherTimezone,label:teacherTimezone} : teacherTimezone}
                                isSearchable={true}
                            />
                        </div>


                        {
                            this.state.slots ? (
                            <span style={{ color: "red" }}>{new Date(this.state.slots.start).toLocaleString('en-US',  {
                                weekday: 'long', // You can use 'short' for abbreviated names or 'narrow' for even shorter names
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                            })}</span>
                        ) : null}
                        <br/>
                        {
                            this.state.slots ? (
                                <span style={{ color: "red" }}>{new Date(this.state.slots.end).toLocaleString('en-US',  {
                                    weekday: 'long', // You can use 'short' for abbreviated names or 'narrow' for even shorter names
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric',
                                })}</span>
                            ) : null}
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="primary"
                            type="button"
                            onClick={()=> this.submitSlots(this.state.slots)}
                        >
                            Save
                        </Button>
                    </div>
                </Modal>

                <Modal
                    className="modal-dialog-centered"
                    isOpen={addTeacherVacation}
                    toggle={() => this.addTeacherVacationModal(addTeacherVacation)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="successModalLabel">
                            {'Select the date teacher on vacation?'}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() =>
                                this.addTeacherVacationModal(addTeacherVacation)
                            }
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ color: "green" }}>
                        <div style={{width: '71%', marginBottom: '20px'}}>
                            <Select
                                isDisabled={true}
                                options={timeZones}
                                placeholder="Select timezone"
                                value={teacherTimezone ? {value:teacherTimezone,label:teacherTimezone} : teacherTimezone}
                                isSearchable={true}
                            />
                        </div>
                        <div style={{width: '71%', marginBottom: '20px'}}>
                            <InputGroup className='input-group-alternative'>
                                <Input
                                    placeholder='Date'
                                    type='date'
                                    name='fromDate'
                                    value={fromDate}
                                    min={(new Date(Date.now() + 24 * 60 * 60 * 1000)).toISOString().split('T')[0]}
                                    onChange={(e) =>
                                        this.setState({
                                            fromDate: e.target.value,
                                        })
                                    }
                                />
                            </InputGroup>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <Button
                            color="primary"
                            type="button"
                            onClick={()=> this.submitVacation(this.state.fromDate)}
                        >
                            Save
                        </Button>
                    </div>
                </Modal>

            </>
        );
    }
}

export default TeacherCalender;
