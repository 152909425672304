import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import {axiosGet, axiosPost} from 'util/apiConfig.js';
import moment from "moment/moment";
import {dateForPaymentListing} from "../../util/constance";
import Select from "react-select";

class MakeupClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      userName: '',
      makeupRequestRefund: [],
      selectedRecord: '',
      page: 1,
      totalPage: '',
      rowLimit: 10,
      name: '',
      nameError: false,
      categoryId: '',
      isActive: false,
      newModal: false,
      editModal: false,
      modalTitle: '',
      successModal: false,
      errorModal: false,
      message: '',
      activeCard: "Initial",
      totalTeacherPending: 0,
      totalStudentPending: 0,
      totalInitial: 0,
      totalCredit: 0,
      totalApproved: 0,
      totalRefunded: 0,
      totalAdminRequest: 0,
      teachersList: [],
      allSectionsData: [],
    };
  }

  componentDidMount() {
    const classId = localStorage.getItem("classId");
    this.setState(
      {
        classId: classId ? classId : null,
        makeupRequestRefund: [],
      },
      () => {
        this.getMakeupRequest();
        this.getAllTeachers();
        this.getAllSections();
      }
    );
  }

  getAllSections = () => {
        this.setState({ loading: false });
        let url = 'section/get-sections-dropdown';
        const { classId, activeCard } = this.state;
        if (classId) {
            url = url + `/${classId}`;
        }
        if(activeCard) {
            url = url + `?status=${activeCard}`
        }
        axiosGet(url)
            .then((response) => {
                if (!response.isError) {
                    this.setSectionData(response.data);
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    setSectionData = (sectionData) => {
        let newObj = [];
        sectionData.map((data) => {
            let tt = { value: data.sectionId, label: data.name + " - " + '(' + data.count + ')' };
            newObj.push(tt);
        });
        let allSectionsData = newObj;
        allSectionsData.unshift({ value: null, label: "All Sections" });
        this.setState({ allSectionsData });
    };

  getAllTeachers = () => {
    let teachers = [{ value: null, label: "All Coaches" }];
    let url = 'teacher/list-of-all-teacher';
    const { classId, activeCard } = this.state;
    if (classId) {
      url = url + `/classId=${classId}`;
    }
    if(activeCard) {
      url = url + `?status=${activeCard}`;
    }
    axiosGet(url, {params: {status: activeCard} } )
        .then((response) => {
          if (!response.isError) {
            let data = response.data?.result || [];
            data.map((_data) => {
              teachers.push({ value: _data.teacherId, label: _data.name + " - " + '(' + _data.count + ')' });
            });
            this.setState({
              teachersList: teachers,
            });
          } else {
            this.setState({
              teachersList: teachers,
            });
          }
        })
        .catch((error) => {
          this.setState({
            teachersList: teachers,
          });
        });
  };

  updatePaymentStatus = (makeupId) => {
    let confirm = window.confirm("Are you sure you want to update refund status?");
    if (confirm) {
      axiosPost(`makeup/update-payment-status/${makeupId}`)
          .then((response) => {
            if (!response.isError) {
              this.componentDidMount();
              this.setState(
                  {
                    successModal: true,
                    modalTitle: "Success",
                    message: "Update successfully.",
                  },
                  () => {
                  }
              );
            } else {
              this.setState({
                errorModal: true,
                modalTitle: "Sorry!",
                message: response.message
                    ? response.message
                    : "Something is wrong. Try again later.",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              errorModal: true,
              modalTitle: "Sorry!",
              message: error.message
                  ? error.message
                  : "Something is wrong. Try again later.",
            });
          });
    }
  };
  sendReminder = (makeupId) => {
    let confirm = window.confirm("Are you sure you want to send Reminder?");
    if (confirm) {
      axiosPost(`makeup/reminder-makeup-class-request/${makeupId}`)
          .then((response) => {
            if (!response.isError) {
              this.componentDidMount();
              this.setState(
                  {
                    successModal: true,
                    modalTitle: "Success",
                    message: "Sent Reminder successfully.",
                  },
                  () => {
                  }
              );
            } else {
              this.setState({
                errorModal: true,
                modalTitle: "Sorry!",
                message: response.message
                    ? response.message
                    : "Something is wrong. Try again later.",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              errorModal: true,
              modalTitle: "Sorry!",
              message: error.message
                  ? error.message
                  : "Something is wrong. Try again later.",
            });
          });
    }
  };
  updateStatus = (status, makeupId) => {
    let confirm = window.confirm("Are you sure you want update status?");
    if (confirm) {
      axiosPost(`makeup/update-makeup-status`, {status: !status, makeupId: makeupId})
          .then((response) => {
            if (!response.isError) {
              this.componentDidMount();
              this.setState(
                  {
                    successModal: true,
                    modalTitle: "Success",
                    message: "Link have been updated.",
                  },
                  () => {
                  }
              );
            } else {
              this.setState({
                errorModal: true,
                modalTitle: "Sorry!",
                message: response.message
                    ? response.message
                    : "Something is wrong. Try again later.",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              errorModal: true,
              modalTitle: "Sorry!",
              message: error.message
                  ? error.message
                  : "Something is wrong. Try again later.",
            });
          });
    }
  };

  rejectMakeup = (makeupId) => {
    let confirm = window.confirm("Are you sure you want to reject?");
    if (confirm) {
      axiosPost(`makeup/reject-makeup-class/${makeupId}`)
          .then((response) => {
            if (!response.isError) {
              this.componentDidMount();
              this.setState(
                  {
                    successModal: true,
                    modalTitle: "Success",
                    message: "Makeup Class Rejected Successfully.",
                  },
                  () => {
                  }
              );
            } else {
              this.setState({
                errorModal: true,
                modalTitle: "Sorry!",
                message: response.message
                    ? response.message
                    : "Something is wrong. Try again later.",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              errorModal: true,
              modalTitle: "Sorry!",
              message: error.message
                  ? error.message
                  : "Something is wrong. Try again later.",
            });
          });
    }
  };

  getMakeupRequest = () => {
    const { page, rowLimit, activeCard, filterTeacher, classId, filterSectionName } = this.state;
    let url = `makeup/get-makeup-class/${page}/${rowLimit}/${activeCard}`;
    if (classId) {
        url = `makeup/get-makeup-class/${page}/${rowLimit}/${activeCard}?classId=${classId}`;
    }
    if (filterTeacher) {
        url = `makeup/get-makeup-class/${page}/${rowLimit}/${activeCard}?teacherId=${filterTeacher}`;
    }
    if (filterSectionName) {
        url = `makeup/get-makeup-class/${page}/${rowLimit}/${activeCard}?sectionId=${filterSectionName}`;
    }
    this.setState({ loading: false });
    axiosGet(url)
        .then((response) => {
          if (!response.isError) {
            this.setState({
              loading: false,
              makeupRequestRefund: response.data?.result,
              totalPage: response.data?.noofpage,
              totalTeacherPending: response.data?.totalTeacherPending,
              totalCredit: response.data?.totalCredit,
              totalStudentPending: response.data?.totalStudentPending,
              totalInitial: response.data?.totalInitial,
              totalApproved: response.data?.totalApproved,
              totalRefunded: response.data?.totalRefunded,
              totalAdminRequest: response.data?.totalAdminRequest,

            });
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  // For pagination -
  previousPage = () => {
    let { page } = this.state;
    this.setState({ page: page - 1 }, () => {
      this.getMakeupRequest();
    });
  };

  // For pagination -
  nextPage = () => {
    let { page } = this.state;
    this.setState({ page: page + 1 }, () => {
      this.getMakeupRequest();
    });
  };


  // saving new category -

  // Modal control -
  toggleModal = (state) => {
    if (state == 'data') {
      this.setState({
        editModal: false,
        newModal: false,
        name: '',
        isActive: false,
      });
    } else {
      this.setState({
        [state]: !this.state[state],
        modalTitle: '',
        message: '',
      });
    }
  };

  render() {
    let {
      makeupRequestRefund,
      page,
      totalPage,
      activeCard,
      teachersList,
        allSectionsData,
        filterSectionName,
    } = this.state;
      let filterSectionNameLable = filterSectionName
          ? allSectionsData
              .filter((_data) => _data.value == filterSectionName)
              .map(({ label }) => label)
          : null;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <br />
          <br />
          {/* Table */}
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <ul className="nav nav-pills" style={{marginLeft: "10px"}}>
                    <li style={{cursor: "pointer"}} className="nav-item">
                      <a
                          className={`nav-link ${
                              activeCard == "Admin Request" ? "active" : ""
                          }`}
                          onClick={() => {
                            this.setState(
                                {activeCard: "Admin Request", page: 1},
                                () => {
                                  this.getMakeupRequest();
                                  this.getAllTeachers();
                                  this.getAllSections();
                                }
                            );
                          }}
                      >
                        Admin Refund Request ({this.state.totalAdminRequest})
                      </a>
                    </li>
                    <li style={{cursor: "pointer"}} className="nav-item">
                      <a
                          className={`nav-link ${
                              activeCard == "Initial" ? "active" : ""
                          }`}
                          onClick={() => {
                            this.setState(
                                {activeCard: "Initial", page: 1},
                                () => {
                                  this.getMakeupRequest();
                                  this.getAllTeachers();
                                  this.getAllSections();
                                }
                            );
                          }}
                      >
                        Student Pending ({this.state.totalInitial})
                      </a>
                    </li>
                    <li style={{cursor: "pointer"}} className="nav-item">
                          <a
                              className={`nav-link ${
                                  activeCard == "Coach Pending" ? "active" : ""
                              }`}
                              onClick={() => {
                                  this.setState(
                                      {activeCard: "Coach Pending", page: 1},
                                      () => {
                                          this.getMakeupRequest();
                                          this.getAllTeachers();
                                          this.getAllSections();
                                      }
                                  );
                              }}
                          >
                              Coach Pending ({this.state.totalTeacherPending})
                          </a>
                    </li>
                    <li style={{cursor: "pointer"}} className="nav-item">
                          <a
                              className={`nav-link ${
                                  activeCard == "Student Pending 2" ? "active" : ""
                              }`}
                              onClick={() => {
                                  this.setState(
                                      {activeCard: "Student Pending 2", page: 1},
                                      () => {
                                          this.getMakeupRequest();
                                          this.getAllTeachers();
                                          this.getAllSections();
                                      }
                                  );
                              }}
                          >
                              Student Pending 2 ({this.state.totalStudentPending})
                          </a>
                    </li>
                    <li style={{cursor: "pointer"}} className="nav-item">
                      <a
                          onClick={() => {
                            this.setState(
                                {activeCard: "Approved", page: 1},
                                () => {
                                  this.getMakeupRequest();
                                  this.getAllTeachers();
                                  this.getAllSections();
                                }
                            );
                          }}
                          className={`nav-link ${
                              activeCard == "Approved" ? "active" : ""
                          }`}
                          aria-current="page"
                      >
                        Approved ({this.state.totalApproved})
                      </a>
                    </li>
                   <li style={{cursor: "pointer"}} className="nav-item">
                          <a
                              className={`nav-link ${
                                  activeCard == "Refunded" ? "active" : ""
                              }`}
                              onClick={() => {
                                  this.setState(
                                      {activeCard: "Refunded", page: 1},
                                      () => {
                                          this.getMakeupRequest();
                                          this.getAllTeachers();
                                          this.getAllSections();
                                      }
                                  );
                              }}
                          >
                              Refunded ({this.state.totalRefunded})
                          </a>
                      </li>
                   <li style={{cursor: "pointer"}} className="nav-item">
                          <a
                              className={`nav-link ${
                                  activeCard == "Credit" ? "active" : ""
                              }`}
                              onClick={() => {
                                  this.setState(
                                      {activeCard: "Credit", page: 1},
                                      () => {
                                          this.getMakeupRequest();
                                          this.getAllTeachers();
                                          this.getAllSections();
                                      }
                                  );
                              }}
                          >
                              Credit ({this.state.totalCredit})
                          </a>
                      </li>
                  </ul>
                </CardHeader>
                <div className="row">
                  <div className="col-md-4 text-center" style={{ float: "left", paddingBottom: '20px' }}>
                    <Select
                        options={teachersList}
                        placeholder="All Coaches"
                        isSearchable={true}
                        onChange={(e) => {
                          this.setState(
                              { filterTeacher: e.value, page: 1 },
                              () => {
                                this.getMakeupRequest();
                              }
                          );
                        }}
                    />
                  </div>
                  <div className="col-md-4 text-center" style={{ paddingBottom: '20px' }}>
                      <Select
                          options={allSectionsData}
                          placeholder="Select section"
                          value={
                              filterSectionName
                                  ? {
                                      value: filterSectionName,
                                      label: filterSectionNameLable,
                                  }
                                  : filterSectionName
                          }
                          onChange={(e) =>
                              this.setState(
                                  {
                                      filterSectionName: e.value,
                                  },
                                  () => {
                                      this.getMakeupRequest();
                                  }
                              )
                          }
                          isSearchable={true}
                      />
                  </div>
                </div>

                <Table
                  className='align-items-center table-flush'
                  responsive
                  style={{ marginBottom: '100px' }}
                >
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Class Name</th>
                      <th scope='col'>Section Name</th>
                      <th scope='col'>Email</th>
                      <th scope='col'>Cancelled Date</th>
                      <th scope='col'>Teacher Name</th>
                      {(activeCard == "Initial" || activeCard ==  "Student Pending 2" || activeCard == "Coach Pending") && <th scope='col'>Link</th>}
                      {(activeCard == "Initial" || activeCard ==  "Student Pending 2" || activeCard == "Coach Pending") && <th scope='col'>Status</th>}
                      {(activeCard ==  "Student Pending 2" || activeCard == "Coach Pending") && <th scope='col'>Reminder</th>}
                      {activeCard == "Refunded" && <th scope='col'>Payment Done</th>}
                      <th scope='col' />
                    </tr>
                  </thead>
                  <tbody>
                    {makeupRequestRefund &&
                      makeupRequestRefund.map((value, index) => {
                        const style = value.isActive === false ? { color: 'red' } : value.isRefund == 'true' ? { color: 'green' }  :{ } ;
                        return (
                            <tr key={index} style={style}>
                                <td>{value?.className || ''}</td>
                                <td>{value?.sectionmodels[0]?.name || ''}</td>
                                <td>{value?.sectionmodels[0]?.publicUserUrl[0]?.email || ''}</td>
                                <td>{value?.sessionDetails && value?.sessionDetails[0]?.dateOfConduct ? moment(value?.sessionDetails[0]?.dateOfConduct).format(dateForPaymentListing) : 'Admin'}</td>
                                <td>{value?.teacherName || ''}</td>
                                {activeCard == "Refunded" &&
                                    <td>{value?.isRefund == 'true' ? 'Yes' : 'No'}</td>
                                }
                                {(activeCard == "Initial" || activeCard ==  "Student Pending 2" || activeCard == "Coach Pending") &&
                                    value?.link && <td> <a href={value.link} target={'_blank'}> Link</a> </td>
                                }

                                {(activeCard == "Initial" || activeCard ==  "Student Pending 2" || activeCard == "Coach Pending") &&
                                    <td>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                this.updateStatus(value?.isActive, value.makeupId);
                                            }}
                                        >{value.isActive === true ? 'Inactive' : 'Active'}
                                        </button>
                                    </td>
                                }
                                {(activeCard == "Initial" || activeCard ==  "Student Pending 2" || activeCard == "Coach Pending") &&
                                    <td>
                                      <button
                                          className="btn btn-primary"
                                          onClick={() =>
                                              this.sendReminder(value.makeupId)
                                          }
                                      >Send Reminder</button>
                                    </td>
                                }
                                {(activeCard ==  "Student Pending 2" || activeCard == "Coach Pending") && value?.status == "pending" &&
                                    <td>
                                      <button
                                          className="btn btn-danger"
                                          onClick={() =>
                                              this.rejectMakeup(value.makeupId)
                                          }
                                      >Reject Makeup Class</button>
                                    </td>
                                }
                                {activeCard == "Refunded" &&
                                    <td>
                                    <button
                                        className={`btn ${value?.isRefund === 'true' ? 'btn-success' : 'btn-primary'}`}
                                        onClick={() =>
                                            this.updatePaymentStatus(value.makeupId)
                                        }
                                    >Done</button>
                                    </td>
                                }
                            </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className='py-4'>
                  <nav aria-label='...'>
                    <Pagination
                      className='pagination justify-content-end mb-0'
                      listClassName='justify-content-end mb-0'
                    >
                      <PaginationItem className={page > 1 ? '' : 'disabled'}>
                        <PaginationLink
                          href='JavaScript:Void(0);'
                          onClick={() => this.previousPage('new')}
                          tabIndex='-1'
                        >
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className='active'>
                        <PaginationLink
                          href='JavaScript:Void(0);'
                          onClick={(e) => e.preventDefault()}
                        >
                          {page}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalPage > page ? '' : 'disabled'}
                      >
                        <PaginationLink
                          href='JavaScript:Void(0);'
                          onClick={() => this.nextPage('new')}
                        >
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default MakeupClass;
