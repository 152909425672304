import classnames from 'classnames';
import React, { useState } from 'react';

import { DESC_DEFAULT_LENGTH } from './constants';

const DescriptionDetail = ({ description }) => {
  const [showMore, setShowMore] = useState(false);
  const descToShow = showMore
    ? description
    : description.substring(0, DESC_DEFAULT_LENGTH);

  return (
    <div className='FullDesc'>
      <p
        className={classnames('Desc', {
          LongText: !showMore && description?.length > DESC_DEFAULT_LENGTH,
        })}
      >
        {descToShow}
      </p>
      {description?.length > DESC_DEFAULT_LENGTH ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a className='MoreItem' onClick={() => setShowMore(!showMore)}>
          {showMore ? 'Read Less << ' : 'Read More >>'}
        </a>
      ) : null}
    </div>
  );
};

export default DescriptionDetail;
