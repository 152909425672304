/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import { axiosGet } from 'util/apiConfig.js';
import { dateForPaymentListing } from 'util/constance';
import moment from 'moment';
class CancelledSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      allCancelledSection: [],

      page: 1,
      totalPage: 0,
      limit: 10,

      successModal: false,
      successMessage: '',
      errorModal: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    const classId = localStorage.getItem("classId");
    this.setState(
      {
        classId: classId ? classId : null,
        allCancelledSection: [],
      },
      () => {
        this.getAllCancelledSection();
      }
    );
  }

  // for getting all approved student -
  getAllCancelledSection = () => {
    const { page, limit, classId } = this.state;
    this.setState({ loading: false });
    let url = `session/get-session-cancel-by-student/${page}/${limit}`;
    if (classId) {
        url = `session/get-session-cancel-by-student/${page}/${limit}?classId=${classId}`;
    }
    axiosGet(url)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            loading: false,
            allCancelledSection: response.data?.result,
            totalPage: response.data?.noofpage,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // For changing page -
  changePage = (status) => {
    if (status && status === 'next') {
      this.setState({ page: this.state.page + 1 }, () => this.getAllCancelledSection());
    } else if (status && status === 'prev') {
      this.setState({ page: this.state.page - 1 }, () => this.getAllCancelledSection());
    }
  };

  render() {
    let { loading, allCancelledSection, page, totalPage } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          {/* Table */}
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <h3 className='mb-0'>All Cancelled Sessions</h3>
                </CardHeader>
                <Table className='align-items-center table-flush' responsive>
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Email</th>
                      <th scope='col'>Class Name</th>
                      <th scope='col'>Section Name</th>
                      <th scope='col'>Date</th>
                      <th scope='col'>Timing</th>
                      <th scope='col' />
                    </tr>
                  </thead>
                  <tbody>
                    {allCancelledSection &&
                      allCancelledSection.length > 0 &&
                      allCancelledSection.map((data, index) => {
                        return (
                          <tr key={index}>
                          <td>{data.email && data.email}</td>
                          <td>{data.className && data.className}</td>
                          <td>{data.sectionName && data.sectionName}</td>
                          <td>{data.dateOfConduct && moment(data.dateOfConduct).format(dateForPaymentListing)}</td>  
                          <td>{data.startTime && data.startTime} TO {data.endTime && data.endTime}</td>  
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className='py-4'>
                  <nav aria-label='...'>
                    <Pagination
                      className='pagination justify-content-end mb-0'
                      listClassName='justify-content-end mb-0'
                    >
                      <PaginationItem className={page > 1 ? '' : 'disabled'}>
                        <PaginationLink
                          onClick={() => this.changePage('prev')}
                          tabIndex='-1'
                        >
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className='active'>
                        <PaginationLink onClick={(e) => e.preventDefault()}>
                          {page}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalPage > page ? '' : 'disabled'}
                      >
                        <PaginationLink onClick={() => this.changePage('next')}>
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default CancelledSection;
