
import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Modal,
  Button,
  Form,
  FormGroup,
  InputGroup,
  Input,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import { axiosGet, axiosPost } from 'util/apiConfig.js';
const pdfRef = React.createRef(null);
class ChapterManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      chapterError: false,
      descriptionError: false,
      chapterList: [],
      editModal: false,
      modalTitle: '',
      successModal: false,
      errorModal: false,
      message: '',
      pdfUrl: '',
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

    onDragEnd(result) {
      const { chapterList } = this.state;
      if (!result.destination) return;
      const items = Array.from(chapterList);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      const updatedOrder = items.map((item, index) => {
        return { chapterId: item.chapterId, index };
      });
      this.updateIndex(updatedOrder);
      this.setState({ chapterList: items });
  }


  componentDidMount() {
    const { curriculumId } = this.props.location.state;
    this.setState(
      {
        userId: '',
        userName: '',
        chapterList: [],
        curriculumId: curriculumId,
      },
      () => {
        this.getChaptersList(curriculumId);
      }
    );
  }

  updateIndex = (updatedOrder) => {
    // call post api for the updated order
    axiosPost('curriculum/update-chapter-index', {chapters: updatedOrder}).then((response) => {
        if (!response.isError) {
            this.setState({
            message: 'Index Updated Successfully',
            successModal: true,
            });
        } else {
            this.setState({
            message: response.message,
            errorModal: true,
            });
        }
    });
  }

  // to get new registered teacher's list -
  getChaptersList = (curriculumId) => {
    this.setState({ loading: true });
    // API -
    axiosGet(`curriculum/get-chapter/${curriculumId}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            chapterList: response.data,
            loading: false,
          });
        } else {
          this.setState({
            chapterList: [],
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          chapterList: [],
          loading: false,
        });
      });
  };


  addChapterForm = () => {
    this.resetForm();
    this.setState({
      chapterModal: true,
    });
  }

  resetForm = () => {
    this.setState({
      loading: false,
      chapterName: '',
      description: '',
      chapterError: false,
      descriptionError: false,
      isVisible: false,
      pdfUrl: '',
    });
  };

  //to edit room -
  editChapter = (index) => {
    let { chapterList } = this.state;
    let selectedChapter = chapterList[index];
    this.setState({
      editModal: true,
      pdfUrl: selectedChapter.pdfUrl,
      chapterName: selectedChapter.name,
      description: selectedChapter.description,
      selectedChapter: selectedChapter,
    });
  };

  addOrUpdateChapter = (isCalledForEditChapter) => {
    let {
      chapterName,
      description,
      curriculumId,
      selectedChapter,
      pdfUrl,
    } = this.state;
    !chapterName
        ? this.setState({ chapterError: true })
        : this.setState({ chapterError: false });
    !description
        ? this.setState({ descriptionError: true })
        : this.setState({ descriptionError: false });
    if (chapterName && description) {
      let dataToSend = {
        name: chapterName,
        description,
        curriculumId,
        pdfUrl,
      };
      if (isCalledForEditChapter) {
        dataToSend = { ...dataToSend, ...{ chapterId: selectedChapter.chapterId} };
      }
      const endPointUrl = isCalledForEditChapter
          ? 'curriculum/edit-chapter'
          : 'curriculum/add-chapter';
      axiosPost(`${endPointUrl}`, dataToSend)
          .then((response) => {
            this.setState({
              editModal: false,
              selectedChapter: '',
              chapterModal: false,
              loading: false,
            });
            if (!response.isError) {
              this.setState(
                  {
                    successModal: true,
                    modalTitle: 'Success',
                    message: 'Successfully saved.',
                  },
                  () => {
                    this.resetForm();
                    this.componentDidMount()
                  }
              );
            } else {
              this.setState(
                  {
                    errorModal: true,
                    modalTitle: 'Sorry!',
                    message: response.message,
                  },
                  () => {
                    this.resetForm();
                  }
              );
            }
          })
          .catch((errorResponse) => {
            this.setState(
                {
                  editModal: false,
                  selectedChapter: '',
                  chapterModal: false,
                  errorModal: true,
                  loading: false,
                  modalTitle: 'Sorry!',
                  message: errorResponse.message,
                },
                () => {
                  this.resetForm();
                }
            );
          });
    }
  };


  //delete record -
  deleteChapter = (index) => {
    let { chapterList, curriculumId } = this.state;
    let selectedChapter = chapterList[index];
    let dataToSend = {
      chapterId: selectedChapter.chapterId,
    };
    this.setState({ loading: true });
    // API -
    axiosPost('curriculum/delete-chapter', dataToSend)
      .then((response) => {
        if (!response.isError) {
          this.setState(
            {
              loading: false,
              successModal: true,
              modalTitle: 'Success',
              message: 'Successfully deleted',
            },
            () => {
              this.getChaptersList(curriculumId);
            }
          );
        } else {
          this.setState({
            loading: false,
            errorModal: true,
            modalTitle: 'Sorry!',
            message: 'Something is wrong. Try again later.',
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errorModal: true,
          modalTitle: 'Sorry!',
          message: 'Something is wrong. Try again later.',
        });
        console.log(error);
      });
  };

  redirectToTopics = (chapterId, curriculumId) => {
    this.props.history.push({
      pathname: "/admin/topics",
      state: { curriculumId: curriculumId, chapterId: chapterId },
    });
  };

  // Modal control -

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      modalTitle: '',
      message: '',
    });
    if (state === 'chapterModal' || state === 'editModal') {
      this.resetForm();
    } else if (state === 'chapterModal') {
      this.setState({
        roomName: '',
        roomUrl: '',
      });
    }
  };


  fileChange = (e) => {
    let file = pdfRef.current && pdfRef.current.files && pdfRef.current.files[0];
    let blnValid = false;
    if (file) {
      let sFileName = file.name;
      var _validFileExtensions = ['.pdf'];
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
            sFileName
                .substr(
                    sFileName.length - sCurExtension.length,
                    sCurExtension.length
                )
                .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (blnValid) {
        this.setState({ uploading: true });
        const data = new FormData();
        data.append('file', file);

        axiosPost(`utility/upload-file`, data, true)
            .then((response) => {
              if (!response.isError) {
                // this.setState((prevState) => {
                //   const links = type === 'homework' ? prevState.homeWorkLink : prevState.classWorkLink;
                //   links[index][name] = response.data?.fileUrl;
                //   return { links };
                // })

                this.setState({
                  pdfUrl: response.data.fileUrl,
                  uploading: false,
                  successModal: true,
                  message: 'Successfully uploaded',
                  modalTitle: 'Success',
                });
              } else {
                this.setState({
                  uploading: false,
                  errorModal: false,
                  message: "Sorry! can't be uploaded.",
                  modalTitle: 'Sorry!',
                });
              }
            })
            .catch((error) => {
              this.setState({
                uploading: false,
                errorModal: false,
                message: "Sorry! can't be uploaded.",
                modalTitle: 'Sorry!',
              });
            });
      } else {
        this.setState({
          errorModal: true,
          message: 'File format is not supported',
          modalTitle: 'Sorry',
        });
      }
    } else {
      this.setState({
        errorModal: true,
        message: 'File size is exceeded',
        modalTitle: 'Sorry',
      });
    }
  };

  render() {
    let {
      loading,
      chapterList,
      descriptionError,
      uploading,
      editModal,
      chapterModal,
      chapterError,
      chapterName,
      successModal,
      errorModal,
      modalTitle,
      message,
      description,
      pdfUrl
    } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <Button color='primary' onClick={() => this.addChapterForm()}>
            Add Chapter
          </Button>
          <br />
          <br />
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <h3 className='mb-0'>Chapters List</h3>
                </CardHeader>

                <DragDropContext onDragEnd={this.onDragEnd}>
                <Table
                  className='align-items-center table-flush'
                  responsive
                  style={{ marginBottom: '100px' }}
                >
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Name</th>
                      <th scope='col'>Description</th>
                      <th scope='col'>Topics</th>
                      <th scope='col' />
                    </tr>
                  </thead>
                  <Droppable droppableId="chapterList">
                    {(provided) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {chapterList &&
                      chapterList.map((value, index) => {
                        return (
                          <Draggable key={value.index} draggableId={value.chapterId} index={index}>
                            {(provided) => (
                          <tr key={index} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                            <th scope='row'>
                              <Media className='align-items-center'>
                                <Media>
                                  <span className='mb-0 text-sm'>
                                    {value.name ? value.name : ''}
                                  </span>
                                </Media>
                              </Media>
                            </th>

                            <td>{value.description ? value.description : ''}</td>


                            <td>
                                <Button color='primary' onClick={() => this.redirectToTopics(value.chapterId, value.curriculumId)}>
                                   Add/Edit Topics
                                </Button>
                            </td>

                            <td className='text-right'>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className='btn-icon-only text-light'
                                  href='JavaScript:Void(0);'
                                  role='button'
                                  size='sm'
                                  color=''
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className='fas fa-ellipsis-v' />
                                </DropdownToggle>
                                <DropdownMenu
                                  className='dropdown-menu-arrow'
                                  right
                                >
                                  <DropdownItem
                                    href='JavaScript:Void(0);'
                                    onClick={() => this.editChapter(index)}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    href='JavaScript:Void(0);'
                                    onClick={() => this.deleteChapter(index)}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                            )}
                          </Draggable>
                        );
                      })}
                  </tbody>
                    )}
                  </Droppable>
                </Table>
                </DragDropContext>

                <CardFooter className='py-4'>
                </CardFooter>
              </Card>
            </div>
          </Row>


        </Container>

        <Modal
            className='modal-dialog-centered'
            size='lg'
            isOpen={chapterModal || editModal}
            toggle={() =>
                this.toggleModal(chapterModal ? 'chapterModal' : 'editModal')
            }
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0'>
              <CardHeader className='bg-transparent pb-5'>
                <div className='text-muted text-center mt-2 mb-3'>
                  <small>{chapterModal ? 'Add new Chapter' : 'Edit Chapter'}</small>
                </div>
              </CardHeader>
              <CardBody className='px-lg-5 py-lg-5'>
                <Form role='form'>
                  <FormGroup className={chapterError ? 'has-danger' : ''}>
                    <label>Chapter Name</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                          placeholder='Chapter name'
                          type='text'
                          name='chapterName'
                          value={chapterName}
                          onChange={(e) =>
                              this.setState({ chapterName: e.target.value })
                          }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={descriptionError ? 'has-danger' : ''}>
                    <label>Description</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                          placeholder='Chapter details'
                          type='textarea'
                          rows='3'
                          name='description'
                          value={description}
                          onChange={(e) =>
                              this.setState({ description: e.target.value })
                          }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    {
                      pdfUrl &&
                          <a>{pdfUrl}</a>
                    }

                    <InputGroup className='input-group-alternative'>
                      <input
                          type='file'
                          name='image'
                          ref={pdfRef}
                          id='myFile'
                          onChange={(e) => this.fileChange(e)}
                          multiple={false}
                      />
                    </InputGroup>
                  </FormGroup>


                  <div className='text-center'>
                    {uploading ? (
                        <span style={{ color: 'red' }}>Wait...</span>
                    ) : null}

                    <Button
                        disabled={uploading || loading ? true : false}
                        className='my-4'
                        color='primary'
                        type='button'
                        onClick={() => {
                          this.addOrUpdateChapter(chapterModal ? false : true);
                        }}
                    >
                      {chapterModal ? 'Save' : 'Update'}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>

        <Modal
            className='modal-dialog-centered'
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
                successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                        ? this.toggleModal('errorModal')
                        : null
            }
        >
          <div className='modal-header'>
            <h5 className='modal-title' id='successModalLabel'>
              {modalTitle}
            </h5>
            <button
                aria-label='Close'
                className='close'
                data-dismiss='modal'
                type='button'
                onClick={() =>
                    successModal
                        ? this.toggleModal('successModal')
                        : errorModal
                            ? this.toggleModal('errorModal')
                            : null
                }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          {this.state.successModal ? (
              <div className='modal-body' style={{ color: 'green' }}>
                {message}
              </div>
          ) : this.state.errorModal ? (
              <div className='modal-body' style={{ color: 'red' }}>
                {message}
              </div>
          ) : null}
          <div className='modal-footer'>
            <Button
                color='primary'
                type='button'
                onClick={() =>
                    successModal
                        ? this.toggleModal('successModal')
                        : errorModal
                            ? this.toggleModal('errorModal')
                            : null
                }
            >
              Ok
            </Button>
          </div>
        </Modal>

      </>
    );
  }
}

export default ChapterManagement;
