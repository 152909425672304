import React from 'react';
import _ from 'lodash';
import moment from 'moment';

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  FormGroup,
  Form,
  CardBody,
  InputGroup,
  Input,
  Modal,
  Button, Badge, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import { axiosGet, axiosPost } from 'util/apiConfig.js';
import {databaseTimeFormat, dateForPaymentListing} from "../../util/constance";
import io from 'socket.io-client';
import "./messages.css";
import classnames from "classnames";
import styles from "./SalesNotification.css";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import Select from "react-select";
import {Device} from "@twilio/voice-sdk";
import {CopyToClipboard} from "react-copy-to-clipboard";

const USER_STATE = {
  CONNECTING : "Connecting",
  READY : "Ready",
  ON_CALL : "On call",
  OFFLINE : "Offline",
}

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userId: '',
      lastReadMessageId: [],
      userName: '',
      activeTab: "1",
      classList: [],
      sectionList: [],
      sectionId: '',
      emailContent: '',

      receivedMessages: [],
      allMessages: [],
      chatMessages: [],
      textMessageList: [],
      voiceMessageList: [],
      messageLoader: false,
      textMessage: '',
      msgClass: '',
      msgClassError: false,
      textMesageError: false,
      selectedMessage: {},
      unreadMessageList: [],

      listPage: 1,
      totalListPage: '',
      rowLimit: 10,
      classId: '',
      mobileNumber: '',
      email: '',
      subject: 'This is Subject',

      fileUploaded: '',

      modalTitle: '',
      successModal: false,
      errorModal: false,
      teacherName: '',
      message: '',
      editModal: false,
      addModal: false,
      replyModal: false,
      showVideoModal: false,
      to: '',
      messageReply: '',
      replyMessageError: false,
      selectedMessageId: '',
      userState: USER_STATE.OFFLINE,
      notificationCallModal: false,
      messageStates: {},
    };
  }

  componentDidMount() {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
    let storage = localStorage.getItem('Sanga_Admin');
    storage = storage ? JSON.parse(storage) : null;
    const socket = io(process.env.REACT_APP_SOCKET)
    socket.on('connection', ()=>console.log(socket.id))
    socket.on('message', function(data) {
      this.showNotification();
      this.getAllReceivedMessages();
      const { selectedMessage } = this.state;
        if (selectedMessage) {
            this.getAllTwilioReceivedMessageUsers(selectedMessage);
        }
    }.bind(this));
    this.setState(
      {
        userId: storage && storage.userId,
        userName: '',
        newRequestList: [],
        allMessages: [],
      },
      () => {
        this.getClass();
        this.getVoiceMessage();
        this.getAllSectionsWithLatestMessage();
        this.getAllReceivedMessages();
        setInterval(() => {
          this.getAllSectionsWithLatestMessage();
        }, 3600000);
      }
    );
  }

  callInit = (sale, index) => {
    axiosPost('sales/token', {})
        .then((response) => {
          if (!response.isError) {
            this.setState(
                {
                  loading: false
                },
                () => {
                  const token = response.data.token;
                  const device = new Device(token, {
                    logLevel: 1,
                    codecPreferences: ["opus", "pcmu"],
                  });
                  device.register();
                  this.setState({device})
                  device.addListener("connect", (device) => {
                    console.log("Connect event listener added .....");
                    return device;
                  });
                  device.on("registered", () => {
                    console.log("Agent registered");
                    this.setState({userState: USER_STATE.READY});
                  });
                  device.on("connect", (connection) => {
                    console.log("Call connect");
                    this.setState({userState: USER_STATE.ON_CALL})
                  });
                  device.on("disconnect", () => {
                    console.log("Disconnect event");
                    this.setState({userState: USER_STATE.READY})
                  });

                  setTimeout(()=>{
                    this.handleCall(sale, index)
                  }, 1000)

                  return () => {
                    device.destroy();
                    this.setState({device: undefined})
                    this.setState({userState: USER_STATE.OFFLINE})
                  };
                }
            );
          } else {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            errorModal: true,
            modalTitle: 'Sorry!',
            message: 'Something is wrong. Try again later.',
          });
          console.log(error);
        });
  }

  getClass = () => {
    // API --
    axiosGet(`class/get-classes-list`)
        .then((response) => {
          if (!response.isError) {
            this.setState({
              classList: response.data || [],
            });
          } else {
            this.setState({
              classList: [],
            });
          }
        })
        .catch((error) => {
          this.setState({
            classList: [],
          });
        });
  };

  getVoiceMessage = () => {
    // API --
    axiosGet(`sales/get-sales-followup-voice-message`)
        .then((response) => {
          if (!response.isError) {
            this.setState({
              voiceMessageList: response.data || [],
            });
          } else {
            this.setState({
              voiceMessageList: [],
            });
          }
        })
        .catch((error) => {
          this.setState({
            voiceMessageList: [],
          });
        });
  };

  showNotification = () => {
    // Check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('Notifications not supported in this browser');
      return;
    }

    // Check if permission has been granted for notifications
    if (Notification.permission !== 'granted') {
      console.log('Permission not granted for notifications');
      return;
    }

    // Create a new notification
    const notification = new Notification('New Notification', {
      body: 'This is a notification',
    });

    // Play a sound
    const audio = new Audio('/audio/iphone_sound.mp3');
    audio.play();
  };

  getAllSectionsWithLatestMessage = () => {
    axiosGet(`message/get-all-section-with-latest-message`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            allMessages: response.data,
          });
        } else {
          this.setState({
            allMessages: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          allMessages: [],
        });
        console.log(error);
      });
  }

  getLastUnreadMessageId = () => {
    axiosGet(`message/get-message-last-unread-id`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            lastReadMessageId: response?.data[0]?.sid || '',
          });
        } else {
          this.setState({
            lastReadMessageId: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          lastReadMessageId: [],
        });
      });
  }
  getAllMessageBySectionId = (sectionId, teacherName) => {
    this.setState({selectedMessageId: sectionId})
    const { allMessages } = this.state;
    axiosGet(`message/get-all-message-section-by-id/${sectionId}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            teacherName: teacherName,
            chatMessages: response.data,
          });
          this.updateSystemMessageRead( sectionId);
        } else {
          this.setState({
            chatMessages: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          chatMessages: [],
        });
      });
  }

  getAllTwilioReceivedMessageUsers = (from, sid) => {
    this.setState({selectedMessage: from})
    const { lastReadMessageId } = this.state;
    axiosGet(`message/get-all-twilio-received-message-users/${from}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            textMessageList: response.data,
          });
          this.updateMessageServiceId( [sid,...lastReadMessageId]);
          this.setState({lastReadMessageId: [sid,...lastReadMessageId]})
        } else {
          this.setState({
            textMessageList: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          textMessageList: [],
        });
      });
  }

  updateMessageServiceId = (sid) => {
    axiosPost(`message/update-message-last-unread-id`, {sid})
      .then((response) => {
        if (!response.isError) {
        }
      })
      .catch((error) => {

      });
  }

  updateSystemMessageRead = (sectionId) => {
    const { allMessages } = this.state;
    axiosPost(`message/update-message-status`, {sectionId})
      .then((response) => {
        if (!response.isError) {
          const index = allMessages.findIndex((item) => item.sectionId === sectionId);
          allMessages[index].latestMessage.adminRead = true;
          this.setState({allMessages});
        }
      })
      .catch((error) => {

      });
  }

  // to get all messages -
  getAllMessages = () => {
    const { listPage, rowLimit } = this.state;
    // API -
    axiosGet(`message/get-all-message/${listPage}/${rowLimit}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            allMessages: response.data?.result,
            totalNewPage: response.data?.noofpage,
          });
        } else {
          this.setState({
            allMessages: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          allMessages: [],
        });
        console.log(error);
      });
  };

  getAllReceivedMessages = () => {
    this.setState({messageLoader: true})
    axiosGet(`message/get-all-twilio-received-message`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            messageLoader: false,
            receivedMessages: response.data,
          });
          this.getLastUnreadMessageId();
        } else {
          this.setState({
            messageLoader: false,
            receivedMessages: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          messageLoader: false,
          receivedMessages: [],
        });
        console.log(error);
      });
  };



  replyMessageResponse = () => {
    const { selectedMessage } = this.state;
    let { replyMessage, loading } = this.state;
    if(!replyMessage || replyMessage == ''){
      return alert('Please enter reply message');
    }
    if (replyMessage && !loading) {
      this.setState({ loading: true });
      let dataToSend = {
       message: replyMessage,
       to: selectedMessage,
      };
      // API -
      axiosPost('message/send-reply', dataToSend)
        .then((response) => {
          if (!response.isError) {
            this.getAllTwilioReceivedMessageUsers(selectedMessage);
            this.setState(
              {
                loading: false,
                successModal: true,
                modalTitle: 'Success',
                message: 'Successfully added',
                replyMessage: '',
                to: '',
              },
              () => {
              }
            );
          } else {
            this.setState({
              loading: false,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
              replyMessage: '',
              to: '',
              replyMessageError: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            errorModal: true,
            message: 'Something is wrong. Try again later.',
            replyMessage: '',
            to: '',
          });
        });
    }
  };

  changePage = (status) => {
    if (status && status === 'next') {
      this.setState({ listPage: this.state.listPage + 1 }, () =>
        this.getAllMessages()
      );
    } else if (status && status === 'prev') {
      this.setState({ listPage: this.state.listPage - 1 }, () =>
        this.getAllMessages()
      );
    }
  };

  // for redirecting to teacher's profile -
  viewMessage = (index) => {
    let { allMessages } = this.state;
    let selectedMessage = allMessages[index];
    if (selectedMessage && selectedMessage.messageType == 'MESSAGE') {
      this.setState({
        selectedMessage: selectedMessage,
        textMessage: selectedMessage.message,
        editModal: true,
      });
    } else if (selectedMessage && selectedMessage.messageType == 'FILE') {
      // console.log("video modal selected");
      this.setState({
        showVideoModal: true,
        selectedMessage: selectedMessage,
      });
    }
  };

  // Modal control -
  toggleModal = (state) => {
    if (state == 'editModal') {
      this.setState({
        [state]: !this.state[state],
        selectedMessage: {},
        textMessage: '',
      });
    } else if (state == 'addModal') {
      this.setState({
        [state]: !this.state[state],
        textMessage: '',
        msgClass: '',
        textMesageError: false,
        msgClassError: false,
      });
    } else if (state == 'addReply') {
      this.setState({
        [state]: !this.state[state],
        to: '',
        replyMessage: '',
      });
    } else {
      this.setState({
        [state]: !this.state[state],
        modalTitle: '',
        message: '',
      });
    }
  };

  getAllSections = () => {
    const { classId } = this.state;
    this.setState({ loading: false });
    axiosGet(`section/get-sections-dropdown/${classId}`)
        .then((response) => {
          if (!response.isError) {
              this.setState({ loading: false, sectionList: response.data });
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  sendMessageByText = () => {
    const {email, mobileNumber, emailContent, classId, sectionId, subject} = this.state;
    if(!email || !mobileNumber || !emailContent || !classId || !sectionId || !subject){
      return alert('Please enter all fields');
    }
    const dataToSend = {
        email,
        mobileNumber,
        message:emailContent,
        classId,
        sectionId,
        subject
    }
    axiosPost('message/send-email-message', dataToSend)
        .then((response) => {
          if (!response.isError) {
            this.setState(
                {
                  loading: false,
                  successModal: true,
                  modalTitle: 'Success',
                  message: 'Successfully added',
                  email: '',
                  mobileNumber: '',
                  emailContent: '',
                  sectionId: null,
                  subject: '',
                  sectionList: [],
                },
                () => {
                }
            );
          } else {
            this.setState({
              loading: false,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            errorModal: true,
            message: 'Something is wrong. Try again later.',
          });
        });
  }

  setSectionDetail = () => {
    const { sectionId } = this.state;
    const section = this.state.sectionList.find((item) => item.sectionId === sectionId);
    this.setState({
        mobileNumber: section?.mobileNumber,
        email: section?.email
    })
  };

  isTimeInRange(timezone) {
    const now = new Date();
    const userTime = new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: false, timeZone: timezone }).format(now);
    const hour = parseInt(userTime, 10);

    // Check if the time is between 8 AM (8) and 9 PM (21)
    return hour >= 8 && hour < 21;
  }

  rejectCall = ()=>{
    const {device, selectedNumber} = this.state
    device.emit("disconnect");
    device.emit("cancel");
    device.emit("reject");
    device.disconnectAll({params: {To: selectedNumber} });
  }

  handleCall = async (sale, index) => {
    const confirm = window.confirm(`Are you sure want to call? \nPlease verify details,\nMobile: ${sale.mobile}, `)
    if(!confirm){
      return false;
    }
    const {device, userType } = this.state;
    device.emit("connect");
    device.connect({params: {To: sale.mobile, userType } }).then((call) => {
      call.on("accept", () => {
        this.updateMessageState(index, USER_STATE.ON_CALL);
        console.log("call accepted");
      });
      call.on("disconnect", () => {
        console.log("The call has been disconnected.");
        this.updateMessageState(index, USER_STATE.READY);
      });
      call.on("reject", () => {
        console.log("The call was rejected.");
      });
      call.on("cancel", () => {
        console.log("The call was rejected.");
        this.updateMessageState(index, USER_STATE.READY);
      });
    })
  };

  updateMessageState = (index, newState) => {
    this.setState(prevState => ({
      messageStates: {
        ...prevState.messageStates,
        [index]: newState
      }
    }));
  };

  clientConnect = (index) => {
    let { voiceMessageList } = this.state;
    let selectedNotification = voiceMessageList[index];
    this.setState({
      notificationCallModal: true,
      selectedNumber: selectedNotification.mobile || ''
    });
  };

  replyMessageResponses = () => {
    let confirm = window.confirm('Are you sure want send message?');
    if (confirm) {
      let { twillioMessage, loading, selectedNumber } = this.state;
      if(!twillioMessage || twillioMessage == ''){
        return alert('Please enter message');
      }
      if (twillioMessage && !loading) {
        this.setState({ loading: true });
        let dataToSend = {
          message: twillioMessage,
          to: `${selectedNumber}`,
        };
        // API -
        axiosPost('message/send-reply', dataToSend)
            .then((response) => {
              if (!response.isError) {
                this.setState(
                    {
                      loading: false,
                      notificationCallModal: false,
                      twillioMessage: '',
                      selectedNumber: '',
                    },
                    () => {
                    }
                );
              } else {
                this.setState({
                  loading: false,
                  modalTitle: 'Sorry!',
                  message: 'Something is wrong. Try again later.',
                  notificationCallModal: false,
                  twillioMessage: '',
                  selectedNumber: '',
                });
              }
            })
            .catch((error) => {
              this.setState({
                loading: false,
                errorModal: true,
                message: 'Something is wrong. Try again later.',
                notificationCallModal: false,
                twillioMessage: '',
                selectedNumber: '',
              });
            });
      }
    }
  };

  render() {
    let {
      allMessages,
      successModal,
      errorModal,
      modalTitle,
      message,
      classList,
      sectionList,
      sectionId,
      receivedMessages,
      replyMessage,
      chatMessages,
      selectedMessageId,
      textMessageList,
      activeTab,
      selectedMessage,
      messageLoader,
      lastReadMessageId,
      teacherName,
      classId,
      mobileNumber,
      email,
      subject,
      voiceMessageList,
      notificationCallModal,
      twillioMessage,
      emailContent,
      loading

    } = this.state;
    const isUnread= allMessages.find((message) => message?.latestMessage?.adminRead === false);
    return (
      <>
        <Header />
          <Nav tabs>
            <NavItem>
              <NavLink
                  className={classnames({ active: activeTab === '1' }, styles.navLink)}
                  onClick={() => {
                    this.toggle("1");
                  }}>
                Text Messages
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  className={classnames({ active: activeTab === '2' }, styles.navLink)}
                  onClick={() => {
                    this.toggle("2");
                  }}
              >
                { isUnread
                     && (
                        <i style={{color: 'red', marginRight: '2px', fontSize: 'small' }} className="fa fa-circle"></i>
                    )
                }
              System Messages
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  className={classnames({ active: activeTab === '3' }, styles.navLink)}
                  onClick={() => {
                    this.toggle("3");
                  }}>
                Send Messages
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  className={classnames({ active: activeTab === '4' }, styles.navLink)}
                  onClick={() => {
                    this.toggle("4");
                  }}>
                Voice Messages
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Container fluid>
                <Row >
                  <div className='col'>
                    <Card className='shadow' >
                      <CardHeader className='border-0'>
                      </CardHeader>
                      <div className={'row'}>
                        <div className="list-view " style={{height: '500px', marginLeft: '10px'}}>
                          <h2>Sections</h2>

                          <ul >
                            {!messageLoader && receivedMessages.length ?
                                receivedMessages.map((value, index) => {
                                  const isExist = lastReadMessageId.includes(value.sid);
                                  return (
                                      <>
                                        <li key={index}
                                            onClick={() => this.getAllTwilioReceivedMessageUsers(value?.from, value?.sid)}
                                            style={{
                                              backgroundColor: value?.from === selectedMessage ? 'rgb(186 215 229)' : 'white',
                                            }}
                                        >
                                          <div className="message-container">
                                            {
                                              !isExist && (
                                                    <i style={{color: 'red', marginRight: '2px', fontSize: 'small' }} className="fa fa-circle"></i>
                                                )
                                            }
                                            <span className="message">{value.from} <span>
                                              {
                                                value?.sectionName ? (
                                                            <a
                                                                href={`https://fiverkids.com/userdashboardpreview/${value.publicUserUrl}`}
                                                                target={'_blank'}
                                                            >
                                                              {value.sectionName}
                                                            </a>
                                                  ): 'Trial'
                                              }</span>  </span>
                                            <br/>
                                            <a className="message-link">{value.body}</a>
                                          </div>

                                        </li>
                                      </>
                                  )
                                }):
                                <><a>Loading....</a></>
                            }
                          </ul>
                        </div>

                        <div className="message-list col" style={{height: '500px'}}>
                          <div style={{ marginTop: '40px' }}>
                            {textMessageList &&
                                textMessageList.map((value, index) => {
                                  const float = value?.direction == 'outbound-api' ? 'right' : 'left';
                                  const color = value?.direction == 'outbound-api' ? '#f8cfcf' : '#c4e1ea';
                                  return (
                                      <>
                                        <div
                                            key={index}
                                            style={{
                                              backgroundColor: color,
                                              padding: '10px',
                                              marginBottom: '10px',
                                              borderRadius: '8px',
                                              float: float,
                                              clear: 'both',
                                              width: '50%',
                                            }}
                                        >
                                          <div>
                                            <span style={{fontWeight: 'bold', fontStyle: 'initial', fontSize: 'large'}}>{value.body}</span>
                                          </div>
                                          <div style={{ marginTop: '5px' }}>
                                            <span>{moment(value.dateCreated).format(dateForPaymentListing)}</span>
                                            <span>&nbsp;{moment(value.dateCreated).format(databaseTimeFormat)}</span>
                                          </div>
                                        </div>
                                      </>
                                  )
                                })
                            }
                          </div>
                        </div>
                      </div>
                      <div>
                        { textMessageList && textMessageList.length > 0 && (
                            <div style={{ display: 'flex', justifyContent: 'end', marginRight: '5px' }}>
                              <Input
                                  placeholder='Message'
                                  type='text'
                                  name='className'
                                  style={{ width: '65%'}}
                                  value={replyMessage}
                                  onChange={(e) =>
                                      this.setState({
                                        replyMessage: e.target.value,
                                        replyMessageError: false,
                                      })
                                  }
                              />
                              <Button
                                  color='primary'
                                  type='button'
                                  onClick={() => this.replyMessageResponse()}
                              >
                                Send
                              </Button>
                            </div>
                        )}
                      </div>
                    </Card>
                  </div>
                </Row>
              </Container>
            </TabPane>
            <TabPane tabId='2'>
                <Container fluid>
                  <Row >
                    <div className='col'>
                      <Card className='shadow' >
                        <CardHeader className='border-0'>
                          <h3 className='mb-0'>All Messages</h3>
                        </CardHeader>
                        <div className={'row'}>
                          <div className="list-view " style={{height: '500px', marginLeft: '10px'}}>
                            <h2>Sections</h2>
                            <ul >
                              {allMessages &&
                                  allMessages.map((value, index) => {
                                    return (
                                        <>
                                          <li key={index} onClick={() => this.getAllMessageBySectionId(value?.sectionId, value?.teacherName)}
                                              style={{
                                                backgroundColor: value?.sectionId === selectedMessageId ? 'rgb(186 215 229)' : 'white',
                                              }}
                                          >
                                            <div className="message-container">
                                              {
                                                  value?.latestMessage?.adminRead === false && (
                                                      <i style={{color: 'red', marginRight: '2px', fontSize: 'small' }} className="fa fa-circle"></i>
                                                  )
                                              }
                                                  <a
                                                      href={`https://fiverkids.com/userdashboardpreview/${value.publicUserUrl[0].publicUrl}`}
                                                      target={'_blank'}
                                                  ><span>{value?.name || ''}</span></a>
                                              <br/>
                                              <a className="message-link">{value?.latestMessage?.message || ''}</a>
                                            </div>
                                          </li>
                                        </>
                                    )
                                  })
                              }
                            </ul>
                          </div>
                          <div className="message-list col" style={{height: '500px'}}>
                            <div>
                              <h2 className={'float-left'}>Student</h2>
                              <h2 className={'float-right'} style={{marginRight: '20px'}}>Teacher
                                {teacherName && (
                                    <span style={{marginLeft: '10px'}}>: {teacherName}</span>
                                )}

                              </h2>
                            </div>
                            <div style={{ marginTop: '40px' }}>
                              {chatMessages &&
                                  chatMessages.map((value, index) => {
                                    const float = value?.userType == 'teacher' ? 'right' : 'left';
                                    const color = value?.userType == 'teacher' ? '#f8cfcf' : '#c4e1ea';
                                    return (
                                        <>
                                          <div
                                              key={index}
                                              style={{
                                                backgroundColor: color,
                                                padding: '10px',
                                                marginBottom: '10px',
                                                borderRadius: '8px',
                                                float: float,
                                                clear: 'both',
                                                width: '50%',
                                              }}
                                          >
                                            <div>
                                              <span style={{fontWeight: 'bold', fontStyle: 'initial', fontSize: 'large'}}>{value.message}</span>
                                            </div>
                                            <div style={{ marginTop: '5px' }}>
                                              <span>{moment(value.createdAt).format(dateForPaymentListing)}</span>
                                              <span>&nbsp;{moment(value.createdAt).format(databaseTimeFormat)}</span>
                                            </div>
                                          </div>
                                        </>
                                    )
                                  })
                              }
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </Row>
                </Container>
            </TabPane>
            <TabPane tabId='3'>
                <Container fluid>
                  <Row >
                    <div className='col'>
                      <Card className='shadow' >
                        <CardHeader className='border-0'>
                          <h3 className='mb-0'>Send Message</h3>
                        </CardHeader>
                        <div className="row">
                        <div className="col-md-6">
                          <FormGroup className="availability-formgroup">
                            <InputGroup className="input-group-alternative">
                              <div style={{ width: "100%", minWidth: "200px" }}>
                                <Select
                                    style={{ width: "100%", minWidth: "200px" }}
                                    options={[
                                      ...(classList &&
                                          classList.map((item) => ({
                                            value: item.classId,
                                            label: item.name,
                                          }))),
                                    ]}
                                    placeholder="Select Class"
                                    onChange={(e) =>
                                        this.setState({ classId: e.value }, () => {
                                          this.getAllSections()
                                        })
                                    }
                                    isSearchable={true}
                                />
                              </div>
                            </InputGroup>
                          </FormGroup>

                          { sectionList && sectionList.length > 0 && (
                              <FormGroup className="availability-formgroup">
                                <InputGroup className="input-group-alternative">
                                  <div style={{ width: "100%", minWidth: "200px", marginTop: '20px' }}>
                                    <Select
                                        style={{ width: "100%", minWidth: "200px" }}
                                        options={[
                                          ...(sectionList &&
                                              sectionList.map((item) => ({
                                                value: item.sectionId,
                                                label: item.name,
                                              }))),
                                        ]}
                                        placeholder="Select Section"
                                        onChange={(e) =>
                                            this.setState({ sectionId: e.value }, () => {
                                              this.setSectionDetail()
                                            })
                                        }
                                        isSearchable={true}
                                    />
                                  </div>
                                </InputGroup>
                              </FormGroup>
                          )}

                          {
                            mobileNumber && (
                                <>
                                <div style={{marginTop: '20px'}}>
                                </div>
                                  <FormGroup>
                                    <InputGroup>
                                      <div style={{width: "100%"}}>
                                        <Input
                                            placeholder='Mobile Number'
                                            type='text'
                                            name='className'
                                            value={mobileNumber}
                                            style={{ width: '100%'}}
                                            onChange={(e) => {this.setState({mobileNumber: e.target.value})}}
                                        ></Input>
                                      </div>
                                    </InputGroup>
                                  </FormGroup>
                                </>
                              )
                          }

                          {
                            email && (
                                  <FormGroup>
                                    <InputGroup >
                                      <div style={{width: "100%"}}>
                                        <Input
                                            placeholder='Email'
                                            type='text'
                                            name='className'
                                            value={email}
                                            style={{ width: '100%'}}
                                            onChange={(e) => {this.setState({email: e.target.value})}}
                                        >
                                        </Input>
                                      </div>
                                    </InputGroup>
                                  </FormGroup>
                              )
                          }
                          {
                              email && (
                                  <>
                                    <FormGroup >
                                      <InputGroup>
                                        <div style={{width: "100%"}}>
                                          <Input
                                              placeholder='Email Subject'
                                              type='text'
                                              name='className'
                                              style={{ width: '100%'}}
                                              value={subject}
                                              onChange={(e) => {this.setState({subject: e.target.value})}}
                                          >
                                          </Input>
                                        </div>
                                      </InputGroup>
                                    </FormGroup>
                                    <FormGroup >
                                      <InputGroup >
                                        <div style={{width: "100%"}}>
                                          <textarea
                                              placeholder='Email Content'
                                              rows={5}
                                              name='className'
                                              style={{ width: '100%'}}
                                              onChange={(e) => {this.setState({emailContent: e.target.value})}}
                                          >
                                          </textarea>
                                        </div>
                                      </InputGroup>
                                    </FormGroup>
                                    <FormGroup >
                                        <InputGroup >
                                            <div   >
                                              <Button
                                                  color='primary'
                                                  type='button'
                                                  onClick={() => this.sendMessageByText()}
                                                >
                                                  Send Message
                                                </Button>
                                            </div>
                                        </InputGroup>
                                    </FormGroup>
                                  </>
                                )
                            }
                        </div>
                          <div className="col-md-6" style={{marginTop: '10px'}}>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </Row>
                </Container>
            </TabPane>
            <TabPane tabId='4'>
                <Container fluid>
                  <Row >
                    <div className='col'>
                      <Card className='shadow' >
                        <CardHeader className='border-0'>
                          <h3 className='mb-0'>Voice Message</h3>
                        </CardHeader>
                        <CardBody>

                          <Table
                              className='align-items-center table-flush cp;'
                              responsive
                              style={{ marginBottom: '100px' }}
                          >
                            <thead className='thead-light'>
                            <tr>
                              <th scope='col'></th>
                              <th scope='col'>Mobile Number</th>
                              <th scope='col'>Audio</th>
                              <th scope='col'>Link</th>
                              <th scope='col'>Status</th>
                              <th scope='col'>Time</th>

                              {/*<th scope='col'>Notification Sent</th>*/}
                              <th scope='col' />
                            </tr>
                            </thead>
                            <tbody>
                            {voiceMessageList &&
                                voiceMessageList.map((value, index) => {
                                  const isActive = Object.values(this.state.messageStates).some(
                                      state => state === 'Connecting' || state === 'On call'
                                  )
                                  const userStateForMessage = this.state.messageStates[index] || USER_STATE.OFFLINE;
                                  return (
                                      value.isActive && (
                                          <tr key={index}>
                                            <td  className={'table-column-width'} >
                                              {
                                                (userStateForMessage === 'Connecting' || userStateForMessage === 'On call') ? (
                                                    <button
                                                        className={'btn btn-danger'}
                                                        onClick={() => this.rejectCall(value.mobile)}
                                                    >Reject
                                                    </button>
                                                ): (
                                                    <button
                                                        className={'btn btn-white'}
                                                        disabled={isActive}
                                                        onClick={() =>this.callInit(value, index)}
                                                    ><i className="fa fa-phone" style={{fontSize:'20px'}} ></i>
                                                    </button>
                                                )
                                              }
                                              <button onClick={() => this.clientConnect(index)} className={'btn btn-white'}>
                                                <i className="fa fa-comments" style={{fontSize:'20px'}} aria-hidden="true" ></i>
                                              </button>
                                            </td>
                                            <td className={'table-column-width'}>
                                              {value.mobile && (
                                                  <a href={"tel:" + value.mobile}>{value.mobile}</a>
                                              )}{" "}
                                            </td>
                                            <td  className={'table-column-width'}>
                                              {
                                                value.fileName ? (
                                                    <AudioPlayer
                                                        autoPlay={false}
                                                        autoPlayAfterSrcChange={false}
                                                        src={value.fileName}
                                                        onPlay={e => console.log("onPlay")}
                                                        // other props here
                                                    />
                                                ): (
                                                    <></>
                                                )
                                              }
                                            </td>
                                            <td className={'table-column-width'}>
                                                {
                                                  value.fileName ? (
                                                      <CopyToClipboard text={value.fileName}>
                                                        <i
                                                            style={{cursor: 'pointer'}}
                                                            className='BtnFill'
                                                            color='success'
                                                        ><i className='fa fa-copy'></i>
                                                        </i>
                                                      </CopyToClipboard>
                                                  ):(
                                                      <></>
                                                  )
                                                }
                                            </td>
                                            <td className={'table-column-width'}>
                                              {value.callStatus && (
                                                  <span>{value.callStatus}</span>
                                              )}{" "}
                                            </td>
                                            <td  className={'table-column-width'}>
                                              <Media className='align-items-center'>
                                                <Media>
                                                  <span className='mb-0 text-sm'>
                                                    {value.createdAt
                                                        ? moment(value.createdAt).format('ll hh:mm A')
                                                        : ''}
                                                  </span>
                                                </Media>
                                              </Media>
                                            </td>
                                          </tr>
                                      )
                                  );
                                })}
                            </tbody>
                          </Table>

                        </CardBody>
                      </Card>
                    </div>
                  </Row>
                </Container>
            </TabPane>

          </TabContent>


          {/* For notification modal */}
          <Modal
            className='modal-dialog-centered'
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
              successModal
                ? this.toggleModal('successModal')
                : errorModal
                ? this.toggleModal('errorModal')
                : null
            }
          >
            <div className='modal-header'>
              <h5 className='modal-title' id='successModalLabel'>
                {modalTitle}
              </h5>
              <button
                aria-label='Close'
                className='close'
                data-dismiss='modal'
                type='button'
                onClick={() =>
                  successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                    ? this.toggleModal('errorModal')
                    : null
                }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            {this.state.successModal ? (
              <div className='modal-body' style={{ color: 'green' }}>
                {message}
              </div>
            ) : this.state.errorModal ? (
              <div className='modal-body' style={{ color: 'red' }}>
                {message}
              </div>
            ) : null}
            <div className='modal-footer'>
              <Button
                color='primary'
                type='button'
                onClick={() =>
                  successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                    ? this.toggleModal('errorModal')
                    : null
                }
              >
                Ok
              </Button>
            </div>
          </Modal>
          <Modal
              className='modal-dialog-centered'
              size='lg'
              isOpen={notificationCallModal}
              toggle={() =>
                  this.toggleModal('notificationCallModal')
              }
          >
            <div className='modal-body p-0'>
              <Card className='bg-secondary shadow border-0'>
                <CardHeader className='bg-transparent pb-5'>
                  <div className='text-muted text-center'>
                    <small>{'Message'}</small>
                  </div>
                </CardHeader>
                <CardBody className='px-lg-5 py-lg-5'>
                  <Form role='form'>
                    <FormGroup >
                      <InputGroup className='input-group-alternative'>
                                       <textarea
                                           placeholder='Enter Message'
                                           cols={100}
                                           rows={5}
                                           value={twillioMessage}
                                           onChange={(e) =>
                                               this.setState({ twillioMessage: e.target.value })
                                           }
                                       />
                      </InputGroup>
                    </FormGroup>

                    <div className="row text-right">
                      <div className="ml-auto" style={{marginRight: '10px'}}>
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => this.setState({ notificationCallModal: !notificationCallModal })}
                        >
                          Cancel
                        </Button>
                        <Button
                            disabled={!!loading}
                            color="primary"
                            type="button"
                            onClick={() => this.replyMessageResponses()}
                        >
                          Send Message
                        </Button>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Modal>
      </>
    );
  }
}

export default Message;
