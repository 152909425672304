/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { axiosGet, axiosDelete } from "util/apiConfig.js";

class Students extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userId: "",
      userName: "",
      studentRequestList: [],
      allStudents: [],

      page: 1,
      totalPage: 0,
      limit: 10,
      modalTitle: null,
      successModal: false,
      successMessage: "",
      errorModal: false,
      errorMessage: "",
      searchString: "",
    };
  }

  componentDidMount() {
    this.setState(
      {
        userId: "",
        userName: "",
        studentRequestList: [],
        allStudents: [],
      },
      () => {
        this.getAllStudents();
      }
    );
  }

  // for getting all approved student -
  getAllStudents = () => {
    const { page, limit, searchString } = this.state;
    let url = `user/list-of-student/${page}/${limit}`;
    if (searchString) {
      url = `${url}?email=${searchString}`;
    }

    this.setState({ loading: false });
    axiosGet(url)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            loading: false,
            allStudents: response.data?.result,
            totalPage: response.data?.noofpage,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // For changing page -
  changePage = (status) => {
    if (status && status === "next") {
      this.setState({ page: this.state.page + 1 }, () => this.getAllStudents());
    } else if (status && status === "prev") {
      this.setState({ page: this.state.page - 1 }, () => this.getAllStudents());
    }
  };

  // to redirect to student profile -
  viewProfile = (userId) => {
    // e.preventDefault();
    this.props.history.push({
      pathname: "/admin/profile/" + userId,
      state: {},
    });
  };

  deleteStudent = (userId) => {
    let confirm = window.confirm("Are you sure you want to delete student!");
    if (confirm) {
      axiosDelete(`user/delete-student/${userId}`)
        .then((response) => {
          if (!response.isError) {
            this.setState(
              {
                successModal: true,
                modalTitle: "Success",
                message: "Student has been deleted successfully.",
              },
              () => {
                this.getAllStudents();
              }
            );
          } else {
            this.setState({
              errorModal: true,
              modalTitle: "Sorry!",
              message: response.message
                ? response.message
                : "Something is wrong. Try again later.",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            errorModal: true,
            modalTitle: "Sorry!",
            message: error.message
              ? error.message
              : "Something is wrong. Try again later.",
          });
        });
    }
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      modalTitle: "",
      message: "",
    });
  };

  render() {
    let {
      loading,
      allStudents,
      page,
      totalPage,
      message,
      modalTitle,
      successModal,
      errorModal,
    } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0" style={{ display: "flex" }}>
                  <h3 className="mb-0">All Students</h3>

                  <div
                    className="col-md-4 text-center"
                    style={{ float: "right", marginLeft: "55%" }}
                  >
                    <input
                      placeholder="Search by Email"
                      type="text"
                      name="search"
                      className="form-control emailInput"
                      onChange={(e) => {
                        this.setState({ searchString: e.target.value }, () => {
                          this.getAllStudents();
                        });
                      }}
                    />
                  </div>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Is Subscribed</th>
                      <th scope="col">Credit</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {allStudents &&
                      allStudents.length > 0 &&
                      allStudents.map((data, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">
                              <Media className="align-items-center">
                                <span className="mb-0 text-sm">
                                  {data.name && data.name}
                                </span>
                              </Media>
                            </th>
                            <td>{data.email && data.email}</td>
                            <td>
                              {data.isInSubscription ? (
                                <Badge color="" className="badge-dot mr-4">
                                  <i className="bg-success" />
                                  Yes
                                </Badge>
                              ) : (
                                <Badge color="" className="badge-dot">
                                  <i className="bg-warning" />
                                  No
                                </Badge>
                              )}
                            </td>
                            <td>{data.classCredit && data.classCredit}</td>

                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="JavaScript:Void(0);"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    href="JavaScript:Void(0);"
                                    onClick={() => this.viewProfile(data.id)}
                                  >
                                    View profile
                                  </DropdownItem>
                                  <DropdownItem
                                    href="JavaScript:Void(0);"
                                    onClick={() => this.deleteStudent(data.id)}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className={page > 1 ? "" : "disabled"}>
                        <PaginationLink
                          onClick={() => this.changePage("prev")}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className="active">
                        <PaginationLink onClick={(e) => e.preventDefault()}>
                          {page}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalPage > page ? "" : "disabled"}
                      >
                        <PaginationLink onClick={() => this.changePage("next")}>
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
              successModal
                ? this.toggleModal("successModal")
                : errorModal
                ? this.toggleModal("errorModal")
                : null
            }
          >
            <div className="modal-header">
              <h5 className="modal-title" id="successModalLabel">
                {modalTitle}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() =>
                  successModal
                    ? this.toggleModal("successModal")
                    : errorModal
                    ? this.toggleModal("errorModal")
                    : null
                }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            {this.state.successModal ? (
              <div className="modal-body" style={{ color: "green" }}>
                {message}
              </div>
            ) : this.state.errorModal ? (
              <div className="modal-body" style={{ color: "red" }}>
                {message}
              </div>
            ) : null}
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                onClick={() =>
                  successModal
                    ? this.toggleModal("successModal")
                    : errorModal
                    ? this.toggleModal("errorModal")
                    : null
                }
              >
                Ok
              </Button>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default Students;
