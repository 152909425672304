import React from 'react';
import moment from 'moment';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Container,
  Row,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroup,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import { fetchTimeZones } from 'util/timeZones';
import Select from 'react-select'
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { withRouter } from 'react-router-dom';

import {
  timeForMessage,
  databaseDateTimeFormat,
  databaseTimeFormat,
  dateWithCardinalDate,
  publicSection,
  privateSection,
  halfClass,
  fullClass
} from 'util/constance';
import classnames from 'classnames';
import { axiosGet, axiosPost } from 'util/apiConfig.js';

const weekDays = [
  { name: 'Sun', key: 'Sunday', isActive: false },
  { name: 'Mon', key: 'Monday', isActive: false },
  { name: 'Tue', key: 'Tuesday', isActive: false },
  { name: 'Wed', key: 'Wednesday', isActive: false },
  { name: 'Thu', key: 'Thursday', isActive: false },
  { name: 'Fri', key: 'Friday', isActive: false },
  { name: 'Sat', key: 'Saturday', isActive: false },
];

const belts = [
  { value: 'TBD', label: 'TBD' },
  { value: 'Yellow', label: 'Yellow' },
  { value: 'Brown', label: 'Brown' },
  { value: 'Green', label: 'Green'},
  { value: 'Black', label: 'Black' },
  { value: 'Blue', label: 'Blue' },
];

class Section extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isUpdateCompletedSession: false,

      page: 1,
      totalPage: 0,
      limit: 10,

      ageGroupList: [],
      beltsList: [...belts],
      curriculumList: [],
      teachersList: [],
      sectionList: [],
      timeZones: [],
      timeZone: '',

      sectionModal: false,
      editSectionModal: false,
      sectionName: '',
      fromDate: '',
      toDate: '',
      startTime: '',
      endTime: '',
      ageGroup: '',
      teacher: '',
      daysOfWeek: [],
      activeDay: [],
      sessions: '',
      uploading: false,
      selectedSection: '',
      roomModal: false,
      roomName: '',
      roomUrl: '',
      zoomCallDetails:null,
      sectionType: 2,
      isRecurring: 1,
      sectionUser: null,
      sectionUserCountryCode: null,
      sectionUserMobile: null,
      sectionUserclassLength: null,

      sectionNameError: false,
      fromDateError: false,
      toDateError: false,
      startTimeError: false,
      endTimeError: false,
      ageGroupError: false,
      sectionSizeError: false,
      sessionsError: false,
      teacherError: false,
      timeZoneError: false,
      sectionTypeError: false,
      sectionUserError: false,
      isRecurringError: false,

      modalTitle: '',
      successModal: false,
      errorModal: false,
      message: '',
      emails: [],
      inputValue : ''
    };
  }




  componentDidMount() {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const classId = searchParams.get('classId');
    const sectionId = searchParams.get('sectionId');
    const userId = searchParams.get('userId');

    this.setState(
      {
        timeZones: [],
        ageGroupList: [],
        curriculumList: [],
        sectionList: [],
        daysOfWeek: [...weekDays],
        classId: classId,
        sectionId: sectionId,
        userId: userId,
        focused: false
      },
      () => {
        this.getAllSectionList(sectionId);
        this.getAgeGroupList();
        this.getTeachersList(classId);
        this.getTimeZones();
        this.getCurriculum(classId);
      }
    );
  }

  getTimeZones = () => {
    let timeZones = fetchTimeZones();
    let newTimeZoneObj = [];
    timeZones.map((data) => {
      let tt = {value:data, label: data }
      newTimeZoneObj.push(tt)
    })
    timeZones = newTimeZoneObj;
    this.setState({ timeZones });
  };

  getAllSectionList = (sectionId = false) => {
    const { page, limit } = this.state;
    console.log('sectionId', sectionId)
    let apiUrl = `section/get-section/${this.state.classId}/${page}/${limit}`
    if(sectionId) {
      apiUrl = `section/get-section/${this.state.classId}/${page}/${limit}/${sectionId}`
    }
    axiosGet(`${apiUrl}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            sectionList: response.data?.result,
            totalPage: response.data?.noofpage,
          });

          if(sectionId) {
            setTimeout(() => {
              this.editSection(sectionId, true)
            }, 1000)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAgeGroupData = (ageGroupList, age) => {
    for (let i = 0; i < ageGroupList.length; i++) {
      const ageGroup = ageGroupList[i];
      const ageRange = ageGroup.ageGroupName.split(" - ");
      const minAge = parseInt(ageRange[0]);
      const maxAge = parseInt(ageRange[1].split(" ")[0]);
      const ageNumber = parseInt(age);
      if (ageNumber >= minAge && ageNumber <= maxAge) {
        return ageGroup.ageGroupId;
      }
    }
  }

  getRegistrationData = (userId = false) => {
    if(userId) {
      const apiUrl = `user/get-student-registration-details/${userId}`
      axiosGet(`${apiUrl}`)
          .then((response) => {
            if (!response.isError) {
              this.resetForm();
              const classLength = response?.data?.classPerWeek.split(' ');
              const is25Mins = classLength.includes('25');
              const childAge = response?.data?.childAge;
              const ageGroupId = this.getAgeGroupData(this.state.ageGroupList, childAge);
              this.setState({
                sectionModal: true,
                sectionUser: response.data.email,
                sectionUserCountryCode: 1,
                sectionUserMobile: response.data.phoneNumber,
                sectionUserclassLength: is25Mins ? halfClass : fullClass,
                timeZone: response.data.timeZone,
                classId: response.data.classId,
                ageGroup: this.getIndexOf(
                    this.state.ageGroupList,
                    'ageGroupId',
                    ageGroupId
                ),
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }
  };

  // For changing page -
  changePage = (status) => {
    if (status && status === 'next') {
      this.setState({ page: this.state.page + 1 }, () => this.getAllSectionList());
    } else if (status && status === 'prev') {
      this.setState({ page: this.state.page - 1 }, () => this.getAllSectionList());
    }
  };

  getAgeGroupList = () => {
    const { userId } = this.state || {};
    axiosGet('age-group/find-age-group')
      .then((response) => {
        if (!response.isError) {
          this.getRegistrationData(userId);
          this.setState({
            ageGroupList: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCurriculum = (classId) => {
    axiosGet('curriculum/get-curriculums/' + classId)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            curriculumList: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getTeachersList = (classId) => {
    axiosGet(`teacher/list-of-all-teacher/${classId}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            teachersList: response.data?.result || [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addSectionForm = () => {
    this.resetForm();
    this.setState({
      sectionModal: true,
    });
  };

  changeDay = (id) => {
    let { daysOfWeek, fromDate, toDate } = this.state;
    if (!fromDate || !toDate) {
      !fromDate
        ? this.setState({ fromDateError: true })
        : this.setState({ fromDateError: false });
      !toDate
        ? this.setState({ toDateError: true })
        : this.setState({ toDateError: false });
    } else {
      let activeDay = [];
      daysOfWeek.map((value, index) => {
        if (index === id) {
          value.isActive = !value.isActive;
        }
        return value;
      });
      daysOfWeek.map((data, index) => {
        if (data.isActive) {
          activeDay.push(data.key);
        }
        return data;
      });
      this.setState(
        {
          daysOfWeek,
          activeDay,
        },
        () => {
          console.log(
            'check box result in active date array == ',
            this.state.activeDay
          );
          this.findSessions();
        }
      );
    }
  };

  findSessions = () => {
    let { fromDate, toDate, activeDay } = this.state;
    let dayDifference = moment(toDate).diff(fromDate, 'days');
    dayDifference = dayDifference + 1;
    let count = 0;
    for (let i = 0; i < dayDifference; i++) {
      let thisDate = moment(fromDate).add(i, 'days');
      let thisDay = moment(thisDate).format('dddd');
      if (activeDay.includes(thisDay)) {
        count += 1;
      }
    }
    this.setState({
      sessions: count,
    });
  };

  addOrUpdateSection = (isEditSection) => {
    let {
      sectionName,
      sectionSize,
      fromDate,
      toDate,
      startTime,
      endTime,
      daysOfWeek,
      sessions,
      ageGroup,
      teacher,
      roomUrl,
      zoomCallDetails,
      timeZone,
      selectedSection,
      sectionType,
      isRecurring,
      isUpdateCompletedSession,
      sectionUser,
      sectionUserCountryCode,
      sectionUserMobile,
      sectionUserclassLength,
      curriculum,
      userId,
        belt,
        emails,
    } = this.state;

    !sectionName
      ? this.setState({ sectionNameError: true })
      : this.setState({ sectionNameError: false });
    !sectionSize
      ? this.setState({ sectionSizeError: true })
      : this.setState({ sectionSizeError: false });
    !fromDate
      ? this.setState({ fromDateError: true })
      : this.setState({ fromDateError: false });
    !toDate
      ? this.setState({ toDateError: true })
      : this.setState({ toDateError: false });
    !startTime
      ? this.setState({ startTimeError: true })
      : this.setState({ startTimeError: false });
    !endTime
      ? this.setState({ endTimeError: true })
      : this.setState({ endTimeError: false });
    !sessions
      ? this.setState({ sessionsError: true })
      : this.setState({ sessionsError: false });
    !ageGroup
      ? this.setState({ ageGroupError: true })
      : this.setState({ ageGroupError: false });
    !teacher
      ? this.setState({ teacherError: true })
      : this.setState({ teacherError: false });
    !timeZone
      ? this.setState({ timeZoneError: true })
      : this.setState({ timeZoneError: false });
    !sectionType
      ? this.setState({ sectionTypeError: true })
      : this.setState({ sectionTypeError: false });
    sectionType && sectionType == privateSection && !sectionUser
    ? this.setState({ sectionUserError: true })
    : this.setState({ sectionUserError: false });



    if (
      sectionName &&
      fromDate &&
      toDate &&
      startTime &&
      endTime &&
      sessions &&
      ageGroup &&
      teacher &&
      timeZone &&
      sectionType &&
      (sectionType && sectionType == privateSection ? sectionUser : true)
    ) {
      let ageGroupId =
        this.state.ageGroupList[ageGroup] &&
        this.state.ageGroupList[ageGroup].ageGroupId;
      let ageGroupName =
        this.state.ageGroupList[ageGroup] &&
        this.state.ageGroupList[ageGroup].ageGroupName;
      let teacherId =
        this.state.teachersList[teacher] &&
        this.state.teachersList[teacher].teacherId;
      let teacherName =
        this.state.teachersList[teacher] &&
        this.state.teachersList[teacher].name;
      let curriculumName =
        this.state.curriculumList[curriculum] &&
        this.state.curriculumList[curriculum].name;
      let curriculumId =
            this.state.curriculumList[curriculum] &&
            this.state.curriculumList[curriculum].curriculumId;

      let timeSplitStart = startTime.split(':');
      let timeSplitEnd = endTime.split(':');

      let startingTime = '';
      let endingTime = '';

      if (timeSplitStart && timeSplitStart[0] && timeSplitStart[1]) {
        let startHours = '';
        let status = '';
        timeSplitStart[0] >= 12
          ? (startHours = parseInt(parseInt(timeSplitStart[0]) - 12))
          : (startHours = timeSplitStart[0]);
        timeSplitStart[0] >= 12 ? (status = 'PM') : (status = 'AM');
        startingTime = startHours + ':' + timeSplitStart[1] + ' ' + status;
      }
      if (timeSplitEnd && timeSplitEnd[0] && timeSplitEnd[1]) {
        let startHours = '';
        let status = '';
        timeSplitEnd[0] >= 12
          ? (startHours = parseInt(parseInt(timeSplitEnd[0]) - 12))
          : (startHours = timeSplitEnd[0]);
        timeSplitEnd[0] >= 12 ? (status = 'PM') : (status = 'AM');
        endingTime = startHours + ':' + timeSplitEnd[1] + ' ' + status;
      }

      this.setState({ loading: true });


      let mobileNumber = null;
      if (sectionUserCountryCode && sectionUserMobile) {
        mobileNumber = `+${sectionUserCountryCode}-${sectionUserMobile}`;
      }

      let dataToSend = {
        registrationId: userId,
        classId: this.state.classId,
        name: sectionName,
        numberOfSessions: sessions,
        startDate: fromDate,
        endDate: toDate,
        startTime: startingTime,
        endTime: endingTime,
        localTimeZone: timeZone,
        belt: belt,
        daysOfWeek: daysOfWeek,
        ageGroupId: ageGroupId,
        ageGroupName: ageGroupName,
        curriculumId: curriculumId,
        curriculumName: curriculumName,
        teacherId: teacherId,
        teacherName: teacherName,
        zoomLink: roomUrl,
        zoomCallDetails: zoomCallDetails,
        sectionType: sectionType,
        isRecurring: Number(isRecurring),
        isUpdateCompletedSession: isUpdateCompletedSession,
        mobileNumber,
        classLength: sectionUserclassLength,
        emails
        //sectionUser: sectionUser ? sectionUser.split(',') : null
      };
      if(sectionUser) {
        dataToSend['sectionUser'] = sectionUser.split(',')
      }
      if (isEditSection) {
        dataToSend = {
          ...dataToSend,
          ...{ sectionId: selectedSection.sectionId },
        };
      }

      const endPointUrl = isEditSection
        ? `section/edit-section-session/${selectedSection.sectionId}`
        : 'section/add-section';

      axiosPost(`${endPointUrl}`, dataToSend)
        .then((response) => {
          this.setState({
            loading: false,
            editSectionModal: false,
            selectedSection: '',
            sectionModal: false,
          });
          if (!response.isError) {
            this.setState(
              {
                successModal: true,
                modalTitle: 'Success',
                message: 'Successfully saved.',
              },
              () => {
                this.getAllSectionList();
                this.resetForm();
              }
            );
          } else {
            this.setState(
              {
                errorModal: true,
                modalTitle: 'Sorry!',
                message: response.message,
              },
              () => {
                this.resetForm();
              }
            );
          }
        })
        .catch((errorResponse) => {
          this.setState(
            {
              loading: false,
              editSectionModal: false,
              selectedSection: '',
              sectionModal: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: errorResponse.message,
            },
            () => {
              this.resetForm();
            }
          );
        });
    }
  };

  editSection = (index, isSectionId= false) => {
    let { sectionList } = this.state;
    let selectedSection;
    if(isSectionId) {
      selectedSection = sectionList.find((_data) => _data.sectionId == index )
    } else {
      selectedSection = sectionList[index];
    }
    let sectionUserEmail = selectedSection.publicUserUrl ? selectedSection.publicUserUrl.map((data) => data.email).toString() : null;

    let countryCode = null;
    let mobileNo = null;
    if (selectedSection.mobileNumber) {
      let mobileNumber = selectedSection.mobileNumber.toString().split('-');
      countryCode = mobileNumber[0].slice(1);
      mobileNo = mobileNumber[1];
    }

    this.setState({
      selectedSection: selectedSection,
      sectionName: selectedSection.name,
      timeZone: selectedSection.localTimeZone,
      belt: selectedSection.belt,
      fromDate: moment(selectedSection.startDate).format('YYYY-MM-DD'),
      toDate: moment(selectedSection.endDate).format('YYYY-MM-DD'),
      startTime: this.stringToTime(selectedSection.startTime),
      endTime: this.stringToTime(selectedSection.endTime),
      sectionUser: sectionUserEmail,
      sectionType: selectedSection.sectionType ? selectedSection.sectionType : publicSection,
      isRecurring: selectedSection.isRecurring ? selectedSection.isRecurring : 0,
      emails: selectedSection.emails,

      ageGroup: this.getIndexOf(
        this.state.ageGroupList,
        'ageGroupId',
        selectedSection.ageGroupId
      ),
      teacher: this.getIndexOf(
        this.state.teachersList,
        'teacherId',
        selectedSection.teacherId
      ),
      curriculum: this.getIndexOf(
        this.state.curriculumList,
        'curriculumId',
        selectedSection.curriculumId
      ),

      daysOfWeek: selectedSection.daysOfWeek,
      sessions: selectedSection.numberOfSessions,
      roomUrl:
        selectedSection.zooms.length > 0 && selectedSection.zooms[0].zoomLink,
      editSectionModal: true,
      zoomCallDetails: selectedSection.zoomCallDetails,
      sectionUserCountryCode: countryCode,
      sectionUserMobile: mobileNo,
      sectionUserclassLength: selectedSection.classLength
    });
  };

  stringToTime = (data) => {
    let splitTime = data.split(' ');
    let time = '';

    if (splitTime && splitTime[0] && splitTime[1]) {
      if (splitTime[1] === 'AM' || splitTime[1] === 'am') {
        let splitString = splitTime[0].split(':');

        time = splitString[0] + ':' + splitString[1] + ':00';
      } else if (splitTime[1] === 'PM' || splitTime[1] === 'pm') {
        let splitString = splitTime[0].split(':');

        time = parseInt(splitString[0]) + 12 + ':' + splitString[1] + ':00';
      }
      return time;
    }
  };

  getIndexOf = (array, name, value) => {
    let key;
    if (array && array.length > 0 && name !== '' && value) {
      array.map((data, index) => {
        let desiredVal = data[`${name}`];
        if (desiredVal === value) {
          key = `${index}`;
        }
        return data;
      });

      return key;
    }
  };

  updateRoom = (index) => {
    let { sectionList } = this.state;
    let selectedSection = sectionList[index];
    if (
      selectedSection &&
      selectedSection.zoomDetailS &&
      selectedSection.zoomDetailS[0]
    ) {
      this.setState({
        selectedSection: selectedSection,
        roomModal: true,
        roomName: selectedSection.zoomDetailS[0].roomName,
        roomUrl: selectedSection.zoomDetailS[0].zoomLink,
      });
    } else {
      this.setState({
        errorModal: true,
        modalTitle: 'Sorry!',
        message: 'Zoom Link for this section is not available.',
      });
    }
  };

  updateRoomLink = () => {
    let { selectedSection, roomUrl } = this.state;

    let dataToSend = {
      zoomId:
        selectedSection &&
        selectedSection.zoomDetailS &&
        selectedSection.zoomDetailS[0].zoomId,
      zoomLink: roomUrl,
    };
    this.setState({ loading: true });

    axiosPost('zoom/edit-zoom-link', dataToSend)
      .then((response) => {
        if (!response.isError) {
          this.setState(
            {
              loading: false,
              roomModal: false,
              roomUrl: '',
              roomName: '',
              selectedRoom: '',
              successModal: true,
              message: 'Successfully updated',
              modalTitle: 'Success',
            },
            () => this.getAllSectionList()
          );
        } else {
          this.setState({
            loading: false,
            roomModal: false,
            roomUrl: '',
            roomName: '',
            selectedRoom: '',
            errorModal: true,
            message: response.message,
            modalTitle: 'Sorry!',
          });
        }
      })
      .catch((errorResponse) => {
        this.setState({
          loading: false,
          roomModal: false,
          roomUrl: '',
          roomName: '',
          selectedRoom: '',
          errorModal: true,
          message: errorResponse.message,
          modalTitle: 'Sorry!',
        });
      });
  };

  deleteSection = (index) => {
    let { sectionList } = this.state;
    let selectedSection = sectionList[index];

    let confimation = window.confirm('Are you sure you want to delete section?')
    if(confimation) {
      axiosPost(`section/delete-section/${selectedSection.sectionId}`)
      .then((response) => {
        if (!response.isError) {
          this.setState(
            {
              successModal: true,
              modalTitle: 'Success',
              message: 'Successfully deleted.',
            },
            () => {
              this.getAllSectionList();
            }
          );
        } else {
          this.setState({
            errorModal: true,
            modalTitle: 'Sorry!',
            message: response.message,
          });
        }
      })
      .catch((errorResponse) => {
        this.setState({
          errorModal: true,
          modalTitle: 'Sorry!',
          message: errorResponse.message,
        });
      });
    }

  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      modalTitle: '',
      message: '',
    });
  };

  handleSelect(data) {
    this.setState({ timeZone: data.value })
  }

  resetForm = () => {
    this.setState({
      loading: false,
      sectionName: '',
      fromDate: '',
      toDate: '',
      startTime: '',
      endTime: '',
      ageGroup: '',
      curriculum: '',
      teacher: '',
      daysOfWeek: [...weekDays],
      activeDay: [],
      sessions: '',
      uploaded: '',
      roomUrl: '',
      zoomCallDetails:'',
      timeZone: '',
      sectionType: 2,
      isRecurring: 1,
      isUpdateCompletedSession: false,
      sectionUser:null,
      sectionUserCountryCode: null,
      sectionUserMobile:null,
      sectionUserclassLength: null,

      sectionNameError: false,
      sectionSizeError: false,
      fromDateError: false,
      toDateError: false,
      startTimeError: false,
      endTimeError: false,
      ageGroupError: false,
      sessionsError: false,
      teacherError: false,
      timeZoneError: false,
      sectionTypeError: false,
      isRecurringError: false,
      sectionUserError: false

    });
  };

  calculateSectionDuration = (section) => {
    const startingTime = moment(section?.startTime, databaseTimeFormat);
    const endingTime = moment(section?.endTime, databaseTimeFormat);
    return `${moment(endingTime).diff(startingTime, 'minute')}`;
  };

  getDatesSchedule = (section) => {
    return `Schedule Dates : ${moment(
      section.startDate,
      databaseDateTimeFormat
    ).format(dateWithCardinalDate)} - ${moment(
      section.endDate,
      databaseDateTimeFormat
    ).format(dateWithCardinalDate)}`;
  };

  //Autofill the teacher room url
  setTeacher = (value) => {
    let {teachersList} = this.state;
    let roomURL = teachersList[value].roomURL || '';
    let zoomCallDetails = teachersList[value].zoomCallDetails || '';
    this.setState({ teacher: value, roomUrl: roomURL, zoomCallDetails })
  }

  getDaysFromSessionDates = (SessionDetails, day) => {
    let daysArr = [];
    SessionDetails.map((_data) => {
      if(!_data.adHocClass) {
        var dt = moment(moment(_data.dateOfConduct).format('YYYY-MM-DD'), "YYYY-MM-DD HH:mm:ss")
        dt.format('dddd');
        daysArr.push(dt.format('dddd').substring(0,3))
      }
    })
    daysArr = daysArr.filter((v, i, a) => a.indexOf(v) === i)
    return daysArr.includes(day)
  }

   getTodayDate = (years = 0 , months = 0) => {
      const today = new Date();
      const year = today.getFullYear() + years;
      const month = String(today.getMonth() + months).padStart(2, '0'); // Month is 0-indexed
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  }

  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value})
  };


  handleInputKeyPress = (event) => {
    const {inputValue} = this.state;
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      this.addEmail(inputValue.trim());
      this.setState({ inputValue: ''})
    }
  };

  addEmail = (email) => {
    const {emails} = this.state;
    if (!emails.includes(email)) {
      this.setState([...emails, email]);
    }
  };

  removeEmail = (indexToRemove) => {
    const {emails} = this.state;
    this.setState(emails.filter((_, index) => index !== indexToRemove));
  };

  render() {
    let {
      loading,
      sectionList,
      sectionName,
      fromDate,
      toDate,
      startTime,
      endTime,
      sectionNameError,
      ageGroup,
      curriculum,
      sessions,
      teacher,
      fromDateError,
      toDateError,
      startTimeError,
      endTimeError,
      ageGroupError,
      sectionSizeError,
      sessionsError,
      teacherError,
      timeZoneError,
      sectionTypeError,
      isRecurringError,
      sectionUserError,
      ageGroupList,
      curriculumList,
      teachersList,
      daysOfWeek,
      successModal,
      errorModal,
      modalTitle,
      message,
      uploading,
      timeZones,
      timeZone,
      sectionModal,
      editSectionModal,
      roomUrl,
      zoomCallDetails,
      sectionUser,
      sectionType,
      isRecurring,
      isUpdateCompletedSession,
      page, totalPage,
      sectionUserCountryCode,
      sectionUserMobile,
      sectionUserclassLength,
      belt,
      beltsList,
      emails,
      inputValue,
      focused
    } = this.state;

    return (
      <>
        <Header />
        <Container className='mt--7' fluid>
          <Button color='primary' onClick={() => this.addSectionForm()}>
            Add Section
          </Button>
          <br />
          <br />
          <Row>
            {sectionList &&
              sectionList.length > 0 &&
              sectionList.map((section, index) => (
                <div className='col-lg-5 ScheduleSide'>
                  <div className='StartDate'>
                    {/*<p>{`$ ${section.price}`}</p>*/}
                  </div>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className='btn-icon-only'
                      role='button'
                      size='sm'
                      color=''
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className='fas fa-ellipsis-v' />
                    </DropdownToggle>
                    <DropdownMenu className='dropdown-menu-arrow' right>
                      <DropdownItem onClick={() => this.editSection(index)}>
                        Edit
                      </DropdownItem>
                      {/* <DropdownItem onClick={() => this.updateRoom(index)}>
                        Update Room
                      </DropdownItem> */}
                      <DropdownItem onClick={() => this.deleteSection(index)}>
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <div className='Tags m-t-7'>
                    <span className='ThemeTag YellowTheme'>
                      {section.teacherName}
                    </span>
                    <span className='ThemeTag PrimaryTheme'>
                      {`Age Group: ${section.ageGroupName}`}
                    </span>
                    <span className='ThemeTag'>
                      {`Class size : ${section.classSize}`}
                    </span>
                    {
                      section.isRecurring ? (
                        <span className='ThemeTag PrimaryTheme'>
                          Recurring
                        </span>
                      ) : ''
                    }

                  </div>
                  <h4>{section.name} -  {section.classLength == fullClass ? 'Full  (50 mins)' : section.classLength == halfClass ? 'Half (25 mins)' : ''}</h4>
                  <p className='ScheduleTime'>
                    {this.getDatesSchedule(section)}
                  </p>
                  <div className='WeekDays'>
                    {section.daysOfWeek?.map((dayOfWeek) => {
                      return (
                        <p
                          key={dayOfWeek.key}
                          className={classnames({ Active: this.getDaysFromSessionDates(section.sessionDetails ,dayOfWeek.name) })}
                        >
                          {dayOfWeek.name}
                        </p>
                      );
                    })}
                  </div>
                  <div className='BtnWrapper'>
                    <Button color='primary'>{`${section.numberOfSessions} Sessions`}</Button>
                    <Button color='primary'>
                      {moment(section.startDate, databaseDateTimeFormat).format(
                        timeForMessage
                      )}
                    </Button>
                    <Button color='primary'>{`${this.calculateSectionDuration(
                      section
                    )} Mins`}</Button>
                  </div>
                </div>
              ))}

              </Row>
              <CardFooter className='py-4'>
                  <nav aria-label='...'>
                    <Pagination
                      className='pagination justify-content-end mb-0'
                      listClassName='justify-content-end mb-0'
                    >
                      <PaginationItem className={page > 1 ? '' : 'disabled'}>
                        <PaginationLink
                          onClick={() => this.changePage('prev')}
                          tabIndex='-1'
                        >
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className='active'>
                        <PaginationLink onClick={(e) => e.preventDefault()}>
                          {page}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalPage > page ? '' : 'disabled'}
                      >
                        <PaginationLink onClick={() => this.changePage('next')}>
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>

        </Container>

        <Modal
          className='modal-dialog-centered'
          size='lg'
          isOpen={sectionModal || editSectionModal}
          toggle={() =>
            this.toggleModal(sectionModal ? 'sectionModal' : 'editSectionModal')
          }
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0'>
              <CardHeader className='bg-transparent pb-5'>
                <div className='text-muted text-center mt-2 mb-3'>
                  <small>
                    {sectionModal ? `Add new section` : `Edit Section`}
                  </small>
                </div>
              </CardHeader>
              <CardBody className='px-lg-5 py-lg-5'>
                <Form role='form'>
                <FormGroup className={isRecurringError ? 'has-danger' : ''}>
                    <label>Recurring</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        type='select'
                        name='isRecurring'
                        value={isRecurring}
                        onChange={(e) =>
                          this.setState({ isRecurring: e.target.value })
                        }
                      >
                        <option value="">Select Recurring</option>
                        <option key={`isRecurring-${0}`} value={0}>
                                No
                        </option>
                        <option key={`isRecurring-${1}`} value={1}>
                                Yes
                        </option>
                      </Input>
                    </InputGroup>
                    </FormGroup>
                <FormGroup className={sectionTypeError ? 'has-danger' : ''}>
                    <label>Section Type</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        type='select'
                        name='sectionType'
                        value={sectionType}
                        onChange={(e) =>
                          this.setState({ sectionType: e.target.value })
                        }
                      >
                        <option value="">Select Section Type</option>
                        <option key={`publicSection-${publicSection}`} value={publicSection}>
                                Public
                        </option>
                        <option key={`privateSection-${privateSection}`} value={privateSection}>
                                Private
                        </option>
                      </Input>
                    </InputGroup>
                  </FormGroup>
                  {sectionType == privateSection && (
                    <>
                    <FormGroup className={sectionUserError ? 'has-danger' : ''}>
                    <label>Section User Emails</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        placeholder='Section User Emails'
                        type='text'
                        id={'email'}
                        name='sectionUser'
                        value={sectionUser}
                        onChange={(e) =>
                          this.setState({ sectionUser: e.target.value })
                        }
                      />
                    </InputGroup>
                  </FormGroup>


                      <FormGroup className={sectionUserError ? 'has-danger' : ''}>
                        <label>User Additional Emails</label>
                        <ReactMultiEmail
                            placeholder='Input your email'
                            emails={emails}
                            onChange={(_emails: string[]) => {
                              this.setState({emails: _emails})
                            }}
                            autoFocus={true}
                            onFocus={() => this.setState({focused: true})}
                            onBlur={() => this.setState({focused: true})}
                            getLabel={(email, index, removeEmail) => {
                              return (
                                  <div data-tag key={index}>
                                    <div data-tag-item>{email}</div>
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                            ×
                                       </span>
                                  </div>
                              );
                            }}
                        />
                      </FormGroup>

                  <FormGroup >
                    <InputGroup className='input-group-alternative'>
                      <div className='col-md-3' >
                      <label>Country Code</label>
                      <Input
                        placeholder='Country Code'
                        type='number'
                        name='sectionUserCountryCode'
                        value={sectionUserCountryCode}
                        onChange={(e) =>
                          this.setState({ sectionUserCountryCode: e.target.value })
                        }
                      />
                      </div>

                      <div className='col-md-9'>
                      <label>Mobile Number</label>
                      <Input
                        placeholder='Mobile number'
                        type='text'
                        name='sectionUserMobile'
                        value={sectionUserMobile}
                        onChange={(e) =>
                          this.setState({ sectionUserMobile: e.target.value })
                        }
                      />
                      </div>
                    </InputGroup>
                  </FormGroup>
                  </>
                  )}
                  <FormGroup className={sectionNameError ? 'has-danger' : ''}>
                    <label>Section Name</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        placeholder='Section name'
                        type='text'
                        name='sessionName'
                        value={sectionName}
                        onChange={(e) =>
                          this.setState({ sectionName: e.target.value })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  {/*<FormGroup className={priceError ? 'has-danger' : ''}>*/}
                  {/*  <label>Section Price</label>*/}
                  {/*  <InputGroup className='input-group-alternative'>*/}
                  {/*    <Input*/}
                  {/*      placeholder='Section price in $'*/}
                  {/*      type='number'*/}
                  {/*      name='sessionPrice'*/}
                  {/*      value={price}*/}
                  {/*      onChange={(e) =>*/}
                  {/*        this.setState({ price: e.target.value })*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*  </InputGroup>*/}
                  {/*</FormGroup>*/}
                  {/*<FormGroup className={sectionSizeError ? 'has-danger' : ''}>*/}
                  {/*  <label>Section Size</label>*/}
                  {/*  <InputGroup className='input-group-alternative'>*/}
                  {/*    <Input*/}
                  {/*      placeholder='Section size'*/}
                  {/*      type='text'*/}
                  {/*      name='sessionSize'*/}
                  {/*      value={sectionSize}*/}
                  {/*      onChange={(e) =>*/}
                  {/*        this.setState({ sectionSize: e.target.value })*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*  </InputGroup>*/}
                  {/*</FormGroup>*/}

                  <FormGroup >
                    <label>Class Length</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        type='select'
                        name='sectionUserclassLength'
                        value={sectionUserclassLength}
                        onChange={(e) =>
                          this.setState({ sectionUserclassLength: e.target.value })
                        }
                      >
                        <option value="">Select Class Length</option>
                        <option key={`${halfClass}`} value={halfClass}>
                        Half (25 mins)
                        </option>
                        <option key={`${fullClass}`} value={fullClass}>
                        Full  (50 mins)
                        </option>
                      </Input>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={fromDateError ? 'has-danger' : ''}>
                    <label>From Date</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        placeholder='Date'
                        type='date'
                        name='fromDate'
                        value={fromDate}
                        min={this.getTodayDate(0, 0)}
                        onChange={(e) =>
                          this.setState({
                            fromDate: e.target.value,
                            fromDateError: false,
                          }, () => {
                            this.changeDay(undefined)
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={toDateError ? 'has-danger' : ''}>
                    <label>To Date</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        placeholder='Date'
                        type='date'
                        name='toDate'
                        value={toDate}
                        min={this.getTodayDate(0)}
                        max={this.getTodayDate(1, 1)}
                        onChange={(e) =>
                          this.setState({
                            toDate: e.target.value,
                            toDateError: false,
                          }, () => {
                            this.changeDay(undefined)
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={startTimeError ? 'has-danger' : ''}>
                    <label>Start Time</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        placeholder='Time'
                        type='time'
                        name='startTime'
                        value={startTime}
                        onChange={(e) =>
                          this.setState({ startTime: e.target.value })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={endTimeError ? 'has-danger' : ''}>
                    <label>End Time</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        placeholder='Time'
                        type='time'
                        name='endTime'
                        value={endTime}
                        onChange={(e) =>
                          this.setState({ endTime: e.target.value })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={timeZoneError ? 'has-danger' : ''}>
                    <label>Time Zone</label>
                    <InputGroup className='input-group-alternative'>
                      {/* <Input
                        type='select'
                        name='timeZone'
                        value={timeZone}
                        onChange={(e) =>
                          this.setState({ timeZone: e.target.value })
                        }
                      >
                        <option>Select time zone</option>
                        {timeZones &&
                          timeZones.map((value, index) => {
                            return (
                              <option key={index} value={value}>
                                {value}
                              </option>
                            );
                          })}
                      </Input> */}
                      <div style={{width: '100%'}}>
                      <Select
                        options={timeZones}
                        placeholder="Select timezone"
                        value={timeZone ? {value:timeZone,label:timeZone} : timeZone}
                        onChange={(e) =>
                          this.setState({ timeZone: e.value })
                        }
                        isSearchable={true}
                      />
                      </div>
                    </InputGroup>
                  </FormGroup>
                  <div className='custom-control custom-control-alternative custom-checkbox mb-12'>
                    {daysOfWeek &&
                      daysOfWeek.map((value, index) => {
                        return (
                          <div className='mb-3' key={index}>
                            <input
                              className='custom-control-input'
                              id={'customCheck' + index + 1}
                              defaultChecked={value.isActive}
                              type='checkbox'
                              onChange={() => this.changeDay(index)}
                              disabled={!fromDate || !toDate ? true : false}
                            />
                            <label
                              className='custom-control-label'
                              htmlFor={'customCheck' + index + 1}
                            >
                              {value.name}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                  <br />
                  <FormGroup className={sessionsError ? 'has-danger' : ''}>
                    <label>No of Sessions</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        placeholder='Sessions'
                        type='number'
                        name='sessions'
                        value={sessions}
                        onChange={(e) =>
                          this.setState({ sessions: e.target.value })
                        }
                        readOnly={true}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={ageGroupError ? 'has-danger' : ''}>
                    <label>Age Group</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        type='select'
                        name='ageGroup'
                        value={ageGroup}
                        onChange={(e) =>
                          this.setState({ ageGroup: e.target.value })
                        }
                      >
                        <option>Select age group</option>
                        {ageGroupList &&
                          ageGroupList.map((value, index) => {
                            return (
                              <option key={index} value={index}>
                                {value.ageGroupName}
                              </option>
                            );
                          })}
                      </Input>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup >
                    <label>Curriculum</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        type='select'
                        name='curriculum'
                        value={curriculum}
                        onChange={(e) =>
                          this.setState({ curriculum: e.target.value })
                        }
                      >
                        <option>Select Curriculum</option>
                        {curriculumList &&
                            curriculumList.map((value, index) => {
                            return (
                              <option key={index} value={index}>
                                {value.name}
                              </option>
                            );
                          })}
                      </Input>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup >
                    <label>Belt</label>
                    <Select
                        options={beltsList}
                        placeholder="Select Belt"
                        value={belt ? {value:belt,label:belt} : belt}
                        onChange={(e) =>
                            this.setState({ belt: e.value })
                        }
                        isSearchable={true}
                    />
                  </FormGroup>
                  <FormGroup className={teacherError ? 'has-danger' : ''}>
                    <label>Teacher</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        type='select'
                        name='teacher'
                        value={teacher}
                        onChange={(e) =>
                          this.setTeacher(e.target.value)
                        }
                      >
                        <option>Select teacher</option>
                        {teachersList &&
                          teachersList.map((value, index) => {
                            return (
                              <option key={index} value={index}>
                                {value.name}
                              </option>
                            );
                          })}
                      </Input>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <label>Room Url</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        placeholder='Room Url'
                        type='text'
                        name='sessionName'
                        value={roomUrl}
                        onChange={(e) =>
                          this.setState({ roomUrl: e.target.value })
                        }
                      />
                    </InputGroup>
                    <span>
                      <a
                        href='JavaScript:Void(0);'
                        onClick={() => this.setState({ roomUrl: '' })}
                      >
                        Remove url
                      </a>
                    </span>
                  </FormGroup>

                  <FormGroup>
                    <label>Room Details</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        placeholder='Room Details'
                        type='text'
                        name='sessionName'
                        value={zoomCallDetails}
                        onChange={(e) =>
                          this.setState({ zoomCallDetails: e.target.value })
                        }
                      />
                    </InputGroup>

                  </FormGroup>

                  <FormGroup >
                    { !sectionModal ?
                      <div className='custom-control custom-control-alternative custom-checkbox mb-12'>
                        <div className='mb-3' >
                          <input
                              className='custom-control-input'
                              id='customCheck'
                              defaultChecked={isUpdateCompletedSession}
                              value={isUpdateCompletedSession}
                              type='checkbox'
                              onChange={(e) =>
                                  this.setState({ isUpdateCompletedSession: !isUpdateCompletedSession })
                              }
                          />
                          <label
                              className='custom-control-label'
                              htmlFor='customCheck'
                          >
                            Update Completed Sections Also [<span className='font-weight-bold text-dark'> BE CAREFUL </span>]
                          </label>
                        </div>
                      </div> : <span></span>
                    }


                  </FormGroup>


                  <div className='text-center'>
                    {uploading ? (
                      <span style={{ color: 'red' }}>Wait...</span>
                    ) : null}

                    <Button
                      disabled={uploading || loading ? true : false}
                      className='my-4'
                      color='primary'
                      type='button'
                      onClick={() => {
                        this.addOrUpdateSection(!sectionModal);
                      }}
                    >
                      {sectionModal ? `Save` : `Update`}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>

        <Modal
          className='modal-dialog-centered'
          size='lg'
          isOpen={this.state.roomModal}
          toggle={() => this.toggleModal('roomModal')}
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0'>
              <CardHeader className='bg-transparent pb-5'>
                <div className='text-muted text-center mt-2 mb-3'>
                  <small>Update Room</small>
                </div>
              </CardHeader>
              <CardBody className='px-lg-5 py-lg-5'>
                <Form role='form'>
                  <FormGroup>
                    <label>Room Name</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        placeholder='Room name'
                        type='text'
                        name='roomName'
                        value={this.state.roomName}
                        readOnly={true}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <label>Room Url</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        placeholder='Room Url'
                        type='text'
                        name='roomUrl'
                        value={this.state.roomUrl}
                        onChange={(e) =>
                          this.setState({ roomUrl: e.target.value })
                        }
                      />
                    </InputGroup>
                    {!loading ? (
                      <span>
                        <a
                          href='JavaScript:Void(0);'
                          onClick={() => this.setState({ roomUrl: '' })}
                        >
                          Remove url
                        </a>
                      </span>
                    ) : null}
                  </FormGroup>

                  <div>
                    <Button
                      disabled={loading ? true : false}
                      className='my-4'
                      color='primary'
                      type='button'
                      onClick={() => this.updateRoomLink()}
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>

        <Modal
          className='modal-dialog-centered'
          isOpen={this.state.successModal || this.state.errorModal}
          toggle={() =>
            successModal
              ? this.toggleModal('successModal')
              : errorModal
              ? this.toggleModal('errorModal')
              : null
          }
        >
          <div className='modal-header'>
            <h5 className='modal-title' id='successModalLabel'>
              {modalTitle}
            </h5>
            <button
              aria-label='Close'
              className='close'
              data-dismiss='modal'
              type='button'
              onClick={() =>
                successModal
                  ? this.toggleModal('successModal')
                  : errorModal
                  ? this.toggleModal('errorModal')
                  : null
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          {this.state.successModal ? (
            <div className='modal-body' style={{ color: 'green' }}>
              {message}
            </div>
          ) : this.state.errorModal ? (
            <div className='modal-body' style={{ color: 'red' }}>
              {message}
            </div>
          ) : null}
          <div className='modal-footer'>
            <Button
              color='primary'
              type='button'
              onClick={() =>
                successModal
                  ? this.toggleModal('successModal')
                  : errorModal
                  ? this.toggleModal('errorModal')
                  : null
              }
            >
              Ok
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(Section);
