import React from "react";
import { Button } from "reactstrap";
const AvailabilityContent = ({
  Availability,
  selectedDays,
  timing,
  onSetDay,
  onUpdateTime,
  addTimeSlot,
  deleteTimeSlot,
  saveAvailability,
  matchAvailablity,
  isShowMatch,
  isNew,
  completeMatch,
  routeName
}) => {
  let matchCount = 0;
  return (
    <div className="availability-box">
      <div className={`hours-box ${isNew || routeName == 'complete' ? 'hours-box-new' : ''}`}>
        <ul>
          {Availability.map((item, i) => {
            let dayName = Object.keys(item);
            let dayData = item[Object.keys(item)];
            return (
              <>
                <li>
                  <div className="day-status">
                    <label>
                      <input
                        onChange={() => {
                          onSetDay(dayName);
                        }}
                        type="checkbox"
                        checked={
                          selectedDays.includes(dayName[0]) ? true : false
                        }
                      />
                      {dayName}
                    </label>
                  </div>
                  <div className="hours-right-main">
                    {dayData.map((_data, iOfItem) => {
                      if (!_data) {
                        return null;
                      }
                      return (
                        <>
                          <div className={`hours-right ${ _data.teacherAvailability && _data.teacherAvailability.length > 0  && (isShowMatch || routeName == 'complete') ? 'hours-right-time' : ''}`}>
                            <div className={`d-flex align-items-center flex-wrap ${isNew || routeName == 'complete'  ? 'min-width-time' : ''} `}>
                              <select
                                onChange={(e) => {
                                  onUpdateTime(
                                    "start",
                                    dayName,
                                    e.target.value,
                                    iOfItem
                                  );
                                }}
                                className="form-control"
                              >
                                {timing.map((time) => {
                                  return (
                                    <option
                                      value={time}
                                      selected={
                                        time == _data.start ? true : false
                                      }
                                    >
                                      {time}
                                    </option>
                                  );
                                })}
                              </select>
                              <span className="spanPadding">-</span>
                              <select
                                onChange={(e) => {
                                  onUpdateTime(
                                    "end",
                                    dayName,
                                    e.target.value,
                                    iOfItem
                                  );
                                }}
                                className="form-control"
                              >
                                {timing.map((time) => {
                                  return (
                                    <option
                                      selected={
                                        time == _data.end ? true : false
                                      }
                                      value={time}
                                    >
                                      {time}
                                    </option>
                                  );
                                })}
                              </select>

                            </div>
                            {iOfItem > 0  && (
                              <button
                                onClick={() => {
                                  deleteTimeSlot(dayName, _data, iOfItem);
                                }}
                                className="delete-ic"
                              >
                                <img
                                  src={require("assets/img/icons/delete.png")}
                                />
                              </button>
                            )}
                            
                            { (isShowMatch || routeName == 'complete')  && _data.teacherAvailability && _data.teacherAvailability.length > 0 && (
                              <div className="availability-tab">
                                {
                                  _data.teacherAvailability.map((_available) => {
                                    matchCount++
                                  return (
                                    <button>{_available}</button>
                                  )
                                })
                                }
                                </div>   
                              )}
                              
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <button
                    onClick={() => {
                      addTimeSlot(dayName);
                    }}
                    className="add-icon"
                  >
                    +
                  </button>
                  
                </li>
              </>
            );
          })}
        </ul>
      </div>
      

      <div className="save-btn">

      { (isNew || (isShowMatch))  && (
        
          <Button
            color="primary"
            type="button"
            onClick={() => isShowMatch ? completeMatch(matchCount) : matchAvailablity(matchCount)}
          >
           {isShowMatch ? matchCount ? 'Complete Match' : 'Manual Match' : 'Match'} 
          </Button>
      
      )}
          <Button
            color="primary"
            type="button"
            onClick={() => saveAvailability()}
          >
            Save Availability
          </Button>
      </div>
      
    </div>
  );
};

export default AvailabilityContent;
