import React from 'react';
import _ from 'lodash';
import moment from 'moment';

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  FormGroup,
  Form,
  CardBody,
  InputGroup,
  Input,
  Modal,
  Button,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import { axiosGet, axiosPost } from 'util/apiConfig.js';

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userId: '',
      userName: '',
      classes: [],
      allMessages: [],
      textMessage: '',
      msgClass: '',
      msgClassError: false,
      textMesageError: false,
      selectedMessage: {},

      listPage: 1,
      totalListPage: '',
      rowLimit: 10,

      modalTitle: '',
      successModal: false,
      errorModal: false,
      message: '',
      editModal: false,
      addModal: false,
      showVideoModal: false,
    };
  }

  componentDidMount() {
    let storage = localStorage.getItem('Sanga_Admin');
    storage = storage ? JSON.parse(storage) : null;
    this.setState(
      {
        userId: storage && storage.userId,
        userName: '',
        newRequestList: [],
        allMessages: [],
      },
      () => {
        this.getAllMessages();
        this.getAllClasses();
      }
    );
  }

  // to get all messages -
  getAllMessages = () => {
    const { listPage, rowLimit } = this.state;
    // API -
    axiosGet(`message/get-all-message/${listPage}/${rowLimit}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            allMessages: response.data?.result,
            totalNewPage: response.data?.noofpage,
          });
        } else {
          this.setState({
            allMessages: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          allMessages: [],
        });
        console.log(error);
      });
  };

  // Getting all classes-
  getAllClasses = () => {
    // API -
    axiosGet('class/get-classes')
      .then((response) => {
        if (!response.isError) {
          this.setState({
            classes: response.data?.result,
          });
        } else {
          this.setState({
            classes: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          classes: [],
        });
        console.log(error);
      });
  };

  // getting class name by classId -
  getClassName = (classId) => {
    let { classes } = this.state;

    let selectedClass = _.filter(classes, { classId: classId });
    if (selectedClass && selectedClass.length > 0) {
      return selectedClass[0].name;
    }
  };

  addTestimonial = () => {
    this.setState({
      addModal: true,
    });
  };

  saveMessage = () => {
    let { textMessage, userId, loading } = this.state;

    !textMessage
      ? this.setState({ textMesageError: true })
      : this.setState({ textMesageError: false });

    if (textMessage && !loading) {
      this.setState({ loading: true });
      let dataToSend = {
        // classId: 'CLASS1635397576698',
        userId: userId,
        message: textMessage,
      };
      // API -
      axiosPost('message/add-message', dataToSend)
        .then((response) => {
          if (!response.isError) {
            this.setState(
              {
                loading: false,
                successModal: true,
                modalTitle: 'Success',
                message: 'Successfully added',
                textMessage: '',
                textMesageError: false,
                msgClassError: false,
                addModal: false,
              },
              () => {
                this.getAllMessages();
              }
            );
          } else {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: 'Somethis is wrong. Try again later.',
              textMessage: '',
              textMesageError: false,
              msgClassError: false,
              addModal: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            errorModal: true,
            modalTitle: 'Sorry!',
            message: 'Somethis is wrong. Try again later.',
            textMessage: '',
            textMesageError: false,
            msgClassError: false,
            addModal: false,
          });
          console.log(error);
        });
    }
  };

  changePage = (status) => {
    if (status && status === 'next') {
      this.setState({ listPage: this.state.listPage + 1 }, () =>
        this.getAllMessages()
      );
    } else if (status && status === 'prev') {
      this.setState({ listPage: this.state.listPage - 1 }, () =>
        this.getAllMessages()
      );
    }
  };

  // for redirecting to teacher's profile -
  viewMessage = (index) => {
    let { allMessages } = this.state;
    let selectedMessage = allMessages[index];
    if (selectedMessage && selectedMessage.messageType == 'MESSAGE') {
      this.setState({
        selectedMessage: selectedMessage,
        textMessage: selectedMessage.message,
        editModal: true,
      });
    } else if (selectedMessage && selectedMessage.messageType == 'FILE') {
      // console.log("video modal selected");
      this.setState({
        showVideoModal: true,
        selectedMessage: selectedMessage,
      });
    }
  };

  //to approve request -
  deleteMessage = (messageId) => {
    // API -
    axiosPost(`message/delete-message/${messageId}`)
      .then((response) => {
        if (!response.isError) {
          this.setState(
            {
              successModal: true,
              modalTitle: 'Success',
              message: 'Successfully deleted',
            },
            () => {
              this.getAllMessages();
            }
          );
        } else {
          this.setState({
            errorModal: true,
            modalTitle: 'Sorry!',
            message: 'Somethis is wrong. Try again later.',
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorModal: true,
          modalTitle: 'Sorry!',
          message: 'Somethis is wrong. Try again later.',
        });
        console.log(error);
      });
  };

  // Modal control -
  toggleModal = (state) => {
    if (state == 'editModal') {
      this.setState({
        [state]: !this.state[state],
        selectedMessage: {},
        textMessage: '',
      });
    } else if (state == 'addModal') {
      this.setState({
        [state]: !this.state[state],
        textMessage: '',
        msgClass: '',
        textMesageError: false,
        msgClassError: false,
      });
    } else {
      this.setState({
        [state]: !this.state[state],
        modalTitle: '',
        message: '',
      });
    }
  };

  closeVideoModal = () => {
    this.setState({
      showVideoModal: false,
      selectedMessage: {},
    });
  };

  render() {
    let {
      loading,
      allMessages,
      textMessage,
      textMesageError,
      successModal,
      errorModal,
      modalTitle,
      message,
      listPage,
      totalNewPage,
      addModal,
      editModal,
    } = this.state;
    return (
      <>
        <Header />
        <Container className='mt--7' fluid>
          <Button color='primary' onClick={() => this.addTestimonial()}>
            Add Testimonial
          </Button>
          <br />
          <br />
          {/* Table */}
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <h3 className='mb-0'>All Messages</h3>
                </CardHeader>
                <Table
                  className='align-items-center table-flush'
                  responsive
                  style={{ marginBottom: '100px' }}
                >
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Date</th>
                      <th scope='col'>Class</th>
                      <th scope='col'>Type</th>
                      <th scope='col'>Content</th>
                      <th scope='col' />
                    </tr>
                  </thead>
                  <tbody>
                    {allMessages &&
                      allMessages.map((value, index) => {
                        return (
                          value.isActive && (
                            <tr key={index}>
                              <th scope='row'>
                                <Media className='align-items-center'>
                                  <Media>
                                    <span className='mb-0 text-sm'>
                                      {value.createdAt
                                        ? moment(value.createdAt).format('ll')
                                        : ''}
                                    </span>
                                  </Media>
                                </Media>
                              </th>
                              <td>
                                {value.classId &&
                                  this.getClassName(value.classId)}
                              </td>
                              <td>
                                {value.messageType ? value.messageType : ''}
                              </td>
                              <td>
                                {value.messageType === 'FILE'
                                  ? value.fileName
                                  : value.message}
                              </td>
                              <td className='text-right'>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className='btn-icon-only text-light'
                                    href='JavaScript:Void(0);'
                                    role='button'
                                    size='sm'
                                    color=''
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className='fas fa-ellipsis-v' />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className='dropdown-menu-arrow'
                                    right
                                  >
                                    <DropdownItem
                                      href='JavaScript:Void(0);'
                                      onClick={() => this.viewMessage(index)}
                                    >
                                      View
                                    </DropdownItem>
                                    <DropdownItem
                                      href='JavaScript:Void(0);'
                                      onClick={() =>
                                        this.deleteMessage(value.messageId)
                                      }
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          )
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className='py-4'>
                  <nav aria-label='...'>
                    <Pagination
                      className='pagination justify-content-end mb-0'
                      listClassName='justify-content-end mb-0'
                    >
                      <PaginationItem
                        className={listPage > 1 ? '' : 'disabled'}
                      >
                        <PaginationLink
                          onClick={() => this.changePage('prev')}
                          tabIndex='-1'
                        >
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className='active'>
                        <PaginationLink onClick={(e) => e.preventDefault()}>
                          {listPage}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalNewPage > listPage ? '' : 'disabled'}
                      >
                        <PaginationLink onClick={() => this.changePage('next')}>
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

          {/* For notification modal */}
          <Modal
            className='modal-dialog-centered'
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
              successModal
                ? this.toggleModal('successModal')
                : errorModal
                ? this.toggleModal('errorModal')
                : null
            }
          >
            <div className='modal-header'>
              <h5 className='modal-title' id='successModalLabel'>
                {modalTitle}
              </h5>
              <button
                aria-label='Close'
                className='close'
                data-dismiss='modal'
                type='button'
                onClick={() =>
                  successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                    ? this.toggleModal('errorModal')
                    : null
                }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            {this.state.successModal ? (
              <div className='modal-body' style={{ color: 'green' }}>
                {message}
              </div>
            ) : this.state.errorModal ? (
              <div className='modal-body' style={{ color: 'red' }}>
                {message}
              </div>
            ) : null}
            <div className='modal-footer'>
              <Button
                color='primary'
                type='button'
                onClick={() =>
                  successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                    ? this.toggleModal('errorModal')
                    : null
                }
              >
                Ok
              </Button>
            </div>
          </Modal>

          {/* For adding or editing testimonial */}
          <Modal
            className='modal-dialog-centered'
            size='lg'
            isOpen={addModal || editModal}
            toggle={() => this.toggleModal(addModal ? 'addModal' : 'editModal')}
          >
            <div className='modal-body p-0'>
              <Card className='bg-secondary shadow border-0'>
                <CardHeader className='bg-transparent pb-5'>
                  <div className='text-muted text-center mt-2 mb-3'>
                    <small>
                      {addModal ? 'Add Testimonial' : 'View Testimonial'}
                    </small>
                  </div>
                </CardHeader>
                <CardBody className='px-lg-5 py-lg-5'>
                  <Form role='form'>
                    <FormGroup className={textMesageError ? 'has-danger' : ''}>
                      <label>Message</label>
                      <InputGroup className='input-group-alternative'>
                        <Input
                          placeholder='Message'
                          type='text'
                          name='className'
                          value={textMessage}
                          onChange={(e) =>
                            this.setState({
                              textMessage: e.target.value,
                              textMesageError: false,
                            })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className='text-center'>
                      <Button
                        disabled={loading ? true : false}
                        className='my-4'
                        color='primary'
                        type='button'
                        onClick={() => this.addOrUpdateTestimonial()}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default Message;
