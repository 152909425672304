import Axios from 'axios';
import Cookies from 'js-cookie';
import cred from './cred';

const getHeaders = (formData = false) => {
  let authToken = Cookies.get('token') ? Cookies.get('token') : undefined;
  let config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  if (formData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }
  return config;
};

export function axiosPost(url, payload, formData = false) {
  return new Promise(async (resolve, reject) => {
    let isError = true;
    let data = null;
    let message = 'Something went wrong!';
    try {
      const response = await Axios.post(
        `${cred.API_PATH}${url}`,
        payload,
        getHeaders(formData)
      );
      if (response?.data) {
        isError = !response.data?.success;
        data = response.data?.data;
        message = response.data?.message;
      }
      return resolve({ data, isError, message });
    } catch (error) {
      if (error?.response) {
        if (error.response?.status === 401) {
          localStorage.setItem('Sanga_Admin', null);
          Cookies.remove('token');
          window.location.reload();
        } else {
          isError = !error.response?.data?.success;
          data = null;
          message = error.response?.data?.message;
        }
      }

      return reject({ data, isError, message });
    }
  });
}

export function axiosGet(url, formData = false) {
  return new Promise(async (resolve, reject) => {
    let isError = true;
    let data = null;
    let message = 'Something went wrong!';
    try {
      const response = await Axios.get(
        `${cred.API_PATH}${url}`,
        getHeaders(formData)
      );
      if (response?.data) {
        isError = !response.data?.success;
        data = response.data?.data;
        message = response.data?.message;
      }
      return resolve({ data, isError, message });
    } catch (error) {
      if (error?.response) {
        if (error.response?.status === 401) {
          localStorage.setItem('Sanga_Admin', null);
          Cookies.remove('token');
          window.location.reload();
        } else {
          isError = !error.response?.data?.success;
          data = null;
          message = error.response?.data?.message;
        }
      }
      return reject({ data, isError, message });
    }
  });
}

export function axiosDelete(url) {
  return new Promise(async (resolve, reject) => {
    let isError = true;
    let data = null;
    let message = 'Something went wrong!';
    try {
      const response = await Axios.delete(
        `${cred.API_PATH}${url}`,
        getHeaders()
      );
      if (response?.data) {
        isError = !response.data?.success;
        data = response.data?.data;
        message = response.data?.message;
      }
      return resolve({ data, isError, message });
    } catch (error) {
      if (error?.response) {
        if (error.response?.status === 401) {
          localStorage.setItem('Sanga_Admin', null);
          Cookies.remove('token');
          window.location.reload();
        } else {
          isError = !error.response?.data?.success;
          data = null;
          message = error.response?.data?.message;
        }
      }

      return reject({ data, isError, message });
    }
  });
}
