/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  FormGroup,
  InputGroup,
  Input,
  Button,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import {axiosGet, axiosPost} from "util/apiConfig.js";
import moment from "moment";
import {
  dateForPaymentListing,
  classStatus,
  classStatusWithCount,
  halfClass,
  fullClass,
} from "util/constance";
import Select from "react-select";
import _ from "lodash";
import { CSVLink } from "react-csv";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      allSessionData: [],
      currentSessionDataList: [],
      allCounts: [],
      page: 1,
      totalPage: 0,
      limit: 10,
      filterStatus: null,
      filterTeacher: null,
      filterFromDate: null,
      filterToDate: null,
      filterSectionName: null,
      statusOptions: [],
      teachersList: [],
      allSectionsData: [],
      activeCard: "Upcoming",
      filterClassLength: null,
      allSessionStatus: [],
      classLengthOptions: [
        { value: fullClass, label: "Full  (50 mins)" },
        { value: halfClass, label: "Half  (25 mins)" },
      ],
      freeClass: [
        { value: true, label: "Free" },
        { value: false, label: "Paid" },
      ],
    };
  }

  componentDidMount() {
    this.setState(
      {
        allSessionData: [],
        allCounts: [],

      },
      () => {
        this.getAllSessions();
        this.getStatuss();
        this.getAllTeachers();
        this.getAllSections();
        this.setState(
            {
              allSessionStatus: [
                {
                  value: "",
                  label: 'Not Set Yet',
                },{
                  value: 'First Class',
                  label: 'First Class',
                },{
                  value: 'Complete',
                  label: 'Complete',
                },{
                  value: 'Last Min Cancel',
                  label: 'Last Min Cancel',
                },{
                  value: 'Student on Leave',
                  label: 'Student on Leave',
                },{
                  value: 'Coach On Leave',
                  label: 'Coach On Leave',
                },{
                  value: 'Cancel Class No Make Up',
                  label: 'Cancel Class No Make Up',
                },
                {
                  value: 'No Show Class',
                  label: 'No Show Class',
                },
              ]
            })
      }
    );
  }
  getStatuss = () => {
    let newstatusObj = [
      { value: null, label: "All Statuses" },
      { value: "cancelled", label: "Cancelled" },
      { value: "upcoming", label: "Not Set Yet" },
    ];
    classStatus.map((data) => {
      newstatusObj.push({ value: data, label: data });
    });
    this.setState({ statusOptions: newstatusObj });
  };
  getAllSessions = () => {
    const {
      filterStatus,
      filterTeacher,
      filterFromDate,
      filterToDate,
      activeCard,
      filterSectionName,
      filterClassLength,
      filterClassType,
      page,
      limit,
    } = this.state;
    let url = `session/get-teacher-session-list/${page}/${limit}?activeCard=${activeCard}`;
    let countUrl = `session/get-teacher-session-list-count?activeCard=${activeCard}`;
    let isFilter = false;
    if (filterStatus) {
      isFilter = true;
      url = `${url}&&status=${filterStatus}`;
      countUrl = `${countUrl}&&status=${filterStatus}`;
    }
    if (filterTeacher) {
      //let concatString = isFilter ? "&&" : "";
      let concatString = "&&";
      url = `${url}${concatString}teacherId=${filterTeacher}`;
      countUrl = `${countUrl}${concatString}teacherId=${filterTeacher}`;
      isFilter = true;
    }
    if (filterSectionName) {
      //let concatString = isFilter ? "&&" : "";
      let concatString = "&&";
      url = `${url}${concatString}sectionId=${filterSectionName}`;
      countUrl = `${countUrl}${concatString}sectionId=${filterSectionName}`;
      isFilter = true;
    }

    if (filterFromDate && filterToDate) {
      //let concatString = isFilter ? "&&" : "";
      let concatString = "&&";
      url = `${url}${concatString}startDate=${filterFromDate}&&endDate=${filterToDate}`;
      countUrl = `${countUrl}${concatString}startDate=${filterFromDate}&&endDate=${filterToDate}`;
    }
    if (filterClassLength) {
      let concatString = "&&";
      url = `${url}${concatString}classLength=${filterClassLength}`;
      countUrl = `${countUrl}${concatString}classLength=${filterClassLength}`;
    }
    if (filterClassType) {
      let concatString = "&&";
      url = `${url}${concatString}isFree=${filterClassType}`;
    }

    axiosGet(url)
      .then((response) => {
        if (!response.isError) {
          let _Details = [];
          this.setState({
            allSessionData: response.data?.result,
            totalPage: response.data?.noofpage,
            total: response.data?.total,
            // totalPage: response.data?.noofpage,
          });
          // this.getAllCounts(_Details);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAllSessionsCSV = () => {
    const {
      filterStatus,
      filterTeacher,
      filterFromDate,
      filterToDate,
      activeCard,
      filterSectionName,
      filterClassLength,
      filterClassType,
    } = this.state;
    let url = `session/get-teacher-session-list-csv?activeCard=${activeCard}`;
    let isFilter = false;
    if (filterStatus) {
      isFilter = true;
      url = `${url}&&status=${filterStatus}`;
    }
    if (filterTeacher) {
      //let concatString = isFilter ? "&&" : "";
      let concatString = "&&";
      url = `${url}${concatString}teacherId=${filterTeacher}`;
    }
    if (filterSectionName) {
      //let concatString = isFilter ? "&&" : "";
      let concatString = "&&";
      url = `${url}${concatString}sectionId=${filterSectionName}`;
    }

    if (filterFromDate && filterToDate) {
      //let concatString = isFilter ? "&&" : "";
      let concatString = "&&";
      url = `${url}${concatString}startDate=${filterFromDate}&&endDate=${filterToDate}`;
    }
    if (filterClassLength) {
      let concatString = "&&";
      url = `${url}${concatString}classLength=${filterClassLength}`;
    }
    if (filterClassType) {
      let concatString = "&&";
      url = `${url}${concatString}isFree=${filterClassType}`;
    }

    axiosGet(url)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            allSessionCSVData: response.data?.result,
          },() => {
            document.getElementById('csvData').click();
          });
        } else {
            this.setState({
                allSessionCSVData: [],
            });
        }
      })
      .catch((error) => {
        this.setState({
          allSessionCSVData: [],
        });
        console.log(error);
      });
  };

  handleSessionStatusChange = (e, index) => {
    let confirm = window.confirm("Are you sure you want to change the status?");
    if (confirm) {
      let {notificationId, currentSessionDataList} = this.state;
      let selectedCurrentSessionData = currentSessionDataList[index];
      let dataToSend = {
        sessionId: selectedCurrentSessionData.sessionId,
        status: e.value
      };
      this.setState({loading: true});
      // API -
      axiosPost('session/change-session-status-by-admin/', dataToSend)
          .then((response) => {
            if (!response.isError) {
              this.setState(
                  {
                    loading: false,
                    successModal: true,
                    modalTitle: 'Success',
                    message: 'Successfully Updated.',
                  },
                  () => {
                    this.getAllSessions()
                  }
              );
            } else {
              this.setState({
                loading: false,
                errorModal: true,
                modalTitle: 'Sorry!',
                message: 'Something is wrong. Try again later.',
              });
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
            });
            console.log(error);
          });
    }
  };

  getAllCounts = (_Details) => {
    let isContainAll = classStatusWithCount.find(
      (_data) => _data.status == "All"
    );
    let statusCountObj;
    if (isContainAll) {
      _.remove(classStatusWithCount, {
        status: "All",
      });
    }

    if (_Details && _Details.length > 0) {
      let result = _(_Details)
        .groupBy("status")
        .map(function (group, status) {
          return {
            status: status,
            count: group.length,
          };
        })
        .value();
      let resultClassLength = _(_Details)
          .groupBy("classLength")
          .map(function (group, classLength) {
            let completedCount = _.filter(group, { status: 'Complete' }).length;
            return {
              status: classLength,
              count: completedCount
            };
          })
          .value();
      const resultFull = resultClassLength.find((item) => item.status === "FULL");
      const resultHalf = resultClassLength.find((item) => item.status === "HALF");

      classStatusWithCount.map((_status) => {
        if (!JSON.stringify(result).includes(_status.status)) {
          result.push({ status: _status.status, count: 0 });
        }
      });

      let allStatusCount = result?.map((_data) => _data.count);
      let all = allStatusCount.reduce((a, b) => a + b);
      result.unshift({ status: "All", count: all });
      if (resultFull){
        result.unshift({ status: "Full Completed", count: resultFull.count });
      }
      if(resultHalf){
        result.unshift({ status: "Half Completed", count: resultHalf.count });
      }
      const countFullLastMinCancel = _Details.filter(item => item.classLength === "FULL" && item.status === "Last Min Cancel").length;
      const countHalfLastMinCancel = _Details.filter(item => item.classLength === "HALF" && item.status === "Last Min" +
          " Cancel").length;
      result.unshift({ status: "Full Last min cancel", count: countFullLastMinCancel });
      result.unshift({ status: "Half Last min cancel", count: countHalfLastMinCancel });
      statusCountObj = result;
    } else {
      classStatusWithCount.unshift({ status: "All", count: 0 });
      statusCountObj = classStatusWithCount;
    }

    this.setState({
      allCounts: _.orderBy(statusCountObj, [], ["desc"]),
    });
  };

  // to get all approved teacher's list -
  getAllTeachers = () => {
    let teachers = [{ value: null, label: "All Coaches" }];
    axiosGet(`teacher/list-of-all-teacher`)
      .then((response) => {
        if (!response.isError) {
          let data = response.data?.result || [];
          data.map((_data) => {
            teachers.push({ value: _data.teacherId, label: _data.name });
          });
          this.setState({
            teachersList: teachers,
          });
        } else {
          this.setState({
            teachersList: teachers,
          });
        }
      })
      .catch((error) => {
        this.setState({
          teachersList: teachers,
        });
      });
  };

  // for getting all section list for add hoc class-
  getAllSections = () => {
    this.setState({ loading: false });
    axiosGet(`section/get-sections-dropdown`)
      .then((response) => {
        if (!response.isError) {
          this.setSectionData(response.data);
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setSectionData = (sectionData) => {
    let newObj = [];
    sectionData.map((data) => {
      let tt = { value: data.sectionId, label: data.name };
      newObj.push(tt);
    });
    let allSectionsData = newObj;
    this.setState({ allSectionsData });
  };

  // For changing page -
  changePage = (status) => {
    if (status && status === "next") {
      this.setState({ page: this.state.page + 1 }, () =>
          this.getAllSessions()
      );
    } else if (status && status === "prev") {
      this.setState({ page: this.state.page - 1 }, () =>
          this.getAllSessions()
      );
    }
  };

  clearSearch = () => {
    this.setState(
      {
        filterStatus: null,
        filterTeacher: null,
        filterFromDate: "",
        filterToDate: "",
        filterSectionName: null,
        filterClassLength: null,
        filterClassType: null,
        page: 1,
      },
      () => {
        this.getAllSessions();
      }
    );
  };

  isFilterApplied = () => {
    let {
      filterStatus,
      filterTeacher,
      filterSectionName,
      filterClassLength,
      filterClassType,
      filterFromDate,
      filterToDate,
    } = this.state;
    if (
      filterStatus ||
      filterTeacher ||
      filterSectionName ||
      filterClassLength ||
      filterClassType ||
      (filterFromDate && filterToDate)
    ) {
      return true;
    }
    return false;
  };

  // //Set current page
  // setPage = (pageNum) => {
  //   this.setState({page: pageNum})
  // }

  getCSVData = () => {
    let { allSessionCSVData } = this.state;
    let csvData = [];
    allSessionCSVData &&
    allSessionCSVData.map((data) => {
        let sectionName = data.name && data.name;
        let date =
          data.dateOfConduct &&
          moment(data.dateOfConduct).format(dateForPaymentListing);
        let startTime =
          data.startTime && moment(data.startTime).format("hh:mm a");
        let endTime = data.endTime && moment(data.endTime).format("hh:mm a");

        let time = `${startTime} TO ${endTime}`;

        let coach = data.teacherName && data.teacherName;
        let classLength =
          data.classLength == fullClass
            ? "Full  (50 mins)"
            : data.classLength == halfClass
            ? "Half (25 mins)"
            : "";
        let status =
          data.status && data.status == "upcoming"
            ? "Not Set Yet"
            : data.status;
        let rowData = {
          sectionName: sectionName,
          date: date,
          time: time,
          coach: coach,
          classLength: classLength,
          status: status,
          topic: data?.topic ? this.truncateText(data?.topic.replace(/\n/g, ' '), 100) : "",
        };
        csvData.push(rowData);
      });

    return csvData;
  };

  truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  getHeaders = () => {
    return [
      { label: "Section Name", key: "sectionName" },
      { label: "Date", key: "date" },
      { label: "Time", key: "time" },
      { label: "Coach", key: "coach" },
      { label: "Class Length", key: "classLength" },
      { label: "Status", key: "status" },
      { label: "Topic", key: "topic" },
    ];
  };

  render() {
    let {
      allCounts,
      allSessionData,
      totalPage,
      page,
      limit,
      filterStatus,
      statusOptions,
      filterTeacher,
      teachersList,
      filterFromDate,
      filterToDate,
      activeCard,
      filterSectionName,
      allSectionsData,
      filterClassLength,
      filterClassType,
      classLengthOptions,
      freeClass,
      allSessionStatus,
      currentSessionDataList,
    } = this.state;
    let filterTeacherLable = filterTeacher
      ? teachersList
          .filter((_data) => _data.value == filterTeacher)
          .map(({ label }) => label)
      : null;

    let filterSectionNameLable = filterSectionName
      ? allSectionsData
          .filter((_data) => _data.value == filterSectionName)
          .map(({ label }) => label)
      : null;

    const indexOfLastPost = page * limit;
    const indexOfFirstPost = indexOfLastPost - limit;
    const currentSessionData =
      allSessionData && allSessionData.slice(indexOfFirstPost, indexOfLastPost);

    this.state.currentSessionDataList = currentSessionData;

    //Implement page numbers
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(allSessionData?.length / limit); i++) {
      pageNumbers.push(i);
    }

    const csvReport = {
      data: this.getCSVData(),
      headers: this.getHeaders(),
      filename: `${moment().format("YYYY-MM-DD")}-All-Class-Report.csv`,
    };

    return (
      <>
        <Header isFromDashboard={true} allCounts={allCounts} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0" style={{ display: "flex" }}>
                  <h3 className="mb-0">All Classes</h3>
                  <ul class="nav nav-pills" style={{ marginLeft: "10px" }}>
                    <li style={{ cursor: "pointer" }} class="nav-item">
                      <a
                        onClick={() => {
                          this.setState(
                            { activeCard: "Completed", page: 1 },
                            () => {
                              this.getAllSessions();
                            }
                          );
                        }}
                        class={`nav-link ${
                          activeCard == "Completed" ? "active" : ""
                        }`}
                        aria-current="page"
                      >
                        Completed
                      </a>
                    </li>
                    <li style={{ cursor: "pointer" }} class="nav-item">
                      <a
                        class={`nav-link ${
                          activeCard == "Upcoming" ? "active" : ""
                        }`}
                        onClick={() => {
                          this.setState(
                            { activeCard: "Upcoming", page: 1 },
                            () => {
                              this.getAllSessions();
                            }
                          );
                        }}
                      >
                        Upcoming
                      </a>
                    </li>
                  </ul>
                </CardHeader>

                <CardHeader
                  className="border-0 availability-header"
                  style={{ display: "flex" }}
                >
                  <div className="row">
                    <div className="col-md-4">
                      <FormGroup className="availability-formgroup">
                        <InputGroup className="input-group-alternative">
                          <div style={{ width: "100%", minWidth: "200px" }}>
                            <Select
                              options={teachersList}
                              placeholder="All Coaches"
                              value={
                                filterTeacher
                                  ? {
                                      value: filterTeacher,
                                      label: filterTeacherLable,
                                    }
                                  : filterTeacher
                              }
                              onChange={(e) => {
                                this.setState(
                                  { filterTeacher: e.value, page: 1 },
                                  () => {
                                    this.getAllSessions();
                                  }
                                );
                              }}
                              isSearchable={true}
                            />
                          </div>
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup className="availability-formgroup">
                        <InputGroup className="input-group-alternative">
                          <div style={{ width: "100%", minWidth: "200px" }}>
                            <Select
                              options={statusOptions}
                              placeholder="All Statuses"
                              value={
                                filterStatus
                                  ? {
                                      value: filterStatus,
                                      label:
                                        filterStatus == "upcoming"
                                          ? "Not Set Yet"
                                          : filterStatus,
                                    }
                                  : filterStatus
                              }
                              onChange={(e) => {
                                this.setState(
                                  { filterStatus: e.value, page: 1 },
                                  () => {
                                    this.getAllSessions();
                                  }
                                );
                              }}
                              isSearchable={true}
                            />
                          </div>
                        </InputGroup>
                      </FormGroup>
                    </div>

                    <div className="col-md-4">
                      <FormGroup className="availability-formgroup">
                        <InputGroup className="input-group-alternative">
                          <div style={{ width: "100%" }}>
                            <Select
                              options={allSectionsData}
                              placeholder="Select section"
                              value={
                                filterSectionName
                                  ? {
                                      value: filterSectionName,
                                      label: filterSectionNameLable,
                                    }
                                  : filterSectionName
                              }
                              onChange={(e) =>
                                this.setState(
                                  {
                                    filterSectionName: e.value,
                                    page: 1,
                                  },
                                  () => {
                                    this.getAllSessions();
                                  }
                                )
                              }
                              isSearchable={true}
                            />
                          </div>
                        </InputGroup>
                      </FormGroup>
                    </div>

                    <div
                      className="col-md-4"
                      style={{ padding: "10px", marginTop: "22px" }}
                    >
                      <FormGroup className="availability-formgroup">
                        <InputGroup className="input-group-alternative">
                          <div style={{ width: "100%" }}>
                            <Select
                              options={classLengthOptions}
                              placeholder="Class Length"
                              value={
                                filterClassLength
                                  ? {
                                      value: filterClassLength,
                                      label:
                                        filterClassLength == fullClass
                                          ? "Full  (50 mins)"
                                          : filterClassLength == halfClass
                                          ? "Half (25 mins)"
                                          : "",
                                    }
                                  : filterClassLength
                              }
                              onChange={(e) =>
                                this.setState(
                                  {
                                    filterClassLength: e.value,
                                    page: 1,
                                  },
                                  () => {
                                    this.getAllSessions();
                                  }
                                )
                              }
                              isSearchable={true}
                            />
                          </div>
                        </InputGroup>
                      </FormGroup>
                    </div>

                    <div
                        className="col-md-4"
                        style={{ padding: "10px", marginTop: "22px" }}
                    >
                      <FormGroup className="availability-formgroup">
                        <InputGroup className="input-group-alternative">
                          <div style={{ width: "100%" }}>
                            <Select
                                options={freeClass}
                                placeholder="Class Type"
                                value={
                                  filterClassType
                                      ? {
                                        value: filterClassType,
                                        label:
                                            filterClassType == true
                                                ? "Free"
                                                : 'Paid',
                                      }
                                      : filterClassType
                                }
                                onChange={(e) =>
                                    this.setState(
                                        {
                                          filterClassType: e.value,
                                          page: 1,
                                        },
                                        () => {
                                          this.getAllSessions();
                                        }
                                    )
                                }
                                isSearchable={true}
                            />
                          </div>
                        </InputGroup>
                      </FormGroup>
                    </div>

                    <div
                      className="col-md-4"
                      style={{ padding: "10px", marginTop: "22px" }}
                    >
                      <FormGroup className="availability-formgroup">
                        <InputGroup
                          style={{ width: "135px" }}
                          className="input-group-alternative"
                        >
                          <Input
                            placeholder="Date"
                            type="date"
                            name="filterFromDate"
                            value={filterFromDate}
                            onChange={(e) =>
                              this.setState(
                                {
                                  filterFromDate: e.target.value,
                                  page: 1,
                                },
                                () => {
                                  this.getAllSessions();
                                }
                              )
                            }
                          />
                        </InputGroup>
                        <small style={{ padding: "10px" }}> TO</small>
                        <InputGroup
                          style={{ width: "135px" }}
                          className="input-group-alternative"
                        >
                          <Input
                            placeholder="Date"
                            type="date"
                            name="filterToDate"
                            value={filterToDate}
                            onChange={(e) =>
                              this.setState(
                                {
                                  filterToDate: e.target.value,
                                  page: 1,
                                },
                                () => {
                                  this.getAllSessions();
                                }
                              )
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <div
                      className="col-md-4"
                      style={{
                        padding: "10px",
                      }}
                    >
                      <InputGroup >
                        <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            style={{ marginLeft: '10px' }}
                            onClick={() => this.getAllSessionsCSV()}
                        >
                          Export to CSV

                        </Button>
                        <CSVLink id={'csvData'} style={{display:"none", color: "white" }} {...csvReport}>
                          Export to CSV
                        </CSVLink>

                      </InputGroup>


                      {this.isFilterApplied() ? (
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => {
                            this.clearSearch();
                          }}
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          Clear Search
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {/* <div className='col-md-4 text-center'>
                      <input
                        placeholder='Search by Section name or Email'
                        className='emailInput'
                        type='text'
                        name='search'
                        className='form-control'
                        onChange={this.searchSection}
                      />
                  </div> */}
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/*{*/}
                      {/*  activeCard == "Completed" &&*/}
                      {/*     <th scope="col">Rating</th>*/}
                      {/*}*/}
                      <th scope="col">Section Name</th>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Coach</th>
                      <th scope="col">Class Length</th>
                      <th scope="col">Status</th>
                      <th scope="col">Free Class</th>
                      <th scope="col">Topic</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {allSessionData &&
                      allSessionData.length > 0 &&
                        allSessionData.map((data, index) => {
                        return (
                          <tr key={index}>
                            {/*{*/}
                            {/*    activeCard == "Completed" &&*/}
                            {/*    <td className={'table-column-width'}>{data.rating ? '*'.repeat(data.rating) : null} </td>*/}
                            {/*}*/}
                            <td className={'table-column-width'}>{data.name && data.name} </td>
                            <td className={'table-column-width'}>
                              {data.dateOfConduct &&
                                moment(data.dateOfConduct).format(
                                  dateForPaymentListing
                                )}
                            </td>
                            <td className={'table-column-width'}>
                              {data.startTime &&
                                moment(data.startTime).format("hh:mm a")}{" "}
                              TO{" "}
                              {data.endTime &&
                                moment(data.endTime).format("hh:mm a")}
                            </td>
                            <td className={'table-column-width'}>{data.teacherName && data.teacherName} </td>
                            <td className={'table-column-width'}>
                              {data.classLength == fullClass
                                ? "Full  (50 mins)"
                                : data.classLength == halfClass
                                ? "Half (25 mins)"
                                : ""}
                            </td>
                            <td  className={'table-column-width'}>
                              <div>
                                <Select
                                    options={allSessionStatus}
                                    placeholder="Select Status"
                                    isSearchable={true}
                                    menuPosition={'fixed'}
                                    menuPlacement={'bottom'}
                                    styles={{
                                      control: styles => ({ ...styles, width: 200 }),
                                      menu: styles => ({ ...styles, width: 200 }),
                                    }}
                                    onChange={(e) => this.handleSessionStatusChange(e, index)}
                                    value={
                                      data.status && data.status === "upcoming"
                                          ? { value: "", label: "Not Set Yet" }
                                          : allSessionStatus.find(option => option.value === data.status)
                                    }
                                />
                              </div>
                            </td>
                            <td className={'table-column-width'}>
                              {
                                <a>{data.isFree ? "Free" : "Paid"}</a>
                              }
                            </td>
                            <td className={'table-column-width'}>
                              {data.topic && data.topic}
                            </td>
                          </tr>
                        );
                      })}
                    {!allSessionData || allSessionData.length === 0 ? (
                      <tr>
                        <td>No Data Found.</td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className={page > 1 ? "" : "disabled"}>
                        <PaginationLink
                          onClick={() => this.changePage("prev")}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className="active">
                        <PaginationLink onClick={(e) => e.preventDefault()}>
                          {page}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalPage > page ? "" : "disabled"}
                      >
                        <PaginationLink onClick={() => this.changePage("next")}>
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
          {/* <Row>
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                        Overview
                      </h6>
                      <h2 className="text-white mb-0">Sales value</h2>
                    </div>
                    <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 1
                            })}
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 1)}
                          >
                            <span className="d-none d-md-block">Month</span>
                            <span className="d-md-none">M</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 2
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 2)}
                          >
                            <span className="d-none d-md-block">Week</span>
                            <span className="d-md-none">W</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>

                  <div className="chart">
                    <Line
                      data={chartExample1[this.state.chartExample1Data]}
                      options={chartExample1.options}
                      getDatasetAtEvent={e => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">Total orders</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>

                  <div className="chart">
                    <Bar
                      data={chartExample2.data}
                      options={chartExample2.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Page visits</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Page name</th>
                      <th scope="col">Visitors</th>
                      <th scope="col">Unique users</th>
                      <th scope="col">Bounce rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">/argon/</th>
                      <td>4,569</td>
                      <td>340</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/index.html</th>
                      <td>3,985</td>
                      <td>319</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/charts.html</th>
                      <td>3,513</td>
                      <td>294</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                        36,49%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/tables.html</th>
                      <td>2,050</td>
                      <td>147</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />{" "}
                        50,87%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/profile.html</th>
                      <td>1,795</td>
                      <td>190</td>
                      <td>
                        <i className="fas fa-arrow-down text-danger mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Social traffic</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Referral</th>
                      <th scope="col">Visitors</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Facebook</th>
                      <td>1,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">60%</span>
                          <div>
                            <Progress
                              max="100"
                              value="60"
                              barClassName="bg-gradient-danger"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Facebook</th>
                      <td>5,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">70%</span>
                          <div>
                            <Progress
                              max="100"
                              value="70"
                              barClassName="bg-gradient-success"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Google</th>
                      <td>4,807</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">80%</span>
                          <div>
                            <Progress max="100" value="80" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Instagram</th>
                      <td>3,678</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">75%</span>
                          <div>
                            <Progress
                              max="100"
                              value="75"
                              barClassName="bg-gradient-info"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">twitter</th>
                      <td>2,645</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">30%</span>
                          <div>
                            <Progress
                              max="100"
                              value="30"
                              barClassName="bg-gradient-warning"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
         */}
        </Container>
      </>
    );
  }
}

export default Index;
