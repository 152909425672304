import React from 'react';
import {
    Card,
    CardHeader,
    Container,
    Row, Table,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import Select from "react-select";
import {axiosGet} from "../../util/apiConfig";

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classId: '',
      activeCard: "Child",
      teachersList: [],
      allSessionData: [],
      sectionReport: [],
    };
  }

  componentDidMount() {
    const classId = localStorage.getItem("classId");
    this.setState(
      {
        classId: classId ? classId : null,
      },
      () => {
          this.getAllSections();
      }
    );
  }

  getSectionReport = (sectionId) => {
      this.setState({ loading: true });
      axiosGet(`section/get-section-report-by-id/${sectionId}`)
          .then((response) => {
              if (!response.isError) {
                  this.setState({ sectionReport: response.data,loading: false });
              } else {
                  this.setState({sectionReport: [], loading: false });
              }
          })
          .catch((error) => {
              this.setState({sectionReport: [], loading: false });
              console.log(error);
          });
  }


  getAllSections = () => {
        this.setState({ loading: false });
        axiosGet(`section/get-sections-dropdown`)
            .then((response) => {
                if (!response.isError) {
                    this.setSectionData(response.data);
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    setSectionData = (sectionData) => {
        let newObj = [];
        sectionData.map((data) => {
            let tt = { value: data.sectionId, label: data.name };
            newObj.push(tt);
        });
        let allSectionsData = newObj;
        this.setState({ allSectionsData });
    };

  render() {
    let {
      activeCard,
      teachersList,
        allSectionsData,
        filterSectionName,
        sectionReport,
        filterTeacher
    } = this.state;
      let filterSectionNameLable = filterSectionName
          ? allSectionsData
              .filter((_data) => _data.value == filterSectionName)
              .map(({ label }) => label)
          : null;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <br />
          <br />
          {/* Table */}
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <ul className="nav nav-pills" style={{marginLeft: "10px"}}>
                    <li style={{cursor: "pointer"}} className="nav-item">
                      <a
                          className={`nav-link ${
                              activeCard == "Child" ? "active" : ""
                          }`}
                          onClick={() => {
                            this.setState(
                                {activeCard: "Child", sectionReport : [], filterSectionName: null, filterTeacher: null},
                                () => {


                                }
                            );
                          }}
                      >
                        Child
                      </a>
                    </li>
                    <li style={{cursor: "pointer"}} className="nav-item">
                      <a
                          className={`nav-link ${
                              activeCard == "Teacher" ? "active" : ""
                          }`}
                          onClick={() => {
                            this.setState(
                                {activeCard: "Teacher",  sectionReport : [],filterSectionName: null, filterTeacher: null},
                                () => {
                                }
                            );
                          }}
                      >
                        Teacher
                      </a>
                    </li>
                  </ul>
                  <div>
                        <div className="col-md-4 text-center" style={{ float: "left", paddingBottom: '20px', paddingTop: '20px' }}>
                            {
                                activeCard == "Child" && (
                                    <div style={{ width: "100%" }}>
                                        <Select
                                            options={allSectionsData}
                                            placeholder="Select section"
                                            value={
                                                filterSectionName
                                                    ? {
                                                        value: filterSectionName,
                                                        label: filterSectionNameLable,
                                                    }
                                                    : filterSectionName
                                            }
                                            onChange={(e) =>
                                                this.setState(
                                                    {
                                                        filterSectionName: e.value,
                                                    },
                                                    () => {
                                                        this.getSectionReport(e.value)
                                                    }
                                                )
                                            }
                                            isSearchable={true}
                                        />
                                    </div>
                                )
                            }
                            {
                                activeCard == "Teacher" && (
                                    <div style={{ width: "100%" }}>
                                        <Select
                                            options={teachersList}
                                            placeholder="All Coaches"
                                            isSearchable={true}
                                            onChange={(e) => {
                                                this.setState(
                                                    { filterTeacher: e.value },
                                                    () => {
                                                        this.getMakeupRequest();
                                                    }
                                                );
                                            }}
                                        />
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </CardHeader>
                  {
                      sectionReport && sectionReport.length > 0 && (
                          <Table className="align-items-center table-flush" responsive>
                              <thead className="thead-light">
                                  <th scope="col">Class Status</th>
                                  <th scope="col">Count</th>
                              </thead>
                              <tbody>
                                  {
                                      sectionReport.map((data, index) => (
                                          <tr key={index} style={data._id == 'Makeup' ? { color: 'red' } : data._id == 'Total' ? { color: 'green' } : { } }>
                                              <td>{data._id}</td>
                                              <td>{data.count}</td>
                                          </tr>
                                      ))
                                  }
                              </tbody>
                          </Table>
                      )
                  }



              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Reports;
