import React from "react";
import axios from "axios";
import cred from "../../util/cred.js";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { axiosGet } from "util/apiConfig.js";
const path = cred.API_PATH;

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUserId: "",
      userDetails: "",
      plans: [],
      countryCode: "",
      mobileNo: "",

      successModal: false,
      successMessage: "",
      errorModal: false,
      errorMessage: "",
    };
  }

  componentDidMount() {
    let Id = this.props.match.params.id;
    this.setState(
      {
        selectedUserId: Id,
      },
      () => {
        this.getUserDetails(this.state.selectedUserId);
      }
    );
  }

  getUserDetails = (userId) => {
    // API -
    axiosGet(`user/get-user-by-id/${userId}`)
      .then((response) => {
        if (!response.isError) {
          if (response.data?.userType === "student") {
            // this.getPlans();
          }
          let countryCode = "";
          let mobileNo = "";
          if (
            response.data &&
            response.data?.mobileNumber &&
            response.data?.mobileNumber !== undefined
          ) {
            let mobileNumber = response.data?.mobileNumber
              .toString()
              .split("-");
            countryCode = mobileNumber[0].slice(1);
            mobileNo = mobileNumber[1];
          }
          this.setState({
            userDetails: response.data,
            countryCode: countryCode,
            mobileNo: mobileNo,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getPlans = () => {
    axios
      .post(path + "payment/get-subscription-plans")
      .then((serverResponse) => {
        let res = serverResponse.data;
        if (res && res.serverResponse && !res.serverResponse.isError) {
          this.setState({
            plans: res.result,
          });
          // res.result && setPlans(res.result);
          // setLoading(false);
        } else {
          this.setState({
            plans: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getPriceOfPlan = (priceId) => {
    let { plans } = this.state;
    priceId &&
      plans.length > 0 &&
      plans.map((value, Index) => {
        if ((value.priceId = priceId)) {
          return value.cost;
        }
      });
  };

  render() {
    let { userDetails, countryCode, mobileNo } = this.state;
    return (
      <>
        <Header />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4"></Col>

            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Username
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={userDetails.name}
                              id="input-username"
                              placeholder="Username"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="email@example.com"
                              defaultValue={userDetails.email}
                              type="email"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <label>Country Code</label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Country Code"
                              type="number"
                              name="countryCode"
                              defaultValue={countryCode}
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="6">
                          <label>Mobile Number</label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Mobile Number"
                              type="text"
                              name="mobileNo"
                              defaultValue={mobileNo}
                            />
                          </InputGroup>
                        </Col>

                        <Col lg="6" style={{ marginTop: "1.5rem" }}>
                          <label>Timezone</label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Timezone"
                              type="text"
                              name="mobileNo"
                              defaultValue={userDetails?.timeZone}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row></Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={userDetails.location}
                              id="input-address"
                              placeholder="Home Address"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row></Row>
                    </div>
                    <hr className="my-4" />
                    {userDetails.userType == "teacher" ? (
                      <>
                        Video
                        <div class="row">
                          <div class="col-lg-6">
                            {/* <iframe width="100%" height="300" src= {userDetails.videoURL} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                            {userDetails.videoURL ? (
                              <video width="320" height="240" controls autoplay>
                                <source
                                  src={userDetails.videoURL}
                                  type="video/mp4"
                                />
                              </video>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : null}
                    {userDetails.userType == "student" ? (
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                Credit
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={userDetails.classCredit}
                                id="input-address"
                                placeholder="Credit"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          {userDetails.isInSubscription ? (
                            <Col md="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-address"
                                >
                                  Subscription Plan
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={this.getPriceOfPlan(
                                    userDetails.priceId
                                  )}
                                  id="input-address"
                                  placeholder="$0.00"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          ) : null}
                        </Row>
                        <Row></Row>
                      </div>
                    ) : null}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Profile;
