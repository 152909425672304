/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
  Button,
  CardBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header.js";
import { axiosGet, axiosPost, axiosDelete } from "../../util/apiConfig.js";
import Select from "react-select";
import moment from "moment/moment";
import {Device} from "@twilio/voice-sdk";
import AudioPlayer from "react-h5-audio-player";
import {CopyToClipboard} from "react-copy-to-clipboard";
import axios from "axios";
import Cookies from "js-cookie";
import cred from "../../util/cred";
const pdfRef = React.createRef(null);

const USER_STATE = {
  CONNECTING : "Connecting",
  READY : "Ready",
  ON_CALL : "On call",
  OFFLINE : "Offline",
}
class TeachersHiring extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teachersList: [],
      classList: [],
      teacherHiringList: [],
      listPage: 1,
      totalListPage: "",
      newPage: 1,
      totalNewPage: "",
      rowLimit: 10,

      modalTitle: "",
      successModal: false,
      errorModal: false,
      message: "",
      userState: USER_STATE.OFFLINE,
      showAddTeacher: false,
      submitting: false,
      messageStates: {},
      salesFollowUpModal: false,
      allTeacherHiringStatus: [],
      addModal: false
    };
  }

  componentDidMount() {
    const classId = localStorage.getItem("classId");
    this.setState(
      {
        globalClassId: classId ? classId : null,
        teachersList: [],
        teacherHiringList: []
      },
      () => {
        this.getClass();
        this.getNewTeachers();
        this.setState({ allTeacherHiringStatus: [
            {
              value: 'No Response',
              label: 'No Response',
            },{
              value: 'Poor Communication Skills',
              label: 'Poor Communication Skills',
            },{
              value: 'Demo Requested',
              label: 'Demo Requested',
            },{
              value: 'Hours Not Matching',
              label: 'Hours Not Matching',
            },{
              value: 'Connect Later',
              label: 'Connect Later',
            }]
        });
      }
    );
  }

  getNewTeachers = () => {
    let { newPage, rowLimit } = this.state;
    let url = `teacher/list-of-teacher-hiring/${newPage}/${rowLimit}`;
    axiosGet(url)
        .then((response) => {
          if (!response.isError) {
            this.setState({
              teacherHiringList: response.data?.result || [],
              totalNewPage: response.data?.noofpage,
            });
          } else {
            this.setState({
              teacherHiringList: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            teacherHiringList: [],
          });
        });
  };

  getClass = () => {
    // API --
    axiosGet(`class/get-classes-list`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            classList: response.data || [],
          });
        } else {
          this.setState({
            classList: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          classList: [],
        });
      });
  };

  // For pagination -
  previousPage = (status) => {
    let { listPage, newPage } = this.state;
    if (status == "list") {
      this.setState({ listPage: listPage - 1 }, () => {
        this.getAllTeachers();
      });
    } else if (status == "new") {
      this.setState({ newPage: newPage - 1 }, () => {
        this.getNewTeachers();
      });
    }
  };

  // For pagination -
  nextPage = (status) => {
    let { listPage, newPage } = this.state;
    if (status == "list") {
      this.setState({ listPage: listPage + 1 }, () => {
        this.getAllTeachers();
      });
    } else if (status == "new") {
      this.setState({ newPage: newPage + 1 }, () => {
        this.getNewTeachers();
      });
    }
  };

  // Modal control -
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      modalTitle: "",
      message: "",
    });
  };

  showAddTeacherModal = (showAddTeacher, data = null) => {
    this.setState({
      showAddTeacher: !showAddTeacher,
    });
  };

  fileChange = (e) => {
    let file = pdfRef.current && pdfRef.current.files && pdfRef.current.files[0];
    let blnValid = false;
    if (file) {
      let sFileName = file.name;
      var _validFileExtensions = ['.csv'];
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
            sFileName
                .substr(
                    sFileName.length - sCurExtension.length,
                    sCurExtension.length
                )
                .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }
      if (blnValid) {
        const data = new FormData();
        data.append('file', file);
        this.setState({
         fileData: data
        });
      } else {
        this.setState({
          errorModal: true,
          message: 'File format is not supported',
          modalTitle: 'Sorry',
          fileData: ''
        });
      }
    } else {
      this.setState({
        errorModal: true,
        message: 'File size is exceeded',
        modalTitle: 'Sorry',
        fileData: ''
      });
    }
  };


  saveTeacher = () => {
    this.setState({ uploading: true, submitting: true});
    const {
      fileData
    } = this.state;
    if(!fileData){
      this.setState({ uploading: false, submitting: false});
      return alert('Please select csv file');
    }
    let authToken = Cookies.get('token') ? Cookies.get('token') : undefined;
    axios({
          method: 'post',
          url: `${cred.API_PATH}teacher/teacher-hiring-upload-csv`,
          data: fileData,
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        })
        .then(response => {
          if (!response.isError) {
            this.setState({
              showAddTeacher: false,
              submitting: false,
              uploading: false,
              successModal: true,
              message: 'Successfully uploaded',
              modalTitle: 'Success',
            }, ()=>{
              setTimeout(()=>{
                window.location.reload();
              }, 2000)
              // this.componentDidMount()
            });
          } else {
            this.setState({
              showAddTeacher: false,
              submitting: false,
              uploading: false,
              errorModal: false,
              message: "Sorry! can't be uploaded.",
              modalTitle: 'Sorry!',
            });
          }
        })
        .catch(error => {
          this.setState({
            showAddTeacher: false,
            uploading: false,
            errorModal: false,
            message: "Sorry! can't be uploaded.",
            modalTitle: 'Sorry!',
          });
        });




    // axiosPost(`teacher/teacher-hiring-upload-csv`, fileData, true)
    //     .then((response) => {
    //       if (!response.isError) {
    //         this.setState({
    //           showAddTeacher: false,
    //           submitting: false,
    //           uploading: false,
    //           successModal: true,
    //           message: 'Successfully uploaded',
    //           modalTitle: 'Success',
    //         }, ()=>{
    //           this.componentDidMount()
    //         });
    //       } else {
    //         this.setState({
    //           showAddTeacher: false,
    //           submitting: false,
    //           uploading: false,
    //           errorModal: false,
    //           message: "Sorry! can't be uploaded.",
    //           modalTitle: 'Sorry!',
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       this.setState({
    //         showAddTeacher: false,
    //         uploading: false,
    //         errorModal: false,
    //         message: "Sorry! can't be uploaded.",
    //         modalTitle: 'Sorry!',
    //       });
    //     });
  };

  handleCall = async (sale, index) => {
    const confirm = window.confirm(`Are you sure want to call? \nPlease verify details\nMobile: ${sale.mobile}, `)
    if(!confirm){
      return false;
    }
    const {device } = this.state;
    device.emit("connect");
    device.connect({params: {To: `+${sale.mobile}`, teacherHiringId: sale.teacherHiringId } }).then((call) => {
      call.on("accept", () => {
        this.updateMessageState(index, USER_STATE.ON_CALL);
        console.log("call accepted");
      });
      call.on("disconnect", () => {
        console.log("The call has been disconnected.");
        this.updateMessageState(index, USER_STATE.READY);
      });
      call.on("reject", () => {
        console.log("The call was rejected.");
      });
      call.on("cancel", () => {
        console.log("The call was rejected.");
        this.updateMessageState(index, USER_STATE.READY);
      });
    })
  };

  updateMessageState = (index, newState) => {
    this.setState(prevState => ({
      messageStates: {
        ...prevState.messageStates,
        [index]: newState
      }
    }));
  };

  callInit = (sale, index) => {
    axiosPost('sales/token', {})
        .then((response) => {
          if (!response.isError) {
            this.setState(
                {
                  loading: false
                },
                () => {
                  const token = response.data.token;
                  const device = new Device(token, {
                    logLevel: 1,
                    codecPreferences: ["opus", "pcmu"],
                  });
                  device.register();
                  this.setState({device})
                  device.addListener("connect", (device) => {
                    console.log("Connect event listener added .....");
                    return device;
                  });
                  device.on("registered", () => {
                    console.log("Agent registered");
                    this.setState({userState: USER_STATE.READY});
                  });
                  device.on("connect", (connection) => {
                    console.log("Call connect");
                    this.setState({userState: USER_STATE.ON_CALL})
                  });
                  device.on("disconnect", () => {
                    console.log("Disconnect event");
                    this.setState({userState: USER_STATE.READY})
                  });

                  setTimeout(()=>{
                    this.handleCall(sale, index)
                  }, 1000)
                  return () => {
                    device.destroy();
                    this.setState({device: undefined})
                    this.setState({userState: USER_STATE.OFFLINE})
                  };
                }
            );
          } else {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            errorModal: true,
            modalTitle: 'Sorry!',
            message: 'Something is wrong. Try again later.',
          });
          console.log(error);
        });
  }


  rejectCall = ()=>{
    const {device, selectedNumber} = this.state
    device.emit("disconnect");
    device.emit("cancel");
    device.emit("reject");
    device.disconnectAll({params: {To: selectedNumber} });
  }

  followupInfo = (index) => {
    let { teacherHiringList } = this.state;
    let selectedNotification = teacherHiringList[index];
    this.setState({
      salesFollowUpModal: true,
      salesFollowUpInfo: selectedNotification?.salesFollowUp || ''
    });
  };

  editNotification = (index) => {
    let { teacherHiringList } = this.state;
    let selectedTeacher = teacherHiringList[index];
    this.setState({
      editModal: true,
      name: selectedTeacher.name,
      email: selectedTeacher.email,
      mobile: selectedTeacher.mobile,
      teacherHiringId: selectedTeacher.teacherHiringId,
    });
  };

  deleteNotification = (teacherHiringId) => {
    let confirm = window.confirm("Are you sure you want to Delete?");
    if (confirm) {
      this.setState({ loading: true });
      // API -
      axiosPost(`teacher/delete-teacher-hiring/${teacherHiringId}`, {})
          .then((response) => {
            if (!response.isError) {
              this.setState(
                  {
                    loading: false,
                    successModal: true,
                    modalTitle: 'Success',
                    message: 'Successfully deleted',
                  },
                  () => {
                    this.componentDidMount();
                    this.resetForm()
                  }
              );
            } else {
              this.setState({
                loading: false,
                errorModal: true,
                modalTitle: 'Sorry!',
                message: 'Something is wrong. Try again later.',
              });
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
            });
            console.log(error);
          });
    }
  };

  resetForm = () => {
    this.setState({
      loading: false,
      name: '',
      email: '',
      mobile: '',
      teacherHiringId: ''
    });
  };

  update = () => {
    let {
      name,
      email,
      mobile,
      teacherHiringId
    } = this.state;
    let dataToSend = {
      name,
      email,
      mobile
    };
    this.setState({
      loading: true,
      uploading: true
    });
    axiosPost(`teacher/update-teacher-hiring/${teacherHiringId}`, dataToSend)
        .then((response) => {
          this.setState({
            editModal: false,
            loading: false,
            uploading: false
          });
          if (!response.isError) {
            this.setState(
                {
                  successModal: true,
                  modalTitle: 'Success',
                  message: 'Successfully saved.',
                },
                () => {
                  this.resetForm();
                  this.componentDidMount()
                }
            );
          } else {
            this.setState(
                {
                  errorModal: true,
                  modalTitle: 'Sorry!',
                  message: response.message,
                },
                () => {
                  this.resetForm();
                }
            );
          }
        })
        .catch((errorResponse) => {
          this.setState(
              {
                editModal: false,
                selectedChapter: '',
                notificationModal: false,
                errorModal: true,
                loading: false,
                modalTitle: 'Sorry!',
                message: errorResponse.message,
              },
              () => {
                this.resetForm();
              }
          );
        });
  };

  add = () => {
    let {
      name,
      email,
      mobile,
    } = this.state;
    if(!name){
      return alert('Please add name');
    }
    let dataToSend = {
      name,
      email,
      mobile
    };
    this.setState({
      loading: true,
      uploading: true
    });
    axiosPost(`teacher/add-teacher-hiring`, dataToSend)
        .then((response) => {
          this.setState({
            editModal: false,
            addModal: false,
            loading: false,
            uploading: false
          });
          if (!response.isError) {
            this.setState(
                {
                  successModal: true,
                  modalTitle: 'Success',
                  message: 'Successfully saved.',
                },
                () => {
                  this.resetForm();
                  this.componentDidMount()
                }
            );
          } else {
            this.setState(
                {
                  addModal: false,
                  errorModal: true,
                  modalTitle: 'Sorry!',
                  message: response.message,
                },
                () => {
                  this.resetForm();
                }
            );
          }
        })
        .catch((errorResponse) => {
          this.setState(
              {
                addModal: false,
                editModal: false,
                selectedChapter: '',
                notificationModal: false,
                errorModal: true,
                loading: false,
                modalTitle: 'Sorry!',
                message: errorResponse.message,
              },
              () => {
                this.resetForm();
              }
          );
        });
  };


  handleNotificationStatusChange = (e, teacherHiringId) => {
    let message = "Are you sure you want to change the status?"
    let confirm = window.confirm(message);
    if (confirm) {
      let dataToSend = {
        status: e.value
      };
      this.setState({loading: true});
      axiosPost(`teacher/update-teacher-hiring/${teacherHiringId}`, dataToSend)
          .then((response) => {
            if (!response.isError) {
              this.setState(
                  {
                    loading: false,
                    successModal: true,
                    modalTitle: 'Success',
                    message: 'Successfully Updated.',
                  },
                  () => {
                    this.componentDidMount();
                    this.resetForm()
                  }
              );
            } else {
              this.setState({
                loading: false,
                errorModal: true,
                modalTitle: 'Sorry!',
                message: 'Something is wrong. Try again later.',
              });
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
            });
            console.log(error);
          });
    }
  };


  render() {
    let {
      teacherHiringList,
      successModal,
      errorModal,
      modalTitle,
      message,
      newPage,
      totalNewPage,
      showAddTeacher,
      teacherEmailError,
      submitting,
      salesFollowUpModal,
      salesFollowUpInfo,
      editModal,
      email,
      name,
      mobile,
      uploading,
      loading,
      allTeacherHiringStatus,
      addModal,
    } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Teachers Hiring</h3>
                  <div style={{ float: "right" }}>
                    <Button
                        color="primary"
                        type="file"
                        onClick={() => this.showAddTeacherModal(showAddTeacher)}
                    >
                      Upload Teachers
                    </Button>
                    <Button
                        color="primary"
                        type="file"
                        onClick={() => this.setState({addModal: true})}
                    >
                      + Add Teacher
                    </Button>
                  </div>
                </CardHeader>
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ marginBottom: "100px" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Mobile No</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {teacherHiringList &&
                      teacherHiringList.map((value, index) => {
                        const isActive = Object.values(this.state.messageStates).some(
                            state => state === 'Connecting' || state === 'On call'
                        )
                        const userStateForMessage = this.state.messageStates[index] || USER_STATE.OFFLINE;

                        return (
                          <tr key={index}>
                            <td  className={'table-column-width'} >
                              {
                                  value.salesFollowUp && value.salesFollowUp.length > 0 && (
                                      <button onClick={() => this.followupInfo(index)} className={'btn btn-white'}>
                                        <i className="fa fa-info" style={{fontSize:'20px'}} aria-hidden="true" ></i>
                                      </button>
                                  )
                              }
                              {

                                (userStateForMessage === 'Connecting' || userStateForMessage === 'On call') ? (
                                    <button
                                        className={'btn btn-danger'}
                                        onClick={() => this.rejectCall(value.mobile)}
                                    >Reject
                                    </button>
                                ): (
                                    <button
                                        className={'btn btn-white'}
                                        disabled={isActive}
                                        onClick={() =>this.callInit(value, index)}
                                    ><i className="fa fa-phone" style={{fontSize:'20px'}} ></i>
                                    </button>
                                )
                              }
                            </td>
                            <td className={'table-column-width'}>{value.name || ""}</td>
                            <td className={'table-column-width'}>{value.email || ""}</td>
                            <td className={'table-column-width'}>{value.mobile || ""}</td>
                            <td className={'table-column-width'}>
                              <Media className='align-items-center'>
                              <Media>
                                <span className='mb-0 text-sm'>
                                  {value.createdAt
                                      ? moment(value.createdAt).format('ll hh:mm A')
                                      : ''}
                                </span>
                              </Media>
                            </Media>
                            </td>
                            <td  className={'table-column-width'}>
                              <div>
                                <Select
                                    options={allTeacherHiringStatus}
                                    placeholder="Select Status"
                                    isSearchable={true}
                                    menuPosition={'fixed'}
                                    menuPlacement={'bottom'}
                                    styles={{
                                      control: styles => ({ ...styles, width: 200 }),
                                      menu: styles => ({ ...styles, width: 200 }),
                                    }}
                                    onChange={(e) => this.handleNotificationStatusChange(e, value.teacherHiringId)}
                                    value={allTeacherHiringStatus.find(option => option.value == value.status)}
                                />
                              </div>
                            </td>
                            <td  className={'table-column-width'} >
                              <UncontrolledDropdown>
                                <DropdownToggle
                                    className='btn-icon-only text-light'
                                    href='JavaScript:Void(0);'
                                    role='button'
                                    size='sm'
                                    color=''
                                    onClick={(e) => e.preventDefault()}
                                >
                                  <i className='fas fa-ellipsis-v' />
                                </DropdownToggle>
                                <DropdownMenu
                                    className='dropdown-menu-arrow'
                                    right
                                >
                                  <DropdownItem
                                      href='JavaScript:Void(0);'
                                      onClick={() => this.editNotification(index)}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                      href='JavaScript:Void(0);'
                                      onClick={() => this.deleteNotification(value.teacherHiringId)}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className={newPage > 1 ? "" : "disabled"}>
                        <PaginationLink
                          href="JavaScript:Void(0);"
                          onClick={() => this.previousPage("new")}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className="active">
                        <PaginationLink
                          href="JavaScript:Void(0);"
                          onClick={(e) => e.preventDefault()}
                        >
                          {newPage}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalNewPage > newPage ? "" : "disabled"}
                      >
                        <PaginationLink
                          href="JavaScript:Void(0);"
                          onClick={() => this.nextPage("new")}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
          {/* Dark table */}

          <Modal
              className='modal-dialog-centered'
              size='lg'
              isOpen={salesFollowUpModal}
              toggle={() =>
                  this.toggleModal('salesFollowUpModal')
              }
          >
            <div className='modal-body p-0'>
              <Card className='bg-secondary shadow border-0'>
                <CardHeader className='bg-transparent pb-5'>
                  <div className='text-muted text-center'>
                    <small>{'Follow Up'}</small>
                  </div>
                </CardHeader>
                <CardBody className='px-lg-5 py-lg-5'>
                  <Table
                      className='align-items-center table-flush cp;'
                      responsive
                      style={{ marginBottom: '100px' }}
                  >
                    <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Date</th>
                      <th scope='col'>Status</th>
                      <th scope='col'>Call By</th>
                      <th scope='col'>Audio</th>
                      <th scope='col'>Link</th>
                      <th scope='col' />
                    </tr>
                    </thead>
                    <tbody>
                    {salesFollowUpInfo &&
                        salesFollowUpInfo.map((value, index) => {
                          return (
                              value.isActive && (
                                  <tr key={index} >
                                    <td  className={'table-column-width'}>
                                      <Media className='align-items-center'>
                                        <Media>
                                            <span className='mb-0 text-sm'>
                                              {value.createdAt
                                                  ? moment(value.createdAt).format('ll hh:mm A')
                                                  : ''}
                                            </span>
                                        </Media>
                                      </Media>
                                    </td>
                                    <td  className={'table-column-width'}>{value?.callStatus || ''}</td>
                                    <td  className={'table-column-width'}>{value?.userType || ''}</td>
                                    <td  className={'table-column-width'}>
                                      {
                                        value.fileName ? (
                                            <AudioPlayer
                                                autoPlay={false}
                                                autoPlayAfterSrcChange={false}
                                                src={value.fileName}
                                                onPlay={e => console.log("onPlay")}
                                                // other props here
                                            />
                                        ): (
                                            <></>
                                        )
                                      }
                                    </td>
                                    <td className={'table-column-width'}>
                                      {
                                        value.fileName ? (
                                                <CopyToClipboard text={value.fileName}>
                                                  <i
                                                      style={{cursor: 'pointer'}}
                                                      className='BtnFill'
                                                      color='success'
                                                  ><i className='fa fa-copy'></i>
                                                  </i>
                                                </CopyToClipboard>
                                            ):(
                                                <></>
                                            )
                                      }
                                    </td>
                                  </tr>
                              )
                          );
                        })}
                    </tbody>
                  </Table>
                  <div className="row text-right">
                    <div className="ml-auto" style={{marginRight: '10px'}}>
                      <Button
                          color="primary"
                          type="button"
                          onClick={() => this.setState({ salesFollowUpModal: !salesFollowUpModal })}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Modal>

          <Modal
              className='modal-dialog-centered'
              size='lg'
              isOpen={addModal || editModal}
              toggle={() =>
                  this.toggleModal(addModal ? 'addModal' : 'editModal')
              }
          >
            <div className='modal-body p-0'>
              <Card className='bg-secondary shadow border-0'>
                <CardHeader className='bg-transparent pb-5'>
                  <div className='text-muted text-center mt-2 mb-3'>
                    <small>{addModal ? 'Add Teacher' : 'Edit Teacher'}</small>
                  </div>
                </CardHeader>
                <CardBody className='px-lg-5 py-lg-5'>
                  <Form role='form'>
                    <FormGroup>
                      <label>Name</label>
                      <InputGroup className='input-group-alternative'>
                        <Input
                            placeholder='Name'
                            type='text'
                            name='name'
                            value={name}
                            onChange={(e) =>
                                this.setState({ name: e.target.value })
                            }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup >
                      <label>Email</label>
                      <InputGroup className='input-group-alternative'>
                        <Input
                            placeholder='Email'
                            type='text'
                            name='email'
                            value={email}
                            onChange={(e) =>
                                this.setState({ email: e.target.value })
                            }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <label>Mobile Number </label>
                      <InputGroup className='input-group-alternative'>
                        <Input
                            placeholder='Mobile Number'
                            ype='text'
                            name='mobile'
                            value={mobile}
                            onChange={(e) => {
                              const input = e.target.value.replace(/\D/g,'').slice(0, 15); // remove non-digits and
                              // limit to 10 digits
                              this.setState({ mobile: input });
                            }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className='text-center'>
                      {uploading ? (
                          <span style={{ color: 'red' }}>Wait...</span>
                      ) : null}

                      <Button
                          disabled={loading}
                          className='my-4'
                          color='primary'
                          type='button'
                          onClick={() => {
                            addModal ? this.add() : this.update()
                          }}
                      >
                        {addModal ? 'Save' : 'Update'}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Modal>

          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
              successModal
                ? this.toggleModal("successModal")
                : errorModal
                ? this.toggleModal("errorModal")
                : null
            }
          >
            <div className="modal-header">
              <h5 className="modal-title" id="successModalLabel">
                {modalTitle}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() =>
                  successModal
                    ? this.toggleModal("successModal")
                    : errorModal
                    ? this.toggleModal("errorModal")
                    : null
                }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            {this.state.successModal ? (
              <div className="modal-body" style={{ color: "green" }}>
                {message}
              </div>
            ) : this.state.errorModal ? (
              <div className="modal-body" style={{ color: "red" }}>
                {message}
              </div>
            ) : null}
            <div className="modal-footer">
              {/* <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("successModal")}
              >
                Close
            </Button> */}
              <Button
                color="primary"
                type="button"
                onClick={() =>
                  successModal
                    ? this.toggleModal("successModal")
                    : errorModal
                    ? this.toggleModal("errorModal")
                    : null
                }
              >
                Ok
              </Button>
            </div>
          </Modal>


          <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={showAddTeacher}
              toggle={() => this.showAddTeacherModal(showAddTeacher)}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>{"Upload Teachers"}</small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form">
                    <FormGroup
                        className={teacherEmailError ? "has-danger" : ""}
                    >
                      <label>Upload Csv</label>
                      <InputGroup className="input-group-alternative">
                        <input
                            type='file'
                            accept={'.csv'}
                            name='image'
                            ref={pdfRef}
                            id='myFile'
                            onChange={(e) => this.fileChange(e)}
                            multiple={false}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      {submitting ? (
                          <span style={{ color: "red" }}>Submitting...</span>
                      ) : null}

                      <Button
                          disabled={submitting ? true : false}
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => {
                            this.saveTeacher();
                          }}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Modal>

        </Container>
      </>
    );
  }
}

export default TeachersHiring;
