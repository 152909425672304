/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from 'views/Index.js';
// import Profile from "views/examples/Profile.js";
import Maps from 'views/examples/Maps.js';
import Register from 'views/examples/Register.js';
import Login from 'views/examples/Login.js';
import Tables from 'views/examples/Tables.js';
import Class from 'views/examples/Class.js';
import Section from 'views/examples/Section.jsx';
import Teachers from 'views/examples/Teachers.js';
import TeachersHiring from 'views/examples/TeachersHiring.js';
import TeachersAvailability from 'views/examples/TeachersAvailability.js';
import StudentAvailability from  'views/examples/StudentAvailability.js';
import Students from 'views/examples/Students.js';
import Curriculum from 'views/examples/Curriculum';
import PrivateSection from 'views/examples/PrivateSection.js';
import CancelledSection from 'views/examples/CancelledSection.js';
import Profile from 'views/examples/TeachersProfile.js';
import StudentsProfile from 'views/examples/StudentsProfile.js';
import Icons from 'views/examples/Icons.js';
import Category from 'views/examples/Category.js';
import AgeGroup from 'views/examples/ageGroup.js';
import RoomManagement from 'views/examples/roomManagement.js';
import Message from 'views/examples/message.js';
import Testimonial from 'views/examples/Testimonial.jsx';
import ChapterManagement from "./views/examples/chapterManagement";
import TopicsManagement from "./views/examples/topicsManagement";
import EmailSetting from "./views/examples/EmailSetting";
import MakeupClass from "./views/examples/MakeupClass";
import SalesNotification from "./views/examples/salesNotifcation";
import UserRegistration from "./views/examples/UserRegistration";
import BeltUpdate from "./views/examples/BeltUpdate";
import TeacherCalender from "./views/examples/TeacherCalender";
import Reports from "./views/examples/Reports";
import EmailError from "./views/examples/EmailError";
import SalesFollowUp from "./views/examples/salesFollowUp";
import PaymentListing from "./views/examples/PaymentListing";
import Listing from "./views/examples/listing";
import StudentManagement from "./views/examples/studentManagement";
import TeacherReport from "./views/examples/teacherReport";

var routes = [
  {
    path: '/listing',
    name: 'Quick View',
    icon: 'ni ni-tv-2 text-primary',
    component: Listing,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: Index,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },
  {
    path: '/class',
    name: 'Class',
    icon: 'ni ni-tv-2 text-primary',
    component: Class,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },
  {
    path: '/message',
    name: 'Message',
    icon: 'ni ni-tv-2 text-primary',
    component: Message,
    roles: ['admin', 'support', 'sub_admin'],
    layout: '/admin',
    show: true,
  },
  {
    path: '/email-error',
    name: 'Email Error Logs',
    icon: 'ni ni-tv-2 text-primary',
    component: EmailError,
    roles: ['admin', 'sub_admin'],
    layout: '/admin',
    show: true,
  },
  {
    path: '/sales-notification',
    name: 'Sales Notification',
    icon: 'ni ni-email-83 text-info',
    component: SalesNotification,
    layout: '/admin',
    roles: ['admin', 'sales', 'sub_admin'],
    show: true,
  },
  {
    path: '/sales-followup',
    name: 'Sales Follow up',
    icon: 'ni ni-email-83 text-info',
    component: SalesFollowUp,
    layout: '/admin',
    roles: ['admin', 'sales_admin', 'sub_admin'],
    show: true,
  },
  {
    path: '/user-registration',
    name: 'User Registration',
    icon: 'ni ni-email-83 text-info',
    component: UserRegistration,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },
  {
    path: '/private-section',
    name: 'Private Section',
    icon: 'ni ni-email-83 text-info',
    component: PrivateSection,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },
  {
    path: '/payments',
    name: 'Subscription',
    icon: 'ni ni-ruler-pencil text-orange',
    component: PaymentListing,
    layout: '/admin',
    roles: ['admin'],
    show: true,
  },
  {
    path: '/student-management',
    name: 'Student Management',
    icon: 'ni ni-ruler-pencil text-orange',
    component: StudentManagement,
    layout: '/admin',
    roles: ['admin'],
    show: true,
  },
  {
    path: '/reports',
    name: 'Reports',
    icon: 'ni ni-ruler-pencil text-orange',
    component: Reports,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },

  {
    path: '/belt-report',
    name: 'Belt Update',
    icon: 'ni ni-ruler-pencil text-orange',
    component: BeltUpdate,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },
  {
    path: '/makeup-class',
    name: 'Makeup Class',
    icon: 'ni ni-ruler-pencil text-orange',
    component: MakeupClass,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },

  {
    path: '/cancelled-section',
    name: 'Cancelled Session',
    icon: 'ni ni-ruler-pencil text-orange',
    component: CancelledSection,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },
  {
    path: '/section',
    name: 'Sections',
    icon: 'ni ni-ruler-pencil text-orange',
    component: Section,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: false,
  },
  {
    path: '/chapters',
    name: 'Chapters',
    icon: 'ni ni-ruler-pencil text-orange',
    component: ChapterManagement,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: false,
  },
  {
    path: '/topics',
    name: 'Topics',
    icon: 'ni ni-ruler-pencil text-orange',
    component: TopicsManagement,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: false,
  },
  {
    path: '/teachers',
    name: 'Teachers',
    icon: 'ni ni-hat-3',
    component: Teachers,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },
  {
    path: '/teacher-calender',
    name: 'Teacher Calender',
    icon: 'ni ni-hat-3',
    component: TeacherCalender,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },
  {
    path: '/teachers-hiring',
    name: 'Teachers Hiring',
    icon: 'ni ni-hat-3',
    component: TeachersHiring,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },
  {
    path: '/teachers-report',
    name: 'Teachers Report',
    icon: 'ni ni-hat-3',
    component: TeacherReport,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },
  // {
  //   path: '/student-availability/new',
  //   name: 'New Student Availability',
  //   icon: 'ni ni-hat-3',
  //   component: StudentAvailability,
  //   layout: '/admin',
  //   roles: ['admin'],
  //   show: true,
  // },
  // {
  //   path: '/student-availability/completed',
  //   name: 'Student Availability',
  //   icon: 'ni ni-hat-3',
  //   component: StudentAvailability,
  //   layout: '/admin',
  //   roles: ['admin'],
  //   show: true,
  // },
  // {
  //   path: '/students',
  //   name: 'Students',
  //   icon: 'ni ni-single-02 text-blue',
  //   component: Students,
  //   layout: '/admin',
  //   roles: ['admin'],
  //   show: true,
  // },
  {
    path: '/curriculum',
    name: 'Curriculum',
    //add icon for the curriculum
    icon: 'ni ni-single-02 curriculum',
    component: Curriculum,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },

  {
    path: '/category',
    name: 'Category',
    icon: 'ni ni-app text-red',
    component: Category,
    roles: ['admin', 'sub_admin'],
    layout: '/admin',
    show: true,
  },
  {
    path: '/email-setting',
    name: 'Email Setting',
    icon: 'ni ni-email-83 text-yellow',
    component: EmailSetting,
    roles: ['admin', 'sub_admin'],
    layout: '/admin',
    show: true,
  },
  {
    path: '/age-group',
    name: 'Age Group',
    icon: 'ni ni-chart-bar-32 text-yellow',
    component: AgeGroup,
    roles: ['admin', 'sub_admin'],
    layout: '/admin',
    show: true,
  },
  {
    path: '/room',
    name: 'Room',
    icon: 'ni ni-box-2 text-pink',
    component: RoomManagement,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: true,
  },

  {
    path: '/profile/:id',
    name: 'Teachers Profile',
    icon: 'ni ni-single-02 text-blue',
    component: Profile,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: false,
  },
  {
    path: '/studentsProfile/:id',
    name: 'Students Profile',
    icon: 'ni ni-single-02 text-blue',
    component: StudentsProfile,
    layout: '/admin',
    roles: ['admin', 'sub_admin'],
    show: false,
  },
  {
    path: '/icons',
    name: 'Icons',
    icon: 'ni ni-planet text-blue',
    component: Icons,
    layout: '/admin',
    roles: ['admin', 'sales'],
    show: false,
  },
  {
    path: '/maps',
    name: 'Maps',
    icon: 'ni ni-pin-3 text-orange',
    component: Maps,
    layout: '/admin',
    roles: ['admin', 'sales'],
    show: false,
  },
  {
    path: '/user-profile',
    name: 'User Profile',
    icon: 'ni ni-single-02 text-yellow',
    component: Profile,
    layout: '/admin',
    roles: ['admin', 'sales'],
    show: false,
  },
  {
    path: '/tables',
    name: 'Tables',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Tables,
    layout: '/admin',
    show: false,
    roles: ['admin', 'sales'],
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    show: false,
    roles: ['admin', 'sales', 'sub_admin'],
  },
  {
    path: '/register',
    name: 'Register',
    icon: 'ni ni-circle-08 text-pink',
    component: Register,
    layout: '/auth',
    roles: ['admin', 'sales', 'sub_admin'],
    show: false,
  },
];
export default routes;
