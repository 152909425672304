import React from 'react';
import moment from 'moment';

// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    FormGroup,
    Form,
    CardBody,
    InputGroup,
    Input,
    Modal,
    Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import { axiosGet, axiosPost } from 'util/apiConfig.js';
import Select from "react-select";
import {value} from "lodash/seq";
import "./SalesNotification.css";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Device } from '@twilio/voice-sdk';
import {CopyToClipboard} from "react-copy-to-clipboard";

const USER_STATE = {
    CONNECTING : "Connecting",
    READY : "Ready",
    ON_CALL : "On call",
    OFFLINE : "Offline",
}

class SalesFollowUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      listPage: 1,
      listPageSale: 1,
      totalListPageSale: '',
      rowLimitSale: 10,
      rowLimit: 10,


      modalTitle: '',
      successModal: false,
      errorModal: false,
      message: '',
      editModal: false,
      editSalesModal: false,
      classList: [],
      notificationNotesModal: false,
      notificationCallModal: false,
      salesFollowUpModal: false,
      salesFollowUpClassScriptModal: false,
      addModal: false,
      filterTeacher: null,

      notificationStatus: '',
      device: undefined,
      userState: USER_STATE.OFFLINE,
      allFollowUpStatus: [],
      salesFollowUpInfo: [],
      messageStates: {},
      activeCard: "followUp",
      followUpScript: '',
      followUpNotes: '',
      salesFollowUpNotes: '',

    };
  }

  componentDidMount() {
    let storage = localStorage.getItem('Sanga_Admin');
    storage = storage ? JSON.parse(storage) : null;
    const classId = localStorage.getItem("classId");
    this.setState(
      {
        globalClassId: classId ? classId : null,
        userId: storage && storage.userId,
        userName: storage && storage.name,
        userType: storage && storage.userType,
        newRequestList: [],
        allMessages: [],
        allCounts: [],
        allSalesUser: [],
      },
      () => {
        this.getClass();
        // this.callInit();
        this.getNotificationAllMessage();
        this.getSalesNotification();
        this.setState({ allNotificationStatus: [
                  {
                      value: 'Default',
                      label: 'Default',
                  },{
                      value: 'Very Interested',
                      label: 'Very Interested',
                  },{
                      value: 'Medium Interested',
                      label: 'Medium Interested',
                  },{
                      value: 'Less Interested',
                      label: 'Less Interested',
                  },{
                      value: 'No Show - Teacher',
                      label: 'No Show - Teacher',
                  },{
                      value: 'No Show - Student',
                      label: 'No Show - Student',
                  },{
                      value: 'Cancel Class',
                      label: 'Cancel Class',
                  },
              ], allFollowUpStatus: [
                {
                    value: 'Default',
                    label: 'Default',
                },{
                    value: 'No Response - 1st Try',
                    label: 'No Response - 1st Try',
                },{
                    value: 'No Response - 2nd Try',
                    label: 'No Response - 2nd Try',
                },{
                    value: 'No Response - 3rd Try',
                    label: 'No Response - 3rd Try',
                },{
                    value: 'Will Sign up',
                    label: 'Will Sign up',
                },{
                    value: 'Enrolled',
                    label: 'Enrolled',
                },{
                    value: 'Connect in few months',
                    label: 'Connect in few months',
                },{
                    value: 'Not Interested',
                    label: 'Not Interested',
                },{
                    value: 'Not Contacted',
                    label: 'Not Contacted',
                },
            ] });
      }
    );
  }

  getClass = () => {
        // API --
        axiosGet(`class/get-classes-list`)
            .then((response) => {
                if (!response.isError) {
                    this.getNotificationMessage(response.data[0].classId);
                    this.setState({
                        classList: response.data || [],
                        classId: response.data[0].classId,
                    });
                } else {
                    this.setState({
                        classList: [],
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    classList: [],
                });
            });
    };

    getNotificationMessage = (classId) => {
        axiosGet(`sales/get-notification-message/${classId}`)
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        followUpScript: response.data[0].followUpScript,
                        messageId: response.data[0]?.messageId,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    followUpScript: '',
                    messageId: '',
                });
            });
    };

    getNotificationAllMessage = () => {
        axiosGet(`sales/get-notification-message/`)
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        salesFollowUpScript: response.data || [],
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    salesFollowUpScript: [],
                });
            });
    };

  // to get all messages -
  getSalesNotification = (search) => {
    const { listPage, rowLimit, globalClassId, activeCard, searchNotification } = this.state;
      let url = `sales/get-all-sales-notification/${listPage}/${rowLimit}`;
      const queryParams = [];
      if (globalClassId) {
         queryParams.push(`classId=${globalClassId}`);
      }
      queryParams.push(`notificationStatus=Very Interested`);
      queryParams.push(`status=${activeCard}`);
      if (searchNotification) {
          queryParams.push(`name=${searchNotification}`);
      }
      if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
      }
    axiosGet(`${url}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            allMessages: response.data?.result,
            totalNewPage: response.data?.noofpage,
          });
        } else {
          this.setState({
            allMessages: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          allMessages: [],
        });
        console.log(error);
      });
  };



  changePage = (status) => {
    if (status && status === 'next') {
      this.setState({ listPage: this.state.listPage + 1 }, () =>
        this.getSalesNotification()
      );
    } else if (status && status === 'prev') {
      this.setState({ listPage: this.state.listPage - 1 }, () =>
        this.getSalesNotification()
      );
    }
  };


  clientConnect = (index) => {
    let { allMessages } = this.state;
    let selectedNotification = allMessages[index];
    this.setState({
      notificationCallModal: true,
      selectedNumber: selectedNotification.mobile || ''
    });
  };

  salesFollowUpScript = () => {
    this.setState({
      salesFollowUpClassScriptModal: true,
    });
  };

  addNotes = (index) => {
     let { allMessages, salesFollowUpScript } = this.state;
     let selectedNotification = allMessages[index];
     const followUpScript = salesFollowUpScript.find((sales)=> sales.classId == selectedNotification.classId);
     this.setState({
      notificationNotesModal: true,
      selectedNotification,
      followUpNotes: selectedNotification.notes || '',
      followUpScript: followUpScript.followUpScript || '',
      salesFollowUpNotes: selectedNotification.salesFollowUpNotes || ''
     });
  };

  followupInfo = (index) => {
    let { allMessages } = this.state;
    let selectedNotification = allMessages[index];
    this.setState({
      salesFollowUpModal: true,
      salesFollowUpInfo: selectedNotification?.salesFollowUp || ''
    });
  };

  addBookMark = (flag, index) => {
   try {
       let {allMessages} = this.state;
       let selectedNotification = allMessages[index];
       let dataToSend = {
           notificationId: selectedNotification.notificationId,
           starBookMark: flag
       };
       this.setState({loading: true});
       axiosPost('sales/update-notification-status', dataToSend)
           .then((response) => {
               if (!response.isError) {
                   this.setState(
                       {
                           loading: false,
                           successModal: true,
                           modalTitle: 'Success',
                           message: 'Successfully Updated.',
                       },
                       () => {
                           this.getSalesNotification();
                       }
                   );
               } else {
                   this.setState({
                       loading: false,
                       errorModal: true,
                       modalTitle: 'Sorry!',
                       message: 'Something is wrong. Try again later.',
                   });
               }
           })
           .catch((error) => {
               this.setState({
                   loading: false,
                   errorModal: true,
                   modalTitle: 'Sorry!',
                   message: 'Something is wrong. Try again later.',
               });
               console.log(error);
           });
   }catch (e) {
    console.log("Error:", e)
   }
  };
  callInit = (sale, index) => {
      axiosPost('sales/token', {})
          .then((response) => {
              if (!response.isError) {
                  this.setState(
                      {
                          loading: false
                      },
                      () => {
                          const token = response.data.token;
                          const device = new Device(token, {
                              logLevel: 1,
                              codecPreferences: ["opus", "pcmu"],
                          });
                          device.register();
                          this.setState({device})
                          device.addListener("connect", (device) => {
                              console.log("Connect event listener added .....");
                              return device;
                          });
                          device.on("registered", () => {
                              console.log("Agent registered");
                              this.setState({userState: USER_STATE.READY});
                          });
                          device.on("connect", (connection) => {
                              console.log("Call connect");
                              this.setState({userState: USER_STATE.ON_CALL})
                          });
                          device.on("disconnect", () => {
                              console.log("Disconnect event");
                              this.setState({userState: USER_STATE.READY})
                          });

                          setTimeout(()=>{
                              this.handleCall(sale, index)
                          }, 1000)
                          return () => {
                              device.destroy();
                              this.setState({device: undefined})
                              this.setState({userState: USER_STATE.OFFLINE})
                          };
                      }
                  );
              } else {
                  this.setState({
                      loading: false,
                      errorModal: true,
                      modalTitle: 'Sorry!',
                      message: 'Something is wrong. Try again later.',
                  });
              }
          })
          .catch((error) => {
              this.setState({
                  loading: false,
                  errorModal: true,
                  modalTitle: 'Sorry!',
                  message: 'Something is wrong. Try again later.',
              });
              console.log(error);
          });
  }

  handleCall = async (sale, index) => {
      const className = this.state.classList.find((item) => item.classId === sale.classId).name;
      const confirm = window.confirm(`Are you sure want to call? \nPlease verify details\nClass name: ${className || ''},\nMobile: ${sale.mobile}, `)
      if(!confirm){
          return false;
      }
      const {device, userType } = this.state;
      device.emit("connect");
      device.connect({params: {To: `+1${sale.mobile}`, notificationId: sale.notificationId, userType } }).then((call) => {
          call.on("accept", () => {
              this.updateMessageState(index, USER_STATE.ON_CALL);
              console.log("call accepted");
          });
          call.on("disconnect", () => {
              console.log("The call has been disconnected.");
              this.updateMessageState(index, USER_STATE.READY);
          });
          call.on("reject", () => {
              console.log("The call was rejected.");
          });
          call.on("cancel", () => {
              console.log("The call was rejected.");
              this.updateMessageState(index, USER_STATE.READY);
          });
      })
    };


    updateMessageState = (index, newState) => {
        this.setState(prevState => ({
            messageStates: {
                ...prevState.messageStates,
                [index]: newState
            }
        }));
    };


  rejectCall = ()=>{
      const {device, selectedNumber} = this.state
      device.emit("disconnect");
      device.emit("cancel");
      device.emit("reject");
      device.disconnectAll({params: {To: selectedNumber} });
  }


  replyMessageResponse = () => {
    let confirm = window.confirm('Are you sure want send message?');
    if (confirm) {
      let { twillioMessage, loading, selectedNumber } = this.state;
      if(!twillioMessage || twillioMessage == ''){
            return alert('Please enter message');
      }
      if (twillioMessage && !loading) {
          this.setState({ loading: true });
          let dataToSend = {
                message: twillioMessage,
                to: `+1${selectedNumber}`,
            };
            // API -
          axiosPost('message/send-reply', dataToSend)
            .then((response) => {
                if (!response.isError) {
                    this.setState(
                        {
                            loading: false,
                            notificationCallModal: false,
                            twillioMessage: '',
                            selectedNumber: '',
                        },
                        () => {
                        }
                    );
                } else {
                    this.setState({
                        loading: false,
                        modalTitle: 'Sorry!',
                        message: 'Something is wrong. Try again later.',
                        notificationCallModal: false,
                        twillioMessage: '',
                        selectedNumber: '',
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    errorModal: true,
                    message: 'Something is wrong. Try again later.',
                    notificationCallModal: false,
                    twillioMessage: '',
                    selectedNumber: '',
                });
            });
        }
    }
  };


    handleNotificationStatusChange = (e, index) => {
        let message = "Are you sure you want to change the status?"
        let confirm = window.confirm(message);
        if (confirm) {
            let {allMessages} = this.state;
            let selectedNotification = allMessages[index];
            let dataToSend = {
                notificationId: selectedNotification.notificationId,
                followUpStatus: e.value
            };
            this.setState({loading: true});
            axiosPost('sales/update-notification-status', dataToSend)
                .then((response) => {
                    if (!response.isError) {
                        this.setState(
                            {
                                loading: false,
                                successModal: true,
                                modalTitle: 'Success',
                                message: 'Successfully Updated.',
                            },
                            () => {
                                this.getSalesNotification();
                            }
                        );
                    } else {
                        this.setState({
                            loading: false,
                            errorModal: true,
                            modalTitle: 'Sorry!',
                            message: 'Something is wrong. Try again later.',
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        errorModal: true,
                        modalTitle: 'Sorry!',
                        message: 'Something is wrong. Try again later.',
                    });
                    console.log(error);
                });
        }
    };

    toggleModal = (state) => {
        this.setState({
            [state]: !this.state[state],
            modalTitle: '',
            message: '',
        });
        if (state === 'notificationModal' || state === 'editModal') {
            this.resetForm();
        } else if (state === 'notificationModal') {
            this.setState({
                email: '',
                name: '',
                mobile: '',
            });
        }
    };

    isTimeInRange(timezone) {
        const now = new Date();
        const userTime = new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: false, timeZone: timezone }).format(now);
        const hour = parseInt(userTime, 10);

        // Check if the time is between 8 AM (8) and 9 PM (21)
        return hour >= 8 && hour < 21;
    }

    resetForm = () => {
        this.setState({
            loading: false,
            followUpScript: '',
            followUpNotes: '',
            salesFollowUpNotes: '',
        });
    };

    updateNotificationNotes = () => {
        let {
            followUpScript,
            followUpNotes,
            salesFollowUpNotes,
            selectedNotification
        } = this.state;
        let dataToSend = {
            followUpScript,
            followUpNotes,
            salesFollowUpNotes,
            notificationId: selectedNotification.notificationId
        };
        axiosPost(`sales/update-notification-notes`, dataToSend)
            .then((response) => {
                this.setState({
                    notificationNotesModal: false,
                    loading: false,
                });
                if (!response.isError) {
                    this.setState(
                        {
                            successModal: true,
                            modalTitle: 'Success',
                            message: 'Successfully saved.',
                        },
                        () => {
                            this.resetForm();
                            this.componentDidMount()
                        }
                    );
                } else {
                    this.setState(
                        {
                            errorModal: true,
                            modalTitle: 'Sorry!',
                            message: response.message,
                        },
                        () => {
                            this.resetForm();
                        }
                    );
                }
            })
            .catch((errorResponse) => {
                this.setState(
                    {
                        notificationNotesModal: false,
                        errorModal: true,
                        loading: false,
                        modalTitle: 'Sorry!',
                        message: errorResponse.message,
                    },
                    () => {
                        this.resetForm();
                    }
                );
            });

    };

    updateNotificatioScript = () => {
        let {
            followUpScript,
            classId,
            messageId
        } = this.state;
        let dataToSend = {
            followUpScript,
            classId,
            messageId
        };
        axiosPost(`sales/update-notification-message-script`, dataToSend)
            .then((response) => {
                this.setState({
                    salesFollowUpClassScriptModal: false,
                    loading: false,
                });
                if (!response.isError) {
                    this.setState(
                        {
                            successModal: true,
                            modalTitle: 'Success',
                            message: 'Successfully saved.',
                        },
                        () => {
                            this.resetForm();
                            this.componentDidMount()
                        }
                    );
                } else {
                    this.setState(
                        {
                            errorModal: true,
                            modalTitle: 'Sorry!',
                            message: response.message,
                        },
                        () => {
                            this.resetForm();
                        }
                    );
                }
            })
            .catch((errorResponse) => {
                this.setState(
                    {
                        salesFollowUpClassScriptModal: false,
                        errorModal: true,
                        loading: false,
                        modalTitle: 'Sorry!',
                        message: errorResponse.message,
                    },
                    () => {
                        this.resetForm();
                    }
                );
            });

    };

    searchNotification = (e) => {
        let val = e.target.value;
        this.setState({ searchNotification: val }, () =>
            this.getSalesNotification()
        );
    };

    changeClassMessage = (classId) => {
        this.getNotificationMessage(classId)
    }

    editNotification = (index) => {
        let { allMessages } = this.state;
        let selectedNotification = allMessages[index];
        this.setState({
            editModal: true,
            name: selectedNotification.name,
            email: selectedNotification.email,
            mobile: selectedNotification.mobile,
            classId: selectedNotification.classId,
            createdBy: selectedNotification.createdBy,
            zoomLinkSales: selectedNotification.zoomLink,
            selectedNotification
        });
    };

    addOrUpdateNotification = (isCalledForEditNotification) => {
        let {
            name,
            mobile,
            selectedNotification,
        } = this.state;
        !name
            ? this.setState({ nameError: true })
            : this.setState({ nameError: false });
        !mobile
            ? this.setState({ mobileError: true })
            : this.setState({ mobileError: false });
        if (name && mobile) {
            let dataToSend = {
                name,
                mobile,
            };
            if (isCalledForEditNotification) {
                dataToSend = { ...dataToSend, ...{ notificationId: selectedNotification.notificationId} };
            }
            const endPointUrl = isCalledForEditNotification
                ? 'sales/edit-notification'
                : 'sales/add-notification';
            axiosPost(`${endPointUrl}`, dataToSend)
                .then((response) => {
                    this.setState({
                        editModal: false,
                        selectedChapter: '',
                        notificationModal: false,
                        loading: false,
                    });
                    if (!response.isError) {
                        this.setState(
                            {
                                successModal: true,
                                modalTitle: 'Success',
                                message: 'Successfully saved.',
                            },
                            () => {
                                this.resetForm();
                                this.componentDidMount()
                            }
                        );
                    } else {
                        this.setState(
                            {
                                errorModal: true,
                                modalTitle: 'Sorry!',
                                message: response.message,
                            },
                            () => {
                                this.resetForm();
                            }
                        );
                    }
                })
                .catch((errorResponse) => {
                    this.setState(
                        {
                            editModal: false,
                            selectedChapter: '',
                            notificationModal: false,
                            errorModal: true,
                            loading: false,
                            modalTitle: 'Sorry!',
                            message: errorResponse.message,
                        },
                        () => {
                            this.resetForm();
                        }
                    );
                });
        }
    };


    render() {
    let {
      loading,
      allMessages,
      listPage,
      totalNewPage,
      notificationCallModal,
      salesFollowUpModal,
      modalTitle,
      notesError,
      message,
      successModal,
      errorModal,
      allNotificationStatus,
      activeCard,
     twillioMessage,
     allFollowUpStatus,
     salesFollowUpInfo,
     notificationNotesModal,
     salesFollowUpClassScriptModal,
    followUpScript,
    followUpNotes,
    salesFollowUpNotes,
    uploading,
    classList,
    userType,
    classId,
    classIdError,
    editModal,
    nameError,
    mobileError,
    email,
    name,
    mobile,

    } = this.state;
    return (
      <>
        <Header  />
        {/* Page content */}
          <Container fluid>
              {/* Table */}
              <Row>
                <div className='col'>
                  <Card className='shadow'>
                    <CardHeader className='border-0'>
                      <h3 className='mb-0'>Follow Up</h3>
                    </CardHeader>
                      <div className="col-md-12 text-center" style={{ float: "left", paddingBottom: '20px' }}>
                          <ul className="nav nav-pills" >
                              <li style={{cursor: "pointer"}} className="nav-item">
                                  <a
                                      className={`nav-link ${
                                          activeCard == "followUp" ? "active" : ""
                                      }`}
                                      onClick={() => {
                                          this.setState(
                                              {activeCard: "followUp", page: 1},
                                              () => {
                                                  this.getSalesNotification();
                                                  // this.getAllPrivateSection();
                                              }
                                          );
                                      }}
                                  >
                                      Follow Up
                                  </a>
                              </li>
                              <li style={{cursor: "pointer"}} className="nav-item">
                                  <a
                                      className={`nav-link ${
                                          activeCard == "Not Interested" ? "active" : ""
                                      }`}
                                      onClick={() => {
                                          this.setState(
                                              {activeCard: "Not Interested", page: 1},
                                              () => {
                                                  this.getSalesNotification();
                                                  // this.getAllPrivateSection();
                                              }
                                          );
                                      }}
                                  >
                                      Not Interested
                                  </a>
                              </li>
                              <li style={{cursor: "pointer"}} className="nav-item">
                                  <a
                                      className={`nav-link ${
                                          activeCard == "Connect in few months" ? "active" : ""
                                      }`}
                                      onClick={() => {
                                          this.setState(
                                              {activeCard: "Connect in few months", page: 1},
                                              () => {
                                                  this.getSalesNotification();
                                                  // this.getAllPrivateSection();
                                              }
                                          );
                                      }}
                                  >
                                      Connect In Few Months
                                  </a>
                              </li>
                          </ul>
                      </div>
                      <CardHeader
                          className="border-0 availability-header"
                          style={{ display: "flex" }}
                      >
                          <div className="row">
                              <div className="col-md-10">
                                  <FormGroup className="availability-formgroup">
                                      <InputGroup >
                                          <div style={{ width: "100%", minWidth: "400px", }}>
                                              <input
                                                  placeholder="Search by Name, Email or Mobile Number"
                                                  type="text"
                                                  name="search"
                                                  id={'1'}
                                                  className="form-control"
                                                  onChange={this.searchNotification}
                                              />
                                          </div>

                                      </InputGroup>
                                      <InputGroup >
                                          {
                                              userType === 'admin' && (
                                                  <Button
                                                      className="my-4"
                                                      color="primary"
                                                      type="button"
                                                      style={{ marginLeft: '10px', float: 'right' }}
                                                      onClick={() => this.salesFollowUpScript()}
                                                  >Followup Script
                                                  </Button>
                                              )
                                          }

                                      </InputGroup>
                                  </FormGroup>
                              </div>
                          </div>
                      </CardHeader>


                    <Table
                        className='align-items-center table-flush cp;'
                        responsive
                        style={{ marginBottom: '100px' }}
                    >
                      <thead className='thead-light'>
                      <tr>
                        <th scope='col'></th>
                        <th scope='col'></th>
                        <th scope='col'>Follow Up</th>
                        <th scope='col'>User name</th>
                        <th scope='col'>Class name</th>
                        <th scope='col'>Mobile Number</th>
                        <th scope='col'>Start Time</th>
                        <th scope='col'>Time Zone</th>
                        <th scope='col'>Created By</th>
                        <th scope='col'>Age</th>
                        <th scope='col'>Email</th>
                        <th scope='col'>Status</th>

                        {/*<th scope='col'>Notification Sent</th>*/}
                        <th scope='col' />
                      </tr>
                      </thead>
                      <tbody>
                      {allMessages &&
                          allMessages.map((value, index) => {
                            const isActiveTime = this.isTimeInRange(value?.timezone || 'Asia/kolkata');
                            const [isRegistration] = value?.sections && value?.sections.map((section) => {
                                if(section.classId == value.classId){
                                    return true
                                }
                            })
                            const isActive = Object.values(this.state.messageStates).some(
                                state => state === 'Connecting' || state === 'On call'
                            )
                           const className = classList?.find((item) => item?.classId === value?.classId)?.name;
                           const startClass =  className === '1:1 Python Class' ? {color: 'purple'} :  {color: ''};
                           const userStateForMessage = this.state.messageStates[index] || USER_STATE.OFFLINE;
                            return (
                                value.isActive && (
                                    <tr key={index} style={startClass}>
                                        <td  className={'table-column-width'} >
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    className='btn-icon-only text-light'
                                                    href='JavaScript:Void(0);'
                                                    role='button'
                                                    size='sm'
                                                    color=''
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <i className='fas fa-ellipsis-v' />
                                                </DropdownToggle>
                                                <DropdownMenu
                                                    className='dropdown-menu-arrow'
                                                    right
                                                >
                                                    <DropdownItem
                                                        href='JavaScript:Void(0);'
                                                        onClick={() => this.editNotification(index)}
                                                    >
                                                        Edit
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                        <td  className={'table-column-width'} >
                                            {
                                                value.starBookMark ? (
                                                    <button onClick={() => this.addBookMark(false, index)} className={'btn btn-white'}>
                                                        <i style={{color: 'green', marginRight: '2px', cursor: 'pointer'}}  className="fa fa-star"></i>
                                                    </button>
                                                ): (
                                                    <button onClick={() => this.addBookMark(true, index)} className={'btn btn-white'}>
                                                        <i style={{color: 'black', marginRight: '2px', cursor: 'pointer'}}  className="fa fa-star"></i>
                                                    </button>
                                                )
                                            }


                                            {
                                                value.salesFollowUp && value.salesFollowUp.length > 0 && (
                                                    <button onClick={() => this.followupInfo(index)} className={'btn btn-white'}>
                                                        <i className="fa fa-info" style={{fontSize:'20px'}} aria-hidden="true" ></i>
                                                    </button>
                                                )
                                            }

                                            {
                                                (userStateForMessage === 'Connecting' || userStateForMessage === 'On call') ? (
                                                    <button
                                                        className={'btn btn-danger'}
                                                        onClick={() => this.rejectCall(value.mobile)}
                                                    >Reject
                                                    </button>
                                                ): (
                                                    isActiveTime ? (
                                                        <button
                                                            className={'btn btn-white'}
                                                            disabled={isActive}
                                                            onClick={() =>this.callInit(value, index)}
                                                        ><i className="fa fa-phone" style={{fontSize:'20px'}} ></i>
                                                        </button>
                                                    ): (
                                                        <></>
                                                    )


                                                )
                                            }
                                            <button onClick={() => this.clientConnect(index)} className={'btn btn-white'}>
                                                <i className="fa fa-comments" style={{fontSize:'20px'}} aria-hidden="true" ></i>
                                            </button>
                                            <button onClick={() => this.addNotes(index)} className={'btn btn-white'}>
                                                <i className="fa fa-book" style={{fontSize:'20px'}} aria-hidden="true" ></i>
                                            </button>
                                        </td>
                                        <td  className={'table-column-width'}>
                                            <div>
                                                <Select
                                                    options={allFollowUpStatus}
                                                    placeholder="Select Follow-up Status"
                                                    isSearchable={true}
                                                    menuPosition={'fixed'}
                                                    menuPlacement={'bottom'}
                                                    styles={{
                                                        control: styles => ({ ...styles, width: 200 }),
                                                        menu: styles => ({ ...styles, width: 200 }),
                                                    }}
                                                    onChange={(e) => this.handleNotificationStatusChange(e, index)}
                                                    value={allFollowUpStatus.find(option => option.value == value.followUpStatus)}
                                                />
                                            </div>
                                       </td>
                                      <td className={'table-column-width'}>
                                          {isRegistration && (
                                              <i style={{color: '#11cdef', marginRight: '2px'}} className="fa fa-circle"></i>
                                          )}{value.name}
                                      </td>
                                      <td  className={'table-column-width'}>{className || ''}</td>
                                      <td>
                                        {value.mobile && (
                                            <a href={"tel:" + value.mobile}>{value.mobile}</a>
                                        )}{" "}
                                      </td>
                                      <td  className={'table-column-width'}>
                                        <Media className='align-items-center'>
                                          <Media>
                                        <span className='mb-0 text-sm'>
                                          {value.startTime
                                              ? moment(value.startTime).format('ll hh:mm A')
                                              : ''}
                                        </span>
                                          </Media>
                                        </Media>
                                      </td>
                                      <td  className={'table-column-width'}>{value?.timezone || ''}</td>
                                      <td  className={'table-column-width'}>{value?.createdBy?.toLocaleUpperCase()}</td>
                                      <td  className={'table-column-width'}>{value?.age || ''}</td>
                                      <td  className={'table-column-width'}>{value.email}</td>
                                      <td  className={'table-column-width'}>
                                        <div>
                                          <Select
                                              options={allNotificationStatus}
                                              placeholder="Select Notification Status"
                                              isSearchable={true}
                                              isDisabled={true}
                                              menuPosition={'fixed'}
                                              menuPlacement={'bottom'}
                                              styles={{
                                                control: styles => ({ ...styles, width: 200 }),
                                                menu: styles => ({ ...styles, width: 200 }),
                                              }}
                                              value={allNotificationStatus.find(option => option.value == value.notificationStatus)}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                )
                            );
                          })}
                      </tbody>
                    </Table>

                    <CardFooter className='py-4'>
                      <nav aria-label='...'>
                        <Pagination
                            className='pagination justify-content-end mb-0'
                            listClassName='justify-content-end mb-0'
                        >
                          <PaginationItem
                              className={listPage > 1 ? '' : 'disabled'}
                          >
                            <PaginationLink
                                onClick={() => this.changePage('prev')}
                                tabIndex='-1'
                            >
                              <i className='fas fa-angle-left' />
                              <span className='sr-only'>Previous</span>
                            </PaginationLink>
                          </PaginationItem>

                          <PaginationItem className='active'>
                            <PaginationLink onClick={(e) => e.preventDefault()}>
                              {listPage}
                            </PaginationLink>
                          </PaginationItem>

                          <PaginationItem
                              className={totalNewPage > listPage ? '' : 'disabled'}
                          >
                            <PaginationLink onClick={() => this.changePage('next')}>
                              <i className='fas fa-angle-right' />
                              <span className='sr-only'>Next</span>
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </nav>
                    </CardFooter>
                  </Card>
                </div>
                <div className='col-md-4'>
                  {/*<h1>test</h1>*/}
                </div>
              </Row>

        </Container>
          <Modal
              className='modal-dialog-centered'
              size='lg'
              isOpen={notificationCallModal}
              toggle={() =>
                  this.toggleModal('notificationCallModal')
              }
          >
              <div className='modal-body p-0'>
                  <Card className='bg-secondary shadow border-0'>
                      <CardHeader className='bg-transparent pb-5'>
                          <div className='text-muted text-center'>
                              <small>{'Message'}</small>
                          </div>
                      </CardHeader>
                      <CardBody className='px-lg-5 py-lg-5'>
                          <Form role='form'>
                              <FormGroup className={notesError ? 'has-danger' : ''}>
                                  <InputGroup className='input-group-alternative'>
                                     <textarea
                                         placeholder='Enter Message'
                                         cols={100}
                                         rows={5}
                                         value={twillioMessage}
                                         onChange={(e) =>
                                             this.setState({ twillioMessage: e.target.value })
                                         }
                                     />
                                  </InputGroup>
                              </FormGroup>

                              <div className="row text-right">
                                  <div className="ml-auto" style={{marginRight: '10px'}}>
                                      <Button
                                          color="primary"
                                          type="button"
                                          onClick={() => this.setState({ notificationCallModal: !notificationCallModal })}
                                      >
                                           Cancel
                                      </Button>
                                      <Button
                                          disabled={!!loading}
                                          color="primary"
                                          type="button"
                                          onClick={() => this.replyMessageResponse()}
                                      >
                                           Send Message
                                      </Button>
                                  </div>
                              </div>
                          </Form>
                      </CardBody>
                  </Card>
              </div>
          </Modal>
          <Modal
              className='modal-dialog-centered'
              size='lg'
              isOpen={salesFollowUpModal}
              toggle={() =>
                  this.toggleModal('salesFollowUpModal')
              }
          >
              <div className='modal-body p-0'>
                  <Card className='bg-secondary shadow border-0'>
                      <CardHeader className='bg-transparent pb-5'>
                          <div className='text-muted text-center'>
                              <small>{'Follow Up'}</small>
                          </div>
                      </CardHeader>
                      <CardBody className='px-lg-5 py-lg-5'>
                          <Table
                              className='align-items-center table-flush cp;'
                              responsive
                              style={{ marginBottom: '100px' }}
                          >
                              <thead className='thead-light'>
                              <tr>
                                  <th scope='col'>Date</th>
                                  <th scope='col'>Status</th>
                                  <th scope='col'>Call By</th>
                                  <th scope='col'>Audio</th>
                                  <th scope='col'>Link</th>
                                  <th scope='col' />
                              </tr>
                              </thead>
                              <tbody>
                              {salesFollowUpInfo &&
                                  salesFollowUpInfo.map((value, index) => {
                                      return (
                                          value.isActive && (
                                              <tr key={index} >
                                                  <td  className={'table-column-width'}>
                                                      <Media className='align-items-center'>
                                                          <Media>
                                                            <span className='mb-0 text-sm'>
                                                              {value.createdAt
                                                                  ? moment(value.createdAt).format('ll hh:mm A')
                                                                  : ''}
                                                            </span>
                                                          </Media>
                                                      </Media>
                                                  </td>
                                                  <td  className={'table-column-width'}>{value?.callStatus || ''}</td>
                                                  <td  className={'table-column-width'}>{value?.userType || ''}</td>
                                                  <td  className={'table-column-width'}>
                                                      {
                                                          value.fileName ? (
                                                              <AudioPlayer
                                                                  autoPlay={false}
                                                                  autoPlayAfterSrcChange={false}
                                                                  src={value.fileName}
                                                                  onPlay={e => console.log("onPlay")}
                                                                  // other props here
                                                              />
                                                          ): (
                                                              <></>
                                                          )
                                                      }
                                                     </td>
                                                      <td className={'table-column-width'}>
                                                          {
                                                              value.fileName ? (
                                                                  <CopyToClipboard text={value.fileName}>
                                                                      <i
                                                                          style={{cursor: 'pointer'}}
                                                                          className='BtnFill'
                                                                          color='success'
                                                                      ><i className='fa fa-copy'></i>
                                                                      </i>
                                                                  </CopyToClipboard>
                                                              ):(
                                                                  <></>
                                                              )
                                                          }
                                                      </td>
                                              </tr>
                                          )
                                      );
                                  })}
                              </tbody>
                          </Table>
                          <div className="row text-right">
                              <div className="ml-auto" style={{marginRight: '10px'}}>
                                  <Button
                                      color="primary"
                                      type="button"
                                      onClick={() => this.setState({ salesFollowUpModal: !salesFollowUpModal })}
                                  >
                                      Cancel
                                  </Button>
                              </div>
                          </div>
                      </CardBody>
                  </Card>
              </div>
          </Modal>
        <Modal
            className='modal-dialog-centered'
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
                successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                        ? this.toggleModal('errorModal')
                        : null
            }
        >
          <div className='modal-header'>
            <h5 className='modal-title' id='successModalLabel'>
              {modalTitle}
            </h5>
            <button
                aria-label='Close'
                className='close'
                data-dismiss='modal'
                type='button'
                onClick={() =>
                    successModal
                        ? this.toggleModal('successModal')
                        : errorModal
                            ? this.toggleModal('errorModal')
                            : null
                }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          {this.state.successModal ? (
              <div className='modal-body' style={{ color: 'green' }}>
                {message}
              </div>
          ) : this.state.errorModal ? (
              <div className='modal-body' style={{ color: 'red' }}>
                {message}
              </div>
          ) : null}
          <div className='modal-footer'>
            <Button
                color='primary'
                type='button'
                onClick={() =>
                    successModal
                        ? this.toggleModal('successModal')
                        : errorModal
                            ? this.toggleModal('errorModal')
                            : null
                }
            >
              Ok
            </Button>
          </div>
        </Modal>


      <Modal
          className='modal-dialog-centered'
          size='lg'
          isOpen={notificationNotesModal}
          toggle={() =>
              this.toggleModal('notificationNotesModal')
          }
      >
          <div className='modal-body p-0'>
              <Card className='bg-secondary shadow border-0'>
                  <CardHeader className='bg-transparent pb-5'>
                      <div className='text-muted text-center mt-2 mb-3'>
                          <small>{'Notes and Script'}</small>
                      </div>
                  </CardHeader>
                  <CardBody className='px-lg-5 py-lg-5'>
                      <Form role='form'>
                          <FormGroup className={notesError ? 'has-danger' : ''}>
                              <label>Followup Notes</label>
                              <InputGroup className='input-group-alternative'>
                                 <textarea
                                     placeholder='Followup notes'
                                     cols={100}
                                     rows={5}
                                     value={salesFollowUpNotes}
                                     onChange={(e) =>
                                         this.setState({ salesFollowUpNotes: e.target.value })
                                     }
                                 />
                              </InputGroup>
                          </FormGroup>
                          <FormGroup className={notesError ? 'has-danger' : ''}>
                              <label>Notes</label>
                              <InputGroup className='input-group-alternative'>
                                 <textarea
                                     disabled={true}
                                     placeholder='Teacher notes'
                                     cols={100}
                                     rows={5}
                                     value={followUpNotes}
                                     onChange={(e) =>
                                         this.setState({ followUpNotes: e.target.value })
                                     }
                                 />
                              </InputGroup>
                          </FormGroup>
                          <FormGroup className={notesError ? 'has-danger' : ''}>
                              <label>Class Script</label>
                              <InputGroup className='input-group-alternative'>
                                 <textarea
                                     disabled={true}
                                     placeholder='Followup Script'
                                     cols={100}
                                     rows={5}
                                     value={followUpScript}
                                     onChange={(e) =>
                                         this.setState({ followUpScript: e.target.value })
                                     }
                                 />
                              </InputGroup>
                          </FormGroup>
                          <div className='text-center'>
                              {uploading ? (
                                  <span style={{ color: 'red' }}>Wait...</span>
                              ) : null}

                              <Button
                                  disabled={uploading || loading ? true : false}
                                  className='my-4'
                                  color='primary'
                                  type='button'

                                  onClick={() => {
                                      this.updateNotificationNotes();
                                  }}
                              >
                                  {'Save'}
                              </Button>
                          </div>
                      </Form>
                  </CardBody>
              </Card>
          </div>
      </Modal>

      <Modal
          className='modal-dialog-centered'
          size='lg'
          isOpen={salesFollowUpClassScriptModal}
          toggle={() =>
              this.toggleModal('salesFollowUpClassScriptModal')
          }
      >
          <div className='modal-body p-0'>
              <Card className='bg-secondary shadow border-0'>
                  <CardHeader className='bg-transparent pb-5'>
                      <div className='text-muted text-center mt-2 mb-3'>
                          <small>{'Update Script'}</small>
                      </div>
                  </CardHeader>
                  <CardBody className='px-lg-5 py-lg-5'>
                      <Form role='form'>
                          <FormGroup className={classIdError ? 'has-danger' : ''}>
                              <InputGroup className="input-group-alternative" styles={{width: '100%'}}>
                                  <div style={{ width: "100%", minWidth: "200px" }}>
                                      <Select
                                          style={{ width: "100%", minWidth: "200px" }}
                                          options={[
                                              ...(classList &&
                                                  classList.map((item) => ({
                                                      value: item.classId,
                                                      label: item.name,
                                                  }))),
                                          ]}
                                          value={
                                              classId && {
                                                  value: classId,
                                                  label: classList.find((item) => item.classId === classId).name,
                                              }
                                          }
                                          placeholder="All Classes"
                                          onChange={(e) => {
                                              this.changeClassMessage(e.value);
                                              this.setState({ classId: e.value })
                                          }}
                                          isSearchable={true}
                                      />
                                  </div>
                              </InputGroup>
                          </FormGroup>
                          <FormGroup className={notesError ? 'has-danger' : ''}>
                              <label>Script</label>
                              <InputGroup className='input-group-alternative'>
                                 <textarea
                                     placeholder='Enter Followup Script'
                                     cols={100}
                                     rows={5}
                                     value={followUpScript}
                                     onChange={(e) =>
                                         this.setState({ followUpScript: e.target.value })
                                     }
                                 />
                              </InputGroup>
                          </FormGroup>
                          <div className='text-center'>
                              {uploading ? (
                                  <span style={{ color: 'red' }}>Wait...</span>
                              ) : null}

                              <Button
                                  disabled={uploading || loading ? true : false}
                                  className='my-4'
                                  color='primary'
                                  type='button'
                                  onClick={() => {
                                      this.updateNotificatioScript();
                                  }}
                              >
                                  {'Save'}
                              </Button>
                          </div>
                      </Form>
                  </CardBody>
              </Card>
          </div>
      </Modal>

          <Modal
              className='modal-dialog-centered'
              size='lg'
              isOpen={editModal}
              toggle={() =>
                  this.toggleModal('editModal')
              }
          >
              <div className='modal-body p-0'>
                  <Card className='bg-secondary shadow border-0'>
                      <CardHeader className='bg-transparent pb-5'>
                          <div className='text-muted text-center mt-2 mb-3'>
                              <small>{'Edit Notification'}</small>
                          </div>
                      </CardHeader>
                      <CardBody className='px-lg-5 py-lg-5'>
                          <Form role='form'>
                              <FormGroup className={nameError ? 'has-danger' : ''}>
                                  <label>Name</label>
                                  <InputGroup className='input-group-alternative'>
                                      <Input
                                          disabled
                                          placeholder='Name'
                                          type='text'
                                          name='name'
                                          value={name}
                                          onChange={(e) =>
                                              this.setState({ name: e.target.value })
                                          }
                                      />
                                  </InputGroup>
                              </FormGroup>
                              <FormGroup className={mobileError || (mobile && mobile[0] === '1') ? 'has-danger' : ''}>
                                  <label>Mobile Number (No Dashes Or Spaces OR Country Code. US number only)</label>
                                  <InputGroup className='input-group-alternative'>
                                      <Input
                                          placeholder='Mobile Number'
                                          ype='text'
                                          name='mobile'
                                          value={mobile}
                                          onChange={(e) => {
                                              const input = e.target.value.replace(/\D/g,'').slice(0, 15); // remove non-digits and
                                              // limit to 10 digits
                                              this.setState({ mobile: input });
                                          }}
                                      />
                                  </InputGroup>
                                  {mobileError && <div className="text-danger">Please enter a valid mobile number.</div>}
                                  {mobile && mobile[0] === '1' && <div className="text-danger">Please enter a valid US mobile number (without country code).</div>}
                              </FormGroup>

                              <div className='text-center'>
                                  {uploading ? (
                                      <span style={{ color: 'red' }}>Wait...</span>
                                  ) : null}

                                  <Button
                                      disabled={uploading || loading ? true : false}
                                      className='my-4'
                                      color='primary'
                                      type='button'
                                      onClick={() => {
                                          this.addOrUpdateNotification(true);
                                      }}
                                  >
                                      {'Update'}
                                  </Button>
                              </div>
                          </Form>
                      </CardBody>
                  </Card>
              </div>
          </Modal>

      </>
    );
  }
}

export default SalesFollowUp;
