/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import moment from 'moment';

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import { axiosGet } from 'util/apiConfig.js';
import { databaseDateTimeFormat, dateForPaymentListing } from 'util/constance';
import Select from "react-select";

class PaymentListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentListingData: [],
      page: 1,
      totalPage: 0,
      lastItemId: null,
      firstItemId: null,
      classList: [],
      limit: 10,
      total: 0,
    };
  }

  componentDidMount() {
    this.setState(
      {
        paymentListingData: [],
        classList: [],
      },
      () => {
        this.getClass();

      }
    );
  }

  getClass = () => {
    // API --
    axiosGet(`class/get-classes-list`)
        .then((response) => {
          if (!response.isError) {
            // this.getNotificationMessage(response.data[0].classId);
            this.setState({
              classList: response.data || [],
              classId: response.data[0].classId,
            },()=> {
              this.getPaymentListingData()
            });
          } else {
            this.setState({
              classList: [],
            });
          }
        })
        .catch((error) => {
          this.setState({
            classList: [],
          });
        });
  };

  // for getting all approved student -
  getPaymentListingData = (startingAfter = null, endingBefore = null) => {
    const { page, limit, classId } = this.state;
    let url = `payment/get-active-subscriptions/${page}/?classId=${classId}&`;
    if (startingAfter) url = url + `startingAfter=${startingAfter}&`;
    if (endingBefore) url = url + `endingBefore=${endingBefore}&`;
    axiosGet(url)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            paymentListingData: response.data?.result,
            total: response.data.total,
            totalPage: response.data?.noofpage,
            lastItemId: response.data.result[response.data.result.length - 1].id,
            firstItemId: response.data.result[0].id
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // For changing page -
  changePage = (status) => {
    const {lastItemId, firstItemId} = this.state
    if (status && status === 'next') {
      this.setState({ page: this.state.page + 1 }, () =>
        this.getPaymentListingData(lastItemId)
      );
    } else if (status && status === 'prev') {
      this.setState({ page: this.state.page - 1 }, () =>
        this.getPaymentListingData(null, firstItemId)
      );
    }
  };

  render() {
    let { paymentListingData, page, totalPage, total, classList, classId } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          {/* Table */}
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <h3 className='mb-0'>Active Subscription</h3>
                  <div className='col-lg-3' style={{marginTop: '10px', left: 0}}>
                    <Select
                        options={[
                          ...(classList &&
                              classList.map((item) => ({
                                value: item.classId,
                                label: item.name,
                              }))),
                        ]}
                        value={
                            classId && {
                              value: classId,
                              label: classList.find((item) => item.classId === classId).name,
                            }
                        }
                        placeholder="All Classes"
                        onChange={(e) => {
                          this.setState({ classId: e.value, page: 1}, () => {
                            this.getPaymentListingData()
                          });
                        }}
                        isSearchable={true}
                    />
                  </div>
                </CardHeader>
                <Table className='align-items-center table-flush' responsive>
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>User Name</th>
                      <th scope='col'>Email</th>
                      <th scope='col'>Plan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentListingData?.length > 0 &&
                      paymentListingData.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data?.customer?.name || ''}</td>
                            <td>{data?.customer?.email || ''}</td>
                            <td>{data?.plan?.nickname || ''}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className='py-4'>
                  <nav aria-label='...'>
                    <Pagination
                      className='pagination justify-content-end mb-0'
                      listClassName='justify-content-end mb-0'
                    >
                      <PaginationItem
                          className={page > 1 ? '' : 'disabled'}>
                        <PaginationLink
                          onClick={() => this.changePage('prev')}
                          tabIndex='-1'
                        >
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className='active'>
                        <PaginationLink onClick={(e) => e.preventDefault()}>
                          {page}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={total < 100 ? 'disabled' : ''}
                      >
                        <PaginationLink onClick={() => this.changePage('next')}>
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default PaymentListing;
