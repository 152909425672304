/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
  Button,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { axiosGet, axiosPost } from "util/apiConfig.js";
import "./TeachersAvailability.css";
import { fetchTimeSlots } from "util/timeSlot";
import { fetchTimeZones } from "util/timeZones";
import Select from 'react-select';
import AvailabilityContent from "./AvailabilityContent"
class StudentAvailability extends React.Component {
  constructor(props) {
    super(props);
    this.defaultStartTime = "10:00 am";
    this.defaultEndTime = "08:00 pm";
    this.timing = [];
    this.defaultObj = {
      start: this.defaultStartTime,
      end: this.defaultEndTime,
      isActive: true,
      isChecked: false,
    };
    this.defaultAvailability = [
      { mon: [this.defaultObj] },
      { tue: [this.defaultObj] },
      { wed: [this.defaultObj] },
      { thu: [this.defaultObj] },
      { fri: [this.defaultObj] },
      { sat: [this.defaultObj] },
      { sun: [this.defaultObj] },
    ];
    this.state = {
      timeZones: [],
      timeZone: null,
      studentList: [],
      selectedDays: [],
      userEmail: null,
      currentStudentName: null,
      currentTeacherAvailability: [],
      Availability: this.defaultAvailability,
      loading: false,
      errorModal: false,
      successModal: false,
      message: "",
      timeZoneError: false,
      currentStudentError: false,
      isShowMatch: false,
    };

    this.isNew = this.props.location.pathname.includes('new')
  }
  componentDidMount() {
    this.setState(
      {
        studentList: [],
        userEmail: null,
        currentStudentName: null,
        currentTeacherAvailability: [],
      },
      () => {
        this.getAllStudents();
        this.timing = fetchTimeSlots();
        this.getTimeZones();
      }
    );
  }

  getTimeZones = () => {
    let timeZones = fetchTimeZones();
    let newTimeZoneObj = [];
    timeZones.map((data) => {
      let tt = {value:data, label: data.replace("_", " ") }
      newTimeZoneObj.push(tt)
    })
    timeZones = newTimeZoneObj;
    this.setState({ timeZones });
  };

  // to get all approved teacher's list -
  getAllStudents = () => {
    let { rowLimit, listPage } = this.state;
    let apiPath = `user/admin-get-user-hours-availability/completed`;
    if(this.isNew) {
        apiPath = `user/admin-get-user-hours-availability/availability`;
    }
    // API --
    axiosGet(apiPath)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            studentList: response.data?.result || [],
          });
        } else {
          this.setState({
            studentList: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          studentList: [],
        });
      });
  };

  //Get selected teacher Availability
  getStudentAvailability = (userEmail) => {
    // const defaultSettings = this.defaultAvailability.map((object) => ({
    //   ...object,
    // }));
    const defaultSettings = JSON.parse(JSON.stringify(this.defaultAvailability))
    let selectedDays = [];
    this.setState({ userEmail: userEmail });
    axiosGet(`user/get-user-hours-availability/${userEmail}`)
      .then((response) => {
        if (!response.isError) {
          if (response.data) {
            const { userHoursDetail } = response.data;
            if (userHoursDetail) {
              const { mon, tue, wed, thu, fri, sat, sun, localTimeZone } = userHoursDetail;
              if (mon && mon.length > 0) {
                selectedDays.push("mon");
                defaultSettings[0]["mon"] = mon;
              }
              if (tue && tue.length > 0) {
                selectedDays.push("tue");
                defaultSettings[1]["tue"] = tue;
              }
              if (wed && wed.length > 0) {
                selectedDays.push("wed");
                defaultSettings[2]["wed"] = wed;
              }
              if (thu && thu.length > 0) {
                selectedDays.push("thu");
                defaultSettings[3]["thu"] = thu;
              }
              if (fri && fri.length > 0) {
                selectedDays.push("fri");
                defaultSettings[4]["fri"] = fri;
              }
              if (sat && sat.length > 0) {
                selectedDays.push("sat");
                defaultSettings[5]["sat"] = sat;
              }
              if (sun && sun.length > 0) {
                selectedDays.push("sun");
                defaultSettings[6]["sun"] = sun;
              }

              this.setState({
                Availability: defaultSettings,
                selectedDays: selectedDays,
                timeZone: localTimeZone ? localTimeZone : null,
                isShowMatch: false,
              });
            } else {
              this.setState({
                Availability: this.defaultAvailability,
                selectedDays: selectedDays,
                timeZone: null,
                isShowMatch: false,
              });
            }
          } else {
            this.setState({
              Availability: this.defaultAvailability,
              selectedDays: selectedDays,
              timeZone: null,
              isShowMatch: false,
            });
          }
        } else {
          this.setState({
            Availability: this.defaultAvailability,
            selectedDays: selectedDays,
            timeZone: null,
            isShowMatch: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          Availability: this.defaultAvailability,
          isShowMatch: false,
        });
      });
  };

  //Add new timeslot
  addTimeSlot = (dayName) => {
    let { Availability: oldAvailability } = this.state;
    let setNewArray = oldAvailability;
    oldAvailability.map((item, i) => {
      if (Object.keys(item) == dayName[0]) {
        setNewArray[i][dayName[0]].push(this.defaultObj);
      }
    });
    this.setState({
      Availability: setNewArray,
    });
  };

  //Update the times state
  onUpdateTime = (name, dayName, data, iOfItem) => {
    let { Availability } = this.state;
    // let setNewArray =  oldAvailability.map((object) => ({
    //   ...object,
    // }));
    let setNewArray = JSON.parse(JSON.stringify(Availability))
    setNewArray.map((item, i) => {
      if (Object.keys(item) == dayName[0]) {
        setNewArray[i][dayName[0]][iOfItem][name] = data;
      }
    });
    this.setState({
      Availability: setNewArray,
    });
  };

  //Save Availability
  saveAvailability = () => {
    let {
      Availability: oldAvailability,
      userEmail,
      currentStudentName,
      selectedDays,
      timeZone,
      studentList
    } = this.state;
    if (!userEmail) {
      this.setState({
        currentStudentError: true,
      });
      return;
    }
    if (!timeZone) {
      this.setState({
        timeZoneError: true,
      });
      return;
    }
    

    const [mon, tue, wed, thu, fri, sat, sun] = oldAvailability.map(
      (item) => item
    );
    
    let userDetails = studentList.filter((_data) => _data.userEmail == userEmail)
    
    if(selectedDays.includes("mon")) {
      mon.mon.map((_data) => {
        if(_data.teacherAvailability) {
          delete _data.teacherAvailability
        }
        return _data
      })
    }

    if(selectedDays.includes("tue")) {
      tue.tue.map((_data) => {
        if(_data.teacherAvailability) {
          delete _data.teacherAvailability
        }
        return _data
      })
    }

    if(selectedDays.includes("wed")) {
      wed.wed.map((_data) => {
        if(_data.teacherAvailability) {
          delete _data.teacherAvailability
        }
        return _data
      })
    }

    if(selectedDays.includes("thu")) {
      thu.thu.map((_data) => {
        if(_data.teacherAvailability) {
          delete _data.teacherAvailability
        }
        return _data
      })
    }

    if(selectedDays.includes("fri")) {
      fri.fri.map((_data) => {
        if(_data.teacherAvailability) {
          delete _data.teacherAvailability
        }
        return _data
      })
    }

    if(selectedDays.includes("sat")) {
      sat.sat.map((_data) => {
        if(_data.teacherAvailability) {
          delete _data.teacherAvailability
        }
        return _data
      })
    }

    if(selectedDays.includes("sun")) {
      sun.sun.map((_data) => {
        if(_data.teacherAvailability) {
          delete _data.teacherAvailability
        }
        return _data
      })
    }

    

    let requestData = {
      userId: userDetails[0].userId,
      localTimeZone: timeZone,
      mon: selectedDays.includes("mon") ? mon.mon : [],
      tue: selectedDays.includes("tue") ? tue.tue : [],
      wed: selectedDays.includes("wed") ? wed.wed : [],
      thu: selectedDays.includes("thu") ? thu.thu : [],
      fri: selectedDays.includes("fri") ? fri.fri : [],
      sat: selectedDays.includes("sat") ? sat.sat : [],
      sun: selectedDays.includes("sun") ? sun.sun : [],
    };

    axiosPost("user/add-user-hours-availability", requestData)
      .then((response) => {
        if (!response.isError) {
          this.setState(
            {
              loading: false,
              successModal: true,
              message: "Succesfully saved",
            },
            () => {
              this.getStudentAvailability(userEmail);
            }
          );
        } else {
          this.setState({
            loading: false,
            successModal: false,
            errorModal: true,
            message: "Error while saving..",
          });
        }
      })
      .catch((errorResponse) => {
        this.setState({
          loading: false,
          successModal: false,
          errorModal: true,
          message: errorResponse.message,
        });
        console.log("errorResponse", errorResponse);
      });
  };

  //Delete time slot
  deleteTimeSlot = (dayName, data, iOfItem) => {
    let { Availability: oldAvailability } = this.state;
    let setNewArray =  oldAvailability.map((object) => ({
      ...object,
    }));

    setNewArray.map((item, i) => {
      if (Object.keys(item) == dayName[0]) {
        //delete setNewArray[i][dayName[0]][iOfItem];
        setNewArray[i][dayName[0]].splice(iOfItem,1);
      }
    });
    
    this.setState({
      Availability: setNewArray,
    });
  };

  // Modal control -
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      modalTitle: "",
      message: "",
    });
  };

  onSetDay = (dayName) => {
    let day = dayName[0];
    const { selectedDays } = this.state;
    if (selectedDays.includes(day)) {
      let index = selectedDays.indexOf(day);
      if (index > -1) {
        selectedDays.splice(index, 1);
      }
    } else {
      selectedDays.push(day);
    }
    this.setState({
      selectedDays: selectedDays,
    });
  };

  matchAvailablity = (matchCount) => {
    let {userEmail} = this.state;
    if(userEmail) {
      this.setState({
        isShowMatch: true,
      });
    }
  }
  completeMatch = (matchCount) => {
    let confirm;
    if(matchCount) {
       confirm =  window.confirm("Are you sure you want to complete match!");
    } else {
      confirm =  window.confirm("No match found!, are you sure you want to manually complete match!");
    }
    
      if(confirm) {
        let {studentList, userEmail} = this.state;
        let userDetails = studentList.filter((_data) => _data.userEmail == userEmail)
        
        let requestData = {
          userHoursId: userDetails[0].userHoursId,
          isMatch: true,
        };
    
        axiosPost("user/update-user-availability-status", requestData)
          .then((response) => {
            if (!response.isError) {
              this.setState(
                {
                  loading: false,
                  successModal: true,
                  message: "Succesfully saved",
                  isShowMatch: false,
                },
                () => {
                  this.getAllStudents();
                }
              );
            } else {
              this.setState({
                loading: false,
                successModal: false,
                errorModal: true,
                message: "Error while saving..",
              });
            }
          })
          .catch((errorResponse) => {
            this.setState({
              loading: false,
              successModal: false,
              errorModal: true,
              message: errorResponse.message,
            });
            console.log("errorResponse", errorResponse);
          });
       
      }
  }
  
  
  render() {
    let {
      studentList,
      userEmail,
      Availability,
      successModal,
      errorModal,
      message,
      modalTitle,
      selectedDays,
      timeZone,
      timeZones,
      timeZoneError,
      currentStudentError,
      isShowMatch
    } = this.state;
    
    //console.log("this.timing", this.timing);
    console.log("currentTeacherAvailability", Availability);

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 availability-header">
                  <h3 className="mb-0"> {this.isNew ? 'New' : ''} Student Availability</h3>
                  
                  <FormGroup  className={timeZoneError ? "has-danger availability-formgroup" : " availability-formgroup"}>
                      <label className="timezone-lable">Time Zone</label>
                      <InputGroup className="input-group-alternative">
                      <div style={{width: '100%', minWidth:'200px'}}>
                      <Select
                        options={timeZones}
                        placeholder="Select timezone"
                        value={timeZone ? {value:timeZone,label:timeZone} : timeZone}
                        onChange={(e) =>
                          this.setState({ timeZone: e.value, timeZoneError: false, })
                        }
                        isSearchable={true}
                      />
                      </div>
                      </InputGroup>
                    </FormGroup>
                </CardHeader>
                <CardHeader className="border-0 availability-header">
                  <h3 className="mb-0"></h3>
                  { (!this.isNew || isShowMatch) &&
                      <h3 className="mb-0">  Available Teacher</h3>
                  }
                </CardHeader>
                {studentList && studentList.length > 0
                 ? (
                  <div className="availability-hr-main">
                  <div className="availability-title ">
                    <h2>Availability Hours</h2>
                  </div>
                 
                  <div className={currentStudentError ? "has-danger availability-tab" : "availability-tab"} >
                    {studentList &&
                      studentList.length > 0 &&
                      studentList.map((_data) => {
                        return (
                          <button
                            onClick={() => {
                              this.setState({ currentStudentName: _data.userName, currentStudentError: false });
                              this.getStudentAvailability(_data.userEmail);
                            }}
                            className={`${
                              userEmail == _data.userEmail ? "active" : ""
                            }`}
                          >
                            {_data.userName}
                          </button>
                        );
                      })}
                  </div>
                  
                  <AvailabilityContent
                    Availability={Availability}
                    selectedDays={selectedDays}
                    timing={this.timing}
                    onSetDay={this.onSetDay}
                    onUpdateTime={this.onUpdateTime}
                    addTimeSlot={this.addTimeSlot}
                    deleteTimeSlot={this.deleteTimeSlot}
                    saveAvailability={this.saveAvailability}
                    isNew={this.isNew}
                    matchAvailablity={this.matchAvailablity}
                    isShowMatch={isShowMatch}
                    routeName={this.isNew ? 'new' : 'complete'}
                    completeMatch={this.completeMatch}
                  />
                </div>

                
                 )
                 : <h1 style={{display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'}} >
                              No data Available
                              </h1>
                 }
                
              </Card>
            </div>
          </Row>

          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
              successModal
                ? this.toggleModal("successModal")
                : errorModal
                ? this.toggleModal("errorModal")
                : null
            }
          >
            <div className="modal-header">
              <h5 className="modal-title" id="successModalLabel">
                {modalTitle}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() =>
                  successModal
                    ? this.toggleModal("successModal")
                    : errorModal
                    ? this.toggleModal("errorModal")
                    : null
                }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            {this.state.successModal ? (
              <div className="modal-body" style={{ color: "green" }}>
                {message}
              </div>
            ) : this.state.errorModal ? (
              <div className="modal-body" style={{ color: "red" }}>
                {message}
              </div>
            ) : null}
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                onClick={() =>
                  successModal
                    ? this.toggleModal("successModal")
                    : errorModal
                    ? this.toggleModal("errorModal")
                    : null
                }
              >
                Ok
              </Button>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default StudentAvailability;
