/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
  Button,
  FormGroup,
  Form,
  Input,
  CardBody,
  InputGroup,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import { axiosGet, axiosPost } from 'util/apiConfig.js';

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      userName: '',
      categoryList: [],
      selectedRecord: '',

      page: 1,
      totalPage: '',
      rowLimit: 10,

      name: '',
      nameError: false,
      categoryId: '',
      isActive: false,

      newModal: false,
      editModal: false,

      modalTitle: '',
      successModal: false,
      errorModal: false,
      message: '',
    };
  }

  componentDidMount() {
    this.setState(
      {
        categoryList: [],
      },
      () => {
        this.getCategoryList();
      }
    );
  }

  // to get new registered teacher's list -
  getCategoryList = () => {
    // API -
    axiosGet('category/find-category')
      .then((response) => {
        if (!response.isError) {
          this.setState({
            categoryList: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // For pagination -
  previousPage = () => {
    let { page } = this.state;

    this.setState({ page: page - 1 }, () => {
      this.getCategoryList();
    });
  };

  // For pagination -
  nextPage = () => {
    let { page } = this.state;
    this.setState({ page: page + 1 }, () => {
      this.getCategoryList();
    });
  };

  // new caterogy -
  addCategory = () => {
    this.setState({
      newModal: true,
      name: '',
    });
  };

  // saving new category -
  saveCategory = () => {
    !this.state.name
      ? this.setState({ nameError: true })
      : this.setState({ nameError: false });
    if (this.state.name && !this.state.nameError && !this.state.loading) {
      this.setState({ loading: true });
      let dataToSend = {
        categoryName: this.state.name,
      };
      axiosPost('category/add-category', dataToSend)
        .then((response) => {
          if (!response.isError) {
            this.setState(
              {
                loading: false,
                name: '',
                newModal: false,
                modalTitle: 'Success',
                successModal: true,
                message: 'Succesfully saved',
              },
              () => {
                this.getCategoryList();
              }
            );
          } else {
            this.setState({
              loading: false,
              name: '',
              newModal: false,
              modalTitle: 'Sorry!',
              errorModal: true,
              message: response.message,
            });
          }
        })
        .catch((errorResponse) => {
          this.setState({
            loading: false,
            name: '',
            newModal: false,
            modalTitle: 'Sorry!',
            errorModal: true,
            message: errorResponse.message,
          });
        });
    }
  };

  // for redirecting to teacher's profile -
  editRecord = (index) => {
    let { categoryList } = this.state;
    let selectedRecord = categoryList[index];
    this.setState({
      selectedRecord: selectedRecord,
      editModal: true,
      name: selectedRecord.categoryName,
      isActive: selectedRecord.isActive,
    });
  };

  // Update
  updateRecord = () => {
    let { name, isActive, selectedRecord } = this.state;
    name !== ''
      ? this.setState({ nameError: true })
      : this.setState({ nameError: false });
    if (name !== '') {
      let dataToSend = {
        categoryName: name,
        isActive: `${isActive}`,
      };
      this.setState({ loading: true });
      // API -
      axiosPost(
        `category/edit-category/${selectedRecord.categoryId}`,
        dataToSend
      )
        .then((response) => {
          if (!response.isError) {
            this.setState(
              {
                loading: false,
                selectedRecord: '',
                editModal: false,
                name: '',
                isActive: false,
                nameError: false,
                modalTitle: 'Success',
                successModal: true,
                message: 'Succesfully updated',
              },
              () => {
                this.getCategoryList();
              }
            );
          } else {
            this.setState({
              loading: false,
              selectedRecord: '',
              editModal: false,
              name: '',
              isActive: false,
              nameError: false,
              modalTitle: 'Sorry!',
              errorModal: true,
              message: response.message,
            });
          }
        })
        .catch((errorResponse) => {
          this.setState({
            loading: false,
            selectedRecord: '',
            editModal: false,
            name: '',
            isActive: false,
            nameError: false,
            modalTitle: 'Sorry!',
            errorModal: true,
            message: errorResponse.message,
          });
        });
    }
  };

  // Modal control -
  toggleModal = (state) => {
    if (state == 'data') {
      this.setState({
        editModal: false,
        newModal: false,
        name: '',
        isActive: false,
      });
    } else {
      this.setState({
        [state]: !this.state[state],
        modalTitle: '',
        message: '',
      });
    }
  };

  render() {
    let {
      loading,
      categoryList,
      successModal,
      errorModal,
      modalTitle,
      message,
      page,
      totalPage,
      name,
      nameError,
      selectedRecord,
      isActive,
    } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <Button
            color='primary'
            href='JavaScript:Void(0);'
            onClick={() => this.addCategory()}
          >
            Add Category
          </Button>
          <br />
          <br />
          {/* Table */}
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <h3 className='mb-0'>Categories</h3>
                </CardHeader>

                <Table
                  className='align-items-center table-flush'
                  responsive
                  style={{ marginBottom: '100px' }}
                >
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Id</th>
                      <th scope='col'>Name</th>
                      <th scope='col'>Status</th>
                      <th scope='col' />
                    </tr>
                  </thead>
                  <tbody>
                    {categoryList &&
                      categoryList.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{value.categoryId ? value.categoryId : ''}</td>
                            <th scope='row'>
                              <Media className='align-items-center'>
                                <Media>
                                  <span className='mb-0 text-sm'>
                                    {value.categoryName
                                      ? value.categoryName
                                      : ''}
                                  </span>
                                </Media>
                              </Media>
                            </th>
                            <td>
                              <Badge color='' className='badge-dot mr-4'>
                                {value.isActive ? (
                                  <>
                                    <i className='bg-success' />
                                    Active
                                  </>
                                ) : null}
                                {!value.isActive ? (
                                  <>
                                    <i className='bg-warning' />
                                    In Active
                                  </>
                                ) : null}
                              </Badge>
                            </td>
                            <td className='text-right'>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className='btn-icon-only text-light'
                                  href='JavaScript:Void(0);'
                                  role='button'
                                  size='sm'
                                  color=''
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className='fas fa-ellipsis-v' />
                                </DropdownToggle>
                                <DropdownMenu
                                  className='dropdown-menu-arrow'
                                  right
                                >
                                  <DropdownItem
                                    href='JavaScript:Void(0);'
                                    onClick={() => this.editRecord(index)}
                                  >
                                    Edit
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className='py-4'>
                  <nav aria-label='...'>
                    <Pagination
                      className='pagination justify-content-end mb-0'
                      listClassName='justify-content-end mb-0'
                    >
                      <PaginationItem className={page > 1 ? '' : 'disabled'}>
                        <PaginationLink
                          href='JavaScript:Void(0);'
                          onClick={() => this.previousPage('new')}
                          tabIndex='-1'
                        >
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className='active'>
                        <PaginationLink
                          href='JavaScript:Void(0);'
                          onClick={(e) => e.preventDefault()}
                        >
                          {page}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalPage > page ? '' : 'disabled'}
                      >
                        <PaginationLink
                          href='JavaScript:Void(0);'
                          onClick={() => this.nextPage('new')}
                        >
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

          {/* For notification */}
          <Modal
            className='modal-dialog-centered'
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
              successModal
                ? this.toggleModal('successModal')
                : errorModal
                ? this.toggleModal('errorModal')
                : null
            }
          >
            <div className='modal-header'>
              <h5 className='modal-title' id='successModalLabel'>
                {modalTitle}
              </h5>
              <button
                aria-label='Close'
                className='close'
                data-dismiss='modal'
                type='button'
                onClick={() =>
                  successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                    ? this.toggleModal('errorModal')
                    : null
                }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            {this.state.successModal ? (
              <div className='modal-body' style={{ color: 'green' }}>
                {message}
              </div>
            ) : this.state.errorModal ? (
              <div className='modal-body' style={{ color: 'red' }}>
                {message}
              </div>
            ) : null}
            <div className='modal-footer'>
              <Button
                color='primary'
                type='button'
                onClick={() =>
                  successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                    ? this.toggleModal('errorModal')
                    : null
                }
              >
                Ok
              </Button>
            </div>
          </Modal>

          {/* For add and edit new category */}
          <Modal
            className='modal-dialog-centered'
            size='lg'
            isOpen={this.state.editModal || this.state.newModal}
            toggle={() => this.toggleModal('data')}
          >
            <div className='modal-body p-0'>
              <Card className='bg-secondary shadow border-0'>
                <CardHeader className='bg-transparent pb-5'>
                  {this.state.newModal ? (
                    <div className='text-muted text-center mt-2 mb-3'>
                      <small>Add new category</small>
                    </div>
                  ) : this.state.editModal ? (
                    <div className='text-muted text-center mt-2 mb-3'>
                      <small>Edit category</small>
                    </div>
                  ) : null}
                </CardHeader>
                <CardBody className='px-lg-5 py-lg-5'>
                  <Form role='form'>
                    {this.state.editModal ? (
                      <FormGroup className=''>
                        <label>Category Id</label>
                        <InputGroup className='input-group-alternative'>
                          <Input
                            placeholder='Category Id'
                            type='text'
                            name='className'
                            value={selectedRecord.categoryId}
                            readOnly={true}
                          />
                        </InputGroup>
                      </FormGroup>
                    ) : null}
                    <FormGroup className={nameError ? 'has-danger' : ''}>
                      <label>Name</label>
                      <InputGroup className='input-group-alternative'>
                        <Input
                          placeholder='Name'
                          type='text'
                          name='name'
                          value={name}
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    {this.state.editModal ? (
                      <div className='custom-control custom-checkbox mb-3'>
                        <input
                          className='custom-control-input'
                          defaultChecked={isActive ? true : false}
                          id='customCheck1'
                          type='checkbox'
                          onChange={() =>
                            this.setState({ isActive: !this.state.isActive })
                          }
                        />
                        <label
                          className='custom-control-label'
                          htmlFor='customCheck1'
                        >
                          Active
                        </label>
                      </div>
                    ) : null}

                    <div className='text-center'>
                      {this.state.newModal && !loading ? (
                        <Button
                          className='my-4'
                          color='primary'
                          type='button'
                          onClick={() => this.saveCategory()}
                        >
                          Save
                        </Button>
                      ) : this.state.editModal && !loading ? (
                        <Button
                          className='my-4'
                          color='primary'
                          type='button'
                          onClick={() => this.updateRecord()}
                        >
                          Update
                        </Button>
                      ) : null}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default Category;
