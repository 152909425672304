/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Container,
  Row,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroup,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import { axiosGet, axiosPost } from 'util/apiConfig.js';
import DescriptionDetail from 'util/DescriptionDetails';
import {CopyToClipboard} from "react-copy-to-clipboard";

const imageRef = React.createRef(null);

const levelList = [
  { levelName: 'Begineer', value: 'beginner' },
  { levelName: 'Intermediate', value: 'intermediate' },
  { levelName: 'Experienced', value: 'experienced' },
];
class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      page: 1,
      totalPage: 0,
      limit: 10,

      categoryList: [],
      classList: [],

      classModal: false,
      editModal: false,
      className: '',
      about: '',
      category: '',
      level: '',
      bannerUrl: '',
      isVisible: false,
      uploading: false,
      selectedClass: '',

      classNameError: false,
      aboutError: false,
      categoryError: false,
      levelError: false,

      modalTitle: '',
      successModal: false,
      errorModal: false,
      message: '',
      copyValue: '',
    };
  }

  componentDidMount() {
    this.setState(
      {
        categoryList: [],
        classList: [],
      },
      () => {
        this.getAllClassList();
        this.getCategoryList();
      }
    );
  }

  getAllClassList = () => {
    const { page, limit } = this.state;
    axiosGet(`class/get-classes/${page}/${limit}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            classList: response.data?.result || [],
            totalPage: response.data?.noofpage,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // For changing page -
  changePage = (status) => {
    if (status && status === 'next') {
      this.setState({ page: this.state.page + 1 }, () => this.getAllClassList());
    } else if (status && status === 'prev') {
      this.setState({ page: this.state.page - 1 }, () => this.getAllClassList());
    }
  };
  getCategoryList = () => {
    axiosGet('category/find-category')
      .then((response) => {
        if (!response.isError) {
          this.setState({
            categoryList: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addClassForm = () => {
    this.setState({
      classModal: true,
    });
  };

  fileChange = (e) => {
    let file =
      imageRef.current && imageRef.current.files && imageRef.current.files[0];
    let blnValid = false;
    let sizeValid = false;

    if (file) {
      let sizeInByte = file.size;
      let sizeInKB = parseFloat(parseFloat(sizeInByte) / 1024).toFixed('2');
      let sFileName = file.name;
      sizeInKB <= 500 ? (sizeValid = true) : (sizeValid = false);

      var _validFileExtensions = ['.jpg', '.png'];
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (sizeValid) {
        if (blnValid) {
          this.setState({ uploading: true });
          const data = new FormData();
          data.append('file', file);

          axiosPost(`utility/upload-file`, data, true)
            .then((response) => {
              if (!response.isError) {
                this.setState({
                  uploading: false,
                  bannerUrl: response.data?.fileUrl,
                  successModal: true,
                  message: 'Successfully uploaded',
                  modalTitle: 'Success',
                });
              } else {
                this.setState({
                  uploading: false,
                  errorModal: false,
                  message: "Sorry! can't be uploaded.",
                  modalTitle: 'Sorry!',
                });
              }
            })
            .catch((error) => {
              this.setState({
                uploading: false,
                errorModal: false,
                message: "Sorry! can't be uploaded.",
                modalTitle: 'Sorry!',
              });
            });
        } else {
          this.setState({
            errorModal: true,
            message: 'File format is not supported',
            modalTitle: 'Sorry',
          });
        }
      } else {
        this.setState({
          errorModal: true,
          message: 'File size is exceeded',
          modalTitle: 'Sorry',
        });
      }
    }
  };

  addOrUpdateClass = (isCalledForEditClass) => {
    let {
      className,
      about,
      category,
      bannerUrl,
      selectedClass,
      isVisible,
      level,
    } = this.state;

    !className
      ? this.setState({ classNameError: true })
      : this.setState({ classNameError: false });
    !about
      ? this.setState({ aboutError: true })
      : this.setState({ aboutError: false });
    !category
      ? this.setState({ categoryError: true })
      : this.setState({ categoryError: false });
    !level
      ? this.setState({ levelError: true })
      : this.setState({ levelError: false });

    if (className && about && category && level) {
      let categoryId =
        this.state.categoryList[category] &&
        this.state.categoryList[category].categoryId;
      let categoryName =
        this.state.categoryList[category] &&
        this.state.categoryList[category].categoryName;
      let levelName = levelList[level] && levelList[level].value;
      this.setState({ loading: true });
      console.log('==========level', levelName);

      let dataToSend = {
        name: className,
        bannerImage: bannerUrl,
        isVisible: isVisible.toString(),
        about: about,
        categoryId: categoryId,
        categoryName: categoryName,
        level: levelName,
      };

      if (isCalledForEditClass) {
        dataToSend = { ...dataToSend, ...{ classId: selectedClass.classId } };
      }

      const endPointUrl = isCalledForEditClass
        ? 'class/edit-class'
        : 'class/add-class';
      axiosPost(`${endPointUrl}`, dataToSend)
        .then((response) => {
          this.setState({
            editModal: false,
            selectedClass: '',
            classModal: false,
            loading: false,
          });
          if (!response.isError) {
            this.setState(
              {
                successModal: true,
                modalTitle: 'Success',
                message: 'Successfully saved.',
              },
              () => {
                this.getAllClassList();
                this.resetForm();
              }
            );
          } else {
            this.setState(
              {
                errorModal: true,
                modalTitle: 'Sorry!',
                message: response.message,
              },
              () => {
                this.resetForm();
              }
            );
          }
        })
        .catch((errorResponse) => {
          this.setState(
            {
              editModal: false,
              selectedClass: '',
              classModal: false,
              errorModal: true,
              loading: false,
              modalTitle: 'Sorry!',
              message: errorResponse.message,
            },
            () => {
              this.resetForm();
            }
          );
        });
    }
  };

  editClass = (index) => {
    const { classList } = this.state;
    const selectedClass = classList[index];

    this.setState({
      selectedClass,
      className: selectedClass.name,
      about: selectedClass.about,
      category: this.getIndexOf(
        this.state.categoryList,
        'categoryId',
        selectedClass.categoryId
      ),
      level: this.getIndexOf(levelList, 'value', selectedClass.level),
      bannerUrl: selectedClass.bannerImage,
      isVisible: selectedClass.isVisible,
      editModal: true,
    });
  };

  getIndexOf = (array, name, value) => {
    let key;
    if (array && array.length > 0 && name !== '' && value) {
      array.map((data, index) => {
        let desiredVal = data[`${name}`];
        if (desiredVal === value) {
          key = `${index}`;
        }
        return data;
      });
      return key;
    }
  };

  openSectionPage = (index) => {
    const { classList } = this.state;
    const selectedClassId = classList[index].classId;
    const url = `/admin/section?classId=${selectedClassId}`;
    window.open(url, '_self');
  };


  deleteClass = (index) => {
    let { classList } = this.state;
    let selectedClass = classList[index];

    let dataToSend = {
      classId: selectedClass.classId,
    };

    axiosPost('class/delete-class', dataToSend)
      .then((response) => {
        if (!response.isError) {
          this.setState(
            {
              successModal: true,
              modalTitle: 'Success',
              message: 'Successfully deleted.',
            },
            () => {
              this.getAllClassList();
            }
          );
        } else {
          this.setState({
            errorModal: true,
            modalTitle: 'Sorry!',
            message: response.message,
          });
        }
      })
      .catch((errorResponse) => {
        this.setState({
          errorModal: true,
          modalTitle: 'Sorry!',
          message: errorResponse.message,
        });
      });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      modalTitle: '',
      message: '',
    });
    if (state === 'classModal' || state === 'editModal') {
      this.resetForm();
    } else if (state === 'roomModal') {
      this.setState({
        roomName: '',
        roomUrl: '',
      });
    }
  };

  resetForm = () => {
    this.setState({
      loading: false,
      className: '',
      about: '',
      category: '',
      level: '',
      bannerUrl: '',
      uploaded: '',
      classNameError: false,
      aboutError: false,
      categoryError: false,
      levelError: false,
      isVisible: false,
    });
  };

  render() {
    let {
      loading,
      classList,
      className,
      about,
      classNameError,
      category,
      bannerUrl,
      aboutError,
      categoryError,
      levelError,
      categoryList,
      successModal,
      errorModal,
      modalTitle,
      message,
      uploading,
      classModal,
      editModal,
      isVisible,
      level,
      page, totalPage
    } = this.state;

    return (
      <>
        <Header />
        <Container className='mt--7' fluid>
          <Button color='primary' onClick={() => this.addClassForm()}>
            Add Class
          </Button>
          <br />
          <br />
          <Row>
            {classList &&
              classList.length > 0 &&
              classList.map((data, index) => {
              const name = data.name.replace(/\s/g, "");
               return (
                data.name ? (
                  <div className='col-md-6 col-lg-4'>
                    <div className='SingleClassBlock'>
                      <div className='Img'>
                        <img src={data.bannerImage} alt={data.name} />
                      </div>

                      <div className='ContentWrapper'>
                        <div className='ClassTitleNBtn'>
                          <h4 className='Title'>{data.name}</h4>
                          <span>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className='btn-icon-only'
                                role='button'
                                size='sm'
                                color=''
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className='fas fa-ellipsis-v' />
                              </DropdownToggle>
                              <DropdownMenu
                                className='dropdown-menu-arrow'
                                right
                              >
                                <DropdownItem
                                  onClick={() => this.editClass(index)}
                                >
                                  Edit Class
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => this.deleteClass(index)}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </span>
                        </div>
                        <DescriptionDetail description={data.about} />
                        <div className='BtnWrapper'>
                          <Button
                            className='BtnFill'
                            color='primary'
                            onClick={() => this.openSectionPage(index)}
                          >
                            Section Details
                          </Button>
                        </div>
                        <br/>
                        <div className='BtnWrapper'>
                          <CopyToClipboard text={`https://fiverkids.com/userRegistration?className=${name}&classId=${data.classId}`}>
                            <Button
                                className='BtnFill'
                                color='success'
                            >Registration Link &nbsp;<i className='fa fa-copy'></i>
                            </Button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null
              )})}
          </Row>
        
          <CardFooter className='py-4'>
                  <nav aria-label='...'>
                    <Pagination
                      className='pagination justify-content-end mb-0'
                      listClassName='justify-content-end mb-0'
                    >
                      <PaginationItem className={page > 1 ? '' : 'disabled'}>
                        <PaginationLink
                          onClick={() => this.changePage('prev')}
                          tabIndex='-1'
                        >
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className='active'>
                        <PaginationLink onClick={(e) => e.preventDefault()}>
                          {page}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalPage > page ? '' : 'disabled'}
                      >
                        <PaginationLink onClick={() => this.changePage('next')}>
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>  
          
        </Container>

        <Modal
          className='modal-dialog-centered'
          size='lg'
          isOpen={classModal || editModal}
          toggle={() =>
            this.toggleModal(classModal ? 'classModal' : 'editModal')
          }
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0'>
              <CardHeader className='bg-transparent pb-5'>
                <div className='text-muted text-center mt-2 mb-3'>
                  <small>{classModal ? 'Add new class' : 'Edit class'}</small>
                </div>
              </CardHeader>
              <CardBody className='px-lg-5 py-lg-5'>
                <Form role='form'>
                  <FormGroup className={classNameError ? 'has-danger' : ''}>
                    <label>Class Name</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        placeholder='Class name'
                        type='text'
                        name='className'
                        value={className}
                        onChange={(e) =>
                          this.setState({ className: e.target.value })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={aboutError ? 'has-danger' : ''}>
                    <label>About</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        placeholder='Class details'
                        type='textarea'
                        rows='3'
                        name='about'
                        value={about}
                        onChange={(e) =>
                          this.setState({ about: e.target.value })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={categoryError ? 'has-danger' : ''}>
                    <label>Category</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        type='select'
                        name='category'
                        value={category}
                        onChange={(e) =>
                          this.setState({ category: e.target.value })
                        }
                      >
                        <option>Select category</option>
                        {categoryList &&
                          categoryList.map((value, index) => {
                            return (
                              <option key={index} value={index}>
                                {value.categoryName}
                              </option>
                            );
                          })}
                      </Input>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={levelError ? 'has-danger' : ''}>
                    <label>Level</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                        type='select'
                        name='level'
                        value={level}
                        onChange={(e) =>
                          this.setState({ level: e.target.value })
                        }
                      >
                        <option>Select Level</option>
                        {levelList &&
                          levelList.map((value, index) => {
                            return (
                              <option key={index} value={index}>
                                {value.levelName}
                              </option>
                            );
                          })}
                      </Input>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <label>Choose a banner image</label>
                    {editModal && (
                      <>
                        <br />
                        <span>
                          <a href={bannerUrl}>{bannerUrl}</a>
                        </span>
                      </>
                    )}
                    <InputGroup className='input-group-alternative'>
                      <input
                        type='file'
                        name='banner'
                        ref={imageRef}
                        id='myFile'
                        onChange={(e) => this.fileChange(e)}
                        multiple={false}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className='custom-control custom-checkbox mb-3'>
                    <input
                      className='custom-control-input'
                      defaultChecked={isVisible}
                      id='customCheck1'
                      type='checkbox'
                      onChange={() =>
                        this.setState({
                          isVisible: !this.state.isVisible,
                        })
                      }
                    />
                    <label
                      className='custom-control-label'
                      htmlFor='customCheck1'
                    >
                      Display this class on Home Page
                    </label>
                  </div>
                  <div className='text-center'>
                    {uploading ? (
                      <span style={{ color: 'red' }}>Wait...</span>
                    ) : null}

                    <Button
                      disabled={uploading || loading ? true : false}
                      className='my-4'
                      color='primary'
                      type='button'
                      onClick={() => {
                        this.addOrUpdateClass(classModal ? false : true);
                      }}
                    >
                      {classModal ? 'Save' : 'Update'}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>

        <Modal
          className='modal-dialog-centered'
          isOpen={this.state.successModal || this.state.errorModal}
          toggle={() =>
            successModal
              ? this.toggleModal('successModal')
              : errorModal
              ? this.toggleModal('errorModal')
              : null
          }
        >
          <div className='modal-header'>
            <h5 className='modal-title' id='successModalLabel'>
              {modalTitle}
            </h5>
            <button
              aria-label='Close'
              className='close'
              data-dismiss='modal'
              type='button'
              onClick={() =>
                successModal
                  ? this.toggleModal('successModal')
                  : errorModal
                  ? this.toggleModal('errorModal')
                  : null
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          {this.state.successModal ? (
            <div className='modal-body' style={{ color: 'green' }}>
              {message}
            </div>
          ) : this.state.errorModal ? (
            <div className='modal-body' style={{ color: 'red' }}>
              {message}
            </div>
          ) : null}
          <div className='modal-footer'>
            {/* <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("successModal")}
              >
                Close
            </Button> */}
            <Button
              color='primary'
              type='button'
              onClick={() =>
                successModal
                  ? this.toggleModal('successModal')
                  : errorModal
                  ? this.toggleModal('errorModal')
                  : null
              }
            >
              Ok
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default Class;
