/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Modal,
  Button,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import { axiosPost } from 'util/apiConfig.js';

class EmailSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      userName: '',
      newModal: false,
      editModal: false,

      modalTitle: '',
      successModal: false,
      errorModal: false,
      message: '',
      isSwitchOn: false,
    };
  }

  componentDidMount() {
    let storage = localStorage.getItem('Sanga_Admin');
    storage = storage ? JSON.parse(storage) : null;
    this.setState({
      userId: storage && storage.userId,
      isSwitchOn : storage && storage.isEmail
    });
  }



  // Modal control -
  toggleModal = (state) => {
    if (state == 'data') {
      this.setState({
        editModal: false,
        newModal: false,
        name: '',
        isActive: false,
      });
    } else {
      this.setState({
        [state]: !this.state[state],
        modalTitle: '',
        message: '',
      });
    }
  };

   onSwitchAction = () => {
    let {isSwitchOn, userId} = this.state;
    this.setState({
        isSwitchOn: !isSwitchOn,
      });
    
    let bodyParms = {
      isEmail: isSwitchOn ? false : true,
      userId: userId,
    };
    let storage = localStorage.getItem('Sanga_Admin');
    storage = storage ? JSON.parse(storage) : null;
    storage.isEmail = bodyParms.isEmail
    axiosPost("user/update-email-notification-student", bodyParms)
      .then((response) => {
        if (!response.isError) {
          this.setState(
            {
              loading: false,
              successModal: true,
              message: "Succesfully saved",
            },
            () => {
                localStorage.setItem('Sanga_Admin', JSON.stringify(storage));
            }
          );
        } else {
          this.setState({
            loading: false,
            successModal: false,
            errorModal: true,
            message: "Error while saving..",
          });
        }
      })
      .catch((errorResponse) => {
        this.setState({
          loading: false,
          successModal: false,
          errorModal: true,
          message: errorResponse.message,
        });
        console.log("errorResponse", errorResponse);
      });
  };

  render() {
    let {
      successModal,
      errorModal,
      modalTitle,
      message,
      isSwitchOn
    } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <br />
          <br />
          {/* Table */}
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0' style={{display:'flex'}}>
                  <h3 className='mb-0' style={{marginRight:'20px'}}>Email Setting</h3>
                  <label className="mb-0 custom-toggle custom-toggle-primary" style={{marginTop:'4px'}}>
                    <input type="checkbox" checked={isSwitchOn} />
                    <span onClick={this.onSwitchAction} className="custom-toggle-slider rounded-circle" data-label-off="OFF" data-label-on="ON">
                    </span>
                  </label>
                </CardHeader>
               </Card>
            </div>
          </Row>

          {/* For notification */}
          <Modal
            className='modal-dialog-centered'
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
              successModal
                ? this.toggleModal('successModal')
                : errorModal
                ? this.toggleModal('errorModal')
                : null
            }
          >
            <div className='modal-header'>
              <h5 className='modal-title' id='successModalLabel'>
                {modalTitle}
              </h5>
              <button
                aria-label='Close'
                className='close'
                data-dismiss='modal'
                type='button'
                onClick={() =>
                  successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                    ? this.toggleModal('errorModal')
                    : null
                }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            {this.state.successModal ? (
              <div className='modal-body' style={{ color: 'green' }}>
                {message}
              </div>
            ) : this.state.errorModal ? (
              <div className='modal-body' style={{ color: 'red' }}>
                {message}
              </div>
            ) : null}
            <div className='modal-footer'>
              <Button
                color='primary'
                type='button'
                onClick={() =>
                  successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                    ? this.toggleModal('errorModal')
                    : null
                }
              >
                Ok
              </Button>
            </div>
          </Modal>

         </Container>
      </>
    );
  }
}

export default EmailSetting;
