/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Container, Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import {axiosGet, axiosPost} from 'util/apiConfig.js';
import {dateForPaymentListing} from 'util/constance';
import moment from 'moment-timezone';
import Select from "react-select";
import {fetchTimeZones} from "../../util/timeZones";
import "./SalesNotification.css";
import {Device} from "@twilio/voice-sdk";

const USER_STATE = {
  CONNECTING : "Connecting",
  READY : "Ready",
  ON_CALL : "On call",
  OFFLINE : "Offline",
}


class UserRegistrationSection extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      allUserRegistration: [],
      timeZones: [],
      teachersHours: [],

      page: 1,
      totalPage: 0,
      limit: 10,

      successModal: false,
      successMessage: '',
      errorModal: false,
      errorMessage: '',
      userState: USER_STATE.OFFLINE,
      messageStates: {},
    };
  }




  componentDidMount() {
    const classId = localStorage.getItem("classId");
    this.setState(
      {
        classId: classId ? classId : null,
        allUserRegistration: [],
      },
      () => {
        this.getAllUserRegistration();
        this.getTimeZones();
      }
    );
  }

  getTimeZones = () => {
    let timeZones = fetchTimeZones();
    let newTimeZoneObj = [];
    timeZones.map((data) => {
      let tt = {value:data, label: data }
      newTimeZoneObj.push(tt)
    })
    timeZones = newTimeZoneObj;
    this.setState({ timeZones });
  };

  // for getting all approved student -
  getAllUserRegistration = () => {
    const { page, limit, classId } = this.state;
    this.setState({ loading: false });
    let url = `user/get-student-registration-list/${page}/${limit}`;
    if (classId) {
        url = `user/get-student-registration-list/${page}/${limit}?classId=${classId}`;
    }
    axiosGet(url)
      .then((response) => {
        if (!response.isError) {
          const allUserRegistration = response.data?.result;
          allUserRegistration.map((item) => {
            const avilabiltyHours = [];
            item.UserRegistration.map((data, index) => {
              if(data.mon.length){
                data.mon.map((data)=>{
                  data.day = 'Mon';
                })
                avilabiltyHours.push(...data.mon)
              }
              if(data.tue.length){
                data.tue.map((data)=>{
                  data.day = 'Tue';
                })
                avilabiltyHours.push(...data.tue)
              }
              if(data.wed.length){
                data.wed.map((data)=>{
                  data.day = 'Wed';
                })
                avilabiltyHours.push(...data.wed)
              }
              if(data.thu.length){
                data.thu.map((data)=>{
                  data.day = 'Thu';
                })
                avilabiltyHours.push(...data.thu)
              }
              if(data.fri.length){
                data.fri.map((data)=>{
                  data.day = 'Fri';
                })
                avilabiltyHours.push(...data.fri)
              }
              if(data.sat.length){
                data.sat.map((data)=>{
                  data.day = 'Sat';
                })
                avilabiltyHours.push(...data.sat)
              }
              if(data.sun.length){
                data.sun.map((data)=>{
                  data.day = 'Sun';
                })
                avilabiltyHours.push(...data.sun)
              }
            })
            item.timeList = avilabiltyHours.map((option) => ({
              value: option,
              label: `${option.day} - ${option.start} - ${option.end}`,
            }));
          });
          console.log(allUserRegistration, 'allUserRegistration')
          this.setState({
            loading: false,
            allUserRegistration: allUserRegistration,
            totalPage: response.data?.noofpage,
          }, () => {
            this.getAllTeacherHours();
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // findAvailableTeachers = (users, teachers, userClassId) => {
  //   const availableTeachers = [];
  //   // Iterate over each user
  //   users.forEach((user) => {
  //     // Iterate over each teacher
  //     teachers.forEach((teacher) => {
  //       // Iterate over each day of the week for the user
  //
  //       Object.keys(user).forEach((day) => {
  //         // Check if the user has provided availability for this day
  //         if (teacher.classId == userClassId && Array.isArray(user[day]) && Array.isArray(teacher[day]) && user[day].length > 0 && teacher[day].length > 0) {
  //           // Find the corresponding availability for this day in the teacher's schedule
  //           const teacherAvailability = teacher[day];
  //           if(Array.isArray(teacherAvailability)){
  //             teacherAvailability.forEach((slot) => {
  //               // get teacher section time and check if teacher is active on that day then not show that teacher
  //               let teacherActiveDays = null;
  //               teacher?.SectionDetails?.filter((section)=>{
  //                 teacherActiveDays = section.daysOfWeek.filter((day) => day.isActive === true);
  //                 teacherActiveDays.map((day)=>{
  //                   day.start = section.startTime;
  //                   day.end = section.endTime;
  //                   day.localTimeZone = teacher.localTimeZone;
  //                 })
  //               })
  //               console.log(teacherActiveDays, 'teacherActiveDays')
  //               debugger;
  //
  //               // set section start time and end time in active days
  //               // Parse the start and end time of the slot
  //               const startTime = this.parseTime(slot.start, teacher.localTimeZone, day);
  //               const endTime = this.parseTime(slot.end, teacher.localTimeZone, day);
  //
  //               // Parse the user's start and end time for this day
  //               const userStartTime = this.parseTime(user[day][0].start, user.localTimeZone, day);
  //               const userEndTime = this.parseTime(user[day][0].end, user.localTimeZone, day);
  //
  //               // Check if the teacher is available during the user's specified time
  //               if (this.isWithinRange(startTime, endTime, userStartTime, userEndTime)) {
  //                 // Add the teacher to the list of available teachers
  //                 availableTeachers.push(teacher);
  //               }
  //             });
  //           }
  //           // Iterate over each availability slot for this day in the teacher's schedule
  //
  //         }
  //       });
  //     });
  //   });
  //
  //   return availableTeachers;
  // }

  findAvailableTeachers = (users, teachers, userClassId) => {
    const availableTeachers = new Set(); // Use a Set to avoid duplicate entries

    teachers.forEach((teacher) => {
      if (teacher.classId !== userClassId) return;

      // Precompute active days with sections for each teacher
      const activeDays = new Map();
      if (teacher && teacher.SectionDetails) {
        teacher.SectionDetails.forEach(section => {
          section.daysOfWeek.forEach(day => {
            if (day.isActive) {
              const start = this.parseTime(section.startTime, section.localTimeZone, day.name.toLowerCase());
              const end = this.parseTime(section.endTime, section.localTimeZone, day.name.toLowerCase());
              activeDays.set(day.name.toLowerCase(), {start, end});
            }
          });
        });
      }

      users.forEach((user) => {
        Object.keys(user).forEach((day) => {
          if (!Array.isArray(user[day]) || user[day].length === 0) return;

          const userStartTime = this.parseTime(user[day][0].start, user.localTimeZone, day);
          const userEndTime = this.parseTime(user[day][0].end, user.localTimeZone, day);

          if (Array.isArray(teacher[day]) && teacher[day].length > 0) {
            teacher[day].forEach(slot => {
              const startTime = this.parseTime(slot.start, teacher.localTimeZone, day);
              const endTime = this.parseTime(slot.end, teacher.localTimeZone, day);

              // Check for overlapping active section times
              if (activeDays.has(day) && this.isWithinRange(activeDays.get(day).start, activeDays.get(day).end, userStartTime, userEndTime)) {
                return; // Skip this teacher for this day if they have active sections overlapping with user times
              }

              if (this.isWithinRange(startTime, endTime, userStartTime, userEndTime)) {
                availableTeachers.add(teacher);
              }
            });
          }
        });
      });
    });

    return Array.from(availableTeachers); // Convert Set back to Array for the output
  }



// Helper function to parse time strings into Date objects
   parseTime = (timeString,  timeZone, day) => {
     const [time, period] = timeString.split(' ');
     let [hours, minutes] = time.split(':').map(Number);
     if (period === 'pm' && hours !== 12) {
       hours += 12;
     } else if (period === 'am' && hours === 12) {
       hours = 0;
     }
     const date = new Date();
     date.setUTCHours(hours, minutes, 0);
     // Adjust day of the week based on local time zone
     date.setDate(date.getDate() + (this.getDayOfWeek(day) - date.getUTCDay()));
     // Adjust time to UTC
     const utcDate = new Date(date.toLocaleString('en-US', { timeZone }));
     return utcDate;
  }

  getDayOfWeek = (day) => {
    const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    return days.indexOf(day);
  }

// Helper function to check if a given time range overlaps with another time range
  isWithinRange = (start1, end1, start2, end2) => {
    return start1 < end2 && end1 > start2;
  }


  getAllTeacherHours = () => {
    const { allUserRegistration } = this.state;
    this.setState({ loading: false });
    axiosGet(`teacher/get-all-teacher-hours`)
      .then((response) => {
        if (!response.isError) {
            console.log(response.data, 'teacjer')
            this.setState({
                loading: false,
                teachersHours: response.data,
            });
          allUserRegistration.map((item)=>{
            console.log(item, 'item');
            console.log(response.data, 'response.data')
            const availableTeachers = this.findAvailableTeachers(item?.UserRegistration || [], response?.data || [], item?.classId);
            if(availableTeachers.length){
              item.teachers = availableTeachers.map((option) => ({
                value: option.teacherName || '',
                label: option.teacherName || '',
              }));
              // remove duplicate teacher
              item.teachers = item.teachers.filter((thing, index, self) =>
                  index === self.findIndex((t) => (
                      t.value === thing.value
                  ))
              )
            }
          })
          this.setState({ allUserRegistration });
          console.log("Available teachers:", allUserRegistration);
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createSection = (userRegistrationId) => {
    window.open(`/admin/section?userId=${userRegistrationId.userId}&classId=${userRegistrationId.classId}`, '_self');
  };

  sendReminder = (data) => {
    axiosPost(`user/student-registration-verification-reminder`, {token: data.token, classId: data.classId})
        .then((response) => {
          if (!response.isError) {
            this.setState(
                {
                  successModal: true,
                  modalTitle: "Success",
                  message: "Mail sent it successfully.",
                },
                () => {
                }
            );
          } else {
            this.setState({
              errorModal: true,
              modalTitle: "Sorry!",
              message: response.message
                  ? response.message
                  : "Something is wrong. Try again later.",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  // For changing page -
  changePage = (status) => {
    if (status && status === 'next') {
      this.setState({ page: this.state.page + 1 }, () => this.getAllUserRegistration());
    } else if (status && status === 'prev') {
      this.setState({ page: this.state.page - 1 }, () => this.getAllUserRegistration());
    }
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      modalTitle: "",
      message: "",
    });
  };

  handleTimeZone = (e, userRegistrationId, timeList, timeZone) => {
    const {allUserRegistration} = this.state;
    const updatedUserRegistration = [...allUserRegistration];
    const registration = updatedUserRegistration[userRegistrationId];
    const convertedData = timeList.map((item) => {
      const registration = updatedUserRegistration[userRegistrationId];
      const start = item.value.start;
      const end = item.value.end;
      const sourceDayOfWeek = item.value.day;
      const startTime = moment.tz(`${sourceDayOfWeek} ${start}`, 'dddd hh:mm A', timeZone);
      const endTime = moment.tz(`${sourceDayOfWeek} ${end}`, 'dddd hh:mm A', timeZone);
      const cloneStartTime = startTime.clone().tz(e.value).format('hh:mm a') || '';
      const cloneEndTime = endTime.clone().tz(e.value).format('hh:mm a') || '';
      const cloneDay = startTime.clone().tz(e.value).format('ddd') || '';
        return {
          ...item,
          start: cloneStartTime,
          end: cloneEndTime,
          label: `${cloneDay} - ${cloneStartTime} - ${cloneEndTime}`,
        };
    });
    registration.timeList = convertedData;
    updatedUserRegistration[userRegistrationId] = registration;
    this.setState({
      allUserRegistration: updatedUserRegistration
    });
  };

  updateMessageState = (index, newState) => {
    this.setState(prevState => ({
      messageStates: {
        ...prevState.messageStates,
        [index]: newState
      }
    }));
  };


  isTimeInRange(timezone) {
    const now = new Date();
    const userTime = new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: false, timeZone: timezone }).format(now);
    const hour = parseInt(userTime, 10);

    // Check if the time is between 8 AM (8) and 9 PM (21)
    return hour >= 8 && hour < 21;
  }

  rejectCall = ()=>{
    const {device, selectedNumber} = this.state
    device.emit("disconnect");
    device.emit("cancel");
    device.emit("reject");
    device.disconnectAll({params: {To: selectedNumber} });
  }

  handleCall = async (sale, index) => {
    const confirm = window.confirm(`Are you sure want to call? \nPlease verify details\n\nMobile: ${sale.phoneNumber || ''}, `)
    if(!confirm){
      return false;
    }
    const {device} = this.state;
    device.emit("connect");
    device.connect({params: {To: `${sale.phoneNumber.replace(/\D/g, '')}`, userId: sale.userId } }).then((call) => {
      call.on("accept", () => {
        this.updateMessageState(index, USER_STATE.ON_CALL);
        console.log("call accepted");
      });
      call.on("disconnect", () => {
        console.log("The call has been disconnected.");
        this.updateMessageState(index, USER_STATE.READY);
      });
      call.on("reject", () => {
        console.log("The call was rejected.");
      });
      call.on("cancel", () => {
        console.log("The call was rejected.");
        this.updateMessageState(index, USER_STATE.READY);
      });
    })
  };

  callInit = (sale, index) => {
    axiosPost('sales/token', {})
        .then((response) => {
          if (!response.isError) {
            this.setState(
                {
                  loading: false
                },
                () => {
                  const token = response.data.token;
                  const device = new Device(token, {
                    logLevel: 1,
                    codecPreferences: ["opus", "pcmu"],
                  });
                  device.register();
                  this.setState({device})
                  device.addListener("connect", (device) => {
                    console.log("Connect event listener added .....");
                    return device;
                  });
                  device.on("registered", () => {
                    console.log("Agent registered");
                    this.setState({userState: USER_STATE.READY});
                  });
                  device.on("connect", (connection) => {
                    console.log("Call connect");
                    this.setState({userState: USER_STATE.ON_CALL})
                  });
                  device.on("disconnect", () => {
                    console.log("Disconnect event");
                    this.setState({userState: USER_STATE.READY})
                  });

                  setTimeout(()=>{
                    this.handleCall(sale, index)
                  }, 1000)
                  return () => {
                    device.destroy();
                    this.setState({device: undefined})
                    this.setState({userState: USER_STATE.OFFLINE})
                  };
                }
            );
          } else {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            errorModal: true,
            modalTitle: 'Sorry!',
            message: 'Something is wrong. Try again later.',
          });
          console.log(error);
        });
  }

  render() {
    let { loading, allUserRegistration, page, totalPage, timeZones,  message,
      successMessage,
      successModal,
      modalTitle,
      errorModal, } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          {/* Table */}
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <h3 className='mb-0'>User Registration</h3>
                </CardHeader>
                <Table className='align-items-center table-flush' responsive>
                  <thead className='thead-light'>

                    <tr >
                      <th scope="col"></th>
                      <th scope='col'>Class Name</th>
                      <th scope='col'>Email</th>
                      <th scope='col'>Phone Number</th>
                      <th scope='col'>Child Name</th>
                      <th scope='col'>Child Age</th>
                      <th scope='col'>Chess Ability</th>
                      <th scope='col'>Class Length</th>
                      <th scope='col'>Time Zone</th>
                      <th scope='col'>Availability</th>
                      <th scope='col' style={{width: '200px'}}>TimeZone Convert</th>
                      <th scope='col' style={{width: '200px'}}>Teacher Available</th>
                      <th scope='col'>Date</th>
                      <th scope='col'>Create Section</th>
                      <th scope='col'>Reminder</th>
                      <th scope='col' />
                    </tr>
                  </thead>
                  <tbody>
                    {allUserRegistration &&
                      allUserRegistration.length > 0 &&
                      allUserRegistration.map((data, index) => {
                        const isActiveTime = this.isTimeInRange('America/New_York');
                        let style = !data.isActive ? {color: 'red'} : {}
                        style = data?.isCreatedSection ? {color: 'green'} : style
                        const userStateForMessage = this.state.messageStates[index] || USER_STATE.OFFLINE;
                        const isActive = Object.values(this.state.messageStates).some(
                            state => state === 'Connecting' || state === 'On call'
                        )
                        return (
                        <React.Fragment key={index}>
                          <tr key={index} style={style}>
                          <td  className={'table-column-width'} >
                              {
                                (userStateForMessage === 'Connecting' || userStateForMessage === 'On call') ? (
                                    <button
                                        className={'btn btn-danger'}
                                        onClick={() => this.rejectCall(data.mobile)}
                                    ><i className="fa fa-phone" style={{fontSize:'20px'}} ></i>
                                    </button>
                                ): (
                                    isActiveTime ? (
                                        <button
                                            className={'btn btn-white'}
                                            disabled={isActive}
                                            onClick={() =>this.callInit(data, index)}
                                        ><i className="fa fa-phone" style={{fontSize:'20px'}} ></i>
                                        </button>
                                    ): (
                                        <></>
                                    )
                                )
                              }
                            </td>
                          <td>{data.ClassDetails && data.ClassDetails.length && data.ClassDetails[0].name}</td>
                          <td>{data.email && data.email}</td>
                          <td>{data.phoneNumber && data.phoneNumber}</td>
                          <td>{data.childName && data.childName}</td>
                          <td>{data.childAge && data.childAge}</td>
                          <td>{data.chessAbility && data.chessAbility}</td>
                          <td>{data.classPerWeek && data.classPerWeek}</td>
                          <td>{data.timeZone && data.timeZone}</td>
                          <td style={{width: '100px'}}>
                            {
                                <Select
                                    options={data.timeList}
                                    placeholder="Select Time"
                                    isSearchable={true}
                                    menuPosition="fixed"
                                    menuPlacement="bottom"
                                    styles={{
                                      control: (styles) => ({ ...styles, width: 200 }),
                                      menu: (styles) => ({ ...styles, width: 200 }),
                                    }}

                                />
                            }
                          </td>
                          <td>
                            <div style={{width: '200px'}}>
                              <Select
                                  options={timeZones}
                                  placeholder="Select timezone"
                                  isSearchable={true}
                                  menuPosition="fixed"
                                  menuPlacement="bottom"
                                  styles={{
                                    control: (styles) => ({ ...styles, width: 200 }),
                                    menu: (styles) => ({ ...styles, width: 200 }),
                                  }}
                                  onChange={(e) => this.handleTimeZone(e, index, data.timeList, data.timeZone)}
                              />
                            </div>
                          </td>
                          <td>
                                  {
                                      data?.teachers && data?.teachers.length ? (
                                          <Select
                                              options={data.teachers}
                                              placeholder="Select Teacher"
                                              isSearchable={true}
                                              menuPosition="fixed"
                                              menuPlacement="bottom"
                                              styles={{
                                                  control: (styles) => ({ ...styles, width: 200 }),
                                                  menu: (styles) => ({ ...styles, width: 200 }),
                                              }}
                                          />
                                      ) : null
                                  }
                            </td>
                          <td>{data.createdAt && moment(data.createdAt).format(dateForPaymentListing)}</td>
                          <td>
                              <Button
                                  color="primary"
                                  type="button"
                                  onClick={() => this.createSection(data)}
                                  >Create Section</Button>
                          </td>
                          <td>
                            {
                              data.isActive === false ? (
                                  <Button
                                      color="primary"
                                      type="button"
                                      onClick={() => this.sendReminder(data)}
                                  >Send Reminder</Button>
                                  ): null
                            }
                          </td>
                          </tr>
                        </React.Fragment>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className='py-4'>
                  <nav aria-label='...'>
                    <Pagination
                      className='pagination justify-content-end mb-0'
                      listClassName='justify-content-end mb-0'
                    >
                      <PaginationItem className={page > 1 ? '' : 'disabled'}>
                        <PaginationLink
                          onClick={() => this.changePage('prev')}
                          tabIndex='-1'
                        >
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className='active'>
                        <PaginationLink onClick={(e) => e.preventDefault()}>
                          {page}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalPage > page ? '' : 'disabled'}
                      >
                        <PaginationLink onClick={() => this.changePage('next')}>
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        <Modal
            className="modal-dialog-centered"
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
                successModal
                    ? this.toggleModal("successModal")
                    : errorModal
                        ? this.toggleModal("errorModal")
                        : null
            }
        >


          <div className="modal-header">
            <h5 className="modal-title" id="successModalLabel">
              {modalTitle}
            </h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() =>
                    successModal
                        ? this.toggleModal("successModal")
                        : errorModal
                            ? this.toggleModal("errorModal")
                            : null
                }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          {this.state.successModal ? (
              <div className="modal-body" style={{ color: "green" }}>
                {message}
              </div>
          ) : this.state.errorModal ? (
              <div className="modal-body" style={{ color: "red" }}>
                {message}
              </div>
          ) : null}
          <div className="modal-footer">
            <Button
                color="primary"
                type="button"
                onClick={() =>
                    successModal
                        ? this.toggleModal("successModal")
                        : errorModal
                            ? this.toggleModal("errorModal")
                            : null
                }
            >
              Ok
            </Button>
          </div>
        </Modal>
       </>

    );
  }
}

export default UserRegistrationSection;
