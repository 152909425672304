/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
  Button,
  CardBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { axiosGet, axiosPost, axiosDelete } from "util/apiConfig.js";
import { fetchTimeZones } from "util/timeZones";
import Select from "react-select";
class Teachers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      userName: "",
      newRequestList: [],
      teachersList: [],
      classList: [],

      listPage: 1,
      totalListPage: "",
      newPage: 1,
      totalNewPage: "",
      rowLimit: 10,

      modalTitle: "",
      successModal: false,
      errorModal: false,
      message: "",

      showAddTeacher: false,
      teacherEmail: "",
      teacherEmailError: false,
      teacherName: "",
      teacherNameError: false,
      teacherLocation: "",
      teacherLocationError: false,
      teacherSubject: "",
      teacherSubjectError: false,
      teacherTimezone: "",
      submitting: false,
      countryCode: "",
      mobileNo: "",
      classId: "",
      roomURL: "",
      videoURL: "",
      zoomCallDetails: "",

      showChangePasswordModal: false,
      currentTeacher: null,
      teacherPassword: "",
      teacherPasswordError: false,
      teacherConfirmPassword: "",
      teacherConfirmPasswordError: false,
      timeZones: [],
    };
  }

  componentDidMount() {
    const classId = localStorage.getItem("classId");
    this.setState(
      {
        globalClassId: classId ? classId : null,
        userId: "",
        userName: "",
        newRequestList: [],
        teachersList: [],
      },
      () => {
        this.getNewTeachers();
        this.getAllTeachers();
        this.getTimeZones();
        this.getClass();
      }
    );
  }

  getTimeZones = () => {
    let timeZones = fetchTimeZones();
    let newTimeZoneObj = [];
    timeZones.map((data) => {
      let tt = { value: data, label: data.replace("_", " ") };
      newTimeZoneObj.push(tt);
    });
    timeZones = newTimeZoneObj;
    this.setState({ timeZones });
  };

  // to get new registered teacher's list -
  getNewTeachers = () => {
    let { newPage, rowLimit, globalClassId } = this.state;
    let url = `teacher/list-of-teacher-request/${newPage}/${rowLimit}`;
    if (globalClassId) {
        url = `teacher/list-of-teacher-request/${newPage}/${rowLimit}?classId=${globalClassId}`;
    }
    axiosGet(url)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            newRequestList: response.data?.result || [],
            totalNewPage: response.data?.noofpage,
          });
        } else {
          this.setState({
            newRequestList: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          newRequestList: [],
        });
      });
  };

  // to get all approved teacher's list -
  getAllTeachers = () => {
    let { rowLimit, listPage, globalClassId } = this.state;
    // API --
    let url = `teacher/list-of-teacher/${listPage}/${rowLimit}`;
    if (globalClassId) {
        url = `teacher/list-of-teacher/${listPage}/${rowLimit}?classId=${globalClassId}`;
    }
    axiosGet(url)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            teachersList: response.data?.result || [],
            totalListPage: response.data?.noofpage,
          });
        } else {
          this.setState({
            teachersList: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          teachersList: [],
        });
      });
  };

  getClass = () => {
    // API --
    axiosGet(`class/get-classes-list`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            classList: response.data || [],
          });
        } else {
          this.setState({
            classList: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          classList: [],
        });
      });
  };

  // For pagination -
  previousPage = (status) => {
    let { listPage, newPage } = this.state;
    if (status == "list") {
      this.setState({ listPage: listPage - 1 }, () => {
        this.getAllTeachers();
      });
    } else if (status == "new") {
      this.setState({ newPage: newPage - 1 }, () => {
        this.getNewTeachers();
      });
    }
  };

  // For pagination -
  nextPage = (status) => {
    let { listPage, newPage } = this.state;
    if (status == "list") {
      this.setState({ listPage: listPage + 1 }, () => {
        this.getAllTeachers();
      });
    } else if (status == "new") {
      this.setState({ newPage: newPage + 1 }, () => {
        this.getNewTeachers();
      });
    }
  };

  // for redirecting to teacher's profile -
  viewProfile = (userId) => {
    // e.preventDefault();
    this.props.history.push({
      pathname: "/admin/profile/" + userId,
      state: {},
    });
  };

  //to approve request -
  approveTeacherRequest = (userId) => {
    let dataToSend = {
      id: userId,
    };
    // API -
    axiosPost("teacher/teacher-request-accepted", dataToSend)
      .then((response) => {
        if (!response.isError) {
          this.setState(
            {
              successModal: true,
              modalTitle: "Success",
              message: "Request has been accepted successfully",
            },
            () => {
              this.getNewTeachers();
              this.getAllTeachers();
            }
          );
        } else {
          this.setState({
            errorModal: true,
            modalTitle: "Sorry!",
            message: "Something is wrong. Try again later.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorModal: true,
          modalTitle: "Sorry!",
          message: "Something is wrong. Try again later.",
        });
      });
  };

  //reject request -
  rejectTeacherRequest = (userId) => {
    let dataToSend = {
      id: userId,
    };
    // API -
    axiosPost("teacher/teacher-request-rejected", dataToSend)
      .then((response) => {
        if (!response.isError) {
          this.setState(
            {
              successModal: true,
              modalTitle: "Success",
              message: "Request has been rejected successfully",
            },
            () => {
              this.getNewTeachers();
              this.getAllTeachers();
            }
          );
        } else {
          this.setState({
            errorModal: true,
            modalTitle: "Sorry!",
            message: "Something is wrong. Try again later.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorModal: true,
          modalTitle: "Sorry!",
          message: "Something is wrong. Try again later.",
        });
      });
  };

  // Modal control -
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      modalTitle: "",
      message: "",
    });
  };

  // Modal control -
  showAddTeacherModal = (showAddTeacher, data = null) => {
    let countryCode = "";
    let mobileNo = "";
    if (data && data?.mobileNumber && data?.mobileNumber !== undefined) {
      let mobileNumber = data?.mobileNumber.toString().split("-");
      countryCode = mobileNumber[0].slice(1);
      mobileNo = mobileNumber[1];
    }

    this.setState({
      userId: data?.userId ? data?.userId : "",
      classId: data?.classId ? data?.classId : "",
      showAddTeacher: !showAddTeacher,
      teacherEmail: data?.email ? data?.email : "",
      teacherName: data?.name ? data?.name : "",
      teacherLocation: data?.location ? data?.location : "",
      teacherSubject: data?.subject ? data?.subject : "",
      roomURL: data?.roomURL ? data?.roomURL : "",
      videoURL: data?.videoURL ? data?.videoURL : "",
      zoomCallDetails: data?.zoomCallDetails ? data?.zoomCallDetails : "",
      teacherNameError: false,
      teacherEmailError: false,
      teacherLocationError: false,
      teacherSubjectError: false,
      countryCode: countryCode,
      mobileNo: mobileNo,
      teacherTimezone: data?.timeZone ? data?.timeZone : "",
    });
  };

  //Add and Update teacher
  saveTeacher = () => {
    const {
      teacherEmail,
      teacherName,
      teacherLocation,
      teacherSubject,
      submitting,
      roomURL,
      videoURL,
      zoomCallDetails,
      userId,
      countryCode,
      mobileNo,
      classId,
      teacherTimezone,
    } = this.state;
    let isValidated = true;
    this.setState({
      submitting: true,
      teacherEmailError: false,
      teacherNameError: false,
      teacherLocationError: false,
      teacherSubjectError: false,
    });
    var pattern = new RegExp(
      /^(([^<>()+*[\]\\.,;:\s@"]+(\.[^<>()+*[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (!teacherEmail) {
      this.setState({
        teacherEmailError: true,
        submitting: false,
      });
      isValidated = false;
    }

    if (!pattern.test(teacherEmail) && teacherEmail) {
      this.setState({
        teacherEmailError: true,
        submitting: false,
      });
      isValidated = false;
    }

    if (!teacherName) {
      this.setState({
        teacherNameError: true,
        submitting: false,
      });
      isValidated = false;
    }
    if (!teacherLocation) {
      this.setState({
        teacherLocationError: true,
        submitting: false,
      });
      isValidated = false;
    }
    if (!teacherSubject) {
      this.setState({
        teacherSubjectError: true,
        submitting: false,
      });
      isValidated = false;
    }

    if (isValidated) {
      let mobileNumber = null;
      if (countryCode && mobileNo) {
        mobileNumber = `+${countryCode}-${mobileNo}`;
      }

      let dataToSend = {
        email: teacherEmail,
        name: teacherName,
        location: teacherLocation,
        subject: teacherSubject,
        roomURL: roomURL,
        videoURL: videoURL,
        zoomCallDetails: zoomCallDetails,
        timeZone: teacherTimezone,
        classId: classId,
      };
      if (mobileNumber) {
        dataToSend["mobileNumber"] = mobileNumber;
      }
      let apiToCall = "teacher/admin-add-as-teacher";
      if (userId) {
        apiToCall = `teacher/admin-edit-teacher-details/${userId}`;
      }
      axiosPost(apiToCall, dataToSend)
        .then((response) => {
          if (!response.isError) {
            this.setState(
              {
                successModal: true,
                modalTitle: "Success",
                message: userId
                  ? "Teacher has been updated successfully"
                  : "Teacher has been added successfully",
                submitting: false,
                showAddTeacher: false,
                userId: "",
              },
              () => {
                this.getAllTeachers();
              }
            );
          } else {
            this.setState({
              errorModal: true,
              modalTitle: "Sorry!",
              message: response.message,
              submitting: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            errorModal: true,
            modalTitle: "Sorry!",
            message: error.message,
            submitting: false,
          });
        });
    }
  };

  // change password Modal control -
  showChangePasswordModal = (showChangePasswordModal, currentTeacher) => {
    this.setState({
      showChangePasswordModal: !showChangePasswordModal,
      currentTeacher: currentTeacher,
      submitting: false,
      teacherPassword: "",
      teacherConfirmPassword: "",
      teacherPasswordError: false,
      teacherConfirmPasswordError: false,
    });
  };

  //Change teacher password
  changePassword = () => {
    const {
      teacherConfirmPassword,
      teacherPassword,
      submitting,
      currentTeacher,
    } = this.state;
    let isValidated = true;
    this.setState({
      submitting: true,
      teacherPasswordError: false,
      teacherConfirmPasswordError: false,
    });

    if (!teacherPassword) {
      this.setState({
        teacherPasswordError: true,
        submitting: false,
      });
      isValidated = false;
    }

    if (!teacherConfirmPassword) {
      this.setState({
        teacherConfirmPasswordError: true,
        submitting: false,
      });
      isValidated = false;
    }

    if (teacherPassword != teacherConfirmPassword) {
      this.setState({
        teacherConfirmPasswordError: true,
        submitting: false,
      });
      isValidated = false;
    }

    if (isValidated) {
      let dataToSend = {
        password: teacherPassword,
      };

      axiosPost(
        `teacher/admin-change-teacher-password/${currentTeacher}`,
        dataToSend
      )
        .then((response) => {
          if (!response.isError) {
            this.setState(
              {
                successModal: true,
                modalTitle: "Success",
                message: "Teacher password has been changed successfully",
                submitting: false,
                showChangePasswordModal: false,
                currentTeacher: null,
              },
              () => [this.getAllTeachers()]
            );
          } else {
            this.setState({
              errorModal: true,
              modalTitle: "Sorry!",
              message: response.message,
              submitting: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            errorModal: true,
            modalTitle: "Sorry!",
            message: error.message
              ? error.message
              : "Something is wrong. Try again later.",
            submitting: false,
          });
        });
    }
  };

  //Delete Teacher
  deleteTeacher = (teacherId) => {
    let confirm = window.confirm("Are you sure you want to delete?");
    if (confirm) {
      axiosDelete(`teacher/admin-delete-teacher/${teacherId}`)
        .then((response) => {
          if (!response.isError) {
            this.setState(
              {
                successModal: true,
                modalTitle: "Success",
                message: "Teacher has been deleted successfully.",
              },
              () => {
                this.getAllTeachers();
              }
            );
          } else {
            this.setState({
              errorModal: true,
              modalTitle: "Sorry!",
              message: response.message
                ? response.message
                : "Something is wrong. Try again later.",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            errorModal: true,
            modalTitle: "Sorry!",
            message: error.message
              ? error.message
              : "Something is wrong. Try again later.",
          });
        });
    }
  };

  //redirect to URL
  redirectToUrl = (urlToRedirect) => {
    urlToRedirect =
      urlToRedirect.includes("http") || urlToRedirect.includes("https")
        ? urlToRedirect
        : `http://${urlToRedirect}`;
    window.open(`${urlToRedirect}`, "_blank");
    return false;
  };

  render() {
    let {
      newRequestList,
      teachersList,
      successModal,
      errorModal,
      modalTitle,
      message,
      listPage,
      totalListPage,
      newPage,
      totalNewPage,
      showAddTeacher,
      teacherEmail,
      teacherName,
      teacherLocation,
      teacherSubject,
      teacherEmailError,
      teacherNameError,
      teacherLocationError,
      teacherSubjectError,
      submitting,
      showChangePasswordModal,
      teacherPassword,
      teacherPasswordError,
      teacherConfirmPassword,
      teacherConfirmPasswordError,
      roomURL,
      videoURL,
      zoomCallDetails,
      userId,
      mobileNo,
      countryCode,
      timeZones,
      classList,
      classId,
      teacherTimezone,
    } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">New Teachers</h3>
                  <Button
                    style={{ float: "right" }}
                    color="primary"
                    type="button"
                    onClick={() => this.showAddTeacherModal(showAddTeacher)}
                  >
                    Add Teacher
                  </Button>
                </CardHeader>
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ marginBottom: "100px" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Location</th>
                      <th scope="col">Mobile No</th>
                      <th scope="col">Timezone</th>
                      <th scope="col">Status</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {newRequestList &&
                      newRequestList.map((value, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {value.name ? value.name : ""}
                                  </span>
                                </Media>
                              </Media>
                            </th>
                            <td>{value.email ? value.email : ""}</td>
                            <td>{value.subject}</td>
                            <td>{value.location ? value.location : ""}</td>
                            <td>
                              {value.mobileNumber ? value.mobileNumber : ""}
                            </td>
                            <td>{value?.timeZone ? value.timeZone : ""}</td>
                            <td>
                              <Badge color="" className="badge-dot mr-4">
                                <i className="bg-warning" />
                                {value.isVerified ? value.isVerified : ""}
                              </Badge>
                            </td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="JavaScript:Void(0);"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    href="JavaScript:Void(0);"
                                    onClick={() =>
                                      this.approveTeacherRequest(value.userId)
                                    }
                                  >
                                    Approve
                                  </DropdownItem>
                                  <DropdownItem
                                    href="JavaScript:Void(0);"
                                    onClick={() =>
                                      this.rejectTeacherRequest(value.userId)
                                    }
                                  >
                                    Reject
                                  </DropdownItem>
                                  <DropdownItem
                                    href="JavaScript:Void(0);"
                                    onClick={() =>
                                      this.viewProfile(value.userId)
                                    }
                                  >
                                    View Profile
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className={newPage > 1 ? "" : "disabled"}>
                        <PaginationLink
                          href="JavaScript:Void(0);"
                          onClick={() => this.previousPage("new")}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className="active">
                        <PaginationLink
                          href="JavaScript:Void(0);"
                          onClick={(e) => e.preventDefault()}
                        >
                          {newPage}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalNewPage > newPage ? "" : "disabled"}
                      >
                        <PaginationLink
                          href="JavaScript:Void(0);"
                          onClick={() => this.nextPage("new")}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
          {/* Dark table */}
          <Row className="mt-5">
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <h3 className="text-white mb-0">All Teachers</h3>
                </CardHeader>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Location</th>
                      <th scope="col">Mobile No</th>
                      <th scope="col">Password</th>
                      <th scope="col">Room URL</th>
                      <th scope="col">Video URL</th>
                      <th scope="col">Timezone</th>
                      <th scope="col">Status</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {teachersList &&
                      teachersList.map((value, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {value.name ? value.name : ""}
                                  </span>
                                </Media>
                              </Media>
                            </th>
                            <td>{value.email ? value.email : ""}</td>
                            <td>{value.subject}</td>
                            <td>{value.location ? value.location : ""}</td>
                            <td>
                              {value.mobileNumber ? value.mobileNumber : ""}
                            </td>
                            <td>{value.password ? value.password : "-"}</td>
                            <td>
                              {value.roomURL && (
                                <a
                                  href={"#"}
                                  onClick={() =>
                                    this.redirectToUrl(value.roomURL)
                                  }
                                >
                                  Go To Room URL
                                </a>
                              )}
                            </td>
                            <td>
                              {value.videoURL && (
                                <a
                                  href={"#"}
                                  onClick={() =>
                                    this.redirectToUrl(value.videoURL)
                                  }
                                >
                                  Go To Video URL
                                </a>
                              )}
                            </td>
                            <td>{value?.timeZone ? value.timeZone : ""}</td>
                            <td>
                              <Badge color="" className="badge-dot mr-4">
                                <i className="bg-success" />
                                {value.isVerified ? value.isVerified : ""}
                              </Badge>
                            </td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="JavaScript:Void(0);"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    href="JavaScript:Void(0);"
                                    onClick={() =>
                                      this.viewProfile(value.userId)
                                    }
                                  >
                                    View Profile
                                  </DropdownItem>

                                  <DropdownItem
                                    href="JavaScript:Void(0);"
                                    onClick={() =>
                                      this.showChangePasswordModal(
                                        showChangePasswordModal,
                                        value.userId
                                      )
                                    }
                                  >
                                    Change Password
                                  </DropdownItem>

                                  <DropdownItem
                                    href="JavaScript:Void(0);"
                                    onClick={() =>
                                      this.showAddTeacherModal(
                                        showAddTeacher,
                                        value
                                      )
                                    }
                                  >
                                    Edit
                                  </DropdownItem>

                                  <DropdownItem
                                    href="JavaScript:Void(0);"
                                    onClick={() =>
                                      this.deleteTeacher(value.userId)
                                    }
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        className={listPage > 1 ? "" : "disabled"}
                      >
                        <PaginationLink
                          href="JavaScript:Void(0);"
                          onClick={() => this.previousPage("list")}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          {listPage}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalListPage > listPage ? "" : "disabled"}
                      >
                        <PaginationLink
                          href="JavaScript:Void(0);"
                          onClick={() => this.nextPage("list")}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
              successModal
                ? this.toggleModal("successModal")
                : errorModal
                ? this.toggleModal("errorModal")
                : null
            }
          >
            <div className="modal-header">
              <h5 className="modal-title" id="successModalLabel">
                {modalTitle}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() =>
                  successModal
                    ? this.toggleModal("successModal")
                    : errorModal
                    ? this.toggleModal("errorModal")
                    : null
                }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            {this.state.successModal ? (
              <div className="modal-body" style={{ color: "green" }}>
                {message}
              </div>
            ) : this.state.errorModal ? (
              <div className="modal-body" style={{ color: "red" }}>
                {message}
              </div>
            ) : null}
            <div className="modal-footer">
              {/* <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("successModal")}
              >
                Close
            </Button> */}
              <Button
                color="primary"
                type="button"
                onClick={() =>
                  successModal
                    ? this.toggleModal("successModal")
                    : errorModal
                    ? this.toggleModal("errorModal")
                    : null
                }
              >
                Ok
              </Button>
            </div>
          </Modal>

          <Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={showAddTeacher}
            toggle={() => this.showAddTeacherModal(showAddTeacher)}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>{userId ? "Update Teacher" : "Add Teacher"}</small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form">
                    <FormGroup
                      className={teacherEmailError ? "has-danger" : ""}
                    >
                      <label>Email</label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          //readOnly={userId ? true : false}
                          placeholder="Email"
                          type="text"
                          name="teacherEmail"
                          value={teacherEmail}
                          onChange={(e) =>
                            this.setState({ teacherEmail: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className={teacherNameError ? "has-danger" : ""}>
                      <label>Name</label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Name"
                          type="text"
                          name="teacherName"
                          value={teacherName}
                          onChange={(e) =>
                            this.setState({ teacherName: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={teacherLocationError ? "has-danger" : ""}
                    >
                      <label>Location</label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Location"
                          type="text"
                          name="teacherLocation"
                          value={teacherLocation}
                          onChange={(e) =>
                            this.setState({ teacherLocation: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={teacherSubjectError ? "has-danger" : ""}
                    >
                      <label>Subject</label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Subject"
                          type="text"
                          name="teacherSubject"
                          value={teacherSubject}
                          onChange={(e) =>
                            this.setState({ teacherSubject: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup
                      className={teacherSubjectError ? "has-danger" : ""}
                    >
                      <div className="row">
                        <div className="col-md-4">
                          <label>Country Code</label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Country Code"
                              type="number"
                              name="countryCode"
                              value={countryCode}
                              onChange={(e) =>
                                this.setState({ countryCode: e.target.value })
                              }
                            />
                          </InputGroup>
                        </div>
                        <div className="col-md-8">
                          <label>Mobile Number</label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Mobile Number"
                              type="text"
                              name="mobileNo"
                              value={mobileNo}
                              onChange={(e) =>
                                this.setState({ mobileNo: e.target.value })
                              }
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <label>Timezone</label>
                      <div style={{ width: "100%", minWidth: "200px" }}>
                        <Select
                          style={{ width: "100%", minWidth: "200px" }}
                          options={timeZones}
                          placeholder="Select timezone"
                          value={
                            teacherTimezone
                              ? {
                                  value: teacherTimezone,
                                  label: teacherTimezone,
                                }
                              : teacherTimezone
                          }
                          onChange={(e) =>
                            this.setState({ teacherTimezone: e.value })
                          }
                          isSearchable={true}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label>Class</label>
                      <div style={{ width: "100%", minWidth: "200px" }}>
                        <Select
                          style={{ width: "100%", minWidth: "200px" }}
                          options={[
                            ...(classList &&
                                classList.map((item) => ({
                                  value: item.classId,
                                  label: item.name,
                                }))),
                          ]}
                          value={
                            classId && {
                                value: classId,
                                label: classList.find((item) => item.classId === classId).name,
                              }
                          }
                          placeholder="Select Class"
                          onChange={(e) =>
                            this.setState({ classId: e.value })
                          }
                          isSearchable={true}
                        />
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <label>Room URL</label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Room URL"
                          type="text"
                          name="roomURL"
                          value={roomURL}
                          onChange={(e) =>
                            this.setState({ roomURL: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <label>Video URL</label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Video URL"
                          type="text"
                          name="videoURL"
                          value={videoURL}
                          onChange={(e) =>
                            this.setState({ videoURL: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <label>Room Details</label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Room Details"
                          type="text"
                          name="videoDetail"
                          value={zoomCallDetails}
                          onChange={(e) =>
                            this.setState({ zoomCallDetails: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      {submitting ? (
                        <span style={{ color: "red" }}>Submitting...</span>
                      ) : null}

                      <Button
                        disabled={submitting ? true : false}
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={() => {
                          this.saveTeacher();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Modal>

          <Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={showChangePasswordModal}
            toggle={() => this.showChangePasswordModal(showChangePasswordModal)}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>Change Teacher Password</small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form">
                    <FormGroup
                      className={teacherPasswordError ? "has-danger" : ""}
                    >
                      <label>Password</label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Passsword"
                          type="password"
                          name="teacherPassword"
                          value={teacherPassword}
                          onChange={(e) =>
                            this.setState({ teacherPassword: e.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={
                        teacherConfirmPasswordError ? "has-danger" : ""
                      }
                    >
                      <label>Confirm Password</label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Confirm Password"
                          type="password"
                          name="teacherConfirmPassword"
                          value={teacherConfirmPassword}
                          onChange={(e) =>
                            this.setState({
                              teacherConfirmPassword: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                    </FormGroup>

                    <div className="text-center">
                      {submitting ? (
                        <span style={{ color: "red" }}>Submitting...</span>
                      ) : null}

                      <Button
                        disabled={submitting ? true : false}
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={() => {
                          this.changePassword();
                        }}
                      >
                        Change Password
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default Teachers;
