/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Container, Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import {axiosGet, axiosPost} from 'util/apiConfig.js';
import "./SalesNotification.css";
import moment from "moment/moment";
import {databaseDateTimeFormat, dateForPaymentListing, timeForMessage} from "../../util/constance";

class EmailError extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      allEmailErrorLogs: [],
      page: 1,
      totalPage: 0,
      limit: 10,
    };
  }




  componentDidMount() {
    const classId = localStorage.getItem("classId");
    this.setState(
      {
        classId: classId ? classId : null,
        allEmailErrorLogs: [],
      },
      () => {
        this.getAllEmailErrorLogs();
      }
    );
  }


  // for getting all approved student -
  getAllEmailErrorLogs = () => {
    const { page, limit, classId } = this.state;
    this.setState({ loading: false });
    let url = `message/get-email-error-logs/${page}/${limit}`;
    axiosGet(url)
      .then((response) => {
        if (!response.isError) {
          const allEmailErrorLogs = response.data?.result;
          this.setState({
            loading: false,
            allEmailErrorLogs: allEmailErrorLogs,
            totalPage: response.data?.noofpage,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // For changing page -
  changePage = (status) => {
    if (status && status === 'next') {
      this.setState({ page: this.state.page + 1 }, () => this.getAllEmailErrorLogs());
    } else if (status && status === 'prev') {
      this.setState({ page: this.state.page - 1 }, () => this.getAllEmailErrorLogs());
    }
  };



  render() {
    let { loading, allEmailErrorLogs, page, totalPage,} = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          {/* Table */}
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <h3 className='mb-0'>Email Error</h3>
                </CardHeader>
                <Table className='align-items-center table-flush' responsive>
                  <thead className='thead-light'>
                    <tr >
                      <th scope='col'>Email</th>
                      <th scope='col'>Reason</th>
                      <th scope='col'>Date</th>
                      <th scope='col' />
                    </tr>
                  </thead>
                  <tbody>
                    {allEmailErrorLogs &&
                      allEmailErrorLogs.length > 0 &&
                      allEmailErrorLogs.map((data, index) => {
                        return (
                        <React.Fragment key={index}>
                          <tr key={index}>
                             <td>{data.email && data.email}</td>
                             <td>{data.reason && data.reason}</td>
                             <td> {moment(data?.createdAt).format(
                                 'YYYY-MM-DD hh:mm a'
                             )}</td>
                          </tr>
                        </React.Fragment>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className='py-4'>
                  <nav aria-label='...'>
                    <Pagination
                      className='pagination justify-content-end mb-0'
                      listClassName='justify-content-end mb-0'
                    >
                      <PaginationItem className={page > 1 ? '' : 'disabled'}>
                        <PaginationLink
                          onClick={() => this.changePage('prev')}
                          tabIndex='-1'
                        >
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className='active'>
                        <PaginationLink onClick={(e) => e.preventDefault()}>
                          {page}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalPage > page ? '' : 'disabled'}
                      >
                        <PaginationLink onClick={() => this.changePage('next')}>
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
       </>

    );
  }
}

export default EmailError;
