/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Cookies from "js-cookie";
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Modal,
  Button,
} from "reactstrap";
import { axiosPost, axiosGet } from "util/apiConfig.js";
import { FaPhone, FaTimes } from 'react-icons/fa';
import {Device} from "@twilio/voice-sdk";
import "./AdminNavbar.css";


const USER_STATE = {
  CONNECTING : "Connecting",
  READY : "Ready",
  ON_CALL : "On call",
  OFFLINE : "Offline",
  INCOMING: "incoming"
}
class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      userEmail: null,
      showModal: false,
      loading: false,
      notifications: null,
      conn: null,
      callerName: null,
      // iphone ringtone url
      audioAlert: new Audio("https://fiverkidsmedia.s3.us-west-2.amazonaws.com/iphone-kling-laut-2503.mp3"),
    };
  }

  componentDidMount() {
    let storage = localStorage.getItem("Sanga_Admin");
    storage = storage ? JSON.parse(storage) : null;
    this.setState({
      userName: storage && storage.name,
      userEmail: storage && storage.email,
      userEmailInput: null,
    });
    this.getNotification();
    this.callInit();
    // setInterval(this.state.userState !== 'incoming' ? this.callInit : null, 200000);
    // setInterval(this.getNotification, 30000);
  }

  callInit = () => {
    axiosPost('sales/token', {})
        .then((response) => {
          if (!response.isError) {
            this.setState(
                {
                  loading: false
                },
                () => {
                  const token = response.data.token;
                  const device = new Device(token, {
                    logLevel: 1,
                    codecPreferences: ["opus", "pcmu"],
                    tokenRefreshMs: 10 * 60 * 1000, // Refresh token every 10 minutes
                  });

                  // Register the device
                  device.register();

                  // Set up event listeners
                  device.on("registered", () => {
                    console.log("Agent registered");
                    this.setState({ userState: USER_STATE.READY });
                  });

                  device.on("connect", (connection) => {
                    console.log("Call connect");
                    this.setState({ conn: connection, userState: USER_STATE.ON_CALL });
                  });

                  device.on("incoming", (connection) => {
                    console.log("Incoming event");
                    this.playAudioAlert();
                    const name = connection.customParameters || '';
                    const subscriberName = name ? name.get("subscriber_name") : '';
                    this.setState({ conn: connection, callerName: subscriberName || '', userState: USER_STATE.INCOMING });
                    connection.on("reject", () => {
                      this.stopAudioAlert();
                      this.setState({ conn: null, userState: USER_STATE.READY });
                    });
                    connection.on("disconnect", (connection) => {
                      console.log("Disconnect event");
                      // Handle disconnection here
                      this.stopAudioAlert();
                      this.setState({ conn: null, userState: USER_STATE.READY });
                    });
                    connection.on("cancel", (connection) => {
                      this.stopAudioAlert();
                      this.setState({ conn: null, userState: USER_STATE.READY });
                    });
                  });

                  device.on("disconnect", (connection) => {
                    console.log("Disconnect event");
                    this.stopAudioAlert();
                    // Handle disconnection here
                    this.setState({ conn: null, userState: USER_STATE.READY });
                  });

                  device.on("tokenWillExpire", () => {
                    console.log("Token expired. Refreshing token...");
                    // Refresh the token
                    this.refreshToken(device);
                  });

                  device.on("cancel", (connection) => {
                    this.stopAudioAlert();
                    this.setState({ conn: null, userState: USER_STATE.READY });
                  });

                  // Set the device in the component state
                  this.setState({ device });

                  return () => {
                    // Clean up when component unmounts
                    device.destroy();
                    this.setState({ device: undefined, userState: USER_STATE.OFFLINE });
                  };
                }
            );
          } else {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            errorModal: true,
            modalTitle: 'Sorry!',
            message: 'Something is wrong. Try again later.',
          });
          console.log(error);
        });
  }


  refreshToken = (device) => {
    axiosPost('sales/token', {})
        .then((response) => {
          if (!response.isError) {
            const token = response.data.token;
            console.log("Token refreshed successfully");
            device.updateToken(token); // Update the device with the new token
          } else {
            console.log("Failed to refresh token");
            // Handle token refresh failure
          }
        })
        .catch((error) => {
          console.log("Error refreshing token:", error);
          // Handle token refresh error
        });
  }

  acceptConnection = () => {
    this.stopAudioAlert();
    this.state.conn.accept();
  };

  rejectConnection = () => {
    this.stopAudioAlert();
    this.setState({conn: null, userState: USER_STATE.READY});
    this.state.conn.reject();
  };

  // Function to play the audio alert
  playAudioAlert = () => {
    try {
      const { audioAlert } = this.state;
      if (audioAlert.paused) {
        audioAlert.play().then(() => {
          console.log("Audio alert played");
        }).catch((error) => {
          console.log("Error playing audio alert", error);
        });
      }
    }catch (e) {
      console.log(e)
    }

  };

  stopAudioAlert = () => {
    try {
      const { audioAlert } = this.state;
      if (!audioAlert.paused) {
        audioAlert.pause();
        audioAlert.currentTime = 0; // Reset the audio to the beginning
      }
    }catch (e) {
      console.log(e)
    }
  };

  LogoutFunctionality = () => {
    localStorage.setItem("Sanga_Admin", null);
    Cookies.remove("token");
    this.props.history.push("/");
  };

  openModal = (showModal) => {
    this.setState({
      showModal: showModal,
      userEmailInput: null,
      successMessage: null,
      errorMessage: null,
      loading: false,
    });
  };

  emailUpdate = (e) => {
    let email = e.target.value;
    this.setState({
      userEmailInput: email,
    });
  };
  updateEmail = () => {
    const { userEmailInput } = this.state;
    if (userEmailInput) {
      let requestData = {
        email: userEmailInput,
      };

      axiosPost("user/update-admin-email", requestData)
        .then((response) => {
          if (!response.isError) {
            this.setState(
              {
                userEmail: userEmailInput,
                userEmailInput: null,
                loading: false,
                errorMessage: null,
                successMessage: "Successfully email updated.",
              },
              () => {
                //this.getTeacherAvailability(currentTeacher);
              }
            );
          } else {
            this.setState({
              loading: false,
              errorMessage: "Error while saving..",
              successMessage: null,
            });
          }
        })
        .catch((errorResponse) => {
          console.log("errorResponse", errorResponse);
        });
    }
  };

  //this will get notification of email services not working
  getNotification = () => {
    let url = "user/get-email-error-notification";
    axiosGet(url)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            notifications: response.data,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    let {
      userEmail,
      userEmailInput,
      successMessage,
      errorMessage,
      notifications,
    } = this.state;
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>
            {
                this.state.userState === USER_STATE.INCOMING &&
                <div className="notification-container">
                    <div className="notification-header">
                    {/*<FaPhone className="phone-icon" /> /!* Phone icon *!/*/}
                    <p className="caller-name">Incoming call from <span style={{fontWeight: 'bold'}}>{this.state.callerName || ''}</span> </p> {/* Caller
                     name */}
                    <FaTimes className="close-icon"  /> {/* Close icon for rejecting the call */}
                    </div>
                    <div className="notification-buttons">
                    <button className="accept-button" onClick={() => this.acceptConnection()}>Accept</button> {/* Accept button */}
                    <button className="reject-button" onClick={() => this.rejectConnection()}>Reject</button> {/* Reject button */}
                    </div>
                </div>
            }

            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("assets/img/brand/logo.png")}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.state.userName}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    href="JavaScript:Void(0);"
                    onClick={() => this.LogoutFunctionality()}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                  <DropdownItem href="JavaScript:Void(0);">
                    <i className="ni ni-email-83" />
                    <span>
                      {userEmail}
                      <Button
                        color="primary"
                        type="button"
                        style={{
                          padding: "0.425rem 1.0rem",
                          marginLeft: "5px",
                        }}
                        onClick={() => this.openModal(true)}
                      >
                        Change
                      </Button>
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>

            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showModal}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="successModalLabel">
                  Change Email
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.openModal(false)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="col-md-12 text-center">
                <input
                  placeholder="Enter Email Address"
                  className="emailInput form-control"
                  type="email"
                  name="email"
                  value={userEmailInput ? userEmailInput : userEmail}
                  onChange={this.emailUpdate}
                />
              </div>

              <div className="modal-footer">
                <span style={{ color: "green" }}>{successMessage}</span>
                <span style={{ color: "red" }}>{errorMessage}</span>
                <Button
                  color="primary"
                  type="button"
                  onClick={this.updateEmail}
                >
                  Save
                </Button>
              </div>
            </Modal>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
