import React from "react";
import {
    Card, CardBody, CardHeader,
    Container, FormGroup, Input, InputGroup, Media,
    Row, Table,
} from "reactstrap";
import Header from "../../components/Headers/Header.js";
import {axiosGet, axiosPost} from "../../util/apiConfig";



class TeacherReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
   const classId = localStorage.getItem("classId");
    this.setState(
      {
        allSessionData: [],
        classId: classId ? classId : null,
        filterFromDate: null,
        filterToDate: null,
        loading: false,
      },
      () => {
          this.getAllTeachersReports();
      }
    );
  }


 getAllTeachersReports = () => {
        let url = 'teacher/get-teacher-report';
        const { classId, filterFromDate, filterToDate } = this.state;
        if (classId) {
            url = `teacher/list-of-all-teacher/${classId}`;
        }
        if (filterFromDate && filterToDate) {
            url = `teacher/get-teacher-report?startDate=${filterFromDate}&endDate=${filterToDate}`;
        }
        this.setState({loading: true});
        axiosGet(url, {} )
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        loading: false,
                        teachersList: response.data || []
                    });
                } else {
                    this.setState({
                        loading: false,
                        teachersList: [],
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    teachersList: [],
                });
            });
    };

  render() {
    let {
    voiceMessageList,
    teachersList,
    filterFromDate,
    filterToDate,
    loading,
    } = this.state;

    return (
      <>
        <Header  />
        {/* Page content */}
         {/*Create two box in row*/}
        <Container fluid>
            <Row>
              <div className="col col-12 ">
                    <div className='col'>
                        <Card className='shadow' >
                            <CardHeader className='border-0'>
                                <h3 className='mb-0'>Teacher Report</h3>
                                <div className="row">
                                    <div
                                        className="col-md-4"
                                        style={{padding: "10px", marginTop: "22px"}}
                                    >
                                        <FormGroup className="availability-formgroup">
                                            <InputGroup
                                                style={{width: "135px"}}
                                                className="input-group-alternative"
                                            >
                                                <Input
                                                    placeholder="Date"
                                                    type="date"
                                                    name="filterFromDate"
                                                  // set max as current date and min date is before 2 years
                                                    max={new Date().toISOString().split('T')[0]}
                                                    min={new Date(new Date().setFullYear(new Date().getFullYear() - 2)).toISOString().split('T')[0]}
                                                    value={filterFromDate}
                                                    onChange={(e) =>
                                                        this.setState(
                                                            {
                                                                filterFromDate: e.target.value,
                                                                page: 1,
                                                            },
                                                            () => {
                                                                this.getAllTeachersReports();
                                                                // this.getAllSessions();
                                                            }
                                                        )
                                                    }
                                                />
                                            </InputGroup>
                                            <small style={{padding: "10px"}}> TO</small>
                                            <InputGroup
                                                style={{width: "135px"}}
                                                className="input-group-alternative"
                                            >
                                                <Input
                                                    placeholder="Date"
                                                    type="date"
                                                    name="filterToDate"
                                                    value={filterToDate}
                                                    max={new Date().toISOString().split('T')[0]}
                                                    min={new Date(new Date().setFullYear(new Date().getFullYear() - 2)).toISOString().split('T')[0]}
                                                    onChange={(e) =>
                                                        this.setState(
                                                            {
                                                                filterToDate: e.target.value,
                                                                page: 1,
                                                            },
                                                            () => {
                                                                this.getAllTeachersReports();
                                                                // this.getAllSessions();
                                                            }
                                                        )
                                                    }
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    className='align-items-center table-flush cp;'
                                    responsive
                                    style={{
                                        marginBottom: '100px',
                                        height: '500px',
                                        overflowY: 'scroll',
                                        display: 'block'
                                    }}
                                >
                                    <thead className='thead-light'>
                                    <tr>
                                        <th scope='col'></th>
                                        <th scope='col'>Name</th>
                                        <th scope='col'>Email</th>
                                        <th scope='col'>No-Show Class</th>
                                        <th scope='col'>Send Reminder</th>
                                        <th scope='col'/>
                                    </tr>
                                    {
                                        loading ? <tr> <td colSpan={5}>Loading...</td> </tr> : (
                                            teachersList && teachersList.length > 0 ? teachersList.map((teacher, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                        </td>
                                                        <td>{teacher?.name}</td>
                                                        <td>{teacher?.email}</td>
                                                        <td>{teacher?.noShowCount || 0}</td>
                                                        <td>{teacher?.cancelSessionCount || 0}</td>
                                                        <td></td>

                                                    </tr>
                                                );
                                            }) : <tr>
                                                <td colSpan={5}>No Data Found</td>
                                            </tr>
                                        )
                                    }
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </div>
              </div>
            </Row>
        </Container>
      </>
    );
  }
}

export default TeacherReport;
