/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// reactstrap components
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import routes from 'routes.js';

class Admin extends React.Component {
  componentDidMount() {
    let storage = localStorage.getItem('Sanga_Admin');
    storage = storage ? JSON.parse(storage) : null;

    if (storage) {
      // if (storage.userType === 'admin') {
      //   this.props.history.push({
      //     pathname: '/admin/index',
      //     state: {},
      //   });
      // } else if (storage.userType === 'sales') {
      //   this.props.history.push({
      //     pathname: '/admin/sales-notification',
      //     state: {},
      //   });
      // }
    } else {
      this.props.history.push({
        pathname: '/auth',
        state: {},
      });
    }
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  getRoutes = (routes, userRole) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin' && prop.roles.includes(userRole)) {
        return (
            <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
            />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };

  render() {
    const userRole = localStorage.getItem('userRole');
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes.filter(route => route.roles.includes(userRole))}
          // logo={{
          //   innerLink: '/admin/index',
          //   imgSrc: require('assets/img/brand/argon-react.png'),
          //   imgAlt: '...',
          // }}
        />
        <div className='main-content' ref='mainContent'>
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes, userRole)}
            userRole === 'admin' ? (
            <Redirect from='*' to='/admin/listing' />
            ) : userRole === 'sub_admin' ? (
            <Redirect from='*' to='/admin/listing' />
            ): userRole === 'sales' ?  (
            <Redirect from='*' to='/admin/sales-notification' />
            ) : userRole === 'support' ? (
            <Redirect from='*' to='/admin/message' />
            ): userRole === 'sales_admin' ? (
            <Redirect from='*' to='/admin/sales-followup' />
            ): (
            <Redirect from='*' to='/auth' />
            )
          </Switch>
        </div>
      </>
    );
  }
}

export default Admin;
