export const timeForMessage = "hh:mma";
export const databaseDateTimeFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
export const databaseTimeFormat = "hh:mm A";
export const dateWithCardinalDate = "D MMMM";
export const dateForPaymentListing = "MM-DD-YYYY";
export const publicSection = 1;
export const privateSection = 2;
export const halfClass = "HALF";
export const fullClass = "FULL";
export const classStatus = [
  "Complete",
  "Last Min Cancel",
  "Student on Leave",
  "Makeup",
  "Coach On Leave",
  'No Show Class',
];

export const classStatusWithCount = [
  { status: "Complete", count: 0 },
  { status: "Last Min Cancel", count: 0 },
  { status: "Student on Leave", count: 0 },
  { status: "Makeup", count: 0 },
  { status: "Coach On Leave", count: 0 },
  { status: "upcoming", count: 0 },
  { status: "cancelled", count: 0 },
];
