
import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Modal,
  Button,
  Form,
  FormGroup,
  InputGroup,
  Input,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import { axiosGet, axiosPost } from 'util/apiConfig.js';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';




class TopicsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageRefs: [React.createRef(null)],
      imageRefNew: [React.createRef(null)],
      loading: false,
      topicError: false,
      descriptionError: false,
      classWorkLinkError: false,
      homeWorkLinkError: false,
      topicList: [],
      editModal: false,
      modalTitle: '',
      successModal: false,
      errorModal: false,
      message: '',
      bannerUrl: '',
      homeWorkLink: [{ link: '', image: '' }],
      classWorkLink: [{ link: '', image: '' }],
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    const { topicList } = this.state;
    if (!result.destination) return;
    const items = Array.from(topicList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const updatedOrder = items.map((item, index) => {
      return { topicId: item.topicId, index };
    });
    this.updateIndex(updatedOrder);
    this.setState({ topicList: items });
  }

  updateIndex = (updatedOrder) => {
    // call post api for the updated order
    axiosPost('curriculum/update-topic-index', {topics: updatedOrder}).then((response) => {
      if (!response.isError) {
        this.setState({
          message: 'Index Updated Successfully',
          successModal: true,
        });
      } else {
        this.setState({
          message: response.message,
          errorModal: true,
        });
      }
    });
  }

  componentDidMount() {
    const { chapterId, curriculumId } = this.props.location.state;
    this.setState(
      {
        topicList: [],
        chapterId: chapterId,
        curriculumId: curriculumId,
      },
      () => {
        this.getTopicsList(chapterId);
      }
    );
  }

  // to get new registered teacher's list -
  getTopicsList = (chapterId) => {
    this.setState({ loading: true });
    // API -
    axiosGet(`curriculum/get-topic/${chapterId}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            topicList: response.data,
            loading: false,
          });
        } else {
          this.setState({
            topicList: [],
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          topicList: [],
          loading: false,
        });
      });
  };

  addTopicForm = () => {
    this.resetForm();
    this.setState({
      topicModal: true
    });
  }

  resetForm = () => {
    this.setState({
      loading: false,
      bannerUrl: '',
      topicName: '',
      description: '',
      topicError: false,
      topicNameError: false,
      descriptionError: false,
      classWorkLinkError: false,
      homeWorkLinkError: false,
      isVisible: false,
      classWorkLink: [{ link: '', image: '' }],
      homeWorkLink: [{ link: '', image: '' }],
    });
  };

  //to edit room -
  editChapter = (index) => {
    let { topicList } = this.state;
    let selectedTopic = topicList[index];
    this.setState({
      editModal: true,
      bannerUrl: selectedTopic.topicImage,
      topicName: selectedTopic.name,
      description: selectedTopic.description,
      classWorkLink: selectedTopic.classWorkLink,
      homeWorkLink: selectedTopic.homeWorkLink,
      selectedTopic: selectedTopic,
    }, () => {
      let { classWorkLink, homeWorkLink } = this.state;
      let { imageRefNew, imageRefs } = this.state;
      imageRefNew = [];
      imageRefs = [];
      for (let i = 0; i < classWorkLink.length; i++) {
        imageRefNew.push(React.createRef(null));
      }
      for (let i = 0; i < homeWorkLink.length; i++) {
        imageRefs.push(React.createRef(null));
      }
      this.setState({ imageRefNew, imageRefs });
    });
  };

  addOrUpdateTopic = (isCalledForEditChapter) => {
    let {
      topicName,
      description,
      curriculumId,
      chapterId,
      selectedTopic,
      classWorkLink,
      bannerUrl,
      homeWorkLink,
    } = this.state;
    !topicName
        ? this.setState({ topicError: true })
        : this.setState({ topicError: false });
    !description
        ? this.setState({ descriptionError: true })
        : this.setState({ descriptionError: false });
    !classWorkLink
        ? this.setState({ classWorkLinkError: true })
        : this.setState({ classWorkLinkError: false });
    !homeWorkLink
        ? this.setState({ homeWorkLinkError: true })
        : this.setState({ homeWorkLinkError: false });
    if (topicName && description) {
      let dataToSend = {
        name: topicName,
        description,
        curriculumId,
        classWorkLink,
        homeWorkLink,
        chapterId,
        topicImage: bannerUrl,
      };
      if (isCalledForEditChapter) {
        dataToSend = { ...dataToSend, ...{ topicId: selectedTopic.topicId} };
      }
      const endPointUrl = isCalledForEditChapter
          ? 'curriculum/edit-topic'
          : 'curriculum/add-topic';
      axiosPost(`${endPointUrl}`, dataToSend)
          .then((response) => {
            this.setState({
              editModal: false,
              selectedTopic: '',
              topicModal: false,
              loading: false,
            });
            if (!response.isError) {
              this.setState(
                  {
                    successModal: true,
                    modalTitle: 'Success',
                    message: 'Successfully saved.',
                  },
                  () => {
                    this.componentDidMount();
                    this.resetForm();
                  }
              );
            } else {
              this.setState(
                  {
                    errorModal: true,
                    modalTitle: 'Sorry!',
                    message: response.message,
                  },
                  () => {
                    this.resetForm();
                  }
              );
            }
          })
          .catch((errorResponse) => {
            this.setState(
                {
                  editModal: false,
                  selectedTopic: '',
                  topicModal: false,
                  errorModal: true,
                  loading: false,
                  modalTitle: 'Sorry!',
                  message: errorResponse.message,
                },
                () => {
                  this.resetForm();
                }
            );
          });
    }
  };


  //delete record -
  deleteTopic = (index) => {
    let { topicList, chapterId } = this.state;
    let selectedTopic = topicList[index];
    let dataToSend = {
      topicId: selectedTopic.topicId,
    };
    this.setState({ loading: true });
    // API -
    axiosPost('curriculum/delete-topic', dataToSend)
      .then((response) => {
        if (!response.isError) {
          this.setState(
            {
              loading: false,
              successModal: true,
              modalTitle: 'Success',
              message: 'Successfully deleted',
            },
            () => {
              this.getTopicsList(chapterId);
            }
          );
        } else {
          this.setState({
            loading: false,
            errorModal: true,
            modalTitle: 'Sorry!',
            message: 'Something is wrong. Try again later.',
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errorModal: true,
          modalTitle: 'Sorry!',
          message: 'Something is wrong. Try again later.',
        });
        console.log(error);
      });
  };

  openSectionPage = (index) => {
    const { curriculumId } = this.props.location.state;
    this.props.history.push({
      pathname: '/admin/chapters',
      state: { curriculumId: curriculumId },
    });
  };


  // Modal control -

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      modalTitle: '',
      message: '',
    });
    if (state === 'topicModal' || state === 'editModal') {
      this.resetForm();
    } else if (state === 'topicModal') {
      this.setState({
        roomName: '',
        roomUrl: '',
      });
    }
  };

  fileChange = (e, index, type) => {
    const { imageRefNew, imageRefs } = this.state;
    let file = type === 'classWork' ?  imageRefNew[index].current && imageRefNew[index].current.files
        && imageRefNew[index].current.files[0] :
        imageRefs[index].current && imageRefs[index].current.files && imageRefs[index].current.files[0];
    let blnValid = false;
    let sizeValid = false;

    if (file) {
      let sizeInByte = file.size;
      let sizeInKB = parseFloat(parseFloat(sizeInByte) / 1024).toFixed('2');
      let sFileName = file.name;
      sizeInKB <= 500 ? (sizeValid = true) : (sizeValid = false);

      var _validFileExtensions = ['.jpg', '.png', '.jpeg'];
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
            sFileName
                .substr(
                    sFileName.length - sCurExtension.length,
                    sCurExtension.length
                )
                .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

        if (blnValid) {
          this.setState({ uploading: true });
          const data = new FormData();
          data.append('file', file);

          axiosPost(`utility/upload-file`, data, true)
              .then((response) => {
                if (!response.isError) {
                  this.setState((prevState) => {
                    const links = type === 'homework' ? prevState.homeWorkLink : prevState.classWorkLink;
                    links[index]['image'] = response.data?.fileUrl;
                    return { links };
                  })

                  this.setState({
                    uploading: false,
                    successModal: true,
                    message: 'Successfully uploaded',
                    modalTitle: 'Success',
                  });
                } else {
                  this.setState({
                    uploading: false,
                    errorModal: false,
                    message: "Sorry! can't be uploaded.",
                    modalTitle: 'Sorry!',
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  uploading: false,
                  errorModal: false,
                  message: "Sorry! can't be uploaded.",
                  modalTitle: 'Sorry!',
                });
              });
        } else {
          this.setState({
            errorModal: true,
            message: 'File format is not supported',
            modalTitle: 'Sorry',
          });
        }
      // } else {
      //   this.setState({
      //     errorModal: true,
      //     message: 'File size is exceeded',
      //     modalTitle: 'Sorry',
      //   });
      // }
    }
  };

  handleAddLink = (type) => {
    this.setState((prevState) => ({
      imageRefNew: [...prevState.imageRefNew, React.createRef()],
    }));
    this.setState((prevState) => ({
      imageRefs: [...prevState.imageRefs, React.createRef()],
    }));
    this.setState((prevState) => {
      const newLink = { link: '', image: '' };
      const links = type === 'homework' ? prevState.homeWorkLink : prevState.classWorkLink;
      links.push(newLink);
      return { links,  };
    });
  };

  handleRemoveLink = (index, type) => {
    this.setState((prevState) => ({
      imageRefNew: prevState.imageRefNew.filter((_, i) => i !== index),
    }));
    this.setState((prevState) => ({
      imageRefs: prevState.imageRefs.filter((_, i) => i !== index),
    }));
    this.setState((prevState) => {
      const links = type === 'homework' ? prevState.homeWorkLink : prevState.classWorkLink;
      links.splice(index, 1);
      return { links };
    });
  };

  handleInputChange = (e, index, type) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      console.log(name, value, prevState.homeWorkLink);
      const links = type === 'homework' ? prevState.homeWorkLink : prevState.classWorkLink;
      links[index][name] = value;
      return { links };
    });
  };


  render() {
    const { chapterId } = this.props.location.state;
    let {
      loading,
      topicList,
      descriptionError,
      classWorkLinkError,
      homeWorkLinkError,

      uploading,
      editModal,
      topicModal,
      topicError,
      topicName,
      successModal,
      errorModal,
      modalTitle,
      message,
      description,
      classWorkLink,
      homeWorkLink,
        imageRefNew,
        imageRefs,
    } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          <Button color='primary' onClick={() => this.addTopicForm()}>
            Add Topic
          </Button>

          <Button color='primary'  onClick={() => this.openSectionPage(chapterId)}>
            Go to Chapters
          </Button>
          <br />

          <br />
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <h3 className='mb-0'>Topics List</h3>
                </CardHeader>
                <DragDropContext onDragEnd={this.onDragEnd}>
                <Table
                  className='align-items-center table-flush'
                  responsive
                  style={{ marginBottom: '100px' }}
                >
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Name</th>
                      <th scope='col'>Description</th>
                      <th scope='col'>Class Work Links</th>
                      <th scope='col'>Home Work Links</th>
                      <th scope='col' />
                    </tr>
                  </thead>
                  <Droppable droppableId="topicList">
                    {(provided) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {topicList &&
                      topicList.map((value, index) => {
                        return (
                            <Draggable key={value.index} draggableId={value.topicId} index={index}>
                              {(provided) => (
                          <tr key={index} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                            <th scope='row'>
                              <Media className='align-items-center'>
                                <Media>
                                  <span className='mb-0 text-sm'>
                                    {value.name ? value.name : ''}
                                  </span>
                                </Media>
                              </Media>
                            </th>

                            <td>{value?.description ? value.description : ''}</td>


                            <td>
                              {value.classWorkLink &&
                                  value.classWorkLink.map((value, index) => {
                                    return (
                                        <span key={index}>  {  value?.link  || '' } </span>
                                    );
                                    })}
                            </td>

                            <td>
                              {value.homeWorkLink &&
                                  value.homeWorkLink.map((value, index) => {
                                    return (
                                        <span key={index}>   {  value?.link || ''} </span>
                                    );
                                  })}
                            </td>

                            <td className='text-right'>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className='btn-icon-only text-light'
                                  href='JavaScript:Void(0);'
                                  role='button'
                                  size='sm'
                                  color=''
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className='fas fa-ellipsis-v' />
                                </DropdownToggle>
                                <DropdownMenu
                                  className='dropdown-menu-arrow'
                                  right
                                >
                                  <DropdownItem
                                    href='JavaScript:Void(0);'
                                    onClick={() => this.editChapter(index)}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    href='JavaScript:Void(0);'
                                    onClick={() => this.deleteTopic(index)}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                              )}
                            </Draggable>
                        );
                      })}
                  </tbody>
                    )}
                  </Droppable>
                </Table>
                </DragDropContext>

                <CardFooter className='py-4'>
                </CardFooter>
              </Card>
            </div>
          </Row>


        </Container>

        <Modal
            className='modal-dialog-centered'
            size='lg'
            isOpen={topicModal || editModal}
            toggle={() =>
                this.toggleModal(topicModal ? 'topicModal' : 'editModal')
            }
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0'>
              <CardHeader className='bg-transparent pb-5'>
                <div className='text-muted text-center mt-2 mb-3'>
                  <small>{topicModal ? 'Add new Topic' : 'Edit Topic'}</small>
                </div>
              </CardHeader>
              <CardBody className='px-lg-5 py-lg-5'>
                <Form role='form'>
                  <FormGroup className={topicError ? 'has-danger' : ''}>
                    <label>Topic Name</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                          placeholder='Topic name'
                          type='text'
                          name='topicName'
                          value={topicName}
                          onChange={(e) =>
                              this.setState({ topicName: e.target.value })
                          }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={descriptionError ? 'has-danger' : ''}>
                    <label>Description</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                          placeholder='Topic details'
                          type='textarea'
                          rows='3'
                          name='description'
                          value={description}
                          onChange={(e) =>
                              this.setState({ description: e.target.value })
                          }
                      />
                    </InputGroup>
                  </FormGroup>





                  <div style={{border: '1px solid #d68e8e',borderRadius: '5px' ,padding: '10px' }}>
                    {classWorkLink.map((link, index) => (
                        <div key={index} className="homework-box" >
                          <FormGroup>
                            <label>Class Work Link {index + 1}</label>
                            <Input
                                type="text"
                                name="link"
                                placeholder="Class Work Link (Enter with new Link)"
                                value={link.link}
                                onChange={(e) => this.handleInputChange(e, index, 'classWork')}
                            />
                          </FormGroup>
                          <FormGroup>
                                <>
                                  <br />
                                  <span>
                                  {
                                      link?.image &&
                                      <img
                                          width='100px'
                                          alt="..."
                                          src={link.image}
                                      />
                                  }
                               </span>
                                </>
                            <InputGroup className='input-group-alternative'>
                              <input
                                  key={index}
                                  type='file'
                                  name={`image-${index}`}
                                  id={`image-${index}`}
                                  ref={imageRefNew[index]}
                                  onChange={(e) => this.fileChange(e, index, 'classWork')}
                                  multiple={false}
                              />
                            </InputGroup>
                          </FormGroup>

                          <div>
                            <Button
                                style={{ float: 'right', marginTop: '13px', marginLeft: '10px', marginBottom: '10px' }}
                                color="danger"
                                onClick={() => this.handleRemoveLink(index, 'classWork')}
                                className="remove-button"
                            >
                              <i className={'fa fa-trash'}></i>
                            </Button>
                          </div>

                        </div>
                    ))}
                    <Button style={{float: 'right',  marginTop: '13px', marginLeft: '10px' }} color="primary" onClick={() => this.handleAddLink('classWork')}>
                      <i className={'fa fa-plus'}></i>
                    </Button>
                  </div>

                  <div style={{border: '1px solid #96bcdd',borderRadius: '5px' ,padding: '10px', marginTop: '50px' }}>
                    {homeWorkLink.map((link, index) => (
                        <div key={index}  >
                          <FormGroup>
                            <label>Homework Link {index + 1}</label>
                            <Input
                                type="text"
                                name="link"
                                placeholder="Homework Link (Enter with new Link)"
                                value={link.link}
                                onChange={(e) => this.handleInputChange(e, index, 'homework')}
                            />
                          </FormGroup>
                          <FormGroup>
                              <>
                                <br />
                                <span>
                                {
                                    link?.image &&
                                    <img
                                        width='100px'
                                        alt="..."
                                        src={link.image}
                                    />
                                }
                             </span>
                              </>
                            <InputGroup className='input-group-alternative'>
                              <input
                                  key={index}
                                  name={`image-${index}`}
                                  id={`image-${index}`}
                                  type='file'
                                  ref={imageRefs[index]}
                                  onChange={(e) => this.fileChange(e, index, 'homework')}
                                  multiple={false}
                              />
                            </InputGroup>
                          </FormGroup>

                          <div>
                            <Button
                                style={{ float: 'right',marginTop: '13px', marginLeft: '10px', marginBottom: '10px' }}
                                color="danger"
                                onClick={() => this.handleRemoveLink(index, 'homework')}
                                className="remove-button"
                            >
                              <i className={'fa fa-trash'}></i>
                            </Button>
                          </div>

                        </div>
                    ))}
                    <Button style={{float: 'right', marginTop: '13px', marginLeft: '10px' }} color="primary" onClick={() => this.handleAddLink('homework')}>
                      <i className={'fa fa-plus'}></i>
                    </Button>
                  </div>



                  <div className='text-center'>
                    {uploading ? (
                        <span style={{ color: 'red' }}>Wait...</span>
                    ) : null}

                    <Button
                        disabled={uploading || loading ? true : false}
                        className='my-4'
                        color='primary'
                        type='button'
                        onClick={() => {
                          this.addOrUpdateTopic(topicModal ? false : true);
                        }}
                    >
                      {topicModal ? 'Save' : 'Update'}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>

        <Modal
            className='modal-dialog-centered'
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
                successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                        ? this.toggleModal('errorModal')
                        : null
            }
        >
          <div className='modal-header'>
            <h5 className='modal-title' id='successModalLabel'>
              {modalTitle}
            </h5>
            <button
                aria-label='Close'
                className='close'
                data-dismiss='modal'
                type='button'
                onClick={() =>
                    successModal
                        ? this.toggleModal('successModal')
                        : errorModal
                            ? this.toggleModal('errorModal')
                            : null
                }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          {this.state.successModal ? (
              <div className='modal-body' style={{ color: 'green' }}>
                {message}
              </div>
          ) : this.state.errorModal ? (
              <div className='modal-body' style={{ color: 'red' }}>
                {message}
              </div>
          ) : null}
          <div className='modal-footer'>
            <Button
                color='primary'
                type='button'
                onClick={() =>
                    successModal
                        ? this.toggleModal('successModal')
                        : errorModal
                            ? this.toggleModal('errorModal')
                            : null
                }
            >
              Ok
            </Button>
          </div>
        </Modal>

      </>
    );
  }
}

export default TopicsManagement;
