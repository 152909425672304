/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// reactstrap components
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Container, Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import {axiosGet, axiosPost} from 'util/apiConfig.js';
import {databaseDateTimeFormat, dateForPaymentListing} from 'util/constance';
import moment from 'moment-timezone';
import Select from "react-select";
import {fetchTimeZones} from "../../util/timeZones";
import "./SalesNotification.css";

class BeltUpdateSection extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      allUserBeltUpdate: [],
      page: 1,
      totalPage: 0,
      limit: 10,
      successModal: false,
      successMessage: '',
      errorModal: false,
      errorMessage: '',
      teachersList: [],
    };
  }




  componentDidMount() {
    const classId = localStorage.getItem("classId");
    this.setState(
      {
        classId: classId ? classId : null,
        allUserBeltUpdate: [],
      },
      () => {
        this.getAllUserBeltUpdate();
        this.getAllTeachers();
      }
    );
  }

  getAllTeachers = () => {
    let teachers = [{ value: null, label: "All Coaches" }];
    axiosGet(`teacher/list-of-all-teacher`)
        .then((response) => {
          if (!response.isError) {
            let data = response.data?.result || [];
            data.map((_data) => {
              teachers.push({ value: _data.teacherId, label: _data.name });
            });
            this.setState({
              teachersList: teachers,
            });
          } else {
            this.setState({
              teachersList: teachers,
            });
          }
        })
        .catch((error) => {
          this.setState({
            teachersList: teachers,
          });
        });
  };


  // for getting all approved student -
  getAllUserBeltUpdate = () => {
    const { page, limit, classId, filterTeacher } = this.state;
    let url = `user/get-student-belt-update/${page}/${limit}`;
    if (classId) {
        url = `user/get-student-belt-update/${page}/${limit}?classId=${classId}`;
    }
    if (filterTeacher) {
      const concatString = url.includes('?') ? '&' : '?';
      url = `${url}${concatString}teacherId=${filterTeacher}`;
    }
    this.setState({ loading: false });
    axiosGet(url)
      .then((response) => {
        if (!response.isError) {
          const allUserBeltUpdate = response.data?.result;
          this.setState({
            loading: false,
            allUserBeltUpdate: allUserBeltUpdate,
            totalPage: response.data?.noofpage,
          });
        } else {
          this.setState({ loading: false,  allUserBeltUpdate: [],
            totalPage: 0, });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // For changing page -
  changePage = (status) => {
    if (status && status === 'next') {
      this.setState({ page: this.state.page + 1 }, () => this.getAllUserBeltUpdate());
    } else if (status && status === 'prev') {
      this.setState({ page: this.state.page - 1 }, () => this.getAllUserBeltUpdate());
    }
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      modalTitle: "",
      message: "",
    });
  };


  render() {
    let { loading, allUserBeltUpdate, page, totalPage, timeZones,  message,
      successMessage,
      successModal,
      modalTitle,
      teachersList,
      errorModal, } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--7' fluid>
          {/* Table */}
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <h3 className='mb-0'>User Belts Track</h3>
                  <div className="col-md-4 text-center" style={{ float: "left", marginTop: '20px' }}>
                    <Select
                        options={teachersList}
                        placeholder="All Coaches"
                        isSearchable={true}
                        onChange={(e) => {
                          this.setState(
                              { filterTeacher: e.value, page: 1 },
                              () => {
                                this.getAllUserBeltUpdate()
                                // this.getAllPrivateSection();
                              }
                          );
                        }}
                    />
                  </div>
                </CardHeader>
                <Table className='align-items-center table-flush' responsive>
                  <thead className='thead-light'>
                    <tr >
                      <th scope='col'>Section Name</th>
                      <th scope='col'>Belt Status</th>
                      <th scope='col'>Coach Name</th>
                      <th scope='col'>Updated</th>
                      <th scope='col'>Date of join</th>
                      <th scope='col' />
                    </tr>
                  </thead>
                  <tbody>
                    {allUserBeltUpdate &&
                      allUserBeltUpdate.length > 0 &&
                      allUserBeltUpdate.map((data, index) => {
                        return (
                        <React.Fragment key={index}>
                          <tr key={index} >
                          <td>{data.sectionName || ''}</td>
                          <td>{data.belt && data.belt}</td>
                          <td>{data.teacherName && data.teacherName}</td>
                          <td>{data.createdAt &&
                              moment(data.createdAt).format(
                                  dateForPaymentListing
                              )}</td>
                          <td>{data.dateOfJoin &&
                              moment(data.dateOfJoin).format(
                                  dateForPaymentListing
                              )}</td>
                          </tr>
                        </React.Fragment>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className='py-4'>
                  <nav aria-label='...'>
                    <Pagination
                      className='pagination justify-content-end mb-0'
                      listClassName='justify-content-end mb-0'
                    >
                      <PaginationItem className={page > 1 ? '' : 'disabled'}>
                        <PaginationLink
                          onClick={() => this.changePage('prev')}
                          tabIndex='-1'
                        >
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem className='active'>
                        <PaginationLink onClick={(e) => e.preventDefault()}>
                          {page}
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem
                        className={totalPage > page ? '' : 'disabled'}
                      >
                        <PaginationLink onClick={() => this.changePage('next')}>
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
       </>

    );
  }
}

export default BeltUpdateSection;
