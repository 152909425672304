
import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Container,
  Row,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroup,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import { axiosGet, axiosPost } from 'util/apiConfig.js';
import DescriptionDetail from 'util/DescriptionDetails';
import Select from "react-select";



class Curriculum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: 1,
      totalPage: 0,
      limit: 10,
      categoryList: [],
      classList: [],
      curriculumList: [],
      modalTitle: '',
      successModal: false,
      errorModal: false,
      message: '',
    };
  }

  componentDidMount() {
    const classId = localStorage.getItem("classId");
    this.setState(
        {
          globalClassId: classId ? classId : null,
          curriculumList: [],
        },
        () => {
          this.getAllCurriculum();
          this.getClass();
        }
    );
  }

   getClass = () => {
        // API --
        axiosGet(`class/get-classes-list`)
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        classList: response.data || [],
                    });
                } else {
                    this.setState({
                        classList: [],
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    classList: [],
                });
            });
    };

  getAllCurriculum = () => {
    const { page, limit, globalClassId } = this.state;
    let url = `curriculum/get-curriculum/${page}/${limit}`;
    if (globalClassId) {
        url = `curriculum/get-curriculum/${page}/${limit}?classId=${globalClassId}`;
    }
    axiosGet(url)
        .then((response) => {
          if (!response.isError) {
            this.setState({
              curriculumList: response.data?.result || [],
              totalPage: response.data?.noofpage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };


  // For changing page -
  changePage = (status) => {
    if (status && status === 'next') {
      this.setState({ page: this.state.page + 1 }, () => this.getAllCurriculum());
    } else if (status && status === 'prev') {
      this.setState({ page: this.state.page - 1 }, () => this.getAllCurriculum());
    }
  };

  addCurriculumForm = () => {
    this.setState({
       curriculumModal: true,
    });
  };


  addOrUpdateCurriculum = (isCalledForEditClass) => {
    let {
      curriculumName,
      description,
      selectedCurriculum,
       classId,
    } = this.state;
    !curriculumName
        ? this.setState({ curriculumError: true })
        : this.setState({ curriculumError: false });
    !description
        ? this.setState({ descriptionError: true })
        : this.setState({ descriptionError: false });
    if (curriculumName && description ) {
      let dataToSend = {
        name: curriculumName,
        classId: classId,
        description
      };

      if (isCalledForEditClass) {
            dataToSend = { ...dataToSend, ...{ curriculumId: selectedCurriculum.curriculumId} };
      }

      const endPointUrl = isCalledForEditClass
          ? 'curriculum/edit-curriculum'
          : 'curriculum/add-curriculum';
      axiosPost(`${endPointUrl}`, dataToSend)
          .then((response) => {
            this.setState({
              editModal: false,
              selectedClass: '',
              curriculumModal: false,
              loading: false,
            });
            if (!response.isError) {
              this.setState(
                  {
                    successModal: true,
                    modalTitle: 'Success',
                    message: 'Successfully saved.',
                  },
                  () => {
                    this.getAllCurriculum();
                    this.resetForm();
                  }
              );
            } else {
              this.setState(
                  {
                    errorModal: true,
                    modalTitle: 'Sorry!',
                    message: response.message,
                  },
                  () => {
                    this.resetForm();
                  }
              );
            }
          })
          .catch((errorResponse) => {
            this.setState(
                {
                  editModal: false,
                  selectedClass: '',
                  curriculumModal: false,
                  errorModal: true,
                  loading: false,
                  modalTitle: 'Sorry!',
                  message: errorResponse.message,
                },
                () => {
                  this.resetForm();
                }
            );
          });
    }



  };

  editCurriculum = (index) => {
      const { curriculumList } = this.state;
      const selectedCurriculum = curriculumList[index];
      this.setState({
          selectedCurriculum,
          classId: selectedCurriculum.classId,
          curriculumName: selectedCurriculum.name,
          description: selectedCurriculum.description,
          editModal: true,
      });
  };

  openSectionPage = (index) => {
    const { curriculumList } = this.state;
    const selectedCurriculumId= curriculumList[index].curriculumId;
    this.props.history.push({
      pathname: '/admin/chapters',
      state: { curriculumId: selectedCurriculumId },
    });
  };

  deleteCurriculum = (index) => {
    let { curriculumList } = this.state;
    let selectedCurriculum = curriculumList[index];

    let dataToSend = {
     curriculumId: selectedCurriculum.curriculumId,
    };

    axiosPost('curriculum/delete-curriculum', dataToSend)
        .then((response) => {
          if (!response.isError) {
            this.setState(
                {
                  successModal: true,
                  modalTitle: 'Success',
                  message: 'Successfully deleted.',
                },
                () => {
                  this.getAllCurriculum();
                }
            );
          } else {
            this.setState({
              errorModal: true,
              modalTitle: 'Sorry!',
              message: response.message,
            });
          }
        })
        .catch((errorResponse) => {
          this.setState({
            errorModal: true,
            modalTitle: 'Sorry!',
            message: errorResponse.message,
          });
        });
  };

  toggleModal = (state) => {
      this.setState({
          [state]: !this.state[state],
          modalTitle: '',
          message: '',
      });
      if (state === 'curriculumModal' || state === 'editModal') {
          this.resetForm();
      } else if (state === 'roomModal') {
          this.setState({
              roomName: '',
              roomUrl: '',
          });
      }
  };

  resetForm = () => {
    this.setState({
      loading: false,
      curriculumName: '',
      description: '',
      curriculumError: false,
      descriptionError: false,
      isVisible: false,
    });
  };

  render() {
    let {
      loading,
      curriculumList,
      curriculumName,
      description,
      curriculumError,
      descriptionError,
      successModal,
      errorModal,
      modalTitle,
      message,
      uploading,
      curriculumModal,
      editModal,
        classList,
      isVisible,
        classId,
      level,
      page, totalPage
    } = this.state;

    return (
        <>
          <Header />
          <Container className='mt--7' fluid>
            <Button color='primary' onClick={() => this.addCurriculumForm()}>
              Add Curriculum
            </Button>
            <br />
            <br />
            <Row>
              {curriculumList &&
                  curriculumList.length > 0 &&
                  curriculumList.map((data, index) =>
                      data.name ? (
                          <div className='col-md-6 col-lg-4'>
                            <div className='SingleClassBlock'>

                              <div className='ContentWrapper'>
                                <div className='ClassTitleNBtn'>
                                  <h4 className='Title'>{data.name}</h4>
                                  <span>
                                  <UncontrolledDropdown>
                                      <DropdownToggle
                                          className='btn-icon-only'
                                          role='button'
                                          size='sm'
                                          color=''
                                          onClick={(e) => e.preventDefault()}
                                      >
                                        <i className='fas fa-ellipsis-v' />
                                      </DropdownToggle>
                                      <DropdownMenu
                                          className='dropdown-menu-arrow'
                                          right
                                      >
                                        <DropdownItem
                                            onClick={() => this.editCurriculum(index)}
                                        >
                                          Edit Curriculum
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => this.deleteCurriculum(index)}
                                        >
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                   </UncontrolledDropdown>
                                </span>
                                </div>
                                <DescriptionDetail description={data?.description} />
                                <div className='BtnWrapper'>
                                  <Button
                                      className='BtnFill'
                                      color='primary'
                                      onClick={() => this.openSectionPage(index)}
                                  >
                                    Chapters Details
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                      ) : null
                  )}
            </Row>

            <CardFooter className='py-4'>
              <nav aria-label='...'>
                <Pagination
                    className='pagination justify-content-end mb-0'
                    listClassName='justify-content-end mb-0'
                >
                  <PaginationItem className={page > 1 ? '' : 'disabled'}>
                    <PaginationLink
                        onClick={() => this.changePage('prev')}
                        tabIndex='-1'
                    >
                      <i className='fas fa-angle-left' />
                      <span className='sr-only'>Previous</span>
                    </PaginationLink>
                  </PaginationItem>

                  <PaginationItem className='active'>
                    <PaginationLink onClick={(e) => e.preventDefault()}>
                      {page}
                    </PaginationLink>
                  </PaginationItem>

                  <PaginationItem
                      className={totalPage > page ? '' : 'disabled'}
                  >
                    <PaginationLink onClick={() => this.changePage('next')}>
                      <i className='fas fa-angle-right' />
                      <span className='sr-only'>Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>

          </Container>

          <Modal
              className='modal-dialog-centered'
              size='lg'
              isOpen={curriculumModal || editModal}
              toggle={() =>
                  this.toggleModal(curriculumModal ? 'curriculumModal' : 'editModal')
              }
          >
            <div className='modal-body p-0'>
              <Card className='bg-secondary shadow border-0'>
                <CardHeader className='bg-transparent pb-5'>
                  <div className='text-muted text-center mt-2 mb-3'>
                    <small>{curriculumModal ? 'Add new Curriculum' : 'Edit Curriculum'}</small>
                  </div>
                </CardHeader>
                <CardBody className='px-lg-5 py-lg-5'>
                  <Form role='form'>
                    <FormGroup>
                          <label>Class</label>
                          <div style={{ width: "100%", minWidth: "200px" }}>
                              <Select
                                  style={{ width: "100%", minWidth: "200px" }}
                                  options={[
                                      ...(classList &&
                                          classList.map((item) => ({
                                              value: item.classId,
                                              label: item.name,
                                          }))),
                                  ]}
                                  value={
                                      classId && {
                                          value: classId,
                                          label: classList.find((item) => item.classId === classId).name,
                                      }
                                  }
                                  placeholder="Select Class"
                                  onChange={(e) =>
                                      this.setState({ classId: e.value })
                                  }
                                  isSearchable={true}
                              />
                          </div>
                      </FormGroup>
                    <FormGroup className={curriculumError ? 'has-danger' : ''}>
                      <label>Curriculum Name</label>
                      <InputGroup className='input-group-alternative'>
                        <Input
                            placeholder='Curriculum name'
                            type='text'
                            name='curriculumName'
                            value={curriculumName}
                            onChange={(e) =>
                                this.setState({ curriculumName: e.target.value })
                            }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className={descriptionError ? 'has-danger' : ''}>
                      <label>About</label>
                      <InputGroup className='input-group-alternative'>
                        <Input
                            placeholder='Curriculum details'
                            type='textarea'
                            rows='3'
                            name='description'
                            value={description}
                            onChange={(e) =>
                                this.setState({ description: e.target.value })
                            }
                        />
                      </InputGroup>
                    </FormGroup>




                    <div className='text-center'>
                      {uploading ? (
                          <span style={{ color: 'red' }}>Wait...</span>
                      ) : null}

                      <Button
                          disabled={uploading || loading ? true : false}
                          className='my-4'
                          color='primary'
                          type='button'
                          onClick={() => {
                            this.addOrUpdateCurriculum(curriculumModal ? false : true);
                          }}
                      >
                        {curriculumModal ? 'Save' : 'Update'}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Modal>

          <Modal
              className='modal-dialog-centered'
              isOpen={this.state.successModal || this.state.errorModal}
              toggle={() =>
                  successModal
                      ? this.toggleModal('successModal')
                      : errorModal
                          ? this.toggleModal('errorModal')
                          : null
              }
          >
            <div className='modal-header'>
              <h5 className='modal-title' id='successModalLabel'>
                {modalTitle}
              </h5>
              <button
                  aria-label='Close'
                  className='close'
                  data-dismiss='modal'
                  type='button'
                  onClick={() =>
                      successModal
                          ? this.toggleModal('successModal')
                          : errorModal
                              ? this.toggleModal('errorModal')
                              : null
                  }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            {this.state.successModal ? (
                <div className='modal-body' style={{ color: 'green' }}>
                  {message}
                </div>
            ) : this.state.errorModal ? (
                <div className='modal-body' style={{ color: 'red' }}>
                  {message}
                </div>
            ) : null}
            <div className='modal-footer'>
              {/* <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("successModal")}
              >
                Close
            </Button> */}
              <Button
                  color='primary'
                  type='button'
                  onClick={() =>
                      successModal
                          ? this.toggleModal('successModal')
                          : errorModal
                              ? this.toggleModal('errorModal')
                              : null
                  }
              >
                Ok
              </Button>
            </div>
          </Modal>
        </>
    );
  }
}

export default Curriculum;
