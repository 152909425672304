import React from 'react';
import moment from 'moment';

// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    FormGroup,
    Form,
    CardBody,
    InputGroup,
    Input,
    Modal,
    Button, Nav, NavItem, NavLink, TabContent,
    TabPane, Badge,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import { axiosGet, axiosPost } from 'util/apiConfig.js';
import Select from "react-select";
import {value} from "lodash/seq";
import "./SalesNotification.css";
import styles from "./SalesNotification.css";
import classnames from "classnames";
import Axios from 'axios';
import {CSVLink} from "react-csv";
import { Device } from '@twilio/voice-sdk';
import debounce from "lodash/debounce";
const pdfRef1 = React.createRef(null);
const pdfRef2 = React.createRef(null);
const pdfRef3 = React.createRef(null);
const pdfRef4 = React.createRef(null);
const pdfRef5 = React.createRef(null);
// import { FaPhone, FaEnvelope } from 'react-icons/fa';

const pdfRefs = {
    input1: pdfRef1,
    input2: pdfRef2,
    input3: pdfRef3,
    input4: pdfRef4,
    input5: pdfRef5,
    // Add more refs as needed
};

const USER_STATE = {
    CONNECTING : "Connecting",
    READY : "Ready",
    ON_CALL : "On call",
    OFFLINE : "Offline",
}
const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '100%',
        zIndex: 1000, // Add your custom z-index value here
    }),
};

class SalesNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      allMessages: [],
      allSalesUser: [],
      allCounts: [],
      allNotificationStatus: [],
      duplicatesNotification: [],
      textMessage: '',
      searchNotification: '',
      nameError: false,
      notesError: false,
      emailError: false,
      mobileError: false,
      classIdError: false,
      selectedMessage: {},
      startClass: false,

      listPage: 1,
      listPageSale: 1,
      totalListPageSale: '',
      rowLimitSale: 100,
      rowLimit: 10,
      activeTab: "1",

      modalTitle: '',
      successModal: false,
      errorModal: false,
      message: '',
      editModal: false,
      editSalesModal: false,
      classList: [],
      notificationNotesModal: false,
      notificationCallModal: false,
      addModal: false,
      filterTeacher: null,

      message10min: '',
      message24hr: '',
      message72hr: '',
      messageBefore10min: '',
      messageBefore24hr: '',
      pdfLinkMessage10min: '',
      pdfLinkMessageBefore10min: '',
      pdfLinkMessageBefore24hr: '',
      pdfLinkMessage24hr: '',
      pdfLinkMessage72hr: '',
      messageId: '',

      filterFromDate: null,
      filterToDate: null,
      salesUser: null,
      filterSaleUser: null,
      filterNotificationStatus : 'upcoming',
      notificationStatus: '',
      device: undefined,
      userState: USER_STATE.OFFLINE,
      messageStates: {},
      allMessageCsvData: [],
      subject10min: '',
      subject24hr: '',
      subject72hr: '',
      subjectBefore10min: '',
      subjectBefore24hr: '',


    };
   this.debouncedSearch = debounce(this.getSalesNotification, 700);
  }

  componentDidMount() {
    let storage = localStorage.getItem('Sanga_Admin');
    storage = storage ? JSON.parse(storage) : null;
    const classId = localStorage.getItem("classId");
    this.setState(
      {
        globalClassId: classId ? classId : null,
        userId: storage && storage.userId,
        userName: storage && storage.name,
        userType: storage && storage.userType,
        newRequestList: [],
        allMessages: [],
        allMessageCsvData: [],
        allCounts: [],
        allSalesUser: [],
        teachersList: [],
      },
      () => {
        // this.callInit()
        this.getSalesNotification();
        this.getClass();
        if(storage && storage.userType === 'admin' || storage.userType === 'sub_admin'){
            this.getSalesUser();
            this.getDuplicatNotification();
            this.getAllTeachers();
        }
        this.setState({ allNotificationStatus: [
            {
              value: 'Default',
              label: 'Default',
            },{
              value: 'Very Interested',
              label: 'Very Interested',
            },{
              value: 'Medium Interested',
              label: 'Medium Interested',
            },{
              value: 'Less Interested',
              label: 'Less Interested',
            },{
              value: 'No Show - Teacher',
              label: 'No Show - Teacher',
            },{
              value: 'No Show - Student',
              label: 'No Show - Student',
            },{
              value: 'Cancel Class',
              label: 'Cancel Class',
            },
            ] });
      }
    );
  }

  getClass = () => {
        // API --
        axiosGet(`class/get-classes-list`)
            .then((response) => {
                if (!response.isError) {
                    this.getNotificationMessage(response.data[0].classId);
                    this.setState({
                        classList: response.data || [],
                        classId: response.data[0].classId,
                    });
                } else {
                    this.setState({
                        classList: [],
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    classList: [],
                });
            });
    };


    getAllTeachers = () => {
        const { globalClassId } = this.state;
        let teachers = [{ value: null, label: "All Coaches" }];
        let url = `teacher/list-of-all-teacher`;
        if (globalClassId) {
            url = `teacher/list-of-all-teacher/${globalClassId}`;
        }
        axiosGet(url)
            .then((response) => {
                if (!response.isError) {
                    let data = response.data?.result || [];
                    data.map((_data) => {
                        teachers.push({ value: _data.teacherId, label: _data.name , timeZone: _data.timeZone });
                    });
                    this.setState({
                        teachersList: teachers,
                    });
                } else {
                    this.setState({
                        teachersList: teachers,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    teachersList: teachers,
                });
            });
    };

  // to get all messages -
  getSalesNotification = (search) => {
    const { listPage, filterFromDate, filterToDate, rowLimit, filterSaleUser, filterNotificationStatus = 'upcoming', searchNotification = '', notificationStatus, globalClassId } = this.state;
      let url = `sales/get-all-sales-notification/${listPage}/${rowLimit}`;
      const queryParams = [];
      if (globalClassId) {
         queryParams.push(`classId=${globalClassId}`);
      }
      if (filterFromDate && filterToDate) {
          queryParams.push(`fromDate=${filterFromDate}`);
          queryParams.push(`toDate=${filterToDate}`);
      }
      if (filterSaleUser) {
          queryParams.push(`salesUser=${filterSaleUser}`);
      }
      if (searchNotification) {
            queryParams.push(`name=${searchNotification}`);
      }
      if (notificationStatus) {
            queryParams.push(`notificationStatus=${notificationStatus}`);
      }
      if (filterNotificationStatus) {
        queryParams.push(`filterNotificationStatus=${filterNotificationStatus}`);
      }
      if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
      }
    axiosGet(`${url}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            allMessages: response.data?.result,
            totalNewPage: response.data?.noofpage,
            allCounts: [{status: "Completed Class", count: response.data?.totalNumberOfCompletedClass},
                        {status: "No Show Teacher", count: response.data?.totalNumberOfNotShowTeacher},
                        {status: "No Show Student", count: response.data?.totalNumberOfNotShowStudent},
                        {status: "Converted Class", count: response.data?.totalNumberRegistered},
                        {status: "Default", count: response.data?.totalNumberOfDefault},
            ],
          });
        } else {
          this.setState({
            allMessages: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          allMessages: [],
        });
        console.log(error);
      });
  };

  getSalesNotificationCSV = async () => {
      const { filterFromDate, filterToDate, filterSaleUser, filterNotificationStatus = 'upcoming', searchNotification = '', notificationStatus, globalClassId } = this.state;
      let url = `sales/get-all-sales-notification-csv`;
      const queryParams = [];
      if (globalClassId) {
          queryParams.push(`classId=${globalClassId}`);
      }
      if (filterFromDate && filterToDate) {
          queryParams.push(`fromDate=${filterFromDate}`);
          queryParams.push(`toDate=${filterToDate}`);
      }
      if (filterSaleUser) {
          queryParams.push(`salesUser=${filterSaleUser}`);
      }
      if (searchNotification) {
          queryParams.push(`name=${searchNotification}`);
      }
      if (notificationStatus) {
          queryParams.push(`notificationStatus=${notificationStatus}`);
      }
      if (filterNotificationStatus) {
          queryParams.push(`filterNotificationStatus=${filterNotificationStatus}`);
      }
      if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
      }
     await axiosGet(`${url}`)
          .then((response) => {
              if (!response.isError) {
                  this.setState({
                      allMessageCsvData: response.data?.result,
                  },() => {
                        document.getElementById('csvData').click();
                  });
              } else {
                  this.setState({
                      allMessageCsvData: [],
                  });
              }
          })
          .catch((error) => {
              this.setState({
                  allMessageCsvData: [],
              });
              console.log(error);
          });
  };

  getSalesUser = () => {
    const { listPageSale, rowLimitSale } = this.state;
    // API -
    axiosGet(`sales/get-sales-users/${listPageSale}/${rowLimitSale}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            allSalesUser: response.data?.result,
            totalNewPageSale: response.data?.noofpage,
          });
        } else {
          this.setState({
            allSalesUser: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          allSalesUser: [],
        });
      });
  };

  getNotificationMessage = (classId) => {
    axiosGet(`sales/get-notification-message/${classId}`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            message10min: response.data[0]?.message10min,
            message24hr: response.data[0]?.message24hr,
            message72hr: response.data[0]?.message72hr,
            messageBefore10min: response.data[0]?.messageBefore10min,
            messageBefore24hr: response.data[0]?.messageBefore24hr,
            subject10min: response.data[0]?.subject10min,
            subject24hr: response.data[0]?.subject24hr,
            subject72hr: response.data[0]?.subject72hr,
            subjectBefore24hr: response.data[0]?.subjectBefore24hr,
            subjectBefore10min: response.data[0]?.subjectBefore10min,
            messageId: response.data[0]?.messageId,
            pdfLinkMessage10min: response.data[0]?.pdfLinkMessage10min,
            pdfLinkMessageBefore10min: response.data[0]?.pdfLinkMessageBefore10min,
            pdfLinkMessageBefore24hr: response.data[0]?.pdfLinkMessageBefore24hr,
            pdfLinkMessage24hr: response.data[0]?.pdfLinkMessage24hr,
            pdfLinkMessage72hr: response.data[0]?.pdfLinkMessage72hr,
          });
        } else {
          this.setState({
            message10min: '',
            subject10min: '',
            subject24hr: '',
            subject72hr: '',
            subjectBefore24hr: '',
            subjectBefore10min: '',
            message24hr: '',
            message72hr: '',
            messageBefore10min: '',
            messageBefore24hr: '',
            pdfLinkMessage10min: '',
            pdfLinkMessageBefore10min: '',
            pdfLinkMessageBefore24hr: '',
            pdfLinkMessage24hr: '',
            pdfLinkMessage72hr: '',
          });
        }
      })
      .catch((error) => {
        this.setState({
          allMessages: [],
        });
        console.log(error);
      });
  };

  getDuplicatNotification = () => {
    axiosGet(`sales/get-all-duplicates-sales-notification`)
      .then((response) => {
        if (!response.isError) {
          this.setState({
            duplicatesNotification: response.data,
          });
        } else {
          this.setState({
            duplicatesNotification: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          duplicatesNotification: [],
        });
        console.log(error);
      });
  };

  addMessage = () => {
    this.setState({
      notificationModal: true,
    });
  };

  resetForm = () => {
    this.setState({
      loading: false,
      name: '',
      email: '',
      mobile: '',
      zoomLinkSales: '',
      nameError: false,
      notesError: false,
      emailError: false,
      mobileError: false,
      classIdError: false,
      isVisible: false,
      createdBy: '',
    });
  };


  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      modalTitle: '',
      message: '',
    });
    if (state === 'notificationModal' || state === 'editModal') {
      this.resetForm();
    } else if (state === 'notificationModal') {
      this.setState({
        email: '',
        name: '',
        mobile: '',
      });
    }
  };

  changePage = (status) => {
    if (status && status === 'next') {
      this.setState({ listPage: this.state.listPage + 1 }, () =>
        this.getSalesNotification()
      );
    } else if (status && status === 'prev') {
      this.setState({ listPage: this.state.listPage - 1 }, () =>
        this.getSalesNotification()
      );
    }
  };

  changePageSale = (status) => {
    if (status && status === 'next') {
      this.setState({ listPageSale: this.state.listPageSale + 1 }, () =>
        this.getSalesUser()
      );
    } else if (status && status === 'prev') {
      this.setState({ listPageSale: this.state.listPageSale - 1 }, () =>
        this.getSalesUser()
      );
    }
  };

  changeClassMessage = (classId) => {
      this.getNotificationMessage(classId)
  }

  addOrUpdateNotification = (isCalledForEditNotification) => {
    let {
      name,
      email,
      mobile,
      selectedNotification,
      classId,
        createdBy,
        zoomLinkSales,
    } = this.state;
    !name
        ? this.setState({ nameError: true })
        : this.setState({ nameError: false });
    !email
        ? this.setState({ emailError: true })
        : this.setState({ emailError: false });
    !mobile
        ? this.setState({ mobileError: true })
        : this.setState({ mobileError: false });
    !classId
        ? this.setState({ classIdError: true })
        : this.setState({ classIdError: false });
    !createdBy
        ? this.setState({ createdByError: true })
        : this.setState({ createdByError: false });
    if (name && email && mobile && classId) {
      let dataToSend = {
        name,
        email,
        mobile,
        classId,
        createdBy,
        zoomLink: zoomLinkSales || '',
      };
      if (isCalledForEditNotification) {
        dataToSend = { ...dataToSend, ...{ notificationId: selectedNotification.notificationId} };
      }
      const endPointUrl = isCalledForEditNotification
          ? 'sales/edit-notification'
          : 'sales/add-notification';
      axiosPost(`${endPointUrl}`, dataToSend)
          .then((response) => {
            this.setState({
              editModal: false,
              selectedChapter: '',
              notificationModal: false,
              loading: false,
            });
            if (!response.isError) {
              this.setState(
                  {
                    successModal: true,
                    modalTitle: 'Success',
                    message: 'Successfully saved.',
                  },
                  () => {
                    this.resetForm();
                    this.componentDidMount()
                  }
              );
            } else {
              this.setState(
                  {
                    errorModal: true,
                    modalTitle: 'Sorry!',
                    message: response.message,
                  },
                  () => {
                    this.resetForm();
                  }
              );
            }
          })
          .catch((errorResponse) => {
            this.setState(
                {
                  editModal: false,
                  selectedChapter: '',
                  notificationModal: false,
                  errorModal: true,
                  loading: false,
                  modalTitle: 'Sorry!',
                  message: errorResponse.message,
                },
                () => {
                  this.resetForm();
                }
            );
          });
    }
  };

  UpdateSalesUser = () => {
    let {
      name,
      email,
      zoomLink,
      selectedUser,
      teacherId
    } = this.state;
    !name
        ? this.setState({ nameError: true })
        : this.setState({ nameError: false });
    !email
        ? this.setState({ emailError: true })
        : this.setState({ emailError: false });
    if (name && email) {
      let dataToSend = {
        name,
        email,
        zoomLink,
       userId: selectedUser.userId,
      teacherId
      };
      axiosPost(`sales/update-sales-user`, dataToSend)
          .then((response) => {
            this.setState({
              editSalesModal: false,
              loading: false,
            });
            if (!response.isError) {
              this.setState(
                  {
                    successModal: true,
                    modalTitle: 'Success',
                    message: 'Successfully saved.',
                  },
                  () => {
                    this.resetForm();
                    this.componentDidMount()
                  }
              );
            } else {
              this.setState(
                  {
                    errorModal: true,
                    modalTitle: 'Sorry!',
                    message: response.message,
                  },
                  () => {
                    this.resetForm();
                  }
              );
            }
          })
          .catch((errorResponse) => {
            this.setState(
                {
                  editSalesModal: false,
                  errorModal: true,
                  loading: false,
                  modalTitle: 'Sorry!',
                  message: errorResponse.message,
                },
                () => {
                  this.resetForm();
                }
            );
          });
    }
  };

  updateNotificationNotes = () => {
    let {
      notes,
      selectedNotification
    } = this.state;
    !notes
        ? this.setState({ notesError: true })
        : this.setState({ notesError: false })
    if (notes) {
      let dataToSend = {
       notes,
       notificationId: selectedNotification.notificationId
      };
      axiosPost(`sales/update-notification-notes`, dataToSend)
          .then((response) => {
            this.setState({
              notificationNotesModal: false,
              loading: false,
            });
            if (!response.isError) {
              this.setState(
                  {
                    successModal: true,
                    modalTitle: 'Success',
                    message: 'Successfully saved.',
                  },
                  () => {
                    this.resetForm();
                    this.componentDidMount()
                  }
              );
            } else {
              this.setState(
                  {
                    errorModal: true,
                    modalTitle: 'Sorry!',
                    message: response.message,
                  },
                  () => {
                    this.resetForm();
                  }
              );
            }
          })
          .catch((errorResponse) => {
            this.setState(
                {
                  notificationNotesModal: false,
                  errorModal: true,
                  loading: false,
                  modalTitle: 'Sorry!',
                  message: errorResponse.message,
                },
                () => {
                  this.resetForm();
                }
            );
          });
    }
  };



  editNotification = (index) => {
    let { allMessages } = this.state;
    let selectedNotification = allMessages[index];
    this.setState({
      editModal: true,
      name: selectedNotification.name,
      email: selectedNotification.email,
      mobile: selectedNotification.mobile,
      classId: selectedNotification.classId,
      createdBy: selectedNotification.createdBy,
      zoomLinkSales: selectedNotification.zoomLink,
      selectedNotification
    });
  };

  editSalesUsers = (index) => {
    let { allSalesUser } = this.state;
    let selectedUser = allSalesUser[index];
    this.setState({
      editSalesModal: true,
      name: selectedUser.name,
      email: selectedUser.email,
      zoomLink: selectedUser.zoomLink,
      teacherId: selectedUser?.teacher[0]?.teacherId || '',
      selectedUser
    });
  };

  addNotes = (index) => {
    let { allMessages } = this.state;
    let selectedNotification = allMessages[index];
    this.setState({
      notificationNotesModal: true,
      notes: selectedNotification.notes,
      selectedNotification
    });
  };

  clientConnect = (index) => {
    let { allMessages } = this.state;
    let selectedNotification = allMessages[index];
    this.setState({
      notificationCallModal: true,
      selectedNumber: selectedNotification.mobile || ''
    });
  };

  startClass = (index) => {
    let { allMessages } = this.state;
    let selectedNotification = allMessages[index];
    this.setState({ startClass: true, selectedNotification }, () => {
        this.callStartEvent(selectedNotification);
    });
  };

  callStartEvent = () => {
    let { selectedNotification, startClass } = this.state;
    let dataToSend = {
        notificationId: selectedNotification.notificationId,
        startClass
    }
    axiosPost(`sales/start-class-update`, dataToSend)
        .then((response) => {
            if (!response.isError) {
                const notification = this.state.allMessages.find((item) => item.notificationId === selectedNotification.notificationId);
                notification.startClass = true;
                this.setState(
                    {
                        successModal: true,
                        modalTitle: 'Success',
                        message: 'Class started successfully After 40 min it will be automatically End Class',
                    }
                );
            } else {
                this.setState(
                    {
                        errorModal: true,
                        modalTitle: 'Sorry!',
                        message: response.message,
                    }
                );
            }
        })
        .catch((errorResponse) => {
            this.setState(
                {
                    errorModal: true,
                    loading: false,
                    modalTitle: 'Sorry!',
                    message: errorResponse.message,
                },
                () => {
                    this.resetForm();
                }
            );
        });
    };



    callCancelEvent = async (notiInfo) => {
        const regex = /\/scheduled_events\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/;
        let eventId = notiInfo.calendlyID.match(regex);
        if(eventId && eventId[1]){
           eventId = eventId[1];
            const token = {
                "CLASS1648760926141": "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjg2Mjg5MjY1LCJqdGkiOiI5YWQzZDk5Yy03NTBhLTRkOGUtYjczZC03Y2ZkOTIxZjRlZjgiLCJ1c2VyX3V1aWQiOiJiODYyZGVjMS1kYjBmLTQ3ODItYmI2MS04OGVkMjI2MjEwZGIifQ.McPqQ9cgj0XN8zRYpUysrOTHK0W4Nv6vLY1zuthiFV1k-mRMJT0r4gF1OydYhDabnOWQf7ld0BXFcxTd0KUFAg" ,
                "CLASS1682531326773": "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjg5MDU2NDAxLCJqdGkiOiIyMWFmYWYxMC0xYjgzLTRjOWItYjFmYi1jZjM1MWEyMWQxOTIiLCJ1c2VyX3V1aWQiOiIzOGRiYzRlZi1iMGNiLTQxNjctYTBmYy0yNmNjNWU0ZTkyZTcifQ.5rwz9VFPiQE63uIimsORMJ0Qzpy-vag8Cvh0Zoe05zvs4l-XqMDEcuyNaN_5_bfyFmgJQcjI1DJAbzKIYADLKw",
                "CLASS1688966054748": "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjg5MDU2NDAxLCJqdGkiOiIyMWFmYWYxMC0xYjgzLTRjOWItYjFmYi1jZjM1MWEyMWQxOTIiLCJ1c2VyX3V1aWQiOiIzOGRiYzRlZi1iMGNiLTQxNjctYTBmYy0yNmNjNWU0ZTkyZTcifQ.5rwz9VFPiQE63uIimsORMJ0Qzpy-vag8Cvh0Zoe05zvs4l-XqMDEcuyNaN_5_bfyFmgJQcjI1DJAbzKIYADLKw"
            }
            const data = await Axios.post(`https://api.calendly.com/scheduled_events/${eventId}/cancellation`, {
                reason: "Admin cancelled the event",
            }, {headers: { Accept: 'application/json', Authorization: `Bearer ${token[notiInfo.classId]}`}});
            if(data.status === 204){
               return this.setState({
                   loading: false,
                   successModal: true,
                   modalTitle: 'Success',
                   message: 'Event cancelled successfully',
               })
            }
            return this.setState({
                loading: false,
                errorModal: true,
                modalTitle: 'Sorry!',
                message: 'Event cancellation failed',
            })
        }
    }

  handleNotificationStatusChange = (e, index) => {
    let message = "Are you sure you want to change the status?"
    if(e.label === "Cancel Class"){
      message = "Are you sure you want to cancel the class? This will cancel session"
    }
    let confirm = window.confirm(message);
    if (confirm) {
      let {notificationId, allMessages} = this.state;
      let selectedNotification = allMessages[index];
      let dataToSend = {
        notificationId: selectedNotification.notificationId,
        notificationStatus: e.value
      };
      this.setState({loading: true});
      axiosPost('sales/update-notification-status', dataToSend)
          .then((response) => {
            if (!response.isError) {
                if(e.label === "Cancel Class"){
                    this.callCancelEvent(selectedNotification)
                }
              this.setState(
                  {
                    loading: false,
                    successModal: true,
                    modalTitle: 'Success',
                    message: 'Successfully Updated.',
                  },
                  () => {
                    this.getSalesNotification();
                  }
              );
            } else {
              this.setState({
                loading: false,
                errorModal: true,
                modalTitle: 'Sorry!',
                message: 'Something is wrong. Try again later.',
              });
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
            });
            console.log(error);
          });
    }
  };

  sendReminder = (notificationId, classId) => {
    let confirm = window.confirm("Are you sure you want to Send Reminder?");
    if (confirm) {
      axiosPost('sales/send-reminder', {notificationId, classId})
          .then((response) => {
            if (!response.isError) {
              this.setState(
                  {
                    loading: false,
                    successModal: true,
                    modalTitle: 'Success',
                    message: 'Successfully Updated.',
                  },
                  () => {
                    this.getSalesNotification();
                  }
              );
            } else {
              this.setState({
                loading: false,
                errorModal: true,
                modalTitle: 'Sorry!',
                message: 'Something is wrong. Try again later.',
              });
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
            });
            console.log(error);
          });
    }

  }

  callInit = (sale, index) => {
      axiosPost('sales/token', {})
          .then((response) => {
              if (!response.isError) {
                  this.setState(
                      {
                          loading: false
                      },
                      () => {
                          const token = response.data.token;
                          const device = new Device(token, {
                              logLevel: 1,
                              codecPreferences: ["opus", "pcmu"],
                          });
                          device.register();
                          this.setState({device})
                          // setDevice(device);
                          device.addListener("connect", (device) => {
                              console.log("Connect event listener added .....");
                              return device;
                          });
                          device.on("registered", () => {
                              console.log("Agent registered");
                              this.setState({userState: USER_STATE.READY});
                          });
                          device.on("connect", (connection) => {
                              console.log("Call connect");
                              this.setState({userState: USER_STATE.ON_CALL})
                          });
                          device.on("disconnect", () => {
                              console.log("Disconnect event");
                              this.setState({userState: USER_STATE.READY})
                          });
                          setTimeout(()=>{
                              this.handleCall(sale, index)
                          }, 1000)

                          return () => {
                              device.destroy();
                              this.setState({device: undefined})
                              this.setState({userState: USER_STATE.OFFLINE})
                          };
                      }
                  );
              } else {
                  this.setState({
                      loading: false,
                      errorModal: true,
                      modalTitle: 'Sorry!',
                      message: 'Something is wrong. Try again later.',
                  });
              }
          })
          .catch((error) => {
              this.setState({
                  loading: false,
                  errorModal: true,
                  modalTitle: 'Sorry!',
                  message: 'Something is wrong. Try again later.',
              });
              console.log(error);
          });
  }

  stopReminder = (notificationId) => {
    let confirm = window.confirm("Are you sure you want to Stop Reminder?");
    if (confirm) {
      axiosPost('sales/stop-reminder', {notificationId})
          .then((response) => {
            if (!response.isError) {
              this.setState(
                  {
                    loading: false,
                    successModal: true,
                    modalTitle: 'Success',
                    message: 'Successfully Updated.',
                  },
                  () => {
                    this.getSalesNotification();
                  }
              );
            } else {
              this.setState({
                loading: false,
                errorModal: true,
                modalTitle: 'Sorry!',
                message: 'Something is wrong. Try again later.',
              });
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
            });
            console.log(error);
          });
    }

  }

  deleteNotification = (index) => {
    let confirm = window.confirm("Are you sure you want to Delete?");
    if (confirm) {
    let { allMessages } = this.state;
    let selectedNotification = allMessages[index];
    this.setState({ loading: true });
    // API -
    axiosPost('sales/delete-notification', {notificationId: selectedNotification.notificationId})
        .then((response) => {
          if (!response.isError) {
            this.setState(
                {
                  loading: false,
                  successModal: true,
                  modalTitle: 'Success',
                  message: 'Successfully deleted',
                },
                () => {
                  this.getSalesNotification();
                }
            );
          } else {
            this.setState({
              loading: false,
              errorModal: true,
              modalTitle: 'Sorry!',
              message: 'Something is wrong. Try again later.',
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            errorModal: true,
            modalTitle: 'Sorry!',
            message: 'Something is wrong. Try again later.',
          });
          console.log(error);
        });
    }
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  updateNotificationMessage = (e) => {
    let {  message10min,
      message24hr,
      message72hr, messageBefore24hr, messageBefore10min, subject10min, subject24hr,
        subject72hr,
        subjectBefore24hr,
        subjectBefore10min, messageId, pdfLinkMessage10min, pdfLinkMessageBefore10min,
        pdfLinkMessageBefore24hr,
        pdfLinkMessage24hr,
        pdfLinkMessage72hr, classId } = this.state;
    if(!message10min && !message24hr &&
        !message72hr && !messageBefore24hr && !subject10min && !messageBefore10min && !subject24hr &&
        !subject72hr && !subjectBefore24hr && !subjectBefore10min && !messageId && !classId){
       return  alert('Please enter all the fields');
    }
    this.setState({loading: true});
    let dataToSend = {
        messageId,
        classId,
        message10min,
        message24hr,
        subject10min,
        subject24hr,
        subject72hr,
        subjectBefore24hr,
        subjectBefore10min,
        message72hr,
        messageBefore10min,
        messageBefore24hr,
        pdfLinkMessage10min,
        pdfLinkMessageBefore10min,
        pdfLinkMessageBefore24hr,
        pdfLinkMessage24hr,
        pdfLinkMessage72hr,

    }
    axiosPost('sales/update-notification-message', dataToSend)
        .then((response) => {
            if (!response.isError) {
            this.setState(
                {
                    loading: false,
                    successModal: true,
                    modalTitle: 'Success',
                    message: 'Successfully Updated.',
                },
                () => {
                    this.getSalesNotification();
                }
            );
            } else {
            this.setState({
                loading: false,
                errorModal: true,
                modalTitle: 'Sorry!',
                message: 'Something is wrong. Try again later.',
            });
            }
        })
        .catch((error) => {
            this.setState({
            loading: false,
            errorModal: true,
            modalTitle: 'Sorry!',
            message: 'Something is wrong. Try again later.',
            });
    });

  }

  searchNotification = (e) => {
    let val = e.target.value;
    this.setState({ searchNotification: val }, () => {
      this.debouncedSearch(val);
    });
  };


    getHeaders = () => {
        return [
            { label: "User Name", key: "userName" },
            { label: "Class Name", key: "className" },
            { label: "Email", key: "email" },
            { label: "Mobile Number", key: "mobileNumber" },
            { label: "Start Time", key: "startTime" },
            { label: "Time Zone", key: "timeZone" },
            { label: "Created By", key: "CreatedBy" },
            { label: "Status", key: "status" },
        ];
    };

  getCSVData = () => {
        let { allMessageCsvData, classList } = this.state;
        let csvData = [];
      allMessageCsvData &&
      allMessageCsvData.map((data) => {
            let rowData = {
                userName: data.name && data.name,
                className: classList.find((classData)=> classData.classId === data.classId).name || '',
                email: data.email,
                mobileNumber: data.mobile,
                startTime: data.startTime ? moment(data.startTime).format('ll hh:mm A') : '',
                timeZone: data.timezone,
                CreatedBy: data?.createdBy?.toLocaleUpperCase(),
                status: data?.notificationStatus || "",
            };
            csvData.push(rowData);
        });
        return csvData;
    };


  changeSales = (value)=> {
      const { allSalesUser } = this.state;
      const saleUser = allSalesUser.find((_data) => {
          return _data.name === value
      })
      this.setState({ createdBy: value, zoomLinkSales: saleUser.zoomLink || '' });
  }

    updateMessageState = (index, newState) => {
        this.setState(prevState => ({
            messageStates: {
                ...prevState.messageStates,
                [index]: newState
            }
        }));
    };

  handleCall = async (sale, index) => {
      const confirm = window.confirm('Are you sure want to call?')
      if(!confirm){
          return false;
      }
      const {device, userType} = this.state;
      device.emit("connect");
      device.connect({params: {To: `+1${sale.mobile}`, notificationId: sale.notificationId, userType } }).then((call) => {
          call.on("accept", () => {
              this.updateMessageState(index, USER_STATE.ON_CALL);
              console.log("call accepted");
          });
          call.on("disconnect", () => {
              console.log("The call has been disconnected.");
              this.updateMessageState(index, USER_STATE.READY);
          });
          call.on("reject", () => {
              console.log("The call was rejected.");
          });
          call.on("cancel", () => {
              console.log("The call was rejected.");
              this.updateMessageState(index, USER_STATE.READY);
          });
      })
    };

  rejectCall = ()=>{
      const {device, selectedNumber} = this.state
      device.emit("disconnect");
      device.emit("cancel");
      device.emit("reject");
      device.disconnectAll({params: {To: selectedNumber} });
  }


  replyMessageResponse = () => {
    let confirm = window.confirm('Are you sure want send message?');
    if (confirm) {
      let { twillioMessage, loading, selectedNumber } = this.state;
      if(!twillioMessage || twillioMessage == ''){
            return alert('Please enter message');
      }
      if (twillioMessage && !loading) {
          this.setState({ loading: true });
          let dataToSend = {
                message: twillioMessage,
                to: `+1${selectedNumber}`,
            };
            // API -
          axiosPost('message/send-reply', dataToSend)
            .then((response) => {
                if (!response.isError) {
                    this.setState(
                        {
                            loading: false,
                            notificationCallModal: false,
                            twillioMessage: '',
                            selectedNumber: '',
                        },
                        () => {
                        }
                    );
                } else {
                    this.setState({
                        loading: false,
                        modalTitle: 'Sorry!',
                        message: 'Something is wrong. Try again later.',
                        notificationCallModal: false,
                        twillioMessage: '',
                        selectedNumber: '',
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    errorModal: true,
                    message: 'Something is wrong. Try again later.',
                    notificationCallModal: false,
                    twillioMessage: '',
                    selectedNumber: '',
                });
            });
        }
    }
  };

    isTimeInRange(timezone) {
        const now = new Date();
        const userTime = new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: false, timeZone: timezone }).format(now);
        const hour = parseInt(userTime, 10);

        // Check if the time is between 8 AM (8) and 9 PM (21)
        return hour >= 8 && hour < 21;
    }

    dataFromListOfUsersState = () => {
        return this.state.allMessageCsvData;
    }

   render() {
    const csvReport = {
      data: this.getCSVData(),
      headers: this.getHeaders(),
      filename: `${moment().format("YYYY-MM-DD")}-Sales-Data.csv`,
    };

   const onChangeHandler = (event) => {
      this.setState({
        selectedFile: event.target.files[0],
        loaded: 0,
      });
    };

   const fileChange = (e, key, pdfRefs) => {
           let file = pdfRefs.current && pdfRefs.current.files && pdfRefs.current.files[0];
           let blnValid = false;
           if (file) {
               let sFileName = file.name;
               var _validFileExtensions = ['.pdf'];
               for (var j = 0; j < _validFileExtensions.length; j++) {
                   var sCurExtension = _validFileExtensions[j];
                   if (
                       sFileName
                           .substr(
                               sFileName.length - sCurExtension.length,
                               sCurExtension.length
                           )
                           .toLowerCase() === sCurExtension.toLowerCase()
                   ) {
                       blnValid = true;
                       break;
                   }
               }

               if (blnValid) {
                   this.setState({ uploading: true });
                   const data = new FormData();
                   data.append('file', file);

                   axiosPost(`utility/upload-file`, data, true)
                       .then((response) => {
                           if (!response.isError) {
                               // this.setState((prevState) => {
                               //   const links = type === 'homework' ? prevState.homeWorkLink : prevState.classWorkLink;
                               //   links[index][name] = response.data?.fileUrl;
                               //   return { links };
                               // })

                               this.setState({
                                   [key]: response.data?.fileUrl,
                                   uploading: false,
                                   successModal: true,
                                   message: 'Successfully uploaded',
                                   modalTitle: 'Success',
                               });
                           } else {
                               this.setState({
                                   uploading: false,
                                   errorModal: false,
                                   message: "Sorry! can't be uploaded.",
                                   modalTitle: 'Sorry!',
                               });
                           }
                       })
                       .catch((error) => {
                           this.setState({
                               uploading: false,
                               errorModal: false,
                               message: "Sorry! can't be uploaded.",
                               modalTitle: 'Sorry!',
                           });
                       });
               } else {
                   this.setState({
                       errorModal: true,
                       message: 'File format is not supported',
                       modalTitle: 'Sorry',
                   });
               }
           } else {
               this.setState({
                   errorModal: true,
                   message: 'File size is exceeded',
                   modalTitle: 'Sorry',
               });
           }
       };



    let {
      loading,
      allMessages,
      listPage,
      listPageSale,
      totalNewPage,
      totalNewPageSale,
      notificationModal,
      notificationCallModal,
      editModal,
      editSalesModal,
      notificationNotesModal,
      notes,
      modalTitle,
      nameError,
      notesError,
      emailError,
      mobileError,
      classIdError,
      createdByError,
      email,
      name,
      mobile,
      uploading,
      message,
      successModal,
      errorModal,
      filterTeacher,
      allNotificationStatus,
      activeTab,
      message10min,
      subject10min,
      subject24hr,
      subject72hr,
      subjectBefore24hr,
      subjectBefore10min,
      message24hr,
      message72hr,
      messageBefore24hr,
      messageBefore10min,
      pdfLinkMessage10min,
      pdfLinkMessageBefore10min,
      pdfLinkMessageBefore24hr,
      pdfLinkMessage24hr,
      pdfLinkMessage72hr,
      allSalesUser,
      userType,
      allCounts,
      duplicatesNotification,
      zoomLink,
      classList,
      classId,
      createdBy,
      zoomLinkSales,
     userState,
     twillioMessage,
     teachersList,
    teacherId,
    allMessageCsvData
    } = this.state;
    return (
      <>
        <Header key={'index'} isFromDashboard={(userType == 'admin' || userType == 'sub_admin') ? true : false}  allCounts={allCounts}/>
        {/* Page content */}
        <Nav tabs>
          <NavItem>
            <NavLink
                className={classnames({ active: activeTab === '1' }, styles.navLink)}
                onClick={() => {
                  this.toggle("1");
                }}>
              Notifications
            </NavLink>
          </NavItem>
            {(userType === 'admin' || userType === 'sub_admin') && (
              <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '2' }, styles.navLink)}
                    onClick={() => {
                      this.toggle("2");
                    }}
                >
                  Messages
                </NavLink>
              </NavItem>
            )}

            {(userType === 'admin' || userType === 'sub_admin') && (
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '3' }, styles.navLink)}
                        onClick={() => {
                            this.toggle("3");
                        }}
                    >
                        Sales Users
                    </NavLink>
                </NavItem>
            )}
            {(userType === 'admin' || userType === 'sub_admin') && (
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '4' }, styles.navLink)}
                        onClick={() => {
                            this.toggle("4");
                        }}
                    >
                        Duplicates Notifications
                    </NavLink>
                </NavItem>
            )}


        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Container fluid>
              {/* Table */}
              <Row>
                <div className='col'>
                  <Card className='shadow'>
                    <CardHeader className='border-0'>
                      <h3 className='mb-0'>All Messages</h3>
                      {/*<Button style={{marginTop: '-31px'}} className={'float-right'} color='primary' onClick={() => this.addMessage()}>*/}
                      {/*  Add SalesNotification*/}
                      {/*</Button>*/}
                    </CardHeader>

                      {( userType === 'admin' || userType === 'sub_admin') && (
                          <CardHeader
                              className="border-0 availability-header"
                              style={{ display: "flex" }}
                          >
                              <div className="row">
                                  <div className="col-md-3">
                                      <FormGroup className="availability-formgroup">
                                          <InputGroup className="input-group-alternative">
                                              <div style={{ width: "100%", minWidth: "200px" }}>
                                                  <Select
                                                      className="react-select react-select-primary"
                                                      placeholder={"Select Sales"}
                                                      options={[
                                                          { value: '', label: 'All' }, // "All" option
                                                          ...(allSalesUser &&
                                                              allSalesUser.map((item) => ({
                                                                  value: item.name,
                                                                  label: item.name,
                                                              }))),
                                                      ]}
                                                      onChange={(e) => { this.setState({ filterSaleUser: e.value, page: 1 }, () => { this.getSalesNotification() }) }}
                                                  />
                                              </div>
                                          </InputGroup>
                                      </FormGroup>
                                  </div>
                                  <div className="col-md-6">
                                      <FormGroup className="availability-formgroup">
                                          <InputGroup
                                              className="input-group-alternative"
                                          >
                                              <div style={{marginLeft:'50px', marginTop: '0px', width: '100%'}}>
                                                  <Input
                                                      placeholder="Date"
                                                      type="date"
                                                      name="filterFromDate"
                                                      onChange={(e) =>
                                                          this.setState(
                                                              {
                                                                  filterFromDate: e.target.value,
                                                                  page: 1,
                                                              },
                                                              () => {
                                                                  this.getSalesNotification();
                                                              }
                                                          )
                                                      }
                                                  />
                                              </div>
                                          </InputGroup>
                                          <small > TO</small>
                                          <InputGroup
                                              className="input-group-alternative"
                                          >
                                              <div style={{marginLeft:'50px', marginTop: '0px', width: '100%'}}>
                                                  <Input
                                                      placeholder="Date"
                                                      type="date"
                                                      name="filterToDate"
                                                      onChange={(e) =>
                                                          this.setState(
                                                              {
                                                                  filterToDate: e.target.value,
                                                                  page: 1,
                                                              },
                                                              () => {
                                                                  this.getSalesNotification();
                                                              }
                                                          )
                                                      }
                                                  />
                                              </div>

                                          </InputGroup>
                                      </FormGroup>
                                  </div>
                                  <div className="col-md-3">
                                      <FormGroup>
                                              <div>
                                                  <Select
                                                      options={allNotificationStatus}
                                                      placeholder="Notification Status"
                                                      isSearchable={true}
                                                      menuPosition={'fixed'}
                                                      menuPlacement={'bottom'}
                                                      styles={{
                                                          control: styles => ({ ...styles, width: 200 }),
                                                          menu: styles => ({ ...styles, width: 200 }),
                                                      }}
                                                      onChange={(e) =>
                                                          this.setState(
                                                              {
                                                                  notificationStatus: e.value,
                                                                  page: 1,
                                                              },
                                                              () => {
                                                                  this.getSalesNotification();
                                                              }
                                                          )
                                                      }
                                                  />
                                            </div>
                                      </FormGroup>
                                  </div>
                              </div>
                          </CardHeader>
                      )}


                          <CardHeader
                              className="border-0 availability-header"
                              style={{ display: "flex" }}
                          >
                              <div className="row">
                                  <div className="col-md-10">
                                      <FormGroup className="availability-formgroup">
                                          <InputGroup className="input-group-alternative">
                                              <div style={{ width: "100%", minWidth: "200px" }}>
                                                  <Select
                                                      className="react-select react-select-primary"
                                                      placeholder={"Select Status"}
                                                      defaultValue={{value: 'upcoming', label: 'Upcoming'}}
                                                      options={[
                                                          { value: 'upcoming', label: 'Upcoming' },
                                                          { value: 'completed', label: 'Completed' }
                                                      ]}
                                                      onChange={(e) => { this.setState({ filterNotificationStatus: e.value, page: 1 }, () => { this.getSalesNotification() }) }}
                                                  />
                                              </div>
                                          </InputGroup>
                                          <InputGroup >
                                              <div style={{ width: "100%", minWidth: "400px", marginLeft: "40px" }}>
                                                  <input
                                                      placeholder="Search by Name, Email or Mobile Number"
                                                      type="text"
                                                      name="search"
                                                      className="form-control"
                                                      onChange={this.searchNotification}
                                                  />
                                              </div>

                                          </InputGroup>
                                          <InputGroup >
                                              {
                                                 ( userType === 'admin' || userType === 'sub_admin') && (
                                                      <Button
                                                          className="my-4"
                                                          color="primary"
                                                          type="button"
                                                          style={{ marginLeft: '10px' }}
                                                          onClick={() => this.getSalesNotificationCSV()}
                                                      >
                                                          Export to CSV

                                                      </Button>
                                                  )

                                              }
                                              <CSVLink id={'csvData'} style={{display:"none", color: "white" }} {...csvReport}>
                                                  Export to CSV
                                              </CSVLink>

                                          </InputGroup>
                                          <InputGroup >
                                              {
                                                 ( userType === 'admin' || userType === 'sub_admin') && (
                                                      <Button
                                                          className="my-4"
                                                          color="primary"
                                                          type="button"
                                                          style={{ marginLeft: '10px' }}
                                                      >
                                                          <a style={{color: 'white'}} target={'_blank'} href={'https://calendly.com/d/224-z46-sf2/free-1-1-python-class'}>Book Python Class</a>

                                                      </Button>
                                                  )

                                              }

                                          </InputGroup>
                                          <InputGroup >
                                              {
                                                 ( userType === 'admin' || userType === 'sub_admin') && (
                                                      <Button
                                                          className="my-4"
                                                          color="primary"
                                                          type="button"
                                                          style={{ marginLeft: '10px' }}
                                                      >
                                                          <a style={{color: 'white'}} target={'_blank'} href={'https://calendly.com/d/yxq-wyn-wnr/free-1-1-chess-class'}>Book Chess Class</a>

                                                      </Button>
                                                  )

                                              }

                                          </InputGroup>


                                      </FormGroup>

                                  </div>
                              </div>
                          </CardHeader>



                    <Table
                        className='align-items-center table-flush cp;'
                        responsive
                        style={{ marginBottom: '100px' }}
                    >
                      <thead className='thead-light'>
                      <tr>
                         {
                         ( userType === 'admin' || userType === 'sub_admin') && (
                              <th scope='col'></th>
                          )
                        }

                        <th scope='col'>User name</th>
                        <th scope='col'>Class name</th>
                        <th scope='col'>Email</th>
                        <th scope='col'>Mobile Number</th>
                        <th scope='col'>Start Time</th>
                        <th scope='col'>Time Zone</th>
                        <th scope='col'>Created By</th>
                        <th scope='col'>Age</th>
                        <th scope='col'>Before Class</th>
                        <th scope='col'>After Class</th>

                        <th scope='col'>Status</th>

                       <th scope='col'>Reminder</th>
                       <th scope='col'>Notes</th>
                       <th scope='col'>Class Status</th>

                        {/*<th scope='col'>Notification Sent</th>*/}
                        <th scope='col' />
                      </tr>
                      </thead>
                      <tbody>
                      {allMessages &&
                          allMessages.map((value, index) => {
                            const isActiveTime = this.isTimeInRange(value?.timezone || 'Asia/kolkata');
                            const startClass =  value?.startClass ? {color: 'green'} : value.notificationStatus == 'Cancel Class' ? {color: 'red'} :  {color: ''};
                            const [isRegistration] = value?.sections && value?.sections.map((section) => {
                                if(section.classId == value.classId){
                                    return true
                                }
                            })
                            const isActive = Object.values(this.state.messageStates).some(
                                  state => state === 'Connecting' || state === 'On call'
                            )
                            const userStateForMessage = this.state.messageStates[index] || USER_STATE.OFFLINE;
                            const className = classList?.find((item) => item?.classId === value?.classId)?.name;
                            return (
                                value.isActive && (
                                    <tr key={index} style={startClass}>
                                        {
                                           ( userType === 'admin'|| userType === 'sub_admin') && (
                                                <td  className={'table-column-width'} >
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            className='btn-icon-only text-light'
                                                            href='JavaScript:Void(0);'
                                                            role='button'
                                                            size='sm'
                                                            color=''
                                                            onClick={(e) => e.preventDefault()}
                                                        >
                                                            <i className='fas fa-ellipsis-v' />
                                                        </DropdownToggle>
                                                        <DropdownMenu
                                                            className='dropdown-menu-arrow'
                                                            right
                                                        >
                                                            <DropdownItem
                                                                href='JavaScript:Void(0);'
                                                                onClick={() => this.editNotification(index)}
                                                            >
                                                                Edit
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                href='JavaScript:Void(0);'
                                                                onClick={() => this.deleteNotification(index)}
                                                            >
                                                                Delete
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>

                                                    {
                                                        (userStateForMessage === 'Connecting' || userStateForMessage === 'On call') ? (
                                                            <button
                                                                className={'btn btn-danger'}
                                                                onClick={() => this.rejectCall(value.mobile)}
                                                            >Reject
                                                            </button>
                                                        ): (
                                                            isActiveTime ? (
                                                                <button
                                                                    className={'btn btn-white'}
                                                                    disabled={isActive}
                                                                    onClick={() =>this.callInit(value, index)}
                                                                ><i className="fa fa-phone" style={{fontSize:'20px'}} ></i>
                                                                </button>
                                                            ): (
                                                                <></>
                                                            )


                                                        )
                                                    }
                                                    <button onClick={() => this.clientConnect(index)} className={'btn btn-white'}>
                                                        <i className="fa fa-comments" style={{fontSize:'20px'}} aria-hidden="true" ></i>
                                                    </button>








                                                </td>
                                            )
                                        }



                                      <td className={'table-column-width'}>
                                          {isRegistration && (
                                              <i style={{color: '#11cdef', marginRight: '2px'}} className="fa fa-circle"></i>
                                          )}
                                          {value?.isSendBeforeMail && (
                                              <i style={{color: 'green', marginRight: '2px'}}
                                                 className="fa fa-circle"></i>
                                          )}
                                          {value.name}</td>
                                        <td  className={'table-column-width'}>{className || ''}</td>
                                          <td  className={'table-column-width'}>{value.email}</td>
                                          <td>
                                            {value.mobile && (
                                                <a href={"tel:" + value.mobile}>{value.mobile}</a>
                                            )}{" "}
                                          </td>


                                      <td  className={'table-column-width'}>
                                        <Media className='align-items-center'>
                                          <Media>
                                        <span className='mb-0 text-sm'>
                                          {value.startTime
                                              ? moment(value.startTime).format('ll hh:mm A')
                                              : ''}
                                        </span>
                                          </Media>
                                        </Media>
                                      </td>
                                      <td  className={'table-column-width'}>{value?.timezone || ''}</td>
                                      <td  className={'table-column-width'}>{value?.createdBy?.toLocaleUpperCase()}</td>
                                      <td  className={'table-column-width'}>{value?.age || ''}</td>
                                      <td  className={'table-column-width'}>
                                        <div className={'deliver-message'} >
                                            <span className={'deliver-message-style'}>1hr {value.isDeliveredBefore10Min ? <span className={'color-red'}>Delivered</span> : <span className={'color-blue'}>Pending</span>}</span>
                                            <span className={'deliver-message-style'}>24hr {value.isDeliveredBefore24hr ? <span className={'color-red'}>Delivered</span> : <span className={'color-blue'}>Pending</span>}</span>
                                        </div>
                                      </td>
                                      <td  className={'table-column-width'}>
                                        <div className={'deliver-message'} >
                                          <span className={'deliver-message-style'}>1Min {value.isDelivered10Min ? <span className={'color-red'}>Delivered</span>  : <span className={'color-blue'}>Pending</span>}</span>
                                          <span className={'deliver-message-style'}>24hr {value.isDelivered24hr ? <span className={'color-red'}>Delivered</span> : <span className={'color-blue'}>Pending</span>}</span>
                                          <span className={'deliver-message-style'}>72hr {value.isDelivered72hr ? <span className={'color-red'}>Delivered</span> : <span className={'color-blue'}>Pending</span>}</span>
                                        </div>
                                      </td>
                                      <td  className={'table-column-width'}>
                                        <div>
                                          <Select
                                              options={allNotificationStatus}
                                              placeholder="Select Notification Status"
                                              isSearchable={true}
                                              menuPosition={'fixed'}
                                              menuPlacement={'bottom'}
                                              styles={{
                                                control: styles => ({ ...styles, width: 200 }),
                                                menu: styles => ({ ...styles, width: 200 }),
                                              }}
                                              onChange={(e) => this.handleNotificationStatusChange(e, index)}
                                              value={allNotificationStatus.find(option => option.value == value.notificationStatus)}
                                          />
                                        </div>
                                      </td>

                                      {
                                        value.notificationStatus == 'Default' ? (
                                            <td  className={'table-column-width'}>
                                              <button className={'btn btn-primary'} onClick={() => this.sendReminder(value.notificationId, value.classId)}>Send Reminder</button>
                                            </td>
                                          ):(<td width={'100px'}>
                                            { value.sendNotification ?
                                                <button className={'btn btn-danger'} onClick={() => this.stopReminder(value.notificationId)}>Stop Reminder</button>
                                                :
                                                <button className={'btn btn-danger'} disabled>Already Stopped</button>
                                            }

                                        </td>)
                                      }
                                      <td>
                                        <div>
                                            <button className={'btn btn-primary'} onClick={()=> this.addNotes(index)}>Add Notes</button>
                                        </div>
                                      </td>

                                    <td>
                                        <div>
                                            <button className={'btn btn-primary'} onClick={()=> this.startClass(index)}>Start Session</button>
                                        </div>
                                      </td>


                                    </tr>
                                )
                            );
                          })}
                      </tbody>
                    </Table>

                    <CardFooter className='py-4'>
                      <nav aria-label='...'>
                        <Pagination
                            className='pagination justify-content-end mb-0'
                            listClassName='justify-content-end mb-0'
                        >
                          <PaginationItem
                              className={listPage > 1 ? '' : 'disabled'}
                          >
                            <PaginationLink
                                onClick={() => this.changePage('prev')}
                                tabIndex='-1'
                            >
                              <i className='fas fa-angle-left' />
                              <span className='sr-only'>Previous</span>
                            </PaginationLink>
                          </PaginationItem>

                          <PaginationItem className='active'>
                            <PaginationLink onClick={(e) => e.preventDefault()}>
                              {listPage}
                            </PaginationLink>
                          </PaginationItem>

                          <PaginationItem
                              className={totalNewPage > listPage ? '' : 'disabled'}
                          >
                            <PaginationLink onClick={() => this.changePage('next')}>
                              <i className='fas fa-angle-right' />
                              <span className='sr-only'>Next</span>
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </nav>
                    </CardFooter>
                  </Card>
                </div>
                <div className='col-md-4'>
                  {/*<h1>test</h1>*/}
                </div>
              </Row>

            </Container>
          </TabPane>
          <TabPane tabId='2'>
            <div className=''>
              <Card className='bg-secondary shadow border-0'>
                <CardBody className='px-lg-12 py-lg-12'>
                  <Form role='form' className={'justify-content-center'}>
                  <FormGroup className={classIdError ? 'has-danger' : ''}>
                          <InputGroup className="input-group-alternative" styles={{width: '100%'}}>
                              <div style={{ width: "100%", minWidth: "200px" }}>
                                  <Select
                                      style={{ width: "100%", minWidth: "200px" }}
                                      options={[
                                          ...(classList &&
                                              classList.map((item) => ({
                                                  value: item.classId,
                                                  label: item.name,
                                              }))),
                                      ]}
                                      value={
                                          classId && {
                                              value: classId,
                                              label: classList.find((item) => item.classId === classId).name,
                                          }
                                      }
                                      placeholder="All Classes"
                                      onChange={(e) => {
                                          this.changeClassMessage(e.value);
                                          this.setState({ classId: e.value })
                                      }}
                                      isSearchable={true}
                                  />
                              </div>
                          </InputGroup>
                      </FormGroup>
                  <FormGroup className={nameError ? 'has-danger' : ''}>
                            <div className="col-md-4 " >
                                <label className="">After 1 Min</label>
                                <Input className="col-md-10 form-group-input" name='subject10min'
                                       onChange={(e) =>
                                           this.setState({ subject10min: e.target.value })
                                       }
                                       value={subject10min}
                                       placeholder="After 1 min subject" type="text" />
                                <textarea
                                    placeholder='1 Mins'
                                    cols={46}
                                    rows={20}
                                    name='message10min'
                                    value={message10min}
                                    onChange={(e) =>
                                        this.setState({ message10min: e.target.value })
                                    }
                                />
                                {/* Add here upload pdf file  */}
                                <FormGroup>
                                    {
                                        pdfLinkMessage10min &&
                                        <div>
                                            <a href={pdfLinkMessage10min} target={'_blank'}>{pdfLinkMessage10min}</a>
                                        </div>

                                    //     Add delete button here

                                    }

                                    <InputGroup className='input-group-alternative'>
                                        <input
                                            type='file'
                                            name='image1'
                                            id='myFile1'
                                            ref={pdfRefs.input1}
                                            onChange={(e) => fileChange(e, 'pdfLinkMessage10min', pdfRefs.input1)}
                                            multiple={false}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </div>
                        </FormGroup>
                  <FormGroup>
                          <div className="col-md-4 " >
                              <label className="">After 24 Hr</label>
                              <Input className="col-md-10 form-group-input"
                                     name='subject24hr'
                                     onChange={(e) =>
                                         this.setState({ subject24hr: e.target.value })
                                     }
                                     value={subject24hr}
                                     placeholder="After 24 Hr subject"
                                      type="text" />
                              <textarea
                                    placeholder='24 Hrs'
                                    cols={50}
                                    rows={20}
                                    name='message24hr'
                                    value={message24hr}
                                    onChange={(e) =>
                                        this.setState({ message24hr: e.target.value })
                                    }
                                />
                               <FormGroup>
                                  {
                                      pdfLinkMessage24hr &&
                                      <a href={pdfLinkMessage24hr} target={'_blank'}>{pdfLinkMessage24hr}</a>
                                  }

                                  <InputGroup className='input-group-alternative'>
                                      <input
                                          type='file'
                                          name='image'
                                          id='myFile'
                                          ref={pdfRefs.input5}
                                          onChange={(e) => fileChange(e, 'pdfLinkMessage24hr', pdfRefs.input5)}
                                          multiple={false}
                                      />
                                  </InputGroup>
                               </FormGroup>
                          </div>
                      </FormGroup>
                  <FormGroup>
                      <div className="col-md-4 " >
                          <label className="">After 72 Hr</label>
                          <Input className="col-md-10 form-group-input"   name='subject72hr'
                                 onChange={(e) =>
                                     this.setState({ subject72hr: e.target.value })
                                 }
                                 value={subject72hr}
                                 placeholder="After 72 Hr subject" type="text" />
                          <textarea
                                placeholder='72 Hrs'
                                cols={50}
                                rows={20}
                                name='message72hr'
                                value={message72hr}
                                onChange={(e) =>
                                    this.setState({ message72hr: e.target.value })
                                }
                            />
                          <FormGroup>
                              {
                                  pdfLinkMessage72hr &&
                                  <a href={pdfLinkMessage72hr} target={'_blank'}>{pdfLinkMessage72hr}</a>
                              }

                              <InputGroup className='input-group-alternative'>
                                  <input
                                      type='file'
                                      name='image'
                                      id='myFile2'
                                      ref={pdfRefs.input2}
                                      onChange={(e) => fileChange(e, 'pdfLinkMessage72hr', pdfRefs.input2)}
                                      multiple={false}
                                  />
                              </InputGroup>
                          </FormGroup>
                      </div>
                  </FormGroup>
                  <FormGroup>
                      <div className="col-md-4 " >
                          <label className="">Before 1 hour</label>
                          <Input className="col-md-10 form-group-input" name='subjectBefore10min'
                                 onChange={(e) =>
                                     this.setState({ subjectBefore10min: e.target.value })
                                 }
                                 value={subjectBefore10min}
                                 placeholder="Before 1 hour subject" type="text" />
                            <textarea
                                placeholder='1 hour Before'
                                cols={50}
                                rows={20}
                                name='message10minBefore'
                                value={messageBefore10min}
                                onChange={(e) =>
                                    this.setState({ messageBefore10min: e.target.value })
                                }
                            />
                          <FormGroup>
                              {
                                  pdfLinkMessageBefore10min &&
                                  <a href={pdfLinkMessageBefore10min}>{pdfLinkMessageBefore10min}</a>
                              }

                              <InputGroup className='input-group-alternative'>
                                  <input
                                      type='file'
                                      name='image'
                                      id='myFile3'
                                      ref={pdfRefs.input3}
                                      onChange={(e) => fileChange(e, 'pdfLinkMessageBefore10min', pdfRefs.input3)}
                                      multiple={false}
                                  />
                              </InputGroup>
                          </FormGroup>
                      </div>
                  </FormGroup>
                  <FormGroup>
                        <div className="col-md-4 " >
                             <label className="">Before 24 Hrs</label>
                             <Input className="col-md-10 form-group-input"  name='subjectBefore24hr'
                                    onChange={(e) =>
                                        this.setState({ subjectBefore24hr: e.target.value })
                                    }
                                    value={subjectBefore24hr}
                                    placeholder="Before 24 hr subject" type="text" />
                             <textarea
                                placeholder='24 Hrs Before'
                                cols={50}
                                rows={20}
                                name='messageBefore24hr'
                                value={messageBefore24hr}
                                onChange={(e) =>
                                    this.setState({ messageBefore24hr: e.target.value })
                                }
                             />
                            <FormGroup>
                                {
                                    pdfLinkMessageBefore24hr &&
                                    <a href={pdfLinkMessageBefore24hr}>{pdfLinkMessageBefore24hr}</a>
                                }

                                <InputGroup className='input-group-alternative'>
                                    <input
                                        type='file'
                                        name='image'
                                        id='myFile4'
                                        ref={pdfRefs.input4}
                                        onChange={(e) => fileChange(e, 'pdfLinkMessageBefore24hr', pdfRefs.input4)}
                                        multiple={false}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </div>
                  </FormGroup>
                    <div className='text-center'>
                      <Button
                          disabled={uploading}
                          className='my-4'
                          color='primary'
                          type='button'
                          onClick={() => {
                            this.updateNotificationMessage();
                          }}
                      >Update
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </TabPane>
          <TabPane tabId='3'>
              <Container fluid>
                  {/* Table */}
                  <Row>
                      <div className='col'>
                          <Card className='shadow'>
                              <CardHeader className='border-0'>
                                  <h3 className='mb-0'>Sales Users</h3>
                              </CardHeader>
                              <Table
                                  className='align-items-center table-flush cp;'
                                  responsive
                                  style={{ marginBottom: '100px' }}
                              >
                                  <thead className='thead-light'>
                                  <tr>
                                      <th scope='col'>User name</th>
                                      <th scope='col'>Email</th>
                                      <th scope='col'>Zoom Link</th>
                                      <th scope='col'>Status</th>

                                      {/*<th scope='col'>Notification Sent</th>*/}
                                      <th scope='col' />
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {allSalesUser &&
                                      allSalesUser.map((value, index) => {
                                          return (
                                              value.isActive && (
                                                  <tr key={index}>
                                                      <td className={'table-column-width'}>{value.name}</td>
                                                      <td className={'table-column-width'}>{value.email}</td>
                                                      <td className={'table-column-width'}>{value.zoomLink}</td>
                                                      <td className={'table-column-width'}>{value.isActive ? 'Active': 'Inactive'}</td>
                                                      <td  className={'table-column-width'}>
                                                          <UncontrolledDropdown>
                                                              <DropdownToggle
                                                                  className='btn-icon-only text-light'
                                                                  href='JavaScript:Void(0);'
                                                                  role='button'
                                                                  size='sm'
                                                                  color=''
                                                                  onClick={(e) => e.preventDefault()}
                                                              >
                                                                  <i className='fas fa-ellipsis-v' />
                                                              </DropdownToggle>
                                                              <DropdownMenu
                                                                  className='dropdown-menu-arrow'
                                                                  right
                                                              >
                                                                  <DropdownItem
                                                                      href='JavaScript:Void(0);'
                                                                      onClick={() => this.editSalesUsers(index)}
                                                                  >
                                                                      Edit
                                                                  </DropdownItem>
                                                                  <DropdownItem
                                                                      href='JavaScript:Void(0);'
                                                                      onClick={() => this.deleteNotification(index)}
                                                                  >
                                                                      Delete
                                                                  </DropdownItem>
                                                              </DropdownMenu>
                                                          </UncontrolledDropdown>
                                                      </td>
                                                  </tr>
                                              )
                                          );
                                      })}
                                  </tbody>
                              </Table>

                              <CardFooter className='py-4'>
                                  <nav aria-label='...'>
                                      <Pagination
                                          className='pagination justify-content-end mb-0'
                                          listClassName='justify-content-end mb-0'
                                      >
                                          <PaginationItem
                                              className={listPage > 1 ? '' : 'disabled'}
                                          >
                                              <PaginationLink
                                                  onClick={() => this.changePageSale('prev')}
                                                  tabIndex='-1'
                                              >
                                                  <i className='fas fa-angle-left' />
                                                  <span className='sr-only'>Previous</span>
                                              </PaginationLink>
                                          </PaginationItem>

                                          <PaginationItem className='active'>
                                              <PaginationLink onClick={(e) => e.preventDefault()}>
                                                  {listPageSale}
                                              </PaginationLink>
                                          </PaginationItem>

                                          <PaginationItem
                                              className={totalNewPageSale > listPageSale ? '' : 'disabled'}
                                          >
                                              <PaginationLink onClick={() => this.changePageSale('next')}>
                                                  <i className='fas fa-angle-right' />
                                                  <span className='sr-only'>Next</span>
                                              </PaginationLink>
                                          </PaginationItem>
                                      </Pagination>
                                  </nav>
                              </CardFooter>
                          </Card>
                      </div>
                      <div className='col-md-4'>
                          {/*<h1>test</h1>*/}
                      </div>
                  </Row>

              </Container>
          </TabPane>

        <TabPane tabId='4'>
                <Container fluid>
                    {/* Table */}
                    <Row>
                        <div className='col'>
                            <Card className='shadow'>
                                <CardHeader className='border-0'>
                                    <h3 className='mb-0'>Duplicates</h3>
                                </CardHeader>
                                <Table
                                    className='align-items-center table-flush cp;'
                                    responsive
                                    style={{ marginBottom: '100px' }}
                                >
                                    <thead className='thead-light'>
                                    <tr>
                                        <th scope='col'>User name</th>
                                        <th scope='col'>Email</th>
                                        <th scope='col'>Age</th>
                                        <th scope='col'>Count</th>
                                        <th scope='col' />
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {duplicatesNotification &&
                                        duplicatesNotification.map((value, index) => {
                                            return (
                                                    <tr key={index}>
                                                        <td className={'table-column-width'}>{value.name}</td>
                                                        <td className={'table-column-width'}>{value.email}</td>
                                                        <td className={'table-column-width'}>{value.age}</td>
                                                        <td className={'table-column-width'}>{value.count}</td>
                                                    </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>

                                <CardFooter className='py-4'>
                                </CardFooter>
                            </Card>
                        </div>
                        <div className='col-md-4'>
                            {/*<h1>test</h1>*/}
                        </div>
                    </Row>

                </Container>
            </TabPane>

        </TabContent>

        <Modal
            className='modal-dialog-centered'
            size='lg'
            isOpen={notificationModal || editModal}
            toggle={() =>
                this.toggleModal(notificationModal ? 'notificationModal' : 'editModal')
            }
        >
          <div className='modal-body p-0'>
            <Card className='bg-secondary shadow border-0'>
              <CardHeader className='bg-transparent pb-5'>
                <div className='text-muted text-center mt-2 mb-3'>
                  <small>{notificationModal ? 'Add new notification' : 'Edit Notification'}</small>
                </div>
              </CardHeader>
              <CardBody className='px-lg-5 py-lg-5'>
                <Form role='form'>
                  <FormGroup className={classIdError ? 'has-danger' : ''}>
                        <InputGroup className="input-group-alternative" styles={{width: '100%'}}>
                            <div style={{ width: "100%", minWidth: "200px" }}>
                                <Select
                                    style={{ width: "100%", minWidth: "200px" }}
                                    options={[
                                        ...(classList &&
                                            classList.map((item) => ({
                                                value: item.classId,
                                                label: item.name,
                                            }))),
                                    ]}
                                    value={
                                        classId && {
                                            value: classId,
                                            label: classList.find((item) => item.classId === classId).name,
                                        }
                                    }
                                    placeholder="All Classes"
                                    onChange={(e) => {
                                        this.setState(
                                            { classId: e.value}
                                        );
                                    }}
                                    isSearchable={true}
                                />
                            </div>
                        </InputGroup>
                    </FormGroup>

                  <FormGroup className={createdByError ? 'has-danger' : ''}>
                        <InputGroup className="input-group-alternative" styles={{width: '100%'}}>
                            <div style={{ width: "100%", minWidth: "200px" }}>
                                <Select
                                    className="react-select react-select-primary"
                                    placeholder={"Select Sales"}
                                    options={[
                                        { value: '', label: 'All' }, // "All" option
                                        ...(allSalesUser &&
                                            allSalesUser.map((item) => ({
                                                value: item.name,
                                                label: item.name,
                                            }))),
                                    ]}
                                    value={
                                        createdBy && {
                                            value: createdBy,
                                            label: allSalesUser.find((item) => item.name === createdBy).name,
                                        }
                                    }
                                    onChange={(e) => { this.changeSales(e.value) }}
                                />
                            </div>
                        </InputGroup>
                    </FormGroup>
                  <FormGroup className={nameError ? 'has-danger' : ''}>
                    <label>Name</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                          placeholder='Name'
                          type='text'
                          name='name'
                          value={name}
                          onChange={(e) =>
                              this.setState({ name: e.target.value })
                          }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={emailError ? 'has-danger' : ''}>
                    <label>Email</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                          placeholder='Email'
                          type='text'
                          name='email'
                          value={email}
                          onChange={(e) =>
                              this.setState({ email: e.target.value })
                          }
                      />
                    </InputGroup>
                  </FormGroup>
                <FormGroup className={mobileError || (mobile && mobile[0] === '1') ? 'has-danger' : ''}>
                    <label>Mobile Number (No Dashes Or Spaces OR Country Code. US number only)</label>
                    <InputGroup className='input-group-alternative'>
                      <Input
                          placeholder='Mobile Number'
                          ype='text'
                          name='mobile'
                          value={mobile}
                          onChange={(e) => {
                              const input = e.target.value.replace(/\D/g,'').slice(0, 15); // remove non-digits and
                              // limit to 10 digits
                              this.setState({ mobile: input });
                          }}
                      />
                    </InputGroup>
                    {mobileError && <div className="text-danger">Please enter a valid mobile number.</div>}
                    {mobile && mobile[0] === '1' && <div className="text-danger">Please enter a valid US mobile number (without country code).</div>}
                  </FormGroup>

                <FormGroup >
                        <label>Zoom Link</label>
                        <InputGroup className='input-group-alternative'>
                            <Input
                                disabled
                                placeholder='Zoom Link'
                                ype='text'
                                name='zoomLink'
                                value={zoomLinkSales}
                            />
                        </InputGroup>
                </FormGroup>

                  <div className='text-center'>
                    {uploading ? (
                        <span style={{ color: 'red' }}>Wait...</span>
                    ) : null}

                    <Button
                        disabled={uploading || loading ? true : false}
                        className='my-4'
                        color='primary'
                        type='button'
                        onClick={() => {
                          this.addOrUpdateNotification(notificationModal ? false : true);
                        }}
                    >
                      {notificationModal ? 'Save' : 'Update'}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>


          <Modal
              className='modal-dialog-centered'
              size='lg'
              isOpen={notificationCallModal}
              toggle={() =>
                  this.toggleModal('notificationCallModal')
              }
          >
              <div className='modal-body p-0'>
                  <Card className='bg-secondary shadow border-0'>
                      <CardHeader className='bg-transparent pb-5'>
                          <div className='text-muted text-center'>
                              <small>{'Message'}</small>
                          </div>
                      </CardHeader>
                      <CardBody className='px-lg-5 py-lg-5'>
                          <Form role='form'>
                              <FormGroup className={notesError ? 'has-danger' : ''}>
                                  <InputGroup className='input-group-alternative'>
                                     <textarea
                                         placeholder='Enter Message'
                                         cols={100}
                                         rows={5}
                                         value={twillioMessage}
                                         onChange={(e) =>
                                             this.setState({ twillioMessage: e.target.value })
                                         }
                                     />
                                  </InputGroup>
                              </FormGroup>

                              <div className="row text-right">
                                  <div className="ml-auto" style={{marginRight: '10px'}}>
                                      <Button
                                          disabled={!!loading}
                                          color="primary"
                                          type="button"
                                          onClick={() => this.replyMessageResponse()}
                                      >
                                           Send Message
                                      </Button>
                                  </div>
                              </div>
                          </Form>
                      </CardBody>
                  </Card>
              </div>
          </Modal>


          <Modal
              className='modal-dialog-centered'
              size='lg'
              isOpen={notificationNotesModal}
              toggle={() =>
                  this.toggleModal('notificationNotesModal')
              }
          >
              <div className='modal-body p-0'>
                  <Card className='bg-secondary shadow border-0'>
                      <CardHeader className='bg-transparent pb-5'>
                          <div className='text-muted text-center mt-2 mb-3'>
                              <small>{'Add Notes'}</small>
                          </div>
                      </CardHeader>
                      <CardBody className='px-lg-5 py-lg-5'>
                          <Form role='form'>
                              <FormGroup className={notesError ? 'has-danger' : ''}>
                                  <label>Notes</label>
                                  <InputGroup className='input-group-alternative'>
                                     <textarea
                                         placeholder='Enter notes'
                                         cols={100}
                                         rows={5}
                                         value={notes}
                                         onChange={(e) =>
                                             this.setState({ notes: e.target.value })
                                         }
                                     />
                                  </InputGroup>
                              </FormGroup>

                              <div className='text-center'>
                                  {uploading ? (
                                      <span style={{ color: 'red' }}>Wait...</span>
                                  ) : null}

                                  <Button
                                      disabled={uploading || loading ? true : false}
                                      className='my-4'
                                      color='primary'
                                      type='button'

                                      onClick={() => {
                                          this.updateNotificationNotes();
                                      }}
                                  >
                                      {'Save'}
                                  </Button>
                              </div>
                          </Form>
                      </CardBody>
                  </Card>
              </div>
          </Modal>

          <Modal
              className='modal-dialog-centered'
              size='lg'
              isOpen={editSalesModal}
              toggle={() =>
                  this.toggleModal('editSalesModal')
              }
          >
              <div className='modal-body p-0'>
                  <Card className='bg-secondary shadow border-0'>
                      <CardHeader className='bg-transparent pb-5'>
                          <div className='text-muted text-center mt-2 mb-3'>
                              <small>{'Edit Sales User'}</small>
                          </div>
                      </CardHeader>
                      <CardBody className='px-lg-5 py-lg-5'>
                          <Form role='form'>
                              <FormGroup className={nameError ? 'has-danger' : ''}>
                                  <label>Name</label>
                                  <InputGroup className='input-group-alternative'>
                                      <Input
                                          placeholder='Name'
                                          type='text'
                                          name='name'
                                          value={name}
                                          onChange={(e) =>
                                              this.setState({ name: e.target.value })
                                          }
                                      />
                                  </InputGroup>
                              </FormGroup>
                              <FormGroup className={emailError ? 'has-danger' : ''}>
                                  <label>Email</label>
                                  <InputGroup className='input-group-alternative'>
                                      <Input
                                          placeholder='Email'
                                          type='text'
                                          name='email'
                                          value={email}
                                          onChange={(e) =>
                                              this.setState({ email: e.target.value })
                                          }
                                      />
                                  </InputGroup>
                              </FormGroup>
                              <FormGroup className={emailError ? 'has-danger' : ''}>
                                  <label>Zoom Link</label>
                                  <InputGroup className='input-group-alternative'>
                                      <Input
                                          placeholder='Zoom Link'
                                          type='text'
                                          name='zoomLink'
                                          value={zoomLink}
                                          onChange={(e) =>
                                              this.setState({ zoomLink: e.target.value })
                                          }
                                      />
                                  </InputGroup>
                              </FormGroup>
                              <FormGroup>
                                  <label>Map with Coach</label>
                                  <InputGroup className='input-group-alternative'>
                                      <Select
                                          className={styles['custom-select'] + styles['rbc-event-label']}
                                          styles={customStyles} // Apply the custom styles
                                          options={teachersList}
                                          value={teachersList && teachersList.find((option) => option.value === teacherId) || ''}
                                          placeholder="All Coaches"
                                          isSearchable={true}
                                          onChange={(e) => {
                                              this.setState(
                                                  { filterTeacher: e.value, page: 1, teacherId: e.value, teacherName: e.label, teacherTimezone: e.timeZone },
                                                  () => {
                                                  }
                                              );
                                          }}
                                      />
                                  </InputGroup>
                              </FormGroup>


                              <div className='text-center'>
                                  {uploading ? (
                                      <span style={{ color: 'red' }}>Wait...</span>
                                  ) : null}

                                  <Button
                                      disabled={uploading || loading ? true : false}
                                      className='my-4'
                                      color='primary'
                                      type='button'
                                      onClick={() => {
                                          this.UpdateSalesUser();
                                      }}
                                  >
                                      {'Update'}
                                  </Button>
                              </div>
                          </Form>
                      </CardBody>
                  </Card>
              </div>
          </Modal>


        <Modal
            className='modal-dialog-centered'
            isOpen={this.state.successModal || this.state.errorModal}
            toggle={() =>
                successModal
                    ? this.toggleModal('successModal')
                    : errorModal
                        ? this.toggleModal('errorModal')
                        : null
            }
        >
          <div className='modal-header'>
            <h5 className='modal-title' id='successModalLabel'>
              {modalTitle}
            </h5>
            <button
                aria-label='Close'
                className='close'
                data-dismiss='modal'
                type='button'
                onClick={() =>
                    successModal
                        ? this.toggleModal('successModal')
                        : errorModal
                            ? this.toggleModal('errorModal')
                            : null
                }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          {this.state.successModal ? (
              <div className='modal-body' style={{ color: 'green' }}>
                {message}
              </div>
          ) : this.state.errorModal ? (
              <div className='modal-body' style={{ color: 'red' }}>
                {message}
              </div>
          ) : null}
          <div className='modal-footer'>
            <Button
                color='primary'
                type='button'
                onClick={() =>
                    successModal
                        ? this.toggleModal('successModal')
                        : errorModal
                            ? this.toggleModal('errorModal')
                            : null
                }
            >
              Ok
            </Button>
          </div>
        </Modal>

      </>
    );
  }
}

export default SalesNotification;
