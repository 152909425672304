/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
    Button,
    Card, CardBody, CardHeader,
    Container, Form, FormGroup, Input, InputGroup, Media, Modal,
    Row, Table,
} from "reactstrap";
import Header from "../../components/Headers/Header.js";
import AudioPlayer from "react-h5-audio-player";
import {CopyToClipboard} from "react-copy-to-clipboard";
import moment from "moment/moment";
import {axiosGet, axiosPost} from "../../util/apiConfig";
import {Device} from "@twilio/voice-sdk";
import {databaseTimeFormat, dateForPaymentListing, fullClass, halfClass} from "../../util/constance";
import Select from "react-select";

const USER_STATE = {
    CONNECTING : "Connecting",
    READY : "Ready",
    ON_CALL : "On call",
    OFFLINE : "Offline",
}


class StudentManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
   const { location } = this.props;
   const searchParams = new URLSearchParams(location.search);
   const sectionId = searchParams.get('sectionId');
   const classId = localStorage.getItem("classId");
    this.setState(
      {
        allSessionData: [],
        allCounts: [],
        voiceMessageList: [],
        userState: USER_STATE.OFFLINE,
        notificationCallModal: false,
        messageStates: {},
        teachersList: [],
        textMessageList: [],
        sectionDetail: {},
        sectionId: sectionId ? sectionId : null,
        classId: classId ? classId : null,
        replyMessageError: false,
        sectionReport: [],
        paymentsInfo: [],
        paymentsSubscription: [],
        refundAmounts: {},
        numberOfClasses: {},
      },
      () => {
          if(sectionId){
              this.getSectionDetail();
              this.getPaymentsInfo();
              this.getPaymentSubscription();
          }
          this.getAllSections();
      }
    );
  }

 getPaymentSubscription = () => {
     const {sectionId} = this.state;
     axiosPost(`payment/get-subscription`,{sectionId})
         .then((response) => {
             if (!response.isError) {
                 this.setState({
                     paymentsSubscription: response.data.data || []
                 });
             } else {
                 this.setState({
                     paymentsSubscription: [],
                 });
             }
         })
         .catch((error) => {
             this.setState({
                 paymentsSubscription: [],
             });
         });
  }

 getPaymentsInfo = () => {
     const {sectionId} = this.state;
     axiosGet(`payment/get-payments-info/${sectionId}`)
         .then((response) => {
             if (!response.isError) {
                 this.setState({
                     paymentsInfo: response.data.data || []
                 });
             } else {
                 this.setState({
                     paymentsInfo: [],
                 });
             }
         })
         .catch((error) => {
             this.setState({
                 paymentsInfo: [],
             });
         });
  }

  getAllSections = () => {
        this.setState({ loading: false });
        axiosGet(`section/get-sections-dropdown`)
            .then((response) => {
                if (!response.isError) {
                    this.setSectionData(response.data);
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    setSectionData = (sectionData) => {
        let newObj = [];
        const sectionName = sectionData.find((data) => data.sectionId == this.state.sectionId || null);
        sectionData.map((data) => {
            let tt = { value: data.sectionId, label: data.name };
            newObj.push(tt);
        });
        let allSectionsData = newObj;
        this.setState({ allSectionsData, filterSectionName: sectionName?.sectionId || null });
    };

    getAllTwilioReceivedMessageUsers = (from) => {
        this.setState({selectedMessage: from});
        axiosGet(`message/get-all-twilio-received-message-users/${from}`)
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        textMessageList: response.data,
                    });
                } else {
                    this.setState({
                        textMessageList: [],
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    textMessageList: [],
                });
            });
    }

    getSectionDetail = () => {
        // API --
        axiosGet(`section/get-section-by-id/${this.state.sectionId}`)
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        sectionDetail: response?.data?.sectionDetails || {},
                    }, () => {
                        this.getSectionReport(this.state.sectionId);
                        this.getAllTwilioReceivedMessageUsers(response?.data?.sectionDetails?.mobileNumber.replace('-', '') || '');
                    });
                } else {
                    this.setState({
                        sectionDetail: {},
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    sectionDetail: {},
                });
            });
    };

    clientConnect = (data) => {
        this.setState({
            notificationCallModal: true,
            selectedNumber: data?.mobileNumber.replace('-', '') || ''
        });
    };

    rejectCall = ()=>{
        const {device, selectedNumber} = this.state
        device.emit("disconnect");
        device.emit("cancel");
        device.emit("reject");
        device.disconnectAll({params: {To: selectedNumber} });
    }

    handleCall = async (sale, index) => {
        const confirm = window.confirm(`Are you sure want to call? \nPlease verify details,\nMobile: ${sale.mobileNumber}, `)
        if(!confirm){
            return false;
        }
        const {device, userType } = this.state;
        device.emit("connect");
        device.connect({params: {To: `${sale.mobileNumber.replace('-', '')}`, userType } }).then((call) => {
            call.on("accept", () => {
                this.setState({userState: USER_STATE.ON_CALL})
                console.log("call accepted");
            });
            call.on("disconnect", () => {
                console.log("The call has been disconnected.");
                this.setState({userState: USER_STATE.READY})
            });
            call.on("reject", () => {
                console.log("The call was rejected.");
            });
            call.on("cancel", () => {
                console.log("The call was rejected.");
                this.setState({userState: USER_STATE.READY})
            });
        })
    };

    callInit = (sale, index) => {
        axiosPost('sales/token', {})
            .then((response) => {
                if (!response.isError) {
                    this.setState(
                        {
                            loading: false
                        },
                        () => {
                            const token = response.data.token;
                            const device = new Device(token, {
                                logLevel: 1,
                                codecPreferences: ["opus", "pcmu"],
                            });
                            device.register();
                            this.setState({device})
                            device.addListener("connect", (device) => {
                                console.log("Connect event listener added .....");
                                return device;
                            });
                            device.on("registered", () => {
                                console.log("Agent registered");
                                this.setState({userState: USER_STATE.READY});
                            });
                            device.on("connect", (connection) => {
                                console.log("Call connect");
                                this.setState({userState: USER_STATE.ON_CALL})
                            });
                            device.on("disconnect", () => {
                                console.log("Disconnect event");
                                this.setState({userState: USER_STATE.READY})
                            });

                            setTimeout(()=>{
                                this.handleCall(sale, index)
                            }, 1000)

                            return () => {
                                device.destroy();
                                this.setState({device: undefined})
                                this.setState({userState: USER_STATE.OFFLINE})
                            };
                        }
                    );
                } else {
                    this.setState({
                        loading: false,
                        errorModal: true,
                        modalTitle: 'Sorry!',
                        message: 'Something is wrong. Try again later.',
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    errorModal: true,
                    modalTitle: 'Sorry!',
                    message: 'Something is wrong. Try again later.',
                });
                console.log(error);
            });
    }

    replyMessageResponseMessage = () => {
        const { selectedMessage } = this.state;
        let { replyMessage, loading } = this.state;
        if(!replyMessage || replyMessage == ''){
            return alert('Please enter reply message');
        }
        if (replyMessage && !loading) {
            this.setState({ loading: true });
            let dataToSend = {
                message: replyMessage,
                to: selectedMessage,
            };
            // API -
            axiosPost('message/send-reply', dataToSend)
                .then((response) => {
                    if (!response.isError) {
                        this.getAllTwilioReceivedMessageUsers(selectedMessage);
                        this.setState(
                            {
                                loading: false,
                                successModal: true,
                                modalTitle: 'Success',
                                message: 'Successfully added',
                                replyMessage: '',
                                to: '',
                            },
                            () => {
                            }
                        );
                    } else {
                        this.setState({
                            loading: false,
                            modalTitle: 'Sorry!',
                            message: 'Something is wrong. Try again later.',
                            replyMessage: '',
                            to: '',
                            replyMessageError: false,
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        errorModal: true,
                        message: 'Something is wrong. Try again later.',
                        replyMessage: '',
                        to: '',
                    });
                });
        }
    };

    replyMessageResponse = () => {
        let confirm = window.confirm('Are you sure want send message?');
        if (confirm) {
            let { twillioMessage, loading, selectedNumber } = this.state;
            if(!twillioMessage || twillioMessage == ''){
                return alert('Please enter message');
            }
            if (twillioMessage && !loading) {
                this.setState({ loading: true });
                let dataToSend = {
                    message: twillioMessage,
                    to: `${selectedNumber}`,
                };
                // API -
                axiosPost('message/send-reply', dataToSend)
                    .then((response) => {
                        if (!response.isError) {
                            this.setState(
                                {
                                    loading: false,
                                    notificationCallModal: false,
                                    twillioMessage: '',
                                    selectedNumber: '',
                                },
                                () => {
                                    this.getAllTwilioReceivedMessageUsers(selectedNumber);
                                }
                            );
                        } else {
                            this.setState({
                                loading: false,
                                modalTitle: 'Sorry!',
                                message: 'Something is wrong. Try again later.',
                                notificationCallModal: false,
                                twillioMessage: '',
                                selectedNumber: '',
                            });
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                            errorModal: true,
                            message: 'Something is wrong. Try again later.',
                            notificationCallModal: false,
                            twillioMessage: '',
                            selectedNumber: '',
                        });
                    });
            }
        }
    };

    getAllTeachers = () => {
        let url = 'teacher/list-of-all-teacher?status=Coach Pending';
        const { classId } = this.state;
        if (classId) {
            url = `teacher/list-of-all-teacher/${classId}?status=Coach Pending`;
        }
        axiosGet(url, {} )
            .then((response) => {
                if (!response.isError) {
                    this.setState({
                        teachersList: response.data?.result || []
                    });
                } else {
                    this.setState({
                        teachersList: [],
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    teachersList: [],
                });
            });
    };

    isTimeInRange(timezone) {
        const now = new Date();
        const userTime = new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: false, timeZone: timezone }).format(now);
        const hour = parseInt(userTime, 10);

        // Check if the time is between 8 AM (8) and 9 PM (21)
        return hour >= 8 && hour < 21;
    }

    getSectionReport = (sectionId) => {
        this.setState({ loading: true });
        axiosGet(`section/get-section-report-by-id/${sectionId}`)
            .then((response) => {
                if (!response.isError) {
                    this.setState({ sectionReport: response.data,loading: false });
                } else {
                    this.setState({sectionReport: [], loading: false });
                }
            })
            .catch((error) => {
                this.setState({sectionReport: [], loading: false });
            });
    }

    getSectionInfo = (value) => {
        window.open(`/admin/student-management?sectionId=${value}`, "_self");
    };

    handleRefundAmountChange = (id, value, amount) => {
        this.setState(prevState => ({
            refundAmounts: {
                ...prevState.refundAmounts,
                [id]: value,
                amount: amount
            }
        }));
    };

    handleNoOfClasses = (id, value) => {
        this.setState(prevState => ({
            numberOfClasses: {
                ...prevState.numberOfClasses,
                [id]: value,
            }
        }));
    };

    pauseSubscription = () => {
        const {sectionId} = this.state;
        axiosPost('payment/pause', {sectionId})
            .then((response) => {
                if (!response.isError) {
                    this.setState(
                        {
                            loading: false,
                            successModal: true,
                            modalTitle: 'Success',
                            message: 'Subscription Paused Successfully',
                            replyMessage: '',
                            to: '',
                        },
                        () => {
                            this.getPaymentsInfo();
                            this.getPaymentSubscription();
                        }
                    );
                } else {
                    this.setState({
                        loading: false,
                        modalTitle: 'Sorry!',
                        message: 'Something is wrong. Try again later.',
                        replyMessage: '',
                        to: '',
                        replyMessageError: false,
                    });
                }
            })
            .catch((error) => {
                this.setState({ loading: false })
            });
    }

    resumeSubscription = () => {
        const {sectionId} = this.state;
        axiosPost('payment/resume', {sectionId})
            .then((response) => {
                if (!response.isError) {
                    this.setState(
                        {
                            loading: false,
                            successModal: true,
                            modalTitle: 'Success',
                            message: 'Subscription Resumed Successfully',
                            replyMessage: '',
                            to: '',
                        },
                        () => {
                            this.getPaymentsInfo();
                            this.getPaymentSubscription();
                        }
                    );
                } else {
                    this.setState({
                        loading: false,
                        modalTitle: 'Sorry!',
                        message: 'Something is wrong. Try again later.',
                        replyMessage: '',
                        to: '',
                        replyMessageError: false,
                    });
                }
            })
            .catch((error) => {
                this.setState({ loading: false })
            });
    }

    handleRefund = (paymentIntentId, amount, stripeAmount, numberOfClasses) => {
        if(amount < 0 || amount > stripeAmount) {
            alert('Please enter valid amount');
            return;
        }
        this.setState({ loading: true })
        axiosPost('payment/refund', { paymentIntentId, amount, sectionId: this.state.sectionId, numberOfClasses })
            .then((response) => {
                if (!response.isError) {
                    this.setState(
                        {
                            loading: false,
                            successModal: true,
                            modalTitle: 'Success',
                            message: 'Refund Successfully',
                            replyMessage: '',
                            to: '',
                        },
                        () => {
                            this.getPaymentsInfo();
                        }
                    );
                } else {
                    this.setState({
                        loading: false,
                        modalTitle: 'Sorry!',
                        message: 'Something is wrong. Try again later.',
                        replyMessage: '',
                        to: '',
                        replyMessageError: false,
                    });
                }
            })
            .catch((error) => {
                this.setState({ loading: false })
            });
    };


    render() {
    let {
    userState,
    sectionDetail,
    notificationCallModal,
    notesError,
    twillioMessage,
    loading,
    textMessageList,
    replyMessage,
    sectionReport,
    filterSectionName,
    allSectionsData,
    paymentsInfo,
    refundAmounts,
    numberOfClasses,
    paymentsSubscription
    } = this.state;
    const isActive =  userState === 'Connecting' || userState === 'On call';
    const activeDays = sectionDetail?.daysOfWeek?.filter(day => day.isActive).map(day => day.name);
    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const isActiveTime = this.isTimeInRange(sectionDetail?.localTimeZone || 'Asia/kolkata');
        let filterSectionNameLable = filterSectionName
            ? allSectionsData
                .filter((_data) => _data.value == filterSectionName)
                .map(({ label }) => label)
            : null;
    return (
      <>
        <Header  />
        {/* Page content */}
         {/*Create two box in row*/}
        <Container fluid>
        <Row>
          <div className="col col-12 ">
            <div className='col'>
                <Card className='shadow' >
                    <CardHeader className='border-0'>
                        <div className={'row'}>
                            <div className={'col-6'}>
                                <div className={'justify-content-start'}>
                                    {
                                        <div style={{ width: "50%" }}>
                                                <Select
                                                    options={allSectionsData}
                                                    placeholder="Select section"
                                                    value={
                                                        filterSectionName
                                                            ? {
                                                                value: filterSectionName,
                                                                label: filterSectionNameLable,
                                                            }
                                                            : filterSectionName
                                                    }
                                                    onChange={(e) =>
                                                        this.setState(
                                                            {
                                                                filterSectionName: e.value,
                                                            },
                                                            () => {
                                                                this.getSectionInfo(e.value);
                                                            }
                                                        )
                                                    }
                                                    isSearchable={true}
                                                />
                                        </div>
                                    }
                                    {
                                        sectionDetail && sectionDetail?.teacherName
                                            ? <h3 className='mb-0' style={{marginTop: '10px', marginLeft: '5px'}}>Coach Name: <span style={{color: 'blue'}}>{sectionDetail?.teacherName || ''}</span> , Class Length: <span style={{color: 'blue'}}> {sectionDetail.classLength == fullClass
                                                ? "Full  (50 mins)"
                                                : sectionDetail.classLength == halfClass
                                                    ? "Half (25 mins)"
                                                    : ""}{" "}</span>, Days: <span style={{color: 'blue'}}>{
                                                activeDays && activeDays.map(day => {
                                                    return (
                                                        <a>{day + " "}</a>
                                                    )
                                                })
                                            }</span> </h3>
                                            : ''
                                    }
                                </div>
                            </div>
                            <div className={'col-6'}>
                                <div className={'justify-content-center'}>
                                    {
                                        sectionDetail && sectionDetail?.publicUserUrl && sectionDetail?.publicUserUrl[0].email
                                            ? <h3 className='mb-0'>{sectionDetail?.publicUserUrl[0]?.email || ''}</h3>
                                            : ''
                                    }
                                </div>
                                <div>
                                    <div style={{float: 'left', marginTop: '10px'}}>
                                        {sectionDetail?.mobileNumber && (
                                            <a href={"tel:" + sectionDetail?.mobileNumber}>{sectionDetail?.mobileNumber}</a>
                                        )}{" "}
                                    </div>
                                    <div style={{float: "right"}}>
                                        {
                                            (userState === 'Connecting' || userState === 'On call') ? (
                                                <button
                                                    className={'btn btn-danger'}
                                                    onClick={() => this.rejectCall(sectionDetail?.mobileNumber)}
                                                ><i className="fa fa-phone" style={{fontSize:'20px'}} ></i>
                                                </button>
                                            ): (
                                                isActiveTime && sectionDetail?.mobileNumber ? (
                                                    <button
                                                        className={'btn btn-white'}
                                                        disabled={isActive}
                                                        onClick={() =>this.callInit(sectionDetail)}
                                                    ><i className="fa fa-phone" style={{fontSize:'20px'}} ></i>
                                                    </button>
                                                ): (
                                                    <></>
                                                )
                                            )
                                        }
                                        {
                                            sectionDetail?.mobileNumber ? (
                                                <button onClick={() => this.clientConnect(sectionDetail)} className={'btn btn-white'}>
                                                    <i className="fa fa-comments" style={{fontSize:'20px'}} aria-hidden="true" ></i>
                                                </button>
                                            )  : ''
                                        }

                                    </div>

                                </div>

                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Row className={'row'}>
                            <div className='col-6'>
                                <div style={{width: '100%', height: '300px'}}>
                                    {
                                        sectionDetail && sectionDetail?.publicUserUrl && (
                                            <iframe
                                                src={'https://fiverkids.com/userdashboardpreview/' + sectionDetail.publicUserUrl[0].publicUrl || ''}
                                                width='100%'
                                                height='500px'
                                                frameBorder='0'
                                                title='iframe'></iframe>
                                        )
                                    }
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="message-list col" style={{height: '470px'}}>
                                    <div style={{ marginTop: '40px' }}>
                                        {textMessageList &&
                                            textMessageList.map((value, index) => {
                                                const float = value?.direction == 'outbound-api' ? 'right' : 'left';
                                                const color = value?.direction == 'outbound-api' ? '#f8cfcf' : '#c4e1ea';
                                                return (
                                                    <>
                                                        <div
                                                            key={index}
                                                            style={{
                                                                backgroundColor: color,
                                                                padding: '10px',
                                                                marginBottom: '10px',
                                                                borderRadius: '8px',
                                                                float: float,
                                                                clear: 'both',
                                                                width: '50%',
                                                            }}
                                                        >
                                                            <div>
                                                                <span style={{fontWeight: 'bold', fontStyle: 'initial', fontSize: 'large'}}>{value.body}</span>
                                                            </div>
                                                            <div style={{ marginTop: '5px' }}>
                                                                <span>{moment(value.dateCreated).format(dateForPaymentListing)}</span>
                                                                <span>&nbsp;{moment(value.dateCreated).format(databaseTimeFormat)}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div>
                                    { textMessageList && textMessageList.length > 0 && (
                                        <div style={{ display: 'flex', justifyContent: 'end', marginRight: '5px' }}>
                                            <Input
                                                placeholder='Message'
                                                type='text'
                                                name='className'
                                                style={{ width: '100%'}}
                                                value={replyMessage}
                                                onChange={(e) =>
                                                    this.setState({
                                                        replyMessage: e.target.value,
                                                        replyMessageError: false,
                                                    })
                                                }
                                            />
                                            <Button
                                                color='primary'
                                                type='button'
                                                onClick={() => this.replyMessageResponseMessage()}
                                            >
                                                Send
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Row>
                        {/*Create Line gap */}
                        <hr className='my-10' style={{border:' 1px solid #a69a9a'}}/>
                        <Row className={'row'}>
                            <div className='col-9'>
                                <div className={'row'}>
                                    {paymentsSubscription && paymentsSubscription.length > 0 && (
                                        <div>
                                            {paymentsSubscription[0]?.pause_collection && paymentsSubscription[0]?.pause_collection.behavior == 'void' && (
                                                <div>
                                                    <Button
                                                        style={{marginBottom: '20px'}}
                                                        onClick={this.resumeSubscription}
                                                        color='success'
                                                        type='button'
                                                    >
                                                        Resume Subscription
                                                    </Button>
                                                </div>
                                            )}
                                            {!paymentsSubscription[0]?.pause_collection && (
                                                <div>
                                                    <Button
                                                        style={{marginBottom: '20px'}}
                                                        onClick={this.pauseSubscription}
                                                        color='danger'
                                                        type='button'
                                                    >
                                                        Pause Subscription
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>

                                {
                                    paymentsInfo && paymentsInfo.length > 0 && (
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                            <th scope="col">Date</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">No. of classes</th>
                                            <th scope="col">Refund ($)</th>
                                            <th scope="col">Action</th>
                                            <th scope="col">Refunded</th>
                                            </thead>
                                            <tbody>
                                            {
                                                paymentsInfo.map((data, index) => (
                                                    <tr key={index}>
                                                        <td>{moment.unix(data.created).format('YYYY-MM-DD')}</td>
                                                        <td>{data.status}</td>
                                                        <td>{currencyFormatter.format(data.amount / 100)}</td>
                                                        <td>
                                                            {((index === 0 || index === 1) && data.status == 'succeeded') && (
                                                                <Input
                                                                    max={100}
                                                                    type="number"
                                                                    value={numberOfClasses[data.payment_intent] || ''}
                                                                    onChange={(e) => this.handleNoOfClasses(data.payment_intent, e.target.value)}
                                                                    min="0"
                                                                    step="1"
                                                                />
                                                            )}
                                                        </td>
                                                        <td>
                                                            {((index === 0 || index === 1) && data.status == 'succeeded') && (
                                                                <Input
                                                                    max={data.amount * 0.9}
                                                                    type="number"
                                                                    value={refundAmounts[data.payment_intent] || ''}
                                                                    onChange={(e) => this.handleRefundAmountChange(data.payment_intent, e.target.value, data.amount)}
                                                                    min="0"
                                                                    step="1"
                                                                />
                                                            )}
                                                        </td>

                                                        <td>
                                                            {((index === 0 || index === 1) && data.status == 'succeeded') && (
                                                                <Button className="btn btn-success" disabled={loading} onClick={() => this.handleRefund(data.payment_intent, parseInt((refundAmounts[data.payment_intent] * 100).toFixed(0)), data.amount, numberOfClasses[data.payment_intent] * 1)}>
                                                                    Refund
                                                                </Button>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {
                                                                data.refunds && data.refunds.data && data.refunds.data.length > 0 && (
                                                                    <Table className="align-items-center table-flush" responsive>
                                                                        <thead className="thead-light">
                                                                        <th scope="col">Date</th>
                                                                        <th scope="col">Amount</th>
                                                                        <th scope="col">Classes</th>
                                                                        </thead>
                                                                        <tbody>
                                                                        {
                                                                            data.refunds.data.map((refund, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{moment.unix(refund.created).format('YYYY-MM-DD')}</td>
                                                                                    <td>{currencyFormatter.format(refund.amount / 100)}</td>
                                                                                    <td>{refund?.metadata?.numberOfClasses || ''}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                        </tbody>
                                                                    </Table>
                                                                )
                                                            }

                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </Table>
                                    )
                                }
                            </div>
                            <div className='col-3'>
                                <div>
                                    <h3>Student Reports</h3>
                                </div>
                                {
                                    sectionReport && sectionReport.length > 0 && (
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                            <th scope="col">Class Status</th>
                                            <th scope="col">Count</th>
                                            </thead>
                                            <tbody>
                                            {
                                                sectionReport.map((data, index) => (
                                                    <tr key={index} style={data._id == 'Makeup' ? { color: 'red' } : data._id == 'Total' ? { color: 'green' } : { } }>
                                                        <td>{data._id}</td>
                                                        <td>{data.count}</td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </Table>
                                    )
                                }
                            </div>
                            <div style={{marginTop: '20px'}}>

                            </div>
                        </Row>
                        <hr className='my-10' style={{border:' 1px solid #a69a9a', marginTop: '20px'}}/>
                    </CardBody>
                </Card>
            </div>
          </div>
        </Row>
        </Container>
          <Modal
              className='modal-dialog-centered'
              size='lg'
              isOpen={notificationCallModal}
              toggle={() =>
                  this.toggleModal('notificationCallModal')
              }
          >
              <div className='modal-body p-0'>
                  <Card className='bg-secondary shadow border-0'>
                      <CardHeader className='bg-transparent pb-5'>
                          <div className='text-muted text-center'>
                              <small>{'Message'}</small>
                          </div>
                      </CardHeader>
                      <CardBody className='px-lg-5 py-lg-5'>
                          <Form role='form'>
                              <FormGroup className={notesError ? 'has-danger' : ''}>
                                  <InputGroup className='input-group-alternative'>
                                     <textarea
                                         placeholder='Enter Message'
                                         cols={100}
                                         rows={5}
                                         value={twillioMessage}
                                         onChange={(e) =>
                                             this.setState({ twillioMessage: e.target.value })
                                         }
                                     />
                                  </InputGroup>
                              </FormGroup>

                              <div className="row text-right">
                                  <div className="ml-auto" style={{marginRight: '10px'}}>
                                      <Button
                                          color="primary"
                                          type="button"
                                          onClick={() => this.setState({ notificationCallModal: !notificationCallModal })}
                                      >
                                          Cancel
                                      </Button>
                                      <Button
                                          disabled={!!loading}
                                          color="primary"
                                          type="button"
                                          onClick={() => this.replyMessageResponse()}
                                      >
                                          Send Message
                                      </Button>
                                  </div>
                              </div>
                          </Form>
                      </CardBody>
                  </Card>
              </div>
          </Modal>
      </>
    );
  }
}

export default StudentManagement;
